// TODO - Documentar
import React from 'react'
import { EditModelValueHook, StateModelParameterHook } from '../hooks/ModelParameterHooks'
import * as con from "../../GlobalConstants"
import { CellNumeric, CellPercentage } from '../../templates/tables/Cells'
import {  isSorted } from '../../GlobalFunctions'
import { GenericElement } from '../../templates/FormElement'
import { ClickOutsideAction } from '../../templates/hooks/ClickOutiseAction'
import { FaMinus, FaPlus } from 'react-icons/fa'
import {  RefApplicationParameterHook } from '../hooks/ApplicationParametersHooks'


function ForwardCurveTable() {

    // Forward Curve
    const [forwardCurveActive, setForwardCurveActive] = StateModelParameterHook(con.CUSTOM_FORWARD_CURVE_ACTIVE)
    const setCustomForwardCurve = EditModelValueHook(con.CUSTOM_FORWARD_CURVE)

    // Market Forward Curve
    const forwardCurve = RefApplicationParameterHook(con.FORWARD_CALCULATOR_FORWARD_CURVE)

    // Editing outside Hook (for sorting)
    const tableRef = ClickOutsideAction(() => sortForwardCurve())


    const updateForwardCurveDay = (i, newVal) =>
    {

      newVal = parseInt(newVal)

      if(isNaN(newVal))
        newVal=0
      
      forwardCurve[con.DAYS][i] = newVal
      setCustomForwardCurve({...forwardCurve})
    } 

    const updateForwardCurvePercentage = (i, type, newVal) =>
    {
      forwardCurve[type][i] = newVal
      setCustomForwardCurve({...forwardCurve})
    } 

    const sortForwardCurve = () =>
    {

      if(forwardCurve !== null)
      {
        // Checks if needs to be sorted
        if(!isSorted(forwardCurve[con.DAYS]))
        {
          forwardCurve[con.BUY].sort((a, b) => forwardCurve[con.DAYS][forwardCurve[con.BUY].indexOf(a)] - forwardCurve[con.DAYS][forwardCurve[con.BUY].indexOf(b)]);
          forwardCurve[con.SELL].sort((a, b) => forwardCurve[con.DAYS][forwardCurve[con.SELL].indexOf(a)] - forwardCurve[con.DAYS][forwardCurve[con.SELL].indexOf(b)]);
          forwardCurve[con.DAYS].sort((a, b) => a-b);
          setCustomForwardCurve({...forwardCurve})
        }
      }

    }

    // Table Controls
    const addRow = (i) =>
    {

      let day = forwardCurve[con.DAYS][0]
      let buy = forwardCurve[con.BUY][0]
      let sell = forwardCurve[con.SELL][0]

      if(i === forwardCurve[con.DAYS].length -1)
      {
        day = forwardCurve[con.DAYS][i]*2
        buy = forwardCurve[con.BUY][i]
        sell = forwardCurve[con.SELL][i]
      }
      else
      {
        day = (forwardCurve[con.DAYS][i] + forwardCurve[con.DAYS][i+1])/2
        buy = (forwardCurve[con.BUY][i] + forwardCurve[con.BUY][i+1])/2
        sell = (forwardCurve[con.BUY][i] + forwardCurve[con.SELL][i+1])/2

      }


      forwardCurve[con.BUY].splice(i+1, 0, buy);
      forwardCurve[con.SELL].splice(i+1, 0, sell);
      forwardCurve[con.DAYS].splice(i+1, 0, day);

      setCustomForwardCurve({...forwardCurve})

    }

    const removeRow = (i) =>
    {
      forwardCurve[con.BUY] = forwardCurve[con.BUY].filter((_, j) => j !== i);
      forwardCurve[con.SELL] = forwardCurve[con.SELL].filter((_, j) => j !== i);
      forwardCurve[con.DAYS] = forwardCurve[con.DAYS].filter((_, j) => j !== i);

      setCustomForwardCurve({...forwardCurve})
    }

    


  return (
    <div className="compact" style={{width : "98%"}}>
    <h4>{forwardCurveActive ? "Curva Forward Personalizada" : "Curva Forward Vinco"}</h4> 
    <div className="smallParameterBox" >        
    
        < GenericElement type={con.BOOLEAN}
            id={con.CUSTOM_FORWARD_CURVE_ACTIVE} 
            value={forwardCurveActive} 
            setValue={setForwardCurveActive} 
            label={'Activar Personalizada'}                                    
          />  
    
    </div>   
    <div className="contenedor-tabla">

        <div className="flex-tabla">
            <div className="flex-fila header-row">
                <div className="flex-celda ">DIAS</div>
                <div className={`flex-celda`}>COMPRA</div>
                <div className={`flex-celda`}>VENTA</div>
                {forwardCurveActive ? <div className={`flex-celda`}>COMANDOS</div> :<></>}               
            </div>
        </div>

       <div ref={tableRef} className="flex-tabla"> 
            {

              forwardCurve[con.DAYS].map((d,i) => {
                return(

                <div key={i} className={`flex-fila hoverable-row ${(i % 2 === 0)? "even-row":"uneven-row"}`}>            
                    <div className="flex-celda"><CellNumeric value={d} editing={forwardCurveActive} setValue={(newVal) => updateForwardCurveDay(i,newVal)} heavyEffect={true}/></div>
                    <div className={`flex-celda`}><CellPercentage value={forwardCurve[con.BUY][i]} editing={forwardCurveActive} setValue={(newVal) => updateForwardCurvePercentage(i, con.BUY, newVal)} heavyEffect={true}  decimalScale={2}/></div>
                    <div className={`flex-celda`}><CellPercentage value={forwardCurve[con.SELL][i]} editing={forwardCurveActive} setValue={(newVal) => updateForwardCurvePercentage(i, con.SELL, newVal)} heavyEffect={true}  decimalScale={2}/></div>
                    {forwardCurveActive ?
                    <div className={`flex-celda comandos`}>        
                            <FaPlus style={{cursor: "pointer"}} 
                                                onClick={() => addRow(i)}
                                                title="Agregar Fila"/>
                            <FaMinus className={forwardCurve[con.DAYS].length === 0 ? 'disabled' : ""} style={{cursor: "pointer"}} 
                                                  onClick={() => removeRow(i)}
                                                  title="Eliminar Fila"
                                                  />                                        
                  </div> : null}
                </div>

                )
              })
             }            
        </div>
        
    </div>
</div>
  )
}

export default ForwardCurveTable