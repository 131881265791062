// TODO - Documentar
import React from 'react'
import Login from '../components/login/Login'


import * as con from "../GlobalConstants"


import "./LoginPage.css"
import logo from "./../img/logo-vinco-dark.png"
import { RefApplicationParameterHook, RefNestedApplicationParameterHook } from '../components/hooks/ApplicationParametersHooks'
import { RefAuthenticationParameterHook } from '../components/hooks/AuthenticationHook'

function LoginPage() {


    // Server
    const server = RefApplicationParameterHook(con.SERVER)
    const loggedIn = RefNestedApplicationParameterHook(con.LOGGED_IN, con.STATUS)

    // Need to login again
    const needsToLoginAgain = RefAuthenticationParameterHook(con.NEEDS_TO_LOGIN_AGAIN)
  
    return (
        
        <div className="noCarret" style={{height : "100vh"}} >         
                <div style={{marginBottom : "80px", paddingTop: "30px"}}>
                    <img src={logo} alt="Logo" width="auto" height="100"/> 
                </div>

                <div className={`midBox ${(server[con.STATUS] === con.ERROR) && "serverDown"}`} style={{height : "250px"}}>
                    <Login/>                   
                </div>
            { loggedIn === con.WRONG_CREDENTIALS ? 
                <div style={{marginBottom : "5vh"}}>
                    <div>
                        <h3>El usuario o contraseña no es correcto. Por favor vuelva a intentarlo.</h3>       
                    </div> 
                </div> : <></>

                }
            { needsToLoginAgain ? 
            <div style={{marginBottom : "5vh"}}>
                <div>
                    <h3>Su sesión ha expirado. Por favor vuelva a ingresar.</h3>       
                </div> 
            </div> : <></>

            }
            { server[con.STATUS] === con.ERROR ? 
              <div style={{marginBottom : "5vh"}}>
                <div>
                    <h3>Lo sentimos, en este momento la página no se encuentra disponible.</h3>       
                </div> 
                <div>            
                    <h3> Por favor comuniquesé directamente con su asesor vinco.</h3>
                </div>
              </div> : <></>

            }

            </div>
        
    )
}

export default LoginPage
