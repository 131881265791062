import React, { useEffect } from 'react'
import { TransactionsSummary } from '../components/transactionsSummary/TransactionsSummary'
import { CurrentPositionParametersMobile } from '../components/parameters/CurrentPositionParameters'
import { setApplicationParameter } from '../store/actions/applicationParameters'
import * as con from "../GlobalConstants"


const firstTableColumns = [
    { [con.ID]: con.PERIOD },
    { [con.ID]: con.EXPOSURE_AMOUNT },
    { [con.ID]: con.COVERAGE_AMOUNT },
    { [con.ID]: con.VAR_RATE },
    { [con.ID]: con.COUNTER_COVERAGE_AMOUNT },
    { [con.ID]: con.NET_EXPOSURE_AMOUNT },
    { [con.ID]: con.COVERAGE_PERCENTAGE }
]

const secondTableColumns = [
    { [con.ID]: con.PERIOD },
    { [con.ID]: con.NET_EXPOSURE_AMOUNT },
    { [con.ID]: con.COVERAGE_PERCENTAGE },
    { [con.ID]: con.VAR_RATE },
    { [con.ID]: con.EFFECTIVE_RATE },
    { [con.ID]: con.VAR_X_USD },
    //{ [con.ID]: con.EXECUTION_COUNTER_BUY_PUT_VAR } // "Var oportu. (millones)"
]

function MobileCurrentPositionPage() {

    useEffect(() => {
        setApplicationParameter(con.VISUALIZATION_PERIODICITY, con.PERIODICITY_MONTH)
        setApplicationParameter(con.ONLY_NON_ZERO_ROWS, true)
    }, [])

    return (
        <div style={{ minHeight: con.MOBILE_USABLE_SCREEN_SIZE + "vh" }}>
            
            {/* Primera tabla de TransactionsSummary */}
            <div style={{ paddingLeft: '15px', paddingRight: '15px' , paddingTop: '30px'}}>
                <TransactionsSummary selectedColumns={firstTableColumns} />
            </div>

            {/* Selector del escenario */}
            <div style={{ marginTop: "20px" }}>
                <CurrentPositionParametersMobile />
            </div>

            {/* Segunda tabla de TransactionsSummary */}
            <div style={{ paddingLeft: '15px', paddingRight: '15px', marginTop: "20px" }}>
                <TransactionsSummary selectedColumns={secondTableColumns} />
            </div>

        </div>
    )
}

export default MobileCurrentPositionPage
