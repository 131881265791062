// TODO - Documentar
import React from 'react'
import Oval from 'react-loading-icons/dist/components/oval'

import "./Loading.css"

function LoadingIconSmall() {
    return (
        <div className="smalIconContainer" >
            <Oval height="20px"/>
        </div>
    )
}

export default LoadingIconSmall
