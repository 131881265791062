// Multiple Bar Chart Hook based on D3

/**
 * Data must be passed with the following structure
 * Array of objects. Each object must include:
 *  - name : string with name
 *  - values : array of values. Must be the length of the numBars parameter.
 *  - labels : array of labels. Must be the length of the numBars parameter.  
 */

import {useMemo, useRef } from 'react'
import { MultiBarChart } from '../classes/MultiBarChart';


 /* Hook returns:
     - Reference for the rendered plot
     - Object of type ChartClass
 */
export const MultiBarChartHook = ({parameters}) =>
{
    
    const objectReference = useRef()
    const initialParameters = useRef(parameters)
    const chart = useMemo(() => new MultiBarChart(initialParameters.current, objectReference),[])    

    return([objectReference, chart])

}
    




