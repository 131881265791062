// TODO - Documentar
// Transaction Summary Hook
import {useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import config from "../../config/initial_parameters.json"

import * as con from "../../GlobalConstants"
import { sum } from '../../GlobalFunctions'
import { getSurrogateUserDictStructure, getSurrogateUserDictStructureWithObject, getSurrogateUserDictStructureWithValue } from '../../store/actions/surrogateTransactions'
import { generateBandsFromModelParameters, getPercentageObjectiveBand } from '../../utils/bandFunctions'
import { getAverageExpiryDay, processTransactionsWithSummary } from '../../utils/transactionFunctions'
import { translateBands } from '../../utils/translateFunctions'
import { RefApplicationParameterHook, RefModelApplicationParameterHook, RefNestedApplicationParameterHook, RefSPOT } from './ApplicationParametersHooks'
import { AllModelParameterHook} from './ModelParameterHooks'
import { ScenariosHook } from './ScenariosHook'

export const AllSurrogateTransactionsSummariesHook = () => {

  // Surrogate transactions
  // -----------------  
  const allSurrogateTransactions = AllSurrogateTransactionsHook()


  // Model Parameters
  // For global scenario variables
  const modelParameters = AllModelParameterHook()
  const spot = RefSPOT()
  const currentDate = RefApplicationParameterHook(con.CURRENT_DATE)

  // Application Parameter
  const transactionsLoaded = RefApplicationParameterHook(con.ALL_SURROGATE_TRANSACTIONS_LOADED)
  const modelApplicationParameters = RefModelApplicationParameterHook()
  
  // Scenarios and Selected Scenario
  const selectedScenarioId = RefApplicationParameterHook(con.SELECTED_SCENARIO)
  const scenarios = ScenariosHook()
  
  // Hooks variables
  const [computing, setComputing] = useState(() => true)
  const [allSummaries, setAllSummaries] = useState(() => getSurrogateUserDictStructure())
  
                          
    // Transactions or model parameters change
    useEffect(() => {
            
      if(transactionsLoaded)
      {
        // Computing
        setComputing(true)
        
        let allSummariesTemp = getSurrogateUserDictStructure()

        let completed = 0
        let totalLines = sum(Object.values(allSummariesTemp).map(ob => Object.keys(ob).length))

        Object.keys(allSummariesTemp).forEach(userId => 
          {
          
          Object.keys(allSummariesTemp[userId]).forEach(lineId =>
            {       
                new Promise(function (success, error) {
                    
                  setTimeout(async () => {
                    
                    // Function for updating the summary (async to not block excecution)
                    let [transSummary, summaryRow] = await processTransactionsWithSummary(
                                                                                currentDate,
                                                                                spot,
                                                                                scenarios[selectedScenarioId].gen_fun,
                                                                                allSurrogateTransactions[userId][lineId][con.MODEL_PARAMETERS], 
                                                                                allSurrogateTransactions[userId][lineId][con.EXPOSURES], 
                                                                                allSurrogateTransactions[userId][lineId][con.COVERAGES_FWD],
                                                                                allSurrogateTransactions[userId][lineId][con.COVERAGES_OPTION],
                                                                                allSurrogateTransactions[userId][lineId][con.ACCOUNTS],
                                                                                modelApplicationParameters)
            
                    success([transSummary, summaryRow])
                  
                  
                  }, con.UI_TIMEOUT);
                  
                }).then(([transSummary, summaryRow]) => {
                              
                  // Sets the summaries
                  allSummariesTemp[userId][lineId] = {[con.SUMMARY_TABLE] : transSummary,
                                                      [con.SUMMARY_ROW] : summaryRow,
                                                      [con.HAS_TRANSACTIONS] : transSummary.length > 0
                                                    }

                  completed += 1                  
                  if(completed === totalLines)
                  {
                    setComputing(false)
                    setAllSummaries(allSummariesTemp)
                    
                  }
                              
                })

        })})

    }
            

    }, [allSurrogateTransactions, modelParameters, scenarios, selectedScenarioId, transactionsLoaded, currentDate, spot, modelApplicationParameters])


    return([allSummaries, computing])

}


export const AllSurrogateTransactionsExpiryDates = () => {

  // Surrogate transactions
  // -----------------  
  const allSurrogateTransactions = AllSurrogateTransactionsHook()
  

  // Aplication Parameters
  const exposuresLoaded = RefNestedApplicationParameterHook(con.SURROGATE_TRANSACTIONS_LOADED, con.EXPOSURES)


  // Model Parameters
  const currentDate = RefApplicationParameterHook(con.CURRENT_DATE)

  const [expiryDays, setExpiryDays] = useState(() => getSurrogateUserDictStructureWithValue(0))




  useEffect(() => {

    if(exposuresLoaded[con.STATUS] === con.OK)
    {
      
      let expDays = getSurrogateUserDictStructureWithValue(0)

      Object.keys(allSurrogateTransactions).forEach(userId => {
        Object.keys(allSurrogateTransactions[userId]).forEach(lineId => {
          expDays[userId][lineId] = getAverageExpiryDay(currentDate, allSurrogateTransactions[userId][lineId][con.EXPOSURES])
        })
      })

      setExpiryDays(expDays)
  }
    
  }, [currentDate, allSurrogateTransactions, exposuresLoaded])
  

  return(expiryDays)
  
}

export const AllSurrogateTransactionsHook = () =>
{
  const trans = useSelector((state) => state[con.STORE][con.REDUCER_SURROGATE_USERS_TRANSACTIONS])
  return(trans)
}


export const AllSurrogateObjectiveBands = () => 
{

  // Surrogate transactions
  // -----------------  
  const allSurrogateTransactions = AllSurrogateTransactionsHook()

  // Aplication Parameters
  const modelParametersLoaded = RefNestedApplicationParameterHook(con.SURROGATE_TRANSACTIONS_LOADED, con.MODEL_PARAMETERS)

  // Return Objecect
  const [objectiveBands, setObjectiveBands] = useState(() => getSurrogateUserDictStructureWithObject({[con.OBJECTIVE_BAND] : translateBands(con.MID_BAND),
                                                                                                      [con.OBJECTIVE_BAND_PERCENTAGE] : 0.4}))
  // Band
  const selectedBand = RefApplicationParameterHook(con.SELECTED_BAND)

  useEffect(() => {

        
    if(modelParametersLoaded[con.STATUS] === con.OK)
    {    

      
      let newObjectiveBands = getSurrogateUserDictStructureWithObject({[con.OBJECTIVE_BAND] : translateBands(con.MID_BAND),
                                                                      [con.OBJECTIVE_BAND_PERCENTAGE] : 0.4})
    
        Object.keys(allSurrogateTransactions).forEach(userId => {
          Object.keys(allSurrogateTransactions[userId]).forEach(lineId => {

            let modelParameters = allSurrogateTransactions[userId][lineId][con.MODEL_PARAMETERS]
            let bandsValues = modelParameters[con.MID_BAND_VALUES]
            let bandWidth = modelParameters[con.BAND_WIDTH]

            if(bandsValues === undefined)
              bandsValues = generateBandsFromModelParameters(modelParameters)
            if(bandWidth === undefined)
              bandWidth = config.default_model_parameters[con.BAND_WIDTH]          

            let summaryObjBand = getPercentageObjectiveBand(bandsValues, bandWidth, selectedBand)

            newObjectiveBands[userId][lineId][con.OBJECTIVE_BAND_PERCENTAGE] = summaryObjBand
            newObjectiveBands[userId][lineId][con.OBJECTIVE_BAND] = translateBands(selectedBand)
          })
        })

        setObjectiveBands(newObjectiveBands)
    }

  }, [allSurrogateTransactions, modelParametersLoaded, selectedBand])

  return(objectiveBands)
}
