// Local Storage Wrapper to deal with different types of objects
// Global constants
import * as con from "./../GlobalConstants"

export const setLocalStorageItem = (key, value, type = con.TEXT) =>
{

    switch (type) {
        case con.TEXT:  
        case con.NUMERIC:
        case con.DATE:
        case con.DATE_TIME:
        case con.MONEY:
        case con.MONEY_MILLIONS:
        case con.MONEY_THOUSANDS:
        case con.PERCENTAGE:
        case con.EMAIL:
        case con.PASSWORD:
        case con.BOOLEAN:
        case con.SPOT_TYPE:
            localStorage.setItem(key, value)            
            break;
        
        case con.OBJECT:
            localStorage.setItem(key, JSON.stringify(value))            
            break;
                    
        default:
            console.warn(`Type ${con.NAV_BAR} not supported for local storage save. Item will be saved as text.`)
            localStorage.setItem(key, value)
            break;
    }

}

export const getLocalStorageItem = (key, type = con.TEXT) =>
{
    let value = null

    switch (type) {
        case con.TEXT:  
        case con.NUMERIC:
        case con.DATE:
        case con.DATE_TIME:
        case con.MONEY:
        case con.MONEY_MILLIONS:
        case con.MONEY_THOUSANDS:
        case con.PERCENTAGE:
        case con.EMAIL:
        case con.PASSWORD:
        case con.BOOLEAN:
        case con.SPOT_TYPE:
            value = localStorage.getItem(key)            
            break;
        
        case con.OBJECT:
            value = localStorage.getItem(key)
            if(value !== null)
                value = JSON.parse(value)            
            break;
                    
        default:
            console.warn(`Type ${con.NAV_BAR} not supported for local storage get. Item will be retrieved as text.`)
            value = localStorage.getItem(key)
            break;
    }

    return(value)

}

export const removeLocalStorageItem = (key) =>
{
    localStorage.removeItem(key)
}

export const removeAllStorageItems = () =>
{
    localStorage.clear()
}