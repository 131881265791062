// TODO - Documentar
import React, { useCallback, useEffect, useReducer } from 'react';
import { useState } from 'react'

// Modulos propios
import * as con from "../../GlobalConstants"
import { addDays, daysBetweenDates, formatDate, getToday, parseDate} from "../../utils/dateFunctions"
import { translateCalculatorParameter, translateParameter } from '../../utils/translateFunctions';
import { CellText, GenericCell, GenericCellFromDict, StaticCompoundDoubleCell, StaticCompoundTripleCell, StaticSingleCell } from '../../templates/tables/Cells';
import { Button } from '@blueprintjs/core';
import { CoverageFWD } from '../../store/actions/transactions';
import { createNotification } from '../../templates/notifications/Notifications';
import { filterObjectByKeys } from '../../GlobalFunctions';
import { computeDevaluationFromForwardValueAndSpot, computeForwardValueFromDevaluationAndSpot, getGeneratorDevaluationFunctionByDay } from '../../utils/forwardFunctions';
import { RefApplicationParameterHook, RefNestedApplicationParameterHook } from '../hooks/ApplicationParametersHooks';
import { getApplicationParameter } from '../../store/actions/applicationParameters';
import Modal from '../../templates/popups/Modal';
import { TextAreaElement } from '../../templates/FormElement';
import LoadingIcon from '../../templates/loading/LoadingIcon';
import { getModelParameter } from '../../store/actions/modelParameters';
import { isValueValid } from '../../templates/Types';


// Constants


/**
 * Static Column with the rows names
 */
 export const ForwardsTableColumnNames = ({shortColumnNames=false}) => {
  return (          
            <div className="tightColumn" style={{maxWidth : "45vw"}}>
                  <StaticSingleCell content={shortColumnNames ? "Cotización" : "Cotización"} isTitle={true} />
                  <StaticSingleCell content={shortColumnNames ? "Instrumento" : "Instrumento"} evenRow={false}/>
                  <StaticSingleCell content={shortColumnNames ? "Tipo" : "Tipo"} evenRow={true}/>
                  <StaticSingleCell content={shortColumnNames ? "Fecha de Ejecución" : "Fecha de Ejecución"} evenRow={false}/>
                  <StaticSingleCell content={shortColumnNames ? "SPOT" : "SPOT (Desmarque para SPOT manual)"} evenRow={true}/>
                  <StaticSingleCell content={shortColumnNames ? "Nominal" : "Nominal"} evenRow={false}/>
                  <StaticSingleCell content={shortColumnNames ? "Dias Para Vencimiento" : "Dias Para Vencimiento"} evenRow={true}/>
                  <StaticSingleCell content={shortColumnNames ? "Fecha Vencimiento" : "Fecha Vencimiento"} evenRow={false}/>
                  <StaticCompoundDoubleCell contentLeft={shortColumnNames ? "Devaluación Referencia" : "Devaluación Referencia"} contentUpperRight={"Tasa FWD"} contentBottomRight={"Devaluación"}  evenRow={true}/>                       
                  <StaticCompoundTripleCell contentLeft={shortColumnNames ? "Cotización Banco" : "Cotización Banco"} contentUpperRight={"SPOT Banco"}  contentMiddleRight={"Tasa FWD"} contentBottomRight={"Devaluación"}  evenRow={true}/>                  
                  <StaticSingleCell content={shortColumnNames ? "Puntos Forward de Ref." : "Puntos Forward de Referencia"} evenRow={false}/>
                  <StaticSingleCell content={shortColumnNames ? "Diferencia en Puntos FWD" : "Diferencia en Puntos FWD (Negativo = Costo)"} evenRow={true}/>
                  <StaticSingleCell content={shortColumnNames ? "Dif Curva Fwd Banco (PxD)" : "Dif Curva Fwd Banco (Pesos x Dólar)"} evenRow={false}/>
                  <StaticSingleCell content={shortColumnNames ? "Dif Spot Banco (PxD)" : "Dif Spot Banco (Pesos x Dólar)"} evenRow={true}/>
                  <StaticSingleCell content={shortColumnNames ? "Dif PxD total" : "Dif Pesos x Dólar total"} evenRow={false}/>
                  <StaticSingleCell content={shortColumnNames ? "Dif Total Referencia - Banco" : "Dif Total Referencia - Banco"} evenRow={true}/>
                  <StaticSingleCell content={shortColumnNames ? "Comandos" : "Comandos"} evenRow={false}/>                        
        </div>
  )
}


// General Functions
// Compute non editable
const computeNonEditable = (parameters) =>
{


  // Multiplicative Constant
  const i = parameters[con.CALCULATOR_INSTRUMENT_TYPE] === con.SELL ? -1 : 1

  const tempFWDPoints = i*(parameters[con.CALCULATOR_QUOTED_FORWARD_RATE] - parameters[con.CALCULATOR_LOCAL_SPOT])

  const tempDifPuntosFWD = i*(parameters[con.CALCULATOR_REFERENCE_DEVALUATION] - parameters[con.CALCULATOR_QUOTED_DEVALUATION])
  const tempDifSpot = i*(parameters[con.CALCULATOR_LOCAL_SPOT] - parameters[con.CALCULATOR_BANK_SPOT])
  const tempDifPesosDevaluacion = (parameters[con.CALCULATOR_BANK_SPOT]) * ((1+parameters[con.CALCULATOR_REFERENCE_DEVALUATION])**(parameters[con.CALCULATOR_EXPIRY_DAYS]/con.DAYS_IN_YEAR_FORWARDS) - (1+parameters[con.CALCULATOR_QUOTED_DEVALUATION])**(parameters[con.CALCULATOR_EXPIRY_DAYS]/con.DAYS_IN_YEAR_FORWARDS))
  const tempDifPesosTotal = tempDifSpot + tempDifPesosDevaluacion
  const tempPyG = tempDifPesosTotal*parameters[con.CALCULATOR_NOMINAL]
  

  return({
        [con.CALCULATOR_FWD_POINTS] : tempFWDPoints,
        [con.CALCULATOR_FWD_POINT_DIFF] : tempDifPuntosFWD, 
        [con.CALCULATOR_SPOT_DIFF] : tempDifSpot,
        [con.CALCULATOR_COP_DEVALUATION_DIFF] : tempDifPesosDevaluacion, 
        [con.CALCULATOR_TOTAL_COP_DIFF] : tempDifPesosTotal, 
        [con.CALCULATOR_P_AND_G] : tempPyG,
    })
}


// Functions for computing circular dependencies
// -------------------------------------
const computeExpiryDays = (params) =>
{
  return(daysBetweenDates(params[con.CALCULATOR_EXECUTION_DATE], params[con.CALCULATOR_EXPIRATION_DATE], false))
}

const computeExpirationDate = (params) =>
{
  return(addDays(params[con.CALCULATOR_EXECUTION_DATE], params[con.CALCULATOR_EXPIRY_DAYS]))
}

const computeQuotedDevaluation = (params) =>
{

  let quotedForwardRate = params[con.CALCULATOR_QUOTED_FORWARD_RATE]

  if(quotedForwardRate === undefined)
    quotedForwardRate = 0

  let dev = computeDevaluationFromForwardValueAndSpot(quotedForwardRate, params[con.CALCULATOR_BANK_SPOT], params[con.CALCULATOR_EXPIRY_DAYS])

  if(dev === undefined || isNaN(dev) || dev < 0)
    dev = 0

  return(dev)

}


const computeQuotedFWDRate = (params) =>
{    
  let val = computeForwardValueFromDevaluationAndSpot(params[con.CALCULATOR_QUOTED_DEVALUATION], params[con.CALCULATOR_BANK_SPOT], params[con.CALCULATOR_EXPIRY_DAYS]) 

  if(val === undefined || isNaN(val) || val < 0)
    val = 0

  return(val)
}


// Main Reducer for the column values
const reducer = (state, action) =>
  {

    let value = action.value

    let paramName = action.type
    
    let newVals = {...state}

    newVals[paramName] = value

    if([con.CALCULATOR_EDITING_DAYS, con.CALCULATOR_EDITING_QUOTED_FORWARD].includes(paramName))
        return(newVals)
      
    let devaluationExtractionFunction = getGeneratorDevaluationFunctionByDay(newVals[con.CALCULATOR_LOCAL_FORWARD_CURVE][con.DAYS], newVals[con.CALCULATOR_LOCAL_FORWARD_CURVE][newVals[con.CALCULATOR_INSTRUMENT_TYPE]])
   
    if([con.CALCULATOR_EXECUTION_DATE, con.CALCULATOR_EXPIRATION_DATE].includes(paramName)){
      if(!isValueValid(con.DATE, value))
        return(newVals)
      }

      switch (paramName) {

        case con.CALCULATOR_INSTRUMENT_TYPE: 

        newVals[con.CALCULATOR_REFERENCE_DEVALUATION] = devaluationExtractionFunction(newVals[con.CALCULATOR_EXPIRY_DAYS])     
        newVals[con.CALCULATOR_REFERENCE_FORWARD_RATE] = computeForwardValueFromDevaluationAndSpot(newVals[con.CALCULATOR_REFERENCE_DEVALUATION], newVals[con.CALCULATOR_LOCAL_SPOT], newVals[con.CALCULATOR_EXPIRY_DAYS])


          break
        case con.CALCULATOR_LOCAL_FORWARD_CURVE:

          newVals[con.CALCULATOR_REFERENCE_DEVALUATION] = devaluationExtractionFunction(newVals[con.CALCULATOR_EXPIRY_DAYS])      
          newVals[con.CALCULATOR_REFERENCE_FORWARD_RATE] = computeForwardValueFromDevaluationAndSpot(newVals[con.CALCULATOR_REFERENCE_DEVALUATION], newVals[con.CALCULATOR_LOCAL_SPOT], newVals[con.CALCULATOR_EXPIRY_DAYS])
          
          break
        case con.CALCULATOR_EXECUTION_DATE:

        
          // Checks derived values
          // Checks if new date is over expiry date
          if(value >= newVals[con.CALCULATOR_EXPIRATION_DATE])
          {
            newVals[con.CALCULATOR_EXPIRATION_DATE] = getToday()
          }

          newVals[con.CALCULATOR_EXPIRY_DAYS] = computeExpiryDays(newVals)
            
          newVals[con.CALCULATOR_REFERENCE_DEVALUATION] = devaluationExtractionFunction(newVals[con.CALCULATOR_EXPIRY_DAYS])        
          newVals[con.CALCULATOR_REFERENCE_FORWARD_RATE] = computeForwardValueFromDevaluationAndSpot(newVals[con.CALCULATOR_REFERENCE_DEVALUATION], newVals[con.CALCULATOR_LOCAL_SPOT], newVals[con.CALCULATOR_EXPIRY_DAYS])

          newVals[con.CALCULATOR_QUOTED_FORWARD_RATE] = computeQuotedFWDRate(newVals)

          break;

        case con.CALCULATOR_EXPIRATION_DATE:

            // Sets Value
            if(value <= newVals[con.CALCULATOR_EXECUTION_DATE])
            {
              value = getToday()
            }

            newVals[con.CALCULATOR_EXPIRATION_DATE] = value

            // Computes derived values
            newVals[con.CALCULATOR_EXPIRY_DAYS] = computeExpiryDays(newVals)
                        
            newVals[con.CALCULATOR_REFERENCE_DEVALUATION] = devaluationExtractionFunction(newVals[con.CALCULATOR_EXPIRY_DAYS]) 
            newVals[con.CALCULATOR_REFERENCE_FORWARD_RATE] = computeForwardValueFromDevaluationAndSpot(newVals[con.CALCULATOR_REFERENCE_DEVALUATION], newVals[con.CALCULATOR_LOCAL_SPOT], newVals[con.CALCULATOR_EXPIRY_DAYS])

            newVals[con.CALCULATOR_QUOTED_FORWARD_RATE] = computeQuotedFWDRate(newVals)

            break;

        case con.CALCULATOR_EXPIRY_DAYS:
        
            // Computes derived values
            newVals[con.CALCULATOR_EXPIRATION_DATE] = computeExpirationDate(newVals)      
            
            newVals[con.CALCULATOR_REFERENCE_DEVALUATION] = devaluationExtractionFunction(newVals[con.CALCULATOR_EXPIRY_DAYS])
            newVals[con.CALCULATOR_REFERENCE_FORWARD_RATE] = computeForwardValueFromDevaluationAndSpot(newVals[con.CALCULATOR_REFERENCE_DEVALUATION], newVals[con.CALCULATOR_LOCAL_SPOT], newVals[con.CALCULATOR_EXPIRY_DAYS])
                        
            newVals[con.CALCULATOR_QUOTED_FORWARD_RATE] = computeQuotedFWDRate(newVals)

            break;

        

        case con.CALCULATOR_LOCAL_SPOT:

            // Computes derived values

            newVals[con.CALCULATOR_REFERENCE_DEVALUATION] = devaluationExtractionFunction(newVals[con.CALCULATOR_EXPIRY_DAYS])
            newVals[con.CALCULATOR_REFERENCE_FORWARD_RATE] = computeForwardValueFromDevaluationAndSpot(newVals[con.CALCULATOR_REFERENCE_DEVALUATION], newVals[con.CALCULATOR_LOCAL_SPOT], newVals[con.CALCULATOR_EXPIRY_DAYS])
            

            break;

        case con.CALCULATOR_BANK_SPOT:

            if(newVals[con.CALCULATOR_EDITING_QUOTED_FORWARD])
              newVals[con.CALCULATOR_QUOTED_DEVALUATION] = computeQuotedDevaluation(newVals)
            else
              newVals[con.CALCULATOR_QUOTED_FORWARD_RATE] = computeQuotedFWDRate(newVals)

            break

        case con.CALCULATOR_QUOTED_DEVALUATION:
 
            // Computes derived values
            newVals[con.CALCULATOR_QUOTED_FORWARD_RATE] = computeQuotedFWDRate(newVals)
  
            break;

        
        case con.CALCULATOR_QUOTED_FORWARD_RATE:
          // Computes derived values
          newVals[con.CALCULATOR_QUOTED_DEVALUATION] = computeQuotedDevaluation(newVals)
                  
          break;

        default:
          break;
      }

      let newNonEditable = computeNonEditable(newVals)
      newVals = {...newVals, ...newNonEditable}

      return(newVals)

  }

/**
 * Column with the values of the forward Calculator
 */
export const ForwardsTableColumn = ({numInstrumento}) => {


    // Local Parameters
    const [comment, setComment] = useState( () => undefined)
    const [addingCoverage, setAddingCoverage] = useState(() => false)


    // Forward Curve
    const forwardCurve = RefApplicationParameterHook(con.FORWARD_CALCULATOR_FORWARD_CURVE)
  

    useEffect(() => {
      dispatch({ type: con.CALCULATOR_LOCAL_FORWARD_CURVE, value: {...forwardCurve} });
    }, [forwardCurve])
    
        

  // Fwd Insertion Loading Parameter
  const coverageInsertionLoadingParameter = RefNestedApplicationParameterHook(con.TRANSACTIONS_INSERTED, con.COVERAGES_FWD)

  const [columnValues, dispatch] = useReducer(reducer, {}, () => {

    let fwd = getApplicationParameter(con.FORWARD_CALCULATOR_FORWARD_CURVE)
    let sp = getModelParameter(con.SPREAD)

    let vals = {  [con.CALCULATOR_INSTRUMENT_TYPE] : con.BUY,
                  [con.CALCULATOR_EXECUTION_DATE] : getToday(),
                  [con.CALCULATOR_EXPIRY_DAYS] : 90,
                  [con.CALCULATOR_EXPIRATION_DATE] : addDays(getToday(), 90),
                  [con.CALCULATOR_REFERENCE_DEVALUATION] : getGeneratorDevaluationFunctionByDay(fwd[con.DAYS], fwd[con.BUY])(90),
                  [con.CALCULATOR_REFERENCE_FORWARD_RATE] :  computeForwardValueFromDevaluationAndSpot(getGeneratorDevaluationFunctionByDay(fwd[con.DAYS], fwd[con.BUY])(90), getApplicationParameter(con.SPOT), 90),
                  [con.CALCULATOR_NOMINAL] : 250000,
                  [con.CALCULATOR_BANK_SPOT] : getApplicationParameter(con.SPOT),
                  [con.CALCULATOR_QUOTED_DEVALUATION] :  getGeneratorDevaluationFunctionByDay(fwd[con.DAYS], fwd[con.BUY])(90),
                  [con.CALCULATOR_QUOTED_FORWARD_RATE] : computeForwardValueFromDevaluationAndSpot(getGeneratorDevaluationFunctionByDay(fwd[con.DAYS], fwd[con.BUY])(90), getApplicationParameter(con.SPOT), 90),
                  [con.CALCULATOR_LOCAL_SPOT] : getApplicationParameter(con.SPOT),
                  [con.CALCULATOR_LOCAL_FORWARD_CURVE] : fwd,
                  [con.CALCULATOR_LOCAL_SPREAD] : sp,
                  [con.CALCULATOR_EDITING_DAYS] : true,
                  [con.CALCULATOR_EDITING_QUOTED_FORWARD] : false
    }

    vals = {...vals, ...computeNonEditable(vals)}
    
    return(vals)
})


  // Spot Update to avoid callback infinite loop
  const updateSpot = useCallback( (val) => { dispatch({ type: con.CALCULATOR_LOCAL_SPOT, value: val })},[dispatch])
  

  return (            
            <div className="tightColumn bordered-column" >
              <Modal show={addingCoverage} doAccept={null} showCancel={false} doCancel={() => setAddingCoverage(false)}>      
                <div >
                    <h3>Agregar Cobertura</h3>
                    <TextAreaElement name={"Comentario"}
                                     value={comment} 
                                     setValue={setComment}
                                     disabled={coverageInsertionLoadingParameter[con.STATUS] === con.LOADING}/>                                      
                    <div className="confirmCancelParameterBox" style={{marginTop : "15px"}}>    
                    {
                      coverageInsertionLoadingParameter[con.STATUS] === con.LOADING
                      ? <LoadingIcon/>
                      : <div>                                    
                            <Button icon="upload" text="Agregar Cobertura" onClick={() =>{

                              let coverage = {
                                 [con.OPENING_DATE] :  formatDate(columnValues[con.CALCULATOR_EXECUTION_DATE]),
                                 [con.EXPIRATION_DATE] : formatDate(columnValues[con.CALCULATOR_EXPIRATION_DATE]),
                                 [con.AMOUNT] : columnValues[con.CALCULATOR_NOMINAL],                           
                                 [con.RATE] : columnValues[con.CALCULATOR_QUOTED_FORWARD_RATE],
                                 [con.OPENING_SPOT] : columnValues[con.CALCULATOR_BANK_SPOT],
                                 [con.COMMENT] : comment === null || comment === undefined ? "" : comment,
                                 [con.COVERAGE_TYPE] : columnValues[con.CALCULATOR_INSTRUMENT_TYPE],
                                 [con.STATE] : con.ACTIVE
                              }
                              CoverageFWD.add(coverage, (trans) => {
                                
                                CoverageFWD.addFromCalculatorAnnotation(trans[con.ID])
                                setAddingCoverage(false)
                                setComment(undefined)
                                createNotification("Cobertura agregada correctamente")})
      
                            }
                            
                            } />
                            <Button icon="cross" text="Cancelar" onClick={() => setAddingCoverage(false)} />
                        </div>
                    }
                    </div>
                </div>
                
            </Modal> 
                  <div className="flex-celda header-row">
                    <div className='cell-static-content'>{"Instrumento "+numInstrumento}</div>
                  </div> 

                    <CellText cellType={con.TEXT} value={"Forward"} editing={false} evenRow={false}/>                  
                    <GenericCellFromDict cellType={con.MULTIPLE_SELECTION}
                                  editing={true}
                                  dictValues={columnValues}
                                  dictId={con.CALCULATOR_INSTRUMENT_TYPE}
                                  setValue={(val) => dispatch({ type: con.CALCULATOR_INSTRUMENT_TYPE, value: val })}
                                  options={[con.BUY, con.SELL]}
                                  valueTranslator={translateParameter} 
                                  evenRow={true}
                                  />                    
                    <GenericCellFromDict cellType={con.DATE}
                                  editing={true} 
                                  dictValues={columnValues}
                                  dictId={con.CALCULATOR_EXECUTION_DATE}
                                setValue={(val) => dispatch({ type: con.CALCULATOR_EXECUTION_DATE, value: parseDate(val) })}
                                evenRow={false}/>                                  
                   <GenericCell cellType={con.SPOT_TYPE}                                                                
                                setValue={updateSpot}
                                evenRow={true}
                                />
                        <GenericCellFromDict cellType={con.MONEY}
                                    dictValues={columnValues}
                                    dictId={con.CALCULATOR_NOMINAL}
                                    editing={true}
                                    setValue={(val) =>  dispatch({ type: con.CALCULATOR_NOMINAL, value: val })}
                                    currency={con.MONEY_USD}
                                    allowDecimals={true}
                                    heavyEffect={true}
                                    evenRow={false}/>                            
                        <GenericCellFromDict cellType={con.NUMERIC}
                                      editing={columnValues[con.CALCULATOR_EDITING_DAYS]}
                                      dictValues={columnValues}
                                      dictId={con.CALCULATOR_EXPIRY_DAYS}
                                      setValue={(val) =>  dispatch({ type: con.CALCULATOR_EXPIRY_DAYS, value: val })}
                                      evenRow={true}
                                      heavyEffect={true}
                                      includeCheckBox={true}
                                      onEditingToggle={(val) => dispatch({ type: con.CALCULATOR_EDITING_DAYS, value: !val })}/>
                    <GenericCellFromDict cellType={con.DATE}
                                editing={!columnValues[con.CALCULATOR_EDITING_DAYS]} 
                                dictValues={columnValues}
                                dictId={con.CALCULATOR_EXPIRATION_DATE}
                               setValue={(val) => dispatch({ type: con.CALCULATOR_EXPIRATION_DATE, value: parseDate(val) })}
                               evenRow={false}
                               includeCheckBox={true}
                               onEditingToggle={(val) => dispatch({ type: con.CALCULATOR_EDITING_DAYS, value: val })}/>          
                  <GenericCellFromDict cellType={con.MONEY}
                                dictValues={columnValues}
                                dictId={con.CALCULATOR_REFERENCE_FORWARD_RATE}
                                editing={false}
                                heavyEffect={true}
                                currency={con.MONEY_COP}
                                allowDecimals={true} 
                                evenRow={true}/>   
                  <GenericCellFromDict cellType={con.PERCENTAGE}
                                dictValues={columnValues}
                                dictId={con.CALCULATOR_REFERENCE_DEVALUATION}
                                editing={false} 
                                heavyEffect={true}
                                decimalScale={2}
                                evenRow={false}/>

                    <GenericCellFromDict cellType={con.MONEY}
                                dictValues={columnValues}
                                dictId={con.CALCULATOR_BANK_SPOT}
                                editing={true}
                                setValue={(val) =>  dispatch({ type: con.CALCULATOR_BANK_SPOT, value: val })}
                                currency={con.MONEY_COP}
                                allowDecimals={true}
                                heavyEffect={true}
                                evenRow={true}/>    
                     <GenericCellFromDict cellType={con.MONEY}
                                dictValues={columnValues}
                                dictId={con.CALCULATOR_QUOTED_FORWARD_RATE}
                                editing={columnValues[con.CALCULATOR_EDITING_QUOTED_FORWARD]}
                                setValue={(val) =>  dispatch({ type: con.CALCULATOR_QUOTED_FORWARD_RATE, value: val })}
                                currency={con.MONEY_COP} 
                                heavyEffect={true}
                                allowDecimals={true} 
                                evenRow={false}
                                includeCheckBox={true}
                                onEditingToggle={(val) => dispatch({ type: con.CALCULATOR_EDITING_QUOTED_FORWARD, value: !val })}/>      
                    <GenericCellFromDict cellType={con.PERCENTAGE}
                                dictValues={columnValues}
                                dictId={con.CALCULATOR_QUOTED_DEVALUATION}
                                editing={!columnValues[con.CALCULATOR_EDITING_QUOTED_FORWARD]}
                                setValue={(val) =>  dispatch({ type: con.CALCULATOR_QUOTED_DEVALUATION, value: val })}
                                fixedRange={false}
                                decimalScale={2} 
                                evenRow={true}
                                heavyEffect={true}
                                includeCheckBox={true}
                                onEditingToggle={(val) => dispatch({ type: con.CALCULATOR_EDITING_QUOTED_FORWARD, value: val })}/>                              
                    <GenericCellFromDict cellType={con.MONEY}
                                dictValues={columnValues}
                                dictId={con.CALCULATOR_FWD_POINTS}
                                editing={false}
                                currency={con.MONEY_COP}
                                allowDecimals={true}
                                evenRow={false} /> 
                    <GenericCellFromDict cellType={con.PERCENTAGE}
                                dictValues={columnValues}
                                dictId={con.CALCULATOR_FWD_POINT_DIFF}
                                editing={false}
                                decimalScale={2}
                                evenRow={true} />
                    <GenericCellFromDict cellType={con.MONEY}
                                dictValues={columnValues}
                                dictId={con.CALCULATOR_COP_DEVALUATION_DIFF}
                                editing={false}
                                currency={con.MONEY_COP}
                                allowDecimals={true} 
                                evenRow={false}/>   
                    <GenericCellFromDict cellType={con.MONEY}                                
                                dictValues={columnValues}
                                dictId={con.CALCULATOR_SPOT_DIFF}                                
                                editing={false}
                                currency={con.MONEY_COP}
                                allowDecimals={true}
                                evenRow={true} />   
                    <GenericCellFromDict cellType={con.MONEY}
                                dictValues={columnValues}
                                dictId={con.CALCULATOR_TOTAL_COP_DIFF}   
                                editing={false}
                                currency={con.MONEY_COP} 
                                allowDecimals={true}
                                evenRow={false}/>     
                    <GenericCellFromDict cellType={con.MONEY}                                
                                dictValues={columnValues}
                                dictId={con.CALCULATOR_P_AND_G}  
                                editing={false}
                                currency={con.MONEY_COP}
                                allowDecimals={true} 
                                evenRow={true}/>        
                    <div className="flex-celda button-cell" title={areValuesValid(columnValues)[1]}>
                      <Button text="Agregar a Coberturas" 
                      disabled={!areValuesValid(columnValues)[0]}                      
                      onClick={() => setAddingCoverage(true)}/>
                  </div>                            
              </div>
    )
  }

const areValuesValid = (vals) =>
{

  // Checks Null in Batch
  const toCheckInBatch = filterObjectByKeys(vals, [con.CALCULATOR_EXECUTION_DATE, con.CALCULATOR_EXPIRATION_DATE, con.CALCULATOR_NOMINAL, con.CALCULATOR_QUOTED_FORWARD_RATE, con.CALCULATOR_LOCAL_SPOT])
  
  let resp =  [true, "Agregar Cobertura"]

  Object.keys(toCheckInBatch).forEach((key) =>
  {
    let val = toCheckInBatch[key]
    if(isNaN(val) || val === null || val === undefined)
    {
      resp = [false, `El parámetro: ${translateCalculatorParameter(key)} no puede ser nulo`]
    }
   
  })

  if(!resp[0])
    return(resp)

  // Checks Special Cases
  if(vals[con.CALCULATOR_NOMINAL] <= 0)
      return([false, "El valor Nominal no puede ser negativo"])


  return([true, "Agregar Cobertura"])
  

}

