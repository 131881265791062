// Actions for the advisor reducer

import axios from 'axios';
import * as types from "./types"
import store from '../store';
import * as con from "./../../GlobalConstants"
import { handleRequestError } from '../../utils/errorFunctions';
import { getToken, getVisualizedUser} from '../logic/supportFunctions';
import { createNotification } from '../../templates/notifications/Notifications';
import { getApplicationParameter, setApplicationParameter } from './applicationParameters';


// Get advisors
export const fetchAdvisors = () =>
{

    const token = getToken()

    const config = {
        headers : {
            "Authorization" : `Token ${token}`
        }
    }

    let url = con.rest_base_url + con.advisors_api 

    axios.get(url, config)
            .then(res => {

                store.dispatch({
                    type : types.SET_ALL_ADVISORS,
                    payload : res.data
                })                               
                
            })
            .catch(err => {                
                handleRequestError(err)})
}


export const addAdvisor = (idAdvisor) => {
    
    setApplicationParameter(con.ADVISOR_SAVED, {[con.STATUS] : con.LOADING})

    const token = getToken()

    const config = {
        headers : {
            "Authorization" : `Token ${token}`
        }
    }
    
    const data = {[con.USER] : getVisualizedUser()[con.ID], [con.ADVISOR] :  idAdvisor, [con.IS_MAIN_ADVISOR] : false }

    let url = con.rest_base_url + con.add_advisors_api 

    axios.post(url, data, config)
            .then(res => {

                store.dispatch({
                    type : types.ADD_ADVISOR,
                    payload : idAdvisor
                })
                
                setApplicationParameter(con.ADVISOR_SAVED, {[con.STATUS] : con.OK})
                
                
            })
            .catch(err => {            
                setApplicationParameter(con.ADVISOR_SAVED, {[con.STATUS] : con.ERROR})    
                handleRequestError(err)})

}



export const setMainAdvisor = (idAdvisor) => {
    
    setApplicationParameter(con.MAIN_ADVISOR_SAVED, {[con.STATUS] : con.LOADING})

    const token = getToken()

    const config = {
        headers : {
            "Authorization" : `Token ${token}`
        }
    }
    
    const data = {[con.USER] : getVisualizedUser()[con.ID], [con.ADVISOR] :  idAdvisor, [con.IS_MAIN_ADVISOR] : true }

    let url = con.rest_base_url + con.add_advisors_api 

    axios.post(url, data, config)
            .then(res => {

                store.dispatch({
                    type : types.SET_MAIN_ADVISOR,
                    payload : idAdvisor
                })
                
                setApplicationParameter(con.MAIN_ADVISOR_SAVED, {[con.STATUS] : con.OK})                                
                
            })
            .catch(err => {            
                setApplicationParameter(con.MAIN_ADVISOR_SAVED, {[con.STATUS] : con.ERROR})    
                handleRequestError(err)})

}




export const deleteAdvisor = (idAdvisor) => {
    
    setApplicationParameter(con.ADVISOR_SAVED, {[con.STATUS] : con.LOADING})

    const token = getToken()

    const config = {
        headers : {
            "Authorization" : `Token ${token}`
        }
    }
    
    const data = {[con.USER] : getVisualizedUser()[con.ID], [con.ADVISOR] :  idAdvisor }

    let url = con.rest_base_url + con.delete_advisors_api 

    

    axios.post(url, data, config)
            .then(res => {

                store.dispatch({
                    type : types.DELETE_ADVISOR,
                    payload : idAdvisor
                })
                setApplicationParameter(con.ADVISOR_SAVED, {[con.STATUS] : con.OK})
                createNotification("Asesor eliminado correctamente", con.NOTIFICATION_TIME_SHORT)
                
                
            })
            .catch(err => {            
                setApplicationParameter(con.ADVISOR_SAVED, {[con.STATUS] : con.ERROR})    
                handleRequestError(err)})

}


export const getMainAdvisor = (userID) => {

    let userDict = getApplicationParameter(con.SURROGATE_USER_DICT)
    return(userDict[userID][con.PROFILE][con.MAIN_ADVISOR])
}

export const getMainAdvisorName = (userID) => {

    let userDict = getApplicationParameter(con.SURROGATE_USER_DICT)
    let mainAdvisorId = userDict[userID][con.PROFILE][con.MAIN_ADVISOR]
    let advisors = store.getState()[con.STORE][con.REDUCER_ADVISORS]
    
    let name = "Sin Asignar"
    if(mainAdvisorId !== null)
    {
        let mainAdvisor = advisors.find((adv) => `${adv[con.ID]}` === `${mainAdvisorId}`)
        name = mainAdvisor[con.NAME_SHORT]
    }
    
    return(name)
}


