import React, { useState, useEffect } from 'react';
import "../../templates/Templates.css";
import "../../templates/tables/Table.css";
import "../ComponentsGlobalStyle.css";
import HistoricalDataRow from './HistoricalDataRow';
import { formatAccordingToType } from '../../templates/Types';  
import * as con from '../../GlobalConstants';  

import chevron from '../../img/icons/chevron.png';
import doubleChevron from '../../img/icons/double-chevron.png';

const calculateRecordsPerPage = (width) => {
    if (width <= 1480) {
        return 6;  
    } else {
        return 9;  
    }
};

const calculateCellStyles = (width) => {
    if (width <= 1480) {
        return {
            fontSize: '15px',
            padding: '5px',
            minWidth: '100px',
        };
    } else {
        return {
            fontSize: '19px',
            padding: '10px',
            minWidth: '130px',
        };
    }
};

function HistoricalDataTable({ fixedSize, data }) {
    const [currentPage, setCurrentPage] = useState(1);
    const [recordsPerPage, setRecordsPerPage] = useState(calculateRecordsPerPage(window.innerWidth));
    const [cellStyles, setCellStyles] = useState(calculateCellStyles(window.innerWidth));

    useEffect(() => {
        const adjustRecordsPerPage = () => {
            setRecordsPerPage(calculateRecordsPerPage(window.innerWidth));
            setCellStyles(calculateCellStyles(window.innerWidth));  
        };
        
        window.addEventListener('resize', adjustRecordsPerPage);
        return () => window.removeEventListener('resize', adjustRecordsPerPage);
    }, []);

    useEffect(() => {
        setCurrentPage(1);
    }, [data]);

    if (!data || data.length === 0) {
        return <div>No hay datos disponibles</div>;
    }

    const indexOfLastRecord = currentPage * recordsPerPage;
    const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
    const currentRecords = data.slice(indexOfFirstRecord, indexOfLastRecord);
    const totalPages = Math.ceil(data.length / recordsPerPage);

    const handleFirstPage = () => setCurrentPage(1);
    const handlePreviousPage = () => setCurrentPage(prevPage => Math.max(prevPage - 1, 1));
    const handleNextPage = () => setCurrentPage(prevPage => Math.min(prevPage + 1, totalPages));
    const handleLastPage = () => setCurrentPage(totalPages);

    return (
        <div>
            <div 
                className="pagination" 
                style={{ 
                    textAlign: 'center', 
                    marginBottom: '14px',  
                    fontSize: cellStyles.fontSize,  
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                }}
            >
                <button 
                    onClick={handleFirstPage} 
                    disabled={currentPage === 1} 
                    style={{
                        background: 'none',
                        border: 'none',
                        cursor: 'pointer',
                        fontSize: 'inherit',
                    }}
                >
                    <img src={doubleChevron} alt="Primera página" style={{ width: '16px', height: '16px', transform: 'scaleX(-1)', filter: 'invert(100%)' }} />
                </button>
                <button 
                    onClick={handlePreviousPage} 
                    disabled={currentPage === 1} 
                    style={{
                        background: 'none',
                        border: 'none',
                        cursor: 'pointer',
                        fontSize: 'inherit',
                    }}
                >
                    <img src={chevron} alt="Página anterior" style={{ width: '16px', height: '16px', transform: 'scaleX(-1)', filter: 'invert(100%)' }} />
                </button>
                <span style={{ margin: '0 10px', color: 'white', lineHeight: '16px', marginTop: '-4px' }}>
                    Página {currentPage} de {totalPages}
                </span>
                <button 
                    onClick={handleNextPage} 
                    disabled={currentPage === totalPages} 
                    style={{
                        background: 'none',
                        border: 'none',
                        cursor: 'pointer',
                        fontSize: 'inherit',
                    }}
                >
                    <img src={chevron} alt="Siguiente página" style={{ width: '16px', height: '16px', filter: 'invert(100%)' }} />
                </button>
                <button 
                    onClick={handleLastPage} 
                    disabled={currentPage === totalPages} 
                    style={{
                        background: 'none',
                        border: 'none',
                        cursor: 'pointer',
                        fontSize: 'inherit',
                    }}
                >
                    <img src={doubleChevron} alt="Última página" style={{ width: '16px', height: '16px', filter: 'invert(100%)' }} />
                </button>
            </div>

            <div className="contenedor-tabla" style={{ fontSize: cellStyles.fontSize, tableLayout: 'fixed' }}>
                <div className="flex-tabla">
                    <div className="flex-fila header-row">
                        <div className="flex-celda" style={{ padding: cellStyles.padding, textAlign: 'center', minWidth: cellStyles.minWidth }}>FECHA</div>
                        <div className="flex-celda" style={{ padding: cellStyles.padding, textAlign: 'center', minWidth: cellStyles.minWidth }}>MONTO</div>
                        <div className="flex-celda" style={{ padding: cellStyles.padding, textAlign: 'center', minWidth: cellStyles.minWidth }}>TRM</div>
                        <div className="flex-celda" style={{ padding: cellStyles.padding, textAlign: 'center', minWidth: cellStyles.minWidth }}>APERTURA</div>
                        <div className="flex-celda" style={{ padding: cellStyles.padding, textAlign: 'center', minWidth: cellStyles.minWidth }}>MÁX</div>
                        <div className="flex-celda" style={{ padding: cellStyles.padding, textAlign: 'center', minWidth: cellStyles.minWidth }}>MÍN</div>
                        <div className="flex-celda" style={{ padding: cellStyles.padding, textAlign: 'center', minWidth: cellStyles.minWidth }}>CIERRE</div>
                    </div>
                </div>

                <div className="flex-tabla">
                    {currentRecords.map((data, index) => (
                        <HistoricalDataRow 
                            key={index} 
                            rowId={index} 
                            data={{
                                ...data,
                                trm: formatAccordingToType(con.MONEY, data.trm, { allowDecimals: true }), 
                                apertura: formatAccordingToType(con.MONEY, data.apertura, { allowDecimals: true }), 
                                max: formatAccordingToType(con.MONEY, data.max, { allowDecimals: true }), 
                                min: formatAccordingToType(con.MONEY, data.min, { allowDecimals: true }), 
                                cierre: formatAccordingToType(con.MONEY, data.cierre, { allowDecimals: true })
                            }} 
                            cellStyles={cellStyles}  
                        />
                    ))}
                </div>
            </div>
        </div>
    );
}

export default HistoricalDataTable;
