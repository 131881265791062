import { Button, Text } from '@blueprintjs/core'
// TODO - Documentar
import React, { useEffect, useState } from 'react'
import { FaEdit, FaSave, FaTrash } from 'react-icons/fa'
import { TextElement } from '../../templates/FormElement'
import LoadingIconSmall from '../../templates/loading/LoadingIconSmall'
import Modal from '../../templates/popups/Modal'
import * as con from "../../GlobalConstants"
import { deleteUserLine, exportUserLine } from '../../store/actions/userLines'
import { RefNestedApplicationParameterHook } from '../hooks/ApplicationParametersHooks'

function EditableLine({line, singleLine}) {

  const [editing, setEditing] = useState(() => false)
  const [newName, setNewName] = useState(line[con.NAME])
  const [saving, setSaving] = useState(() => false)
  const [deleting, setDeleting] = useState(() => false)

  // Show Modal
  const [showDeleteDialog, setShowDeleteDialog] = useState(() => false)

  // Hooks
  const savingLineStatus = RefNestedApplicationParameterHook(con.USER_LINE_SAVED, con.STATUS)
  const deletingLineStatus = RefNestedApplicationParameterHook(con.USER_LINE_DELETED, con.STATUS)

  useEffect(() => {
    
    if(savingLineStatus === con.OK)
      setSaving(false)

  }, [saving, savingLineStatus])
  
  useEffect(() => {
    
    if(deletingLineStatus === con.OK)
      setDeleting(false)

  }, [deleting, deletingLineStatus])
  



  return (
    <div className={`line-arrangement ${saving || deleting ? 'disabled' : ""}`}>      
        <Modal show={showDeleteDialog} doAccept={null}  doCancel={(e) => setShowDeleteDialog(false)} showCancel={false}>
              <p>¿Esta seguro que quiere eliminar la linea: <strong>{line[con.NAME]} </strong>?</p>
              <div className="confirmCancelParameterBox">
                  <Button text="Si" onClick={(e) => {
                    deleteUserLine(line)
                    setShowDeleteDialog(false)
                  }} />
                <Button text="No" onClick={(e) => setShowDeleteDialog(false)} />
              </div>
        </Modal>
      <div className="line-arrangement-element">
        {  editing ? <TextElement value={newName} 
                                setValue={setNewName} />
                              : <div className="line-name-text"><Text>{line[con.NAME]} </Text></div>
        }
      </div>
      <div className="line-arrangement-element active">
        {
          saving && savingLineStatus === con.LOADING ? <LoadingIconSmall /> : editing ? <FaSave onClick={()=> {
                                                                                if(line[con.NAME] !== newName)
                                                                                {
                                                                                    setSaving(true)
                                                                                    exportUserLine({...line, [con.NAME] : newName})
                                                                                }
                                                                                setEditing(false)}} />
                                                                            : <FaEdit onClick={() => setEditing(true)}/>
                          
        }
        </div>
        <div className={`line-arrangement-element ${singleLine ? "disabled" : "active"}`}
              title={singleLine ? "Debe tener al menos una linea" : null}>
          {deleting && deletingLineStatus === con.LOADING ? <LoadingIconSmall /> : <FaTrash onClick={(e) => 
            {
              !singleLine && setDeleting(true)
              !singleLine && setShowDeleteDialog(true)}}/>}
        </div>
    </div>
  )
}

EditableLine.defaultProps = {
  singleLine : false
}

export default EditableLine