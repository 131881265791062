import { Button } from '@blueprintjs/core'
// TODO - Documentar
import React, { useEffect, useState } from 'react'
import { makePositionReport } from '../../utils/reportFunctions'


// Global constants
import * as con from '../../GlobalConstants'

// Style
import '../ComponentsGlobalStyle.css'


import LoadingIconSmall from '../../templates/loading/LoadingIconSmall';
import { RefApplicationParameterHook } from '../hooks/ApplicationParametersHooks';
import { ScenariosSelect } from '../parameters/SingleParameters';
import { RefTransactionsSummaryTableHook } from '../hooks/TransactionsSummaryTableHook'

function PositionReport() {


    // Position Report
    const positionReport = RefApplicationParameterHook(con.POSITION_REPORT)

    // Has PositionReport
    const [hasPositionReport, setHasPositionReport] = useState(() => false)

    // eslint-disable-next-line
    const [transactionSummaryObject, summaryRow, computing, hasSummaryTable] = RefTransactionsSummaryTableHook()
    
    useEffect(() => {
      
        setHasPositionReport(hasSummaryTable)

    }, [hasSummaryTable])
    

    return (
        <div className="columnParameterBoxTop">

            <h3>Reporte Posición Actual</h3>

            <ScenariosSelect />
            
            <div>                
              <Button disabled={!hasPositionReport || positionReport[con.GENERATING]} 
                      onClick={() => makePositionReport()}
                      title={hasPositionReport ? null :  con.NO_TRANSACTIONS_MESSAGE}>Generar Reporte</Button>  
            </div>
            <div style={{width : "20px" , marginTop: "15px"}}>
            {
                positionReport[con.GENERATING]
                && <LoadingIconSmall/> 
            }
            </div>
        </div>
    )
}

export default PositionReport
