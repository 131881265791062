// TODO - Documentar
import React from 'react'
import { Calculator } from '../components/forwardsCalculator/Calculator'
import ForwardCurveTable from '../components/forwardsCalculator/ForwardCurveTable'
import { USABLE_SCREEN_SIZE } from '../GlobalConstants'
import "./Pages.css"

function CalculatorPage() {
    return (
        <div style={{height : USABLE_SCREEN_SIZE + "vh", paddingLeft : "5px", paddingRight : "5px", paddingTop : "5px"}}>

            <div className="gridBox" style={{alignItems : "center"}}>
                <div style={{flexBasis : "80%"}}>                                         
                    <Calculator/>                                                                                         
                </div>    
                <div style={{flexBasis : "20%", }}>                                               
                    <ForwardCurveTable />              
                </div>
            </div>

                      
        </div>
    )
}

export default CalculatorPage
