// TODO - Documentar
import React from 'react'

import Scenarios from '../components/scenarios/Scenarios'
import ScenariosParameters from '../components/parameters/ScenariosParameters'
import MarketSummaries from '../components/market/MarketSummaries'
import { PROPORTIONAL_HEIGHT, PROPORTIONAL_WIDTH } from '../templates/charts/ChartConstants'
import CustomScenario from '../components/scenarios/CustomScenario'


function ScenariosPage() {

    
    return (
        <div  style={{ marginTop : "10px"}}>            
                <ScenariosParameters />                       
            <div className="gridBox" style={{marginLeft : "15px", marginTop : "10px"}}>
                <div style={{flexBasis : "20%"}}>                                         
                    <CustomScenario />                                                                                          
                </div>    
                <div style={{flexBasis : "60%"}}>                                               
                    <Scenarios chartParameters={{[PROPORTIONAL_WIDTH] : 800,
                                                  [PROPORTIONAL_HEIGHT] : 320}}/>                                                  
                </div>
                <div style={{flexBasis : "20%"}}>    
                    <div className='columnParameters'>                      
                        <MarketSummaries />
                    </div>                                                                                 
                </div>
            </div>
        </div>
    )
}

export default ScenariosPage
