// TODO - Documentar
import React, { useEffect, useState } from 'react'
import * as con from "../../GlobalConstants"
import {StaticTable} from '../../templates/tables/StaticTable'
import { RefMarketValueHook, RefOrionRealTimeValueHook } from '../hooks/MarketValuesHook'
import { buildHistoricTable } from '../../utils/orionFunctions'
import LoadingIcon from '../../templates/loading/LoadingIcon'




function OrionHistoricTable() {

    // Orion Real Time Value
    const orionRealTimeValue = RefOrionRealTimeValueHook() 

    const orionSummary =  RefMarketValueHook(con.ORION_SUMMARY)

    const columnArray = [{ [con.NAME] : 'FECHA', [con.TYPE] : con.TEXT, [con.ID] : con.DATE},
                         { [con.NAME] : 'CIERRE DEL MERCADO', [con.TYPE] : con.MONEY,  [con.ID] : con.ORION_REAL_RATE, [con.CURRENCY] : con.MONEY_COP },
                         { [con.NAME] : 'ESTIMACIÓN DEL MODELO', [con.TYPE] : con.MONEY,  [con.ID] : con.ORION_ESTIMATED_RATE, [con.CURRENCY] : con.MONEY_COP },
                         { [con.NAME] : 'SCORE', [con.TYPE] : con.PERCENTAGE, [con.ID] : con.ORION_SCORE },
                         { [con.NAME] : 'SEÑAL', [con.TYPE] : con.TEXT, [con.ID] : con.ORION_SIGNAL }]


  const [dataDictionary, setDataDictionary] = useState(() => [])                                                                                   


    useEffect(() => {
    
        if(orionSummary !== null)
            setDataDictionary(buildHistoricTable(orionRealTimeValue, orionSummary))
        


    }, [orionSummary, orionRealTimeValue])

      

  return (
    <div> 
    {
                    
        orionSummary === null
        ? <div style={{verticalAlign : "middle"}}>
            <LoadingIcon />
            </div>
        : <StaticTable  columnArray = {columnArray} 
                dataDictionary={dataDictionary}                      
                sortable={false}/>
    }      
  

    </div>
  )
}

export default OrionHistoricTable