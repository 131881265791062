// Generic Chart class
import * as cc from "../ChartConstants";
import * as d3 from "d3";
import { getDefaultColorScheme } from "../ChartFunctions";

d3.timeFormatDefaultLocale(cc.es_ES);

export const DEFAULT_PARAMETERS = {// All charts 
                                    [cc.MARGIN] : { [cc.TOP]: 25, [cc.RIGHT]: 30, [cc.BOTTOM]: 30, [cc.LEFT]: 70 },
                                    [cc.PROPORTIONAL_WIDTH] : 800,
                                    [cc.PROPORTIONAL_HEIGHT] : 270,
                                    [cc.X_LABEL_FONT_SIZE] : 12,
                                    [cc.Y_LABEL_FONT_SIZE] : 12,
                                    [cc.X_LABEL] : "",
                                    [cc.Y_LABEL] : "",
                                    [cc.Y_PADDING] : 0.05,
                                    [cc.X_PADDING] : 0.05,
                                    [cc.TITLE] : "",
                                    [cc.TITLE_FONT_SIZE] : 15,
                                    [cc.SECOND_Y_AXIS] : false,
                                    [cc.SECOND_Y_LABEL] : "",
                                    [cc.INCLUDE_BORDER] : false,
                                    [cc.Y_AXIS_ON_LEFT] : true,
                                    
                                    // Opacity
                                    [cc.FULL_OPACITY] : 1,
                                    [cc.HIDDEN_OPACITY] : 0.2,
                                    [cc.MIN_OPACITY] : 0.5,                                                                 
                                    
                                    // Animation
                                    [cc.DATA_CHANGE_TRANSITION_TIME] : 1500,
                                    [cc.ANIMATION_TIME] : 700}

export class GenericChart{


    initialized = false

    // General constructor
    // -------------------
    constructor(parameters, objectReference){

        // Binds
        this.objectReference = objectReference
        
        // Assigns parameters
        this.parameters = {...cc.GLOBAL_DEFAULT_PARAMETERS, ...DEFAULT_PARAMETERS, ...parameters}
        

        // Declares the starting attributes

        // Dimensions    
        this.svgWidth = this.parameters[cc.PROPORTIONAL_WIDTH];
        this.svgHeight = this.parameters[cc.PROPORTIONAL_HEIGHT];
        this.width = this.svgWidth  - this.parameters[cc.MARGIN][cc.LEFT]  - this.parameters[cc.MARGIN][cc.RIGHT];

        

        // Checks if legend is included
        this.legend_height = 0
        this.legend_margin_from_chart =  0
        if(this.parameters[cc.INCLUDE_LEGEND])
        {
            this.legend_height = this.parameters[cc.LEGEND_HEIGHT]* this.parameters[cc.PROPORTIONAL_HEIGHT];
            this.legend_margin_from_chart =  this.parameters[cc.LEGEND_MARGIN_FROM_CHART]* this.parameters[cc.PROPORTIONAL_HEIGHT];
        }
        
        this.height =  this.svgHeight  - this.parameters[cc.MARGIN][cc.TOP] - this.parameters[cc.MARGIN][cc.BOTTOM] - this.legend_height - this.legend_margin_from_chart;
        this.chart_middle_height = this.height/2 + this.parameters[cc.MARGIN][cc.TOP] 
        
        this.borderWidth = this.svgWidth
        this.borderHeight = this.svgHeight -2

    
        // Variables that will be used by the chart functions
        // -------------------------------------------
        // Focus
        this.focusElement = null;

        // Color Scheme
        this.colorScheme = null
        
        // Scales
        this.xScale = d3.scaleLinear().domain([0,10]).range([0, this.width])
        
        this.min_left_y = 1000
        this.max_left_y = 5000
        this.final_left_padding = (this.max_left_y -this.min_left_y)*parameters[cc.Y_PADDING]
        this.yScale = d3.scaleLinear().domain([this.min_left_y - this.final_left_padding, this.max_left_y + this.final_left_padding]).range([this.height, 0])


    }

    // Main Chart Method
    // ------------------
    initialize(){
        
        let self = this;

        this.svgEl = d3.select(this.objectReference.current)
        
        // Removes all inside elements
        this.svgEl.selectAll("*").remove(); // Clear svg content before adding new elements 
        
        // Construct view box
        this.svg = this.svgEl.attr("viewBox", `0 0 ${this.svgWidth} ${this.svgHeight}` )
                            .attr('width', '100%')                        
    

        // Adds grid
        this.grid = this.svg.append('rect')
            .attr('class', 'zoom')
            .attr('fill', 'none')
            .attr('width', this.svgWidth)
            .attr('height', this.svgHeight)
            .attr("transform", `translate(${this.parameters[cc.MARGIN][cc.LEFT]},${this.parameters[cc.MARGIN][cc.TOP]})`)
            .attr("class", "overlay")
            .on("mousemove", (event) => self.onMouseMove(self, event))
            .on("click", (event) => self.onMouseClickEmpty(self, event))

            // Adds component for the lines
            this.mainComponent = this.svg.append("g")
                                    .attr("transform", `translate(${this.parameters[cc.MARGIN][cc.LEFT]},${this.parameters[cc.MARGIN][cc.TOP]})`)
                                    .attr('width', this.width)
                                    .attr('height', this.height);
                                                                


            // Border
            if(this.parameters[cc.INCLUDE_BORDER])
            {
                this.svg.append("rect")
                    .attr("x", 0)
                    .attr("y", 0)
                    .attr("height", this.borderHeight)
                    .attr("width", this.borderWidth)
                    .style("stroke", "var(--text-color)")
                    .style("fill", "none")
                    .style("stroke-width", 2);
            }


        // Axis Labels
        // Adds Y Label
        if(this.parameters[cc.Y_LABEL] !== "")
        {
            if(this.parameters[cc.Y_AXIS_ON_LEFT])
            {
                this.svgEl.append("text")
                .attr("transform", "rotate(-90)")
                .style("font-size", this.parameters[cc.Y_LABEL_FONT_SIZE] + "px")
                .style('fill', 'var(--text-color)')
                .attr("y", -1*this.parameters[cc.Y_LABEL_FONT_SIZE] )
                .attr("x", -1*this.chart_middle_height)
                .attr("dy", "2.5em")
                .style("text-anchor", "middle")
                .text(this.parameters[cc.Y_LABEL]);  
            }
            else
            {
                this.svgEl.append("text")
                .attr("transform", "rotate(-90)")
                .style("font-size", this.parameters[cc.Y_LABEL_FONT_SIZE] + "px")
                .style('fill', 'var(--text-color)')
                .attr("x", -1*this.chart_middle_height )
                .attr("y", this.svgWidth - 3.5*this.parameters[cc.Y_LABEL_FONT_SIZE])
                .attr("dy", "3em")
                .style("text-anchor", "middle")
                .text(this.parameters[cc.Y_LABEL]);  
            }

        }

        // Adds X Label
        if(this.parameters[cc.X_LABEL] !== "")
        {
            this.svgEl.append("text")  
            .style("font-size", this.parameters[cc.Y_LABEL_FONT_SIZE] + "px")           
            .attr("transform",
                  "translate(" + (this.svgWidth/2) + " ," + (this.height + this.parameters[cc.MARGIN][cc.TOP]) + ")")
            .attr("dy", "3em")
            .style("text-anchor", "middle")
            .style('fill', 'var(--text-color)')
            .text(this.parameters[cc.X_LABEL]);
            
        }

        // Add Title
        if(this.parameters[cc.TITLE] !== "")
        {
            this.mainComponent.append("text")  
            .style("font-size", this.parameters[cc.TITLE_FONT_SIZE] + "px")                    
            .attr("transform",
                    "translate(" + (this.width/2) + " ," + (0) + ")")
            .attr("y", -1*this.parameters[cc.MARGIN][cc.TOP] + this.parameters[cc.TITLE_FONT_SIZE])
            .style("text-anchor", "middle")
            .attr("font-weight", 900)   
            .style('fill', 'var(--text-color)')
            .text(this.parameters[cc.TITLE]);
            
        }


            this.initialized = true

            
    }


    build(initialData)
    {
        if(!this.initialized)
            this.initialize()       

        this.data = initialData
    }


    updateData(newData){

        if(!this.initialized)
            this.initialize()       

        this.data = newData
    }


    // Generic Methods
    // ------------------

    onMouseMove(self,event)
    {
        // Empty Method
    }


    // Generic Methods
    onMouseClickEmpty(self, event)
    {
        // Empty Method
    }


    getColorScheme(numElements)
    {
        // Builds default color scheme
        return(getDefaultColorScheme(numElements))
    }




}