// TODO - Documentar
import { useSelector } from 'react-redux';

import * as con from "../../GlobalConstants"

/**
 * Hook for the errors in database
 */
export function ErrorsHook() {

    const errors = useSelector(state => state[con.STORE][con.REDUCER_ERRORS])

    return([errors[con.LATEST_ERROR], errors[con.SESSION_ERRORS]]);
}


/**
 * Hook for the latest errors in database
 */
 export function LatestErrorsHook() {

    const errors = useSelector(state => state[con.STORE][con.REDUCER_ERRORS])

    return(errors[con.LATEST_ERROR]);
}

