// TODO - Documentar
import React from 'react'
import { CLIENT_TYPE, IMPORTER } from '../../GlobalConstants';


// Style
import '../ComponentsGlobalStyle.css'
import { RefModelParameterHook } from '../hooks/ModelParameterHooks';


import { AverageForPercentage, ClientType, PassThrough, Periodicity, TermPeriodicity } from './SingleParameters';


function CoverageProfileParameters() {

    // Client Type
    const clientType = RefModelParameterHook(CLIENT_TYPE)

    return (
        <div>
                <div className='bigParameterBox' >
                    <ClientType />
                </div>
                <div className='bigParameterBox' >                
                    <Periodicity />                                        
                    <AverageForPercentage />
                   { clientType === IMPORTER && <PassThrough /> }
                    < TermPeriodicity />

                </div>
        </div>
    )
}

export default CoverageProfileParameters
