// Error functions

import { pushError } from "../store/actions/errors";

// Error constants
const SERVER_ERROR = "SERVER_ERROR"

export const handleRequestError = (error) => {
    if (error.response) {
        // Request made and server responded
        console.error(error.response.data);
        console.error(error.response.status);
        console.error(error.response.headers);

        pushError(SERVER_ERROR, "Hubo un error interno en el servidor", {})
      } else if (error.request) {
        // The request was made but no response was received
        console.error(error.request);
        pushError(SERVER_ERROR, "Hubo un error interno en el servidor", {})

      } else {
        // Something happened in setting up the request that triggered an Error
        //throw error
        console.error('Error', error.message);
      }
}

export const handleInternalError = (errorMessage) => {

  console.error(errorMessage)
}

export const handleParametersSavedError = (error) =>
{
  if(error.response)
    pushError(SERVER_ERROR, "Hubo un error guardando los parámetros.", {...error.response})
  else
    pushError(SERVER_ERROR, "Hubo un error guardando los parámetros.", {...error.request})

    console.error(error)

}


export const handleUserLineApplicationParametersSavedError = (error) =>
{
  if(error.response)
    pushError(SERVER_ERROR, "Hubo un error guardando los parámetros.", {...error.response})
  else
    pushError(SERVER_ERROR, "Hubo un error guardando los parámetros.", {...error.request})

    console.error(error)

}


export const handleLineSavedError = (error) =>
{
  if(error.response)
    pushError(SERVER_ERROR, "Hubo un error guardando la linea.", {...error.response})
  else
    pushError(SERVER_ERROR, "Hubo un error guardando la linea.", {...error.request})

    console.error(error)

}

export const handleLineDeletedError = (error) =>
{
  if(error.response)
    pushError(SERVER_ERROR, "Hubo un error eliminando la linea.", {...error.response})
  else
    pushError(SERVER_ERROR, "Hubo un error eliminando la linea.", {...error.request})

    console.error(error)

}