// GlobalView.js
import React, { useState, useEffect, useCallback } from 'react';
import * as con from "../GlobalConstants";
import axios from 'axios';
import { RefToken } from '../components/hooks/AuthenticationHook';
import { RefUserAttributesHook } from '../components/hooks/UserAttributesHook';

import BandComparisonGlobalViewComponent from '../components/globalViewComponents/BandComparisonGlobalViewComponent';
import MarketViewGlobalViewComponent from '../components/globalViewComponents/MarketViewGlobalViewComponent';
import CurrentPositionGlobalViewComponent from '../components/globalViewComponents/CurrentPositionGlobalViewComponent';
import ScenariosGlobalViewComponent from '../components/globalViewComponents/ScenariosGlobalViewComponent';

function GlobalView() {
    const componentHeight = con.USABLE_SCREEN_SIZE / 2;

    const [alerts, setAlerts] = useState([]);

    const user = RefUserAttributesHook('user');
    const token = RefToken();

    const fetchAlerts = useCallback(async () => {
        if (user && token) {
            try {
                const response = await axios.get(
                    `${con.rest_base_url}${con.transactions_api}${con.usdcop_alerts_null_reached}${user}/`,
                    {
                        headers: {
                            Authorization: `Token ${token}`,
                        },
                    }
                );
                setAlerts(response.data);
                localStorage.setItem('alerts', JSON.stringify(response.data));
            } catch (error) {
                console.error('Error fetching alerts:', error);
            }
        } else {
            setAlerts([]);
            localStorage.removeItem('alerts');
        }
    }, [user, token]);

    useEffect(() => {
        fetchAlerts();
    }, [fetchAlerts]);

    useEffect(() => {
        const handleStorageChange = (event) => {
            if (event.key === 'alerts') {
                const updatedAlerts = event.newValue ? JSON.parse(event.newValue) : [];
                setAlerts(updatedAlerts);
            }
        };

        window.addEventListener('storage', handleStorageChange);

        return () => {
            window.removeEventListener('storage', handleStorageChange);
        };
    }, []);

    return (      
        <div>
            <div className="gridBox" style={{ minHeight: `${componentHeight}vh` }}>
                <MarketViewGlobalViewComponent alerts={alerts} />
                <CurrentPositionGlobalViewComponent componentHeight={componentHeight} />        
            </div>
            <div className="gridBox" style={{ height: `${componentHeight - 2}vh`, marginTop: "0vh" }}>
                <ScenariosGlobalViewComponent />
                <BandComparisonGlobalViewComponent />
            </div>        
        </div>
    );
}

export default GlobalView;
