// TODO - Documentar
import React from 'react'

import * as con from "../../GlobalConstants"

import { RefApplicationParameterHook, StateApplicationParameterHook } from '../hooks/ApplicationParametersHooks'

import { GenericCell, StaticSingleCell } from '../../templates/tables/Cells'

import { translateBands, translateParameter } from '../../utils/translateFunctions'



function CoveragesGridControls() {

    // Application Parameter Hooks
    const [clientType, setClientType] = StateApplicationParameterHook(con.COVERAGES_GRID_CLIENT_TYPE)
    const [stopLoss, setStopLoss] = StateApplicationParameterHook(con.COVERAGES_GRID_STOP_LOSS)
    const [initialLevel, setInitialLevel] = StateApplicationParameterHook(con.COVERAGES_GRID_INITIAL_LEVEL)
    const [finalLevel, setFinalLevel] = StateApplicationParameterHook(con.COVERAGES_GRID_FINAL_LEVEL)
    const [spread, setSpread] = StateApplicationParameterHook(con.COVERAGES_GRID_SPREAD)
    const [desiredBand, setDesiredBand] = StateApplicationParameterHook(con.COVERAGES_GRID_DESIRED_BAND)
    const amountToPay = RefApplicationParameterHook(con.COVERAGES_GRID_AMOUNT_TO_BUY)

  

    return(
      <div>
        <h3>Resumen de Derivados Activos</h3>
        <div className="tightTable">
          <div className="tightColumn">
            <StaticSingleCell content={"Tipo Grilla"} isTitle={true}/>
            <StaticSingleCell content={"Stop Loss"} isTitle={true}/>
            <StaticSingleCell content={"Nivel Inicial"} isTitle={true}/>
            <StaticSingleCell content={"Nivel Final"} isTitle={true}/>
            <StaticSingleCell content={"Spread"} isTitle={true}/>
            <StaticSingleCell content={"Banda Deseada"} isTitle={true}/>
            <StaticSingleCell content={"Monto (Comprar)"} isTitle={true}/>                       

          </div>
          <div className="tightColumn">
            <GenericCell cellType={con.MULTIPLE_SELECTION} value={clientType} setValue={setClientType} editing={true} options={con.CLIENT_AVAILABLE_TYPES} valueTranslator={translateParameter}/>
            <GenericCell cellType={con.MONEY} value={stopLoss} setValue={setStopLoss} currency={con.MONEY_COP} editing={true}/>
            <GenericCell cellType={con.MONEY} value={initialLevel} setValue={setInitialLevel} currency={con.MONEY_COP} editing={true}/>
            <GenericCell cellType={con.MONEY} value={finalLevel} setValue={setFinalLevel} currency={con.MONEY_COP} editing={true}/>
            <GenericCell cellType={con.MONEY} value={spread} setValue={setSpread} currency={con.MONEY_COP} editing={true}/>
            <GenericCell cellType={con.MULTIPLE_SELECTION} value={desiredBand} setValue={setDesiredBand} currency={con.MONEY_COP} editing={true} options={con.BANDS} valueTranslator={translateBands}/>
            <GenericCell evenRow={false} cellType={con.MONEY} value={amountToPay} currency={con.MONEY_COP} editing={false}/>
          </div>
      </div>
    </div>
    )
}

export default CoveragesGridControls

