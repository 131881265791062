// Module with the different band functions

// Global constants
import * as con from "../GlobalConstants"
import { average } from "../GlobalFunctions";
import config from "../config/initial_parameters.json"

/**
 * Method that modifies the term in months of the mid band values.
 * If the new term is less than the previous one, the resulting array is a slice of the original one.
 * If the new term is longer, it adds the new values to the original one with the formula: initial_coverage x (1 - gradient)^period  
 * @param {array[float]} bands - The current band values, one for each period (month) 
 * @param {number} TERM_PERIODICITY - The new term in months
 * @param {float} initial_coverage - The initial coverage 
 * @param {float} gradient - The descend gradient of the coverage bands 
 * @returns {array[float]} - The new band values for each period (months)
 */
export const modifyTerm = (bands, TERM_PERIODICITY, initial_coverage, gradient) =>
{
    const new_bands_length = TERM_PERIODICITY+1;
    if(bands.length >= new_bands_length)
        return(bands.slice(0,new_bands_length))
    else
        return(bands.concat([...Array(new_bands_length - bands.length).keys()].map(i => Math.max(initial_coverage*(1-gradient)**(i+bands.length),0))))
    
    
}

// 
/**
 * Function that generates bands based on gradient and initial value, following the formula: initial_coverage x (1 - gradient)^period  
 * @param {float} average_for_percentage - Parameter used for the initial period in a previous version of the formula. The parameter is left as legacy.
 * @param {number} TERM_PERIODICITY - The term in months
 * @param {float} initial_coverage - The initial coverage 
 * @param {float} gradient - The descend gradient of the coverage bands 
 * @returns {array[float]} - The band values for each period (months)
 */
export const generateBands = (average_for_percentage, TERM_PERIODICITY, initial_coverage, gradient) => {

    const bands = [...Array(TERM_PERIODICITY+1).keys()].map(i => Math.max(initial_coverage*(1-gradient)**i,0))
   
    return(bands)
}


// 
/**
 * Function that generates bands based on the received Model Parameters (term in months, initial coverage and gradient). 
 * If values are missing will use default
 * @param {Oject} modelParameters - The current model parameters for the application  
 * @returns {array[float]} - The band values for each period (months)
*/
export const generateBandsFromModelParameters = (modelParameters) => {

    let localModelParameters = {...config.default_model_parameters,
                                ...modelParameters}    
    
    return(generateBands(localModelParameters[con.AVERAGE_FOR_PERCENTAGE], 
                        localModelParameters[con.TERM_PERIODICITY], 
                        localModelParameters[con.INITIAL_COVERAGE], 
                        localModelParameters[con.GRADIENT]))
}


/**
 * Method that returns the lower band value given a single middle band value and band width.
 * Current formula: value - bands_width
 * @param {float} value - Current mid band value 
 * @param {float} bands_width - The band width
 * @returns {float} - The lower band value
 */
export const getLowerBand = (value, bands_width) =>
{
    return(Math.max(0, Math.min(value - bands_width, 1)))
}


/**
 * Method that returns the mid lower band value given a single middle band value and band width.
 * Current formula: value - bands_width/2
 * @param {float} value - Current mid band value 
 * @param {float} bands_width - The band width
 * @returns {float} - The mid lower band value
 */
export const getMidLowerBand = (value, bands_width) =>
{
    return(Math.max(0, Math.min(value - bands_width/2, 1)))
}

/**
 * Method that returns the lower band values given the entire middle band values and band width.
 * Current formula: bands - bands_width
 * @param {array[float]} bands - Current mid band values 
 * @param {float} bands_width - The band width
 * @returns {array[float]} - The lower band values
 */
export const getLowerBands = (bands, bands_width) =>
{
    return(bands.map(val => Math.max(0, Math.min(val - bands_width, 1))))
}


/**
 * Method that returns the mid higher band value given a single middle band value and band width.
 * Current formula: value + bands_width/2
 * @param {float} value - Current mid band value 
 * @param {float} bands_width - The band width
 * @returns {float} - The mid higher band value
 */
export const getMidHigherBand = (value, bands_width) =>
{
    return(Math.max(0, Math.min(value + bands_width/2, 1)))
}


/**
 * Method that returns the higher band value given a single middle band value and band width.
 * Current formula: value + bands_width
 * @param {float} value - Current mid band value 
 * @param {float} bands_width - The band width
 * @returns {float} - The higher band value
 */export const getHigherBand = (value, bands_width) =>
{
    return(Math.max(0, Math.min(value + bands_width, 1)))
}



/**
 * Method that returns the high band values given the entire middle band values and band width.
 * Current formula: bands + bands_width
 * @param {array[float]} bands - Current mid band values 
 * @param {float} bands_width - The band width
 * @returns {array[float]} - The higher band values
 */
export const getHigherBands = (bands, bands_width) =>
{
    return(bands.map(val => Math.max(0, Math.min(val+bands_width, 1))))
}




/**
 * Function that return the bands construction function by period (month) and type
 * @param {array[float]} bands - Current mid band values 
 * @param {float} bands_width - The band width
 * @param {string} band_type - Band type. Should be one of the band types: low, mid low, mid, mid higher and higher.   
 * @returns {function} - Function that receives the period and returns the corresponding band value.
 */
export const getBandsConstructionFunction = (bands, bands_width, band_type) =>
{
    switch(band_type)
    {
        case con.LOWER_BAND:
            return((i) => Math.max(0,bands[i] - bands_width))
        case con.MID_LOW_BAND:
            return((i) => Math.max(0,bands[i] - bands_width/2))
        case con.MID_BAND:
            return((i) => bands[i])
        case con.MID_HIGH_BAND:
            return((i) => Math.min(1,bands[i] + bands_width/2))
        case con.HIGHER_BAND:
            return((i) => Math.min(1,bands[i] + bands_width))
        default:
            throw new Error(`No support for band type: ${band_type}`)        
    }
}


/**
 * Returns the summary values (for tables), of a given and values and band width. 
 * The current summary values are the average value excluding the first period.
 * @param {array[float]} bands - Current mid band values 
 * @param {float} bands_width - The band width
 * @returns {array[float]} - Te summary values for each band in the order: low, mid low, mid, mid higher and higher.
 */
export const getSummaryBands = (bandValues, bandWidth) =>
{    
    let lowerBand = average(bandValues.slice(1).map((val) => val - bandWidth))
    let midLowerBand = average(bandValues.slice(1).map((val) => val - bandWidth/2))
    let midBand = average(bandValues.slice(1))
    let midHighBand = average(bandValues.slice(1).map((val) => val + bandWidth/2))
    let highBand = average(bandValues.slice(1).map((val) => val + bandWidth))

    return([lowerBand, midLowerBand, midBand, midHighBand, highBand])
}

/**
 * Computes the summary value for the middle band. 
 * The current summary value is the average value excluding the first period.
 * @param {array[float]} bands - Current mid band values 
 * @returns {float} - The summa value
 */
export const getMidSummaryBand = (bands) =>
{
    
    return(average(bands.slice(1)))
}

/**
 * Computes the object band given the average middle band value
 * @param {float} aveRageMidBandPercentage - The average (summary) mid band value.
 * @returns {string} - THe id of the objective band
 */
export const getObjectiveBand = (aveRageMidBandPercentage) =>
{
    if(aveRageMidBandPercentage <= 0.19)
        return(con.LOWER_BAND)
    else if(aveRageMidBandPercentage <= 0.39)
        return(con.MID_LOW_BAND)
    else if(aveRageMidBandPercentage <= 0.59)
        return(con.MID_BAND)
    else if(aveRageMidBandPercentage <= 0.79)
        return(con.MID_HIGH_BAND)
    else
        return(con.HIGHER_BAND)
}

/**
 * Computes the position of a given coverage in the band types
 * @param {float} coverage - The coverage percentage to determine the position 
 * @param {object} bandSummary - Object with all the bands summaries. 
 * @returns {string} - The id of the band corresponding to the position obtained by the coverage.
 */
export const getPositionInBands = (coverage, bandSummary) =>
{

    // Calculates the band width
    let band_width = bandSummary[con.HIGHER_BAND] - bandSummary[con.LOWER_BAND]
    let difference = (coverage - bandSummary[con.LOWER_BAND])/(band_width)

    // Adjusts Possible Values
    difference = difference*(con.POSITION_IN_BANDS_ARRAY.length - 2)

    // Adjusts the extremes
    difference = difference < 0 ? -1 : difference
    difference = difference > con.POSITION_IN_BANDS_ARRAY.length - 2 ? con.POSITION_IN_BANDS_ARRAY.length - 2 : difference

    
    // Construct index
    let ind = Math.floor(difference) + 1

    return(con.POSITION_IN_BANDS_ARRAY[ind])
    
}

/**
 * Function that return the bands construction function by period (month) and type
 * @param {array[float]} bands - Current mid band values 
 * @param {float} bands_width - The band width  
 * @param {string} band_type - Band type. Should be one of the band types: low, mid low, mid, mid higher and higher. 
 * @returns {float} - Objective Band (Percentage)
 */
export const getPercentageObjectiveBand = (bands, bandWidth, band_type) =>
{
    let [lBand, mlBand, mBand, mhBand,  hBand] = getSummaryBands(bands, bandWidth)
    
    switch(band_type)
    {
        case con.LOWER_BAND:
            return(lBand)
        case con.MID_LOW_BAND:
            return(mlBand)
        case con.MID_BAND:
            return(mBand)
        case con.MID_HIGH_BAND:
            return(mhBand)
        case con.HIGHER_BAND:
            return(hBand)
        default:
            throw new Error(`No support for band type: ${band_type}`)        
    }
}