// TODO - Documentar
import React from 'react'
import ForwardAssessmentReport from '../components/reports/ForwardAssessmentReport'
import InputsSummary from '../components/reports/InputsSummary'
import PositionReport from '../components/reports/PositionReport'
import PurchaseReport from '../components/reports/PurchaseReport'
import ResultsSummary from '../components/reports/ResultsSummary'
import SimpleFillFooter from '../templates/footer/SimpleFillFooter'
import { HorizontalLine } from '../templates/horizontalLines/HorizontalLine'
import "./MobilePages.css"

function MobileReportsPage() {
    return (
        <div>
            <div className='columnParameterBox'>   
                      
                <ResultsSummary />
                <HorizontalLine marginTop='5%'/>
                <InputsSummary />
                <HorizontalLine />
                <PurchaseReport columnLayout={true}/>                                                                                                                            
                <HorizontalLine />                                                                                                                       
                <PositionReport />
                <HorizontalLine />  
                <ForwardAssessmentReport />     
                <SimpleFillFooter/>                                       
            
            </div>              
        </div>
    )
}


export default MobileReportsPage
