// TODO - Documentar
import React, { useCallback, useEffect, useState } from 'react'
import { GenericElement } from '../../templates/FormElement';

// Global constants
import * as con from "./../../GlobalConstants"
import { getTotalVars } from '../../utils/transactionFunctions';
import { Card, Elevation } from '@blueprintjs/core';

import "../ComponentsGlobalStyle.css"
import "./RiskComparisonForTwoScenarios.css"
import { round } from '../../GlobalFunctions';
import { LABELS, NAME, TITLE, VALUES, Y_LABEL } from '../../templates/charts/ChartConstants';
import { MultiBarChartHook } from '../../templates/charts/hooks/MultiBarChartHook';
import { AllModelParameterHook } from '../hooks/ModelParameterHooks';
import { ScenariosHook } from '../hooks/ScenariosHook';
import { AccountsHook, ExposuresHook, ForwardCoveragesHook, OptionCoveragesHook } from '../hooks/TransactionsHooks';
import { RefApplicationParameterHook, RefModelApplicationParameterHook, RefSPOT } from '../hooks/ApplicationParametersHooks';
import { MediumLabel, SmallLabel } from '../../templates/label/Labels';
import { formatAccordingToType } from '../../templates/Types';

function RiskComparisonForTwoScenarios() {

    // Posible Scenarios
    const scenarios = ScenariosHook()

    // Transactions    
    const exposures = ExposuresHook()
    const fwdCoverages = ForwardCoveragesHook()
    const optionCoverages = OptionCoveragesHook()
    const accounts = AccountsHook()

    // Model Parameters
    const modelParameters = AllModelParameterHook()
    const spot = RefSPOT()
    const currentDate = RefApplicationParameterHook(con.CURRENT_DATE)

    // Model Application Parameters
    const modelApplicationParameters = RefModelApplicationParameterHook()

    

    // Computed Scenarios
    const [computedScenarios, setComputedScenarios] = useState(() =>  Object.assign({}, ...Object.keys(scenarios).map((id) => ({[id]: null})))) 

    // Selected Scenarios Id
    const [scenario1, setScenario1] = useState(() => null)
    const [scenario2, setScenario2] = useState(() => null)

    // Selected scenarios
    const [scenarioId1, setScenarioId1] = useState(() => con.SCENARIO_VAR_UP)
    const [scenarioId2, setScenarioId2] = useState(() => con.SCENARIO_PERCENTAGE)
                               
    
    // Chart Hook
    const [barPlotRef, chart] = MultiBarChartHook({ parameters : {[Y_LABEL] : "Millones COP",
                                                                  [TITLE] : "Comparación de escenarios por banda"}})    


    useEffect(() => {

        
        if(!chart.initialized)
            chart.build(getDefautlData())

    }, [chart])
    

    // Updates computed scenarios
    useEffect(() => {
        
        // Updates computed
        setComputedScenarios(Object.assign({}, ...Object.keys(scenarios).map((id) => ({[id]: null}))))

        // Updates both scenarios
        setScenario1(null)
        setScenario2(null)


    }, [scenarios, exposures, fwdCoverages, optionCoverages, accounts, modelParameters])

    

    // Function for updating a scenario (async to not block excecution)
    const updateScenario =  useCallback((id, num) =>
    {
        
        let updateFun = num === 1 ? setScenario1 : setScenario2;
        num === 1 ? setScenarioId1(id) : setScenarioId2(id);

        updateFun(computedScenarios[id])
            
        if(computedScenarios[id] === null)
        {

            new Promise(function (success, error) {

                setTimeout(() => {
                    let totVars = getTotalVars(
                        currentDate,
                        spot,
                        scenarios[id].gen_fun,
                        modelParameters, 
                        exposures,
                        fwdCoverages,
                        optionCoverages,
                        accounts,
                        modelApplicationParameters)
                    
                    success(totVars);
                }, con.UI_TIMEOUT);
                
                
            }).then((totVars) => {

                if(totVars !== null)
                {
                    totVars = Object.assign({}, ...Object.keys(totVars).map((id) => ({[id]: totVars[id]/con.MILLION})))
                    computedScenarios[id] = totVars 
                    updateFun(totVars)
                }
                               
            })                        
            
            
        }

        
    }, [scenarios, exposures, fwdCoverages, optionCoverages, accounts, modelParameters, computedScenarios, currentDate, spot, modelApplicationParameters])

    useEffect(() => {

        // Decalres Make Label
        const makeLabel = (num, var_id, band_name) =>
        {
            let scen = num === 1 ? scenario1 : scenario2;
            let sceID = num === 1 ? scenarioId1 : scenarioId2;
            return(`${scenarios[sceID][NAME]}: ${round(scen[var_id],2)} COP (${band_name})`)

        }

        if(!chart.initialized)
            chart.initialize()

        if(scenario1 === null)
            updateScenario(scenarioId1, 1)
        if(scenario2 === null)
            updateScenario(scenarioId2, 2)
        
        let chartData = []
        if(scenario1 != null && scenario2 != null)
        {
            
            
            chartData = [{[VALUES] : [scenario1[con.VAR], scenario2[con.VAR]], [LABELS]: [makeLabel(1, con.VAR, "Var Oportunístico"),makeLabel(2, con.VAR, "Var Oportunístico")]},
                        {[VALUES] : [scenario1[con.LOWER_BAND+"_"+con.VAR], scenario2[con.LOWER_BAND+"_"+con.VAR]], [LABELS]: [makeLabel(1, con.LOWER_BAND+"_"+con.VAR, "Banda Baja"),makeLabel(2, con.LOWER_BAND+"_"+con.VAR, "Banda Baja")]},            
                        {[VALUES] :[ scenario1[con.MID_BAND+"_"+con.VAR], scenario2[con.MID_BAND+"_"+con.VAR]], [LABELS]: [makeLabel(1, con.MID_BAND+"_"+con.VAR, "Banda Media"),makeLabel(2, con.MID_BAND+"_"+con.VAR, "Banda Media")]},
                        {[VALUES] : [scenario1[con.HIGHER_BAND+"_"+con.VAR], scenario2[con.HIGHER_BAND+"_"+con.VAR]], [LABELS]:[makeLabel(1, con.HIGHER_BAND+"_"+con.VAR, "Banda Alta"),makeLabel(2, con.HIGHER_BAND+"_"+con.VAR, "Banda Alta")]}]


            
            chart.updateData(chartData)
        }


    }, [scenario1, scenario2, scenarioId1, scenarioId2, chart, updateScenario, scenarios])


 

    return (
        
        <div>                       
            <div className="cardsAndChart">
                <div>
                < GenericElement type={con.MULTIPLE_SELECTION}
                            name={"Escenario VaR 1"}
                            id={"escenario_1"} 
                            value={scenarioId1} 
                            setValue={(val) => updateScenario(val,1)} 
                            values={Object.keys(scenarios)}
                            valueLabels={Object.values(scenarios).map((esc) => {return(esc.name)})}
                            />   
                    <div className="cardBox scenario-1">
                        
                        <Card elevation={Elevation.TWO} className="card-4">
                            <MediumLabel text="VaR Oportunístico" />
                            <p>Millones de Pesos</p>
                            <SmallLabel text={scenario1 == null ? "Computando..." : formatAccordingToType(con.MONEY, scenario1[con.VAR], {[con.CURRENCY]: con.MONEY_COP, [con.ALLOW_DECIMALS]: true})} />
                        </Card>

                        <Card elevation={Elevation.TWO} className="card-4">
                            <MediumLabel text="VaR Banda Alta" />
                            <p>Millones de Pesos</p>
                            <SmallLabel text={scenario1 == null ? "Computando..." : formatAccordingToType(con.MONEY, scenario1[con.HIGHER_BAND+"_"+con.VAR], {[con.CURRENCY]: con.MONEY_COP, [con.ALLOW_DECIMALS]: true})}/>
                        </Card>

                        <Card elevation={Elevation.TWO} className="card-4">
                            <MediumLabel text="VaR Banda Media" />
                            <p>Millones de Pesos</p>
                            <SmallLabel text={scenario1 == null ? "Computando..." : formatAccordingToType(con.MONEY, scenario1[con.MID_BAND+"_"+con.VAR], {[con.CURRENCY]: con.MONEY_COP, [con.ALLOW_DECIMALS]: true})}/>
                        </Card>

                        <Card elevation={Elevation.TWO} className="card-4">
                            <MediumLabel text="VaR Banda Baja" />
                            <p>Millones de Pesos</p>
                            <SmallLabel text={scenario1 == null ? "Computando..." : formatAccordingToType(con.MONEY, scenario1[con.LOWER_BAND+"_"+con.VAR], {[con.CURRENCY]: con.MONEY_COP, [con.ALLOW_DECIMALS]: true})}/>
                        </Card>

                        

                    </div>
                </div>
                <div style={{width : "60%"}}>
                <div>
                    <svg ref={barPlotRef}/>
                </div>
                </div>                
                <div>
                < GenericElement type={con.MULTIPLE_SELECTION}
                            name={"Escenario VaR 2"}
                            id={"escenario_2"} 
                            value={scenarioId2} 
                            setValue={(val) => updateScenario(val,2)} 
                            values={Object.keys(scenarios)}
                            valueLabels={Object.values(scenarios).map((esc) => {return(esc.name)})}
                            />

                    <div className="cardBox scenario-2">
                        
                        <Card elevation={Elevation.TWO} className="card-4">
                            <MediumLabel text="VaR Oportunístico" />
                            <p>Millones de Pesos</p>
                            <SmallLabel text={scenario2 == null ? "Computando..." : formatAccordingToType(con.MONEY, scenario2[con.VAR], {[con.CURRENCY]: con.MONEY_COP, [con.ALLOW_DECIMALS]: true})}/>
                        </Card>

                        <Card elevation={Elevation.TWO} className="card-4">
                            <MediumLabel text="VaR Banda Alta"/>
                            <p>Millones de Pesos</p>
                            <SmallLabel text={scenario2 == null ? "Computando..." : formatAccordingToType(con.MONEY, scenario2[con.HIGHER_BAND+"_"+con.VAR], {[con.CURRENCY]: con.MONEY_COP, [con.ALLOW_DECIMALS]: true})}/>
                        </Card>

                        <Card elevation={Elevation.TWO} className="card-4">
                            <MediumLabel text="VaR Banda Media"/>
                            <p>Millones de Pesos</p>
                            <SmallLabel text={scenario2 == null ? "Computando..." :  formatAccordingToType(con.MONEY, scenario2[con.MID_BAND+"_"+con.VAR], {[con.CURRENCY]: con.MONEY_COP, [con.ALLOW_DECIMALS]: true})}/>
                        </Card>


                        <Card elevation={Elevation.TWO} className="card-4">
                            <MediumLabel text="VaR Banda Baja"/>
                            <p>Millones de Pesos</p>
                            <SmallLabel text={scenario2 == null ? "Computando..." :  formatAccordingToType(con.MONEY, scenario2[con.LOWER_BAND+"_"+con.VAR], {[con.CURRENCY]: con.MONEY_COP, [con.ALLOW_DECIMALS]: true})}/>
                        </Card>



                    </div>
                </div>
            </div>
        </div>
    )
}



const getDefautlData = () =>
{
    return( [{ [NAME] :'Var Oportu.',  [VALUES] : [100, 100], [LABELS]: ["",""]},        
    { [NAME] :'Banda Baja',  [VALUES] : [100, 100], [LABELS]: ["",""]},
    { [NAME] :'Banda Media',  [VALUES] : [100, 100], [LABELS]: ["",""]},
    { [NAME] :'Banda Alta',  [VALUES] : [100, 100], [LABELS]: ["",""]}])
}


export default RiskComparisonForTwoScenarios
