// MobileGlobalView.js
import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import * as con from "../GlobalConstants";
import { RefToken } from '../components/hooks/AuthenticationHook';
import { RefUserAttributesHook } from '../components/hooks/UserAttributesHook';

import MarketViewGlobalViewComponent from '../components/globalViewComponents/MarketViewGlobalViewComponent';
import CurrentPositionGlobalViewComponent from '../components/globalViewComponents/CurrentPositionGlobalViewComponent';

function MobileGlobalView() {
    const componentHeight = con.MOBILE_USABLE_SCREEN_SIZE;

    const [alerts, setAlerts] = useState([]);
    const user = RefUserAttributesHook('user');
    const token = RefToken();

    const fetchAlerts = useCallback(async () => {
        if (user && token) {
            try {
                const response = await axios.get(
                    `${con.rest_base_url}${con.transactions_api}${con.usdcop_alerts_null_reached}${user}/`,
                    {
                        headers: {
                            Authorization: `Token ${token}`,
                        },
                    }
                );
                setAlerts(response.data);
                localStorage.setItem('alerts', JSON.stringify(response.data));
            } catch (error) {
                console.error('Error fetching alerts:', error);
            }
        } else {
            setAlerts([]);
            localStorage.removeItem('alerts');
        }
    }, [user, token]);

    useEffect(() => {
        fetchAlerts();
    }, [fetchAlerts]);

    useEffect(() => {
        const handleStorageChange = (event) => {
            if (event.key === 'alerts') {
                const updatedAlerts = event.newValue ? JSON.parse(event.newValue) : [];
                setAlerts(updatedAlerts);
            }
        };

        window.addEventListener('storage', handleStorageChange);

        return () => {
            window.removeEventListener('storage', handleStorageChange);
        };
    }, []);

    return (      
        <div style={{ minHeight: `${componentHeight}vh`, paddingTop: "15px" }}>
            <MarketViewGlobalViewComponent alerts={alerts} />
            <div style={{ height: "10vh", width: "100%" }}></div> 
            <CurrentPositionGlobalViewComponent componentHeight={componentHeight / 2} />
        </div>
    );
}

export default MobileGlobalView;
