// Categorical Bar and Line Chart Hook based on D3
import  {useMemo, useRef } from 'react'
import { CategoricalBarAndLineChart } from '../classes/CategoricalBarAndLineChart'

 /* Hook returns:
     - Reference for the rendered plot
     - Object of type ChartClass
 */
 export const CategoricalBarAndLineChartHook = ({parameters}) =>
 {
     
    const objectReference = useRef()
    const initialParameters = useRef(parameters)
    const chart = useMemo(() => new CategoricalBarAndLineChart(initialParameters.current, objectReference), [])    
 
    return([objectReference, chart])
 
 }

                             
 