import { Text } from '@blueprintjs/core'
// TODO - Documentar
import React from 'react'
import LoadingIcon from '../../templates/loading/LoadingIcon'
import * as con from "../../GlobalConstants"
import {  RefSelectedAdvisors } from '../hooks/AdvisorsHooks'
import { RefNestedApplicationParameterHook } from '../hooks/ApplicationParametersHooks'
import { IsStaff } from '../hooks/AuthenticationHook'
import { AdvisorsAdd } from '../parameters/SingleParameters'
import Advisor from './Advisor'


function AdvisorPage() {

  // advisors
  const advisors = RefSelectedAdvisors()

  // Is Staff
  const isStaff = IsStaff()

  // Saving Advisor
  const savingStatus = RefNestedApplicationParameterHook(con.ADVISOR_SAVED, con.STATUS)

  return (
    <div>
        <h2>Asesores Vinco</h2>    
        {
        savingStatus === con.LOADING ? 
                  <div style={{marginTop : "35px"}}>
                    <LoadingIcon/>
                    </div> :
                advisors.length === 0 ?
                <Text> Usted todavia no tiene un asesor de vinco asignado. Por favor solicitelo para que le sea asignado uno.</Text>
                : advisors.map(advisor => <Advisor key={advisor[con.ID]} advisor={advisor}/>)                                                
                }
        {isStaff &&
          <div style={{marginTop : "13px"}}>
              <AdvisorsAdd disabled={savingStatus === con.LOADING}/>              
          </div>
        }  

    </div>
  )
}

export default AdvisorPage