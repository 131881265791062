// TODO - Documentar
import React, { useEffect, useState } from 'react'

// Global constants
import * as con from "../../GlobalConstants"

import { StateApplicationParameterHook } from '../hooks/ApplicationParametersHooks'
import { UserLinesHook } from '../hooks/UserLinesHook'
import CustomNavSelect from './CustomNavSelect'

function CustomLineSelect() {

  // Lines 
  const userLines = UserLinesHook()
  const [selectedLine, setSelectedUserLine] = StateApplicationParameterHook(con.SELECTED_LINE)
  
  // Local selectedLine
  const [localSelectedLine, setLocalSelectedLine] = useState(() => selectedLine)

  useEffect(() => {
    
    setLocalSelectedLine(selectedLine)


  }, [userLines, selectedLine])
  
  
  return (
    <CustomNavSelect values={userLines.map(ob => ob[con.ID])} 
                    labels = {userLines.map(ob => ob[con.NAME])} 
                    onChange ={(ob) => setSelectedUserLine(ob.target.value)}
                    selectedValue={localSelectedLine}/> 
  )
}

export default CustomLineSelect