import React from 'react'
import * as con from "../../GlobalConstants"
import * as cc from '../../templates/charts/ChartConstants'
import { SmallLabel } from '../../templates/label/Labels'
import LoadingIcon from '../../templates/loading/LoadingIcon'
import { BandComparisonSummary } from '../bandComparisonSummary/BandComparisonSummary'
import { RefApplicationLoadingParameterHook, RefApplicationParameterHook } from '../hooks/ApplicationParametersHooks'
import { RefUserLineApplicationParameterHook } from '../hooks/UserLineApplicationParametersHooks'
import BandComparisonScenariosSelector from './BandComparisonScenariosSelector'
import GlobalViewComponent from './GlobalViewComponent'


function BandComparisonGlobalViewComponent() {

  const selectedScenarios = RefUserLineApplicationParameterHook(con.SELECTED_BAND_COMPARISON_SCENARIOS)

  const loadingLineApplicationParameters = RefApplicationLoadingParameterHook(con.USER_LINE_APPLICATION_PARAMETERS_FETCHED)
  const transactionsLoaded = RefApplicationParameterHook(con.ALL_TRANSACTIONS_LOADED)

  return (
    <GlobalViewComponent pageLink={con.PAGE_BAND_COMPARISON} 
                            title={"VALOR EN RIESGO OPORTUNÍSTICO"}
                            controls={<BandComparisonScenariosSelector />}> 
                            {

                              (loadingLineApplicationParameters === con.NOT_STARTED || loadingLineApplicationParameters === con.LOADING ||
                                !transactionsLoaded) ?
                                <div style={{marginTop : "25px"}}>
                                  <LoadingIcon />                        
                                </div>

                              : selectedScenarios.length === 0 
                              ? <SmallLabel text="Seleccione al menos un escenario para visualizar la gráfica" />
                              : <BandComparisonSummary selectedScenarios={selectedScenarios}
                              chartParameters = {{[cc.LEGEND_HEIGHT] : 0.15,
                                                  [cc.LEGEND_NUM_ROWS] :  3,
                                                  [cc.INCLUDE_BORDER] : false}}/>  
                            }                       
                                  
    </GlobalViewComponent>     
  )
}

export default BandComparisonGlobalViewComponent