// Module for surrogate transaction functions

import axios from 'axios';
import * as types from "./types"
import store from '../store';
import { getApplicationParameter, setApplicationParameter, setLoadingSurrogateParameter } from './applicationParameters';
import * as con from "./../../GlobalConstants";
import { fetchSurrogateUserList } from './authentication';
import { getToken } from '../logic/supportFunctions';
import { handleRequestError } from '../../utils/errorFunctions';
import { buildSurrogateUserTransactionsDict, buildSurrogateUserTransactionsDictWithObject, buildSurrogateUserTransactionsDictWithValue } from '../../GlobalFunctions';
import { getAllModelParameters } from './modelParameters';
import { fetchAllUserLineComments } from './userLines';


// Surrogate transactions
export const fetchAllSurrogateTransactions = async () =>
{
    // Refreshes all Surrogate User Attributes
    
    setApplicationParameter(con.SURROGATE_TRANSACTIONS_LOADED, {[con.EXPOSURES] : {[con.STATUS] : con.LOADING},
                                                                [con.COVERAGES_FWD] : {[con.STATUS] : con.LOADING},
                                                                [con.COVERAGES_SPOT] : {[con.STATUS] : con.LOADING},
                                                                [con.COVERAGES_OPTION] : {[con.STATUS] : con.LOADING},
                                                                [con.ACCOUNTS] : {[con.STATUS] : con.LOADING},
                                                                [con.MODEL_PARAMETERS] : {[con.STATUS] : con.LOADING},
                                                                [con.COMMENTS] : {[con.STATUS] : con.LOADING}
                                                            })
    setApplicationParameter(con.ALL_SURROGATE_TRANSACTIONS_LOADED, false)
    

    await fetchSurrogateUserList()    

    // Fetches all transactions
    fetchAllSurrogateTransactionsByType(con.transactions_api + con.all_surrogate_user_model_parameters_api, con.MODEL_PARAMETERS)
    fetchAllSurrogateTransactionsByType(con.transactions_api + con.all_surrogate_user_exposures_api, con.EXPOSURES)
    fetchAllSurrogateTransactionsByType(con.transactions_api + con.all_surrogate_user_coverages_fwd_api, con.COVERAGES_FWD)
    fetchAllSurrogateTransactionsByType(con.transactions_api + con.all_surrogate_user_coverages_spot_api, con.COVERAGES_SPOT)
    fetchAllSurrogateTransactionsByType(con.transactions_api + con.all_surrogate_user_coverages_option_api, con.COVERAGES_OPTION)
    fetchAllSurrogateTransactionsByType(con.transactions_api + con.all_surrogate_user_accounts_api, con.ACCOUNTS)
    fetchAllUserLineComments()

}


export const fetchAllSurrogateTransactionsByType = (api, transactionType) =>
{

    setLoadingSurrogateParameter(transactionType, con.LOADING)

    const token = getToken()


    const config = {
        headers : {
            "Authorization" : `Token ${token}`
        }
    }


    axios.get(con.rest_base_url + api, config)
            .then(res => {

                let payload = {[con.TRANSACTION_TYPE]: transactionType, [con.VALUE] : res.data}

                // Sets default values for missing parameter values
                if(transactionType === con.MODEL_PARAMETERS)
                {
                    let defautModelParameters = getAllModelParameters()
                    payload[con.VALUE] = payload[con.VALUE].map(ob => {return({...defautModelParameters, ...ob})})
                    
                }
                

                store.dispatch({
                    type : types.SET_ALL_SURROGATE_USER_TRANSACTIONS,
                    payload : payload
                })

            })
            .catch(err => {
                setLoadingSurrogateParameter(transactionType, con.ERROR)
                handleRequestError(err)}            
            )
}


export const getSurrogateUserDictStructure = (depth = 0) =>
{
    const userDict = getApplicationParameter(con.SURROGATE_USER_DICT)
    return(buildSurrogateUserTransactionsDict(userDict, depth))
} 


export const getSurrogateUserDictStructureWithValue = (value) =>
{
    const userDict = getApplicationParameter(con.SURROGATE_USER_DICT)
    return(buildSurrogateUserTransactionsDictWithValue(userDict, value))
} 


export const getSurrogateUserDictStructureWithObject = (ob) =>
{
    const userDict = getApplicationParameter(con.SURROGATE_USER_DICT)
    return(buildSurrogateUserTransactionsDictWithObject(userDict, ob))
} 