import "./MobilePages.css"

import * as con from "../GlobalConstants"
import { AssetSelectionBar } from "../components/tradingViewComponentes/AssetSelectionBar"
import CustomRealTimeWidgetInitialView from "../components/tradingViewComponentes/CustomRealTimeWidgetInitialView"


function MobileMarketsTechnicalAnalysisPage() {

    return (             
            <div>
                <AssetSelectionBar />
                <div style={{height : (con.MOBILE_USABLE_SCREEN_SIZE/2)+"vh", marginTop : "4vh", paddingLeft : "1vh", paddingRight : "1vh"}}>                
                    <CustomRealTimeWidgetInitialView fixedAsset={false}/>    
                </div>  
            </div>            
    )
}

export default MobileMarketsTechnicalAnalysisPage
