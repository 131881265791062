// Methods for User Lines
import axios from 'axios';
import * as types from "./types"
import store from '../store';
import * as con from "./../../GlobalConstants"
import { handleLineSavedError, handleRequestError } from '../../utils/errorFunctions';
import { setApplicationParameter, setLoadingSurrogateParameter } from './applicationParameters';
import { getSurrogateUser, getToken, getUser, surrogateActive } from '../logic/supportFunctions';



export const getLines = () =>
{
    return(store.getState()[con.STORE][con.REDUCER_USER_LINES])
}

// Exports an edited user line
export const exportUserLine = (line) =>
{
    // Gets Token
    const token = getToken()


    setApplicationParameter(con.USER_LINE_SAVED, {[con.STATUS] : con.LOADING})


    const config = {
        headers : {
            "Authorization" : `Token ${token}`
        }
    }

    let url = con.rest_base_url + `${surrogateActive() ? con.staff_user_line_api : con.user_line_api}`
    url = url + `${line[con.ID]}/`

    axios.put(url, line, config)
            .then(res => {

                store.dispatch({
                    type : types.SET_USER_LINE,
                    payload : res.data
                })

                setApplicationParameter(con.USER_LINE_SAVED, {[con.STATUS] : con.OK})



            })
            .catch((err) => {
                setApplicationParameter(con.USER_LINE_SAVED, {[con.STATUS] : con.ERROR})
                handleLineSavedError(err)
                
            })
}





// Deletes a user line
export const deleteUserLine = (line) =>
{
    // Gets Token
    const token = getToken()


    setApplicationParameter(con.USER_LINE_DELETED, {[con.STATUS] : con.LOADING})


    const config = {
        headers : {
            "Authorization" : `Token ${token}`
        }
    }

        
    let url = con.rest_base_url + `${surrogateActive() ? con.staff_user_line_api : con.user_line_api}`
    url = url + `${line[con.ID]}/`

    axios.delete(url, config)
            .then(res => {

                store.dispatch({
                    type : types.DELETE_USER_LINE,
                    payload : line
                })

                setApplicationParameter(con.USER_LINE_DELETED, {[con.STATUS] : con.OK})

            })
            .catch((err) => {
                setApplicationParameter(con.USER_LINE_DELETED, {[con.STATUS] : con.ERROR})
                handleLineSavedError(err)
                
            })
}



// Creates a new User line
export const createUserLine = (line) =>
{
    // Gets Token
    const token = getToken()

    setApplicationParameter(con.USER_LINE_ADDED, {[con.STATUS] : con.LOADING})

    line[con.USER] = surrogateActive() ? getSurrogateUser()[con.ID] : getUser()[con.ID]


    const config = {
        headers : {
            "Authorization" : `Token ${token}`
        }
    }

        
    let url = con.rest_base_url + `${surrogateActive() ? con.staff_user_line_api : con.user_line_api}`

    axios.post(url, line, config)
            .then(res => {

                store.dispatch({
                    type : types.ADD_USER_LINE,
                    payload : res.data
                })

                setApplicationParameter(con.USER_LINE_ADDED, {[con.STATUS] : con.OK})

            })
            .catch((err) => {
                setApplicationParameter(con.USER_LINE_ADDED, {[con.STATUS] : con.ERROR})
                handleLineSavedError(err)
                
            })
}


export const getNewLineName = () => {


    const linesNames = getLines().map(l => l[con.NAME])

    let newLineName = con.NEW_LINE_NAME
    let counter = 1
    while(linesNames.includes(newLineName))
    {
        newLineName = `${con.NEW_LINE_NAME} ${counter}`
        counter+=1                    
    }

    return(newLineName)

}



// For Staff Only
export const fetchAllUserLineComments = ()=>
{

     // Gets Token
     const token = getToken()

     setLoadingSurrogateParameter(con.COMMENTS, con.LOADING)
  
     const config = {
         headers : {
             "Authorization" : `Token ${token}`
         }
     }
         
     let url = con.rest_base_url + con.staff_user_line_comments_api 
 
     axios.get(url, config)
             .then(res => {
 
                 store.dispatch({
                     type : types.SET_ALL_USER_LINE_COMMENTS,
                     payload : res.data
                 })
 
                 setLoadingSurrogateParameter(con.COMMENTS, con.OK)
 
             })
             .catch((err) => {
                setLoadingSurrogateParameter(con.COMMENTS, con.ERROR)
                 handleRequestError(err)
                 
             })

}


// For Staff Only
export const createUserLineComment = (user_line, user, commenting_user, comment, cleanupFun = () => true)=>
{

     // Gets Token
     const token = getToken()

     setApplicationParameter(con.USER_LINE_COMMENT_ADDED, {[con.STATUS] : con.LOADING})

     let data = {[con.USER_LINE] : user_line,
                 [con.USER] : user,
                 [con.COMMENTING_USER] : commenting_user,
                 [con.COMMENT] : comment}
 
 
     const config = {
         headers : {
             "Authorization" : `Token ${token}`
         }
     }
 
         
     let url = con.rest_base_url + con.staff_user_line_comments_api 
 
     axios.post(url, data, config)
             .then(res => {
 
                 store.dispatch({
                     type : types.ADD_USER_LINE_COMMENT,
                     payload : res.data
                 })
 
                 setApplicationParameter(con.USER_LINE_COMMENT_ADDED, {[con.STATUS] : con.OK})
                 cleanupFun()
 
             })
             .catch((err) => {
                 setApplicationParameter(con.USER_LINE_COMMENT_ADDED, {[con.STATUS] : con.ERROR})
                 handleRequestError(err)
                 
             })

}