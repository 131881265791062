import { Button, FormGroup } from '@blueprintjs/core'
// TODO - Documentar
import React from 'react'
import * as con from "../../GlobalConstants"
import * as XLSX from 'xlsx';
import { Account, CoverageFWD, CoverageOption, CoverageSPOT, Exposure } from '../../store/actions/transactions';
import { AccountsHook, ExposuresHook, ForwardCoveragesHook, OptionCoveragesHook, SpotCoveragesHook } from '../hooks/TransactionsHooks';
import { downloadWorkBook } from '../../utils/downloadFunctions';
import { RefModelParameterHook } from '../hooks/ModelParameterHooks';
import { formatWorkSheet } from '../../utils/excelFunctions';
import { createNotification } from '../../templates/notifications/Notifications';
import { constructFileName } from '../../utils/reportFunctions';


function TransactionsDownload() {

    // Client Type
    const clientType = RefModelParameterHook(con.CLIENT_TYPE)

    // Transactions
    const exposures = ExposuresHook()
    const coveragesFwd = ForwardCoveragesHook()
    const coveragesOption = OptionCoveragesHook()
    const coveragesSpot = SpotCoveragesHook()
    const accounts = AccountsHook()

    // Download currentLine
    const downloadCurrentLine = () =>
    {

      const workbook = XLSX.utils.book_new();

      // Constructs Woorksheets
      const exposuresWoorkSheet = formatWorkSheet(XLSX.utils.json_to_sheet(Exposure.exportToObject(exposures)))
      const coveragesFwdWoorkSheet = formatWorkSheet(XLSX.utils.json_to_sheet(CoverageFWD.exportToObject(coveragesFwd)))
      const coveragesOptionWoorkSheet = formatWorkSheet(XLSX.utils.json_to_sheet(CoverageOption.exportToObject(coveragesOption)))
      const coveragesSpotWoorkSheet = formatWorkSheet(XLSX.utils.json_to_sheet(CoverageSPOT.exportToObject(coveragesSpot, clientType)))
      const accountsWoorkSheet = formatWorkSheet(XLSX.utils.json_to_sheet(Account.exportToObject(accounts, clientType)))

      // Adds to Book
      XLSX.utils.book_append_sheet(workbook, exposuresWoorkSheet, con.EXPOSURES_SHEET_NAME);
      XLSX.utils.book_append_sheet(workbook, coveragesFwdWoorkSheet, con.COVERAGES_FWD_SHEET_NAME);
      XLSX.utils.book_append_sheet(workbook, coveragesOptionWoorkSheet, con.COVERAGES_OPTIONS_SHEET_NAME);

      if(clientType === con.IMPORTER)
        XLSX.utils.book_append_sheet(workbook, coveragesSpotWoorkSheet, con.COVERAGES_SPOT_SHEET_NAME_IMPORTER);
      else if(clientType === con.EXPORTER)
        XLSX.utils.book_append_sheet(workbook, coveragesSpotWoorkSheet, con.COVERAGES_SPOT_SHEET_NAME_EXPORTER);
      else
        throw new Error(`No support for client type: ${clientType}`)   

      XLSX.utils.book_append_sheet(workbook, accountsWoorkSheet, con.ACCOUNTS_SHEET_NAME);
      
      downloadWorkBook(workbook, constructFileName(con.TRANSACTIONS_SHEET_NAME, con.XLSX_FILE_TYPE))

      createNotification("Entradas Descargadas")
      

    }


  return (
    <div>

    
        <div className="columnParameterBox">                    
                  <FormGroup label={"Flujos Actuales"}>
                    <Button text={"Descargar Excel"} icon="download" onClick={(e) => downloadCurrentLine()}/>
                  </FormGroup>                  
              </div>

    </div>
  )
}

export default TransactionsDownload