// Module for annotation functions

import { formatDateAndTime, getNow } from "../../utils/dateFunctions"

// Global constants
import * as con from "../../GlobalConstants"


export const createEditingAnnotation = (transactionID) => {

    const annotation = { [con.TRANSACTION_ID] : transactionID,
                        [con.DATE] : formatDateAndTime(getNow(true)),
                        [con.TEXT] : "Registro editado."}

    return(annotation)

}

export const createFromCalculatorAnnotation = (transactionID) => {

    const annotation = { [con.TRANSACTION_ID] : transactionID,
                        [con.DATE] : formatDateAndTime(getNow(true)),
                        [con.TEXT] : "Generada usando la calculadora."}

    return(annotation)

}