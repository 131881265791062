// TODO - Documentar
import React from 'react'
import * as con from "../../GlobalConstants"


// Style
import "../ComponentsGlobalStyle.css"
import { TransactionTable } from '../transactionTable/TransactionTable'
import { CoverageOption, getTransactionsAnnotationFunction } from '../../store/actions/transactions'
import { OptionCoveragesHook } from '../hooks/TransactionsHooks'
import TransactionTableVisualizer from './TransactionTableVisualizer'
import { RefApplicationParameterHook } from '../hooks/ApplicationParametersHooks'
import { allOptionCoverageColumns, selectedOptionCoverageColumns } from '../../utils/transactionFunctions'

function TableCoveragesOption() {

  // Transaction
  const trans = OptionCoveragesHook()
    
  const filterFun = RefApplicationParameterHook(con.FILTER_FUN)
  const searchFun = RefApplicationParameterHook(con.SEARCH_FUN)
  
  // Columns
  const columns = selectedOptionCoverageColumns.map(col => allOptionCoverageColumns[col[con.ID]])

  return (
        <TransactionTableVisualizer transactionType={con.COVERAGES_OPTION}>           
                    <TransactionTable transactionType={con.COVERAGES_OPTION}
                                      columnArray={columns} 
                                      dataDictionary={trans} 
                                      setRow={(id, vals) => CoverageOption.set(id, vals)} 
                                      addRow={(vals) => CoverageOption.add(vals)}
                                      deleteRow={(id) => CoverageOption.delete(id)}
                                      getAnnotations={getTransactionsAnnotationFunction(con.COVERAGES_OPTION)}
                                      filterFunction={filterFun}
                                      searchFunction={searchFun}/>
        </TransactionTableVisualizer>        
  )
}

export default TableCoveragesOption