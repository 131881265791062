// TODO - Documentar
import { useSelector } from 'react-redux';

import * as con from "../../GlobalConstants"
import { setModelParameter } from '../../store/actions/modelParameters';


/**
 * Hook for all model parameter in the store 
 */
 export function AllModelParameterHook() {

    const value = useSelector(state => state[con.STORE][con.REDUCER_MODEL_PARAMETERS])


    return(value);
}



/**
 * Hook for a single model parameter in the store read only
 */
export function RefModelParameterHook(parameterName) {

    const value = useSelector(state => state[con.STORE][con.REDUCER_MODEL_PARAMETERS][parameterName])


    return(value);
}


/**
 * Hook for a single nested model parameter in the store read only
 */
 export function RefNestedModelParameterHook(parameterName, attributeName) {

    const value = useSelector(state => state[con.STORE][con.REDUCER_MODEL_PARAMETERS][parameterName][attributeName])

    return(value);
}


/**
 * Hook for a single model parameter in the store read and write
 */
 export function StateModelParameterHook(parameterName) {

    const value = useSelector(state => state[con.STORE][con.REDUCER_MODEL_PARAMETERS][parameterName])

    const setParameter = (newVal) => setModelParameter(parameterName, newVal)

    return([value, setParameter]);
}

/**
 * Hook for a single model value in the store. Only write
 */
 export function EditModelValueHook(parameterName) {

    const setParameter = (newVal) => setModelParameter(parameterName, newVal)

    return(setParameter);
}
