// Module for the different cells in a transaction table

// TODO - Documentar
import React from 'react';

import { useState } from 'react'


// Icons
import { FaDollarSign, FaEdit, FaList, FaSave, FaTrashAlt } from "react-icons/fa"



// Custom Modues

import * as con from "../../GlobalConstants"
import Modal from '../../templates/popups/Modal';
import Annotations from './Annotations';
import Prepay from './Prepay';
import { EDIT_COMMAND, PREPAY_EXPOSURE_COMMAND, REMOVE_COMMAND, VIEW_ANNOTATIONS_COMMAND } from './TransactionTableConstants';


// Style
import "../../templates/Templates.css"
import "../../templates/tables/Table.css"
import { GenericCell } from '../../templates/tables/Cells';
import { RefNestedApplicationParameterHook } from '../hooks/ApplicationParametersHooks';


/**
 * Component in charge of managing a cell from the different transactions table
 */
export const GenericCellFromRow = (props) => {

    const {initialValue, setValueLocalRow, ...otherParameters} = props

    const [localValue, setLocalValue] = useState(() => initialValue)

    const setValue = (val) => {

        setLocalValue(val)
        setValueLocalRow(val)
    }
    
    return(<GenericCell {...otherParameters} value={localValue} setValue={setValue} editing={true}  />)

}



/**
 * Component in charge of managing a cell from the different transactions table
 */
export const StaticGenericCellFromRow = (props) => {
    
    return(<GenericCell {...props} editing={false} />)

}


/**
 * Commands Cell
 */
 export const CellCommands = ({idRow, state, comands, editing, startEditRow, stopEditingRow, deleteRow, getAnnotations}) => {
    
    // For annotations
    const [showAnnotations, setshowAnnotations] = useState(() => false)
    
    // For Prepaying
    const [showPrepayDialog, setShowPrepayDialog] = useState(() => false)
    const transactionPepayedStatus = RefNestedApplicationParameterHook(con.TRANSACTION_PREPAYED, con.STATUS)

    return (
        <div className={`flex-celda comandos ${editing ? "command-cell-editing":""}`}>        
            {
            // Editing Button
            comands.includes(EDIT_COMMAND) &&
            editing ? <FaSave style={{cursor: "pointer"}} 
                                onClick={() => stopEditingRow()}
                                title="Salvar"/>
                      : <FaEdit style={{cursor: "pointer"}} 
                                   onClick={() => startEditRow()}
                                   title="Editar"/>
            }
            {
            // Annotations Button
            comands.includes(VIEW_ANNOTATIONS_COMMAND) &&                
                    <FaList style={{cursor: "pointer"}}
                                onClick={() => setshowAnnotations(!showAnnotations)}
                                title="Ver Anotaciones"/>                      
            }
            {
            // Prepay Button
            comands.includes(PREPAY_EXPOSURE_COMMAND)  &&             
                    <FaDollarSign style={{cursor: (state === con.ACTIVE) ? "pointer" : "not-allowed",
                                          opacity: (state === con.ACTIVE) ? 1 : 0.4}}
                                    onClick={() => setShowPrepayDialog(!showPrepayDialog)}
                                    title="Prepagar"/>                      
            }            
            {
            // Delete Button
            comands.includes(REMOVE_COMMAND) &&
            <FaTrashAlt style={{cursor: "pointer"}}
                        onClick={deleteRow}
                        title="Eliminar"/>                                   
            }
            {
                // Annotations Modal
                comands.includes(VIEW_ANNOTATIONS_COMMAND) &&
                <Modal show={showAnnotations} doAccept={null} doCancel={() => setshowAnnotations(false)}>
                    <Annotations annotations={getAnnotations()} />
                </Modal>
            }
            {
                // Prepay Modal
                comands.includes(PREPAY_EXPOSURE_COMMAND) && state === con.ACTIVE &&
                <Modal show={showPrepayDialog} doAccept={null} doCancel={transactionPepayedStatus === con.LOADING ? null : () => setShowPrepayDialog(false)}>
                    <Prepay expId={idRow} closeDialog={() => setShowPrepayDialog(false)}/>
                </Modal>
            }
            
        </div>
    )
}





