// TODO - Documentar
import React, { useEffect, useState } from 'react'
import { RefApplicationParameterHook } from '../hooks/ApplicationParametersHooks'
import { RefSurrogateUser, RefUser } from '../hooks/AuthenticationHook'
import CustomNavSelect from './CustomNavSelect'
// Global constants
import * as con from "../../GlobalConstants"
import { setSurrogateUserById } from '../../store/actions/authentication'
import { sortArrayByAttribute } from '../../GlobalFunctions'

function CustomUsersSelect() {

    // Current user
   const user = RefUser()

   // Surrogate User List
   const surrogateUserList = RefApplicationParameterHook(con.SURROGATE_USER_DICT)


    // Surrogate user
    const surrogateUser = RefSurrogateUser()

    const [selectedValue, setSelectedValue] = useState(() => user[con.ID])

    useEffect(() => {
              
        setSelectedValue(surrogateUser === null ? user[con.ID] : surrogateUser[con.ID])

    }, [surrogateUser, user])
    

    
   // Local Data
   const [completeSurrogateList, setCompleteSurrogateList] = useState(() => [user].concat(Object.values(surrogateUserList)))


   useEffect(() => {
     setCompleteSurrogateList([user].concat(Object.values(surrogateUserList)))
     
   }, [surrogateUserList, user])
   

  return (
    
    <CustomNavSelect values={sortArrayByAttribute(completeSurrogateList,con.USERNAME).map(ob => ob[con.ID])} 
                    labels = {sortArrayByAttribute(completeSurrogateList,con.USERNAME).map(ob => ob[con.USERNAME])} 
                    onChange ={(ob) => setSurrogateUserById(ob.target.value)}
                    selectedValue={selectedValue}/> 
                                                              
  )
}

export default CustomUsersSelect