// TODO - Documentar
import React from 'react'
import "./Pages.css"

import OrionHistoricChart from '../components/orion/OrionHistoricChart'
import OrionSpeedometerChart from '../components/orion/OrionSpeedometerChart'
import OrionHistoricTable from '../components/orion/OrionHistoricTable'

function MarketsOrionOverview() {
    
    return (
        <div >
            <h2>Modelo Orión</h2>
            <div style={{marginLeft : "15%", marginRight : "15%", marginTop : "10px"}}>
                <OrionHistoricTable />
            </div>
          <div className="gridBox" style={{paddingLeft : "1vh", paddingRight : "1vh", marginTop : "5px"}}>                 
                <div style={{flexBasis : "70%", marginRight : "0.5vh"}}>                             
                        <OrionHistoricChart/>
                    </div>           
                <div style={{flexBasis : "30%", marginLeft : "0.5vh"}}>                   
                    <OrionSpeedometerChart />                               
                </div>               
          </div>  
        </div>
      

    )
}

export default MarketsOrionOverview
