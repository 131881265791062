// Exposures
// TODO - Documentar
import React from 'react'
import * as con from "../../GlobalConstants"

import { EDIT_COMMAND, PREPAY_EXPOSURE_COMMAND, REMOVE_COMMAND, VIEW_ANNOTATIONS_COMMAND } from '../transactionTable/TransactionTableConstants'

// Style
import "../ComponentsGlobalStyle.css"
import { TransactionTable } from '../transactionTable/TransactionTable'
import { Exposure, getTransactionsAnnotationFunction } from '../../store/actions/transactions'
import TransactionTableVisualizer from './TransactionTableVisualizer'
import { ExposuresHook } from '../hooks/TransactionsHooks'
import { RefApplicationParameterHook } from '../hooks/ApplicationParametersHooks'
import { allExposureColumns, selectedExposureColumns } from '../../utils/transactionFunctions'

function TableExposures() {

  // Transaction
  const trans = ExposuresHook()
    
  const filterFun = RefApplicationParameterHook(con.FILTER_FUN)
  const searchFun = RefApplicationParameterHook(con.SEARCH_FUN)
    
  // Columns
  const columns = selectedExposureColumns.map(col => allExposureColumns[col[con.ID]])

  return (
         
            <TransactionTableVisualizer transactionType={con.EXPOSURES}>             
                    <TransactionTable transactionType={con.EXPOSURES}
                            columnArray={columns} 
                            dataDictionary={trans} 
                            setRow={(id, vals) => Exposure.set(id, vals)} 
                            addRow={(vals) => Exposure.add(vals)}
                            deleteRow={(id) => Exposure.delete(id)}
                            getAnnotations={getTransactionsAnnotationFunction(con.EXPOSURES)}
                            commands={[EDIT_COMMAND, VIEW_ANNOTATIONS_COMMAND, PREPAY_EXPOSURE_COMMAND, REMOVE_COMMAND]}
                            filterFunction={filterFun}
                            searchFunction={searchFun}/>
            </TransactionTableVisualizer>
 
  )
}

export default TableExposures