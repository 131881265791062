import React, { useState, useEffect } from 'react';
import { Button } from '@blueprintjs/core';
import Modal from '../../templates/popups/Modal';
import { FaTrash } from 'react-icons/fa'; 
import axios from 'axios';
import EditableRow from './EditableRow';
import chevron from '../../img/icons/chevron.png';
import doubleChevron from '../../img/icons/double-chevron.png';
import { RefToken } from '../hooks/AuthenticationHook';
import { RefUserAttributesHook } from '../hooks/UserAttributesHook';
import * as con from "./../../GlobalConstants";

const Loader = () => (
    <div className="spinner" style={{
        display: 'inline-block',
        width: '40px',
        height: '40px',
        border: '4px solid rgba(255, 255, 255, 0.3)',
        borderRadius: '50%',
        borderTop: '4px solid white',
        animation: 'spin 1s linear infinite'
    }}></div>
);

function AlertasModal({ isOpen, handleClose, alerts, setAlerts }) {
    const [addingNewAlert, setAddingNewAlert] = useState(false);
    const [newAlert, setNewAlert] = useState({ alert_type: 'above', level: '' });
    const [errorMessage, setErrorMessage] = useState('');
    const [addingAlert, setAddingAlert] = useState(false);
    const [deletingAlert, setDeletingAlert] = useState(false);

    const [currentPage, setCurrentPage] = useState(1);
    const alertsPerPage = 5; 

    useEffect(() => {
        setCurrentPage(1);
    }, [alerts]);

    const token = RefToken();
    const userId = RefUserAttributesHook('user');

    const handleAddAlert = async () => {
        setAddingAlert(true); 
        try {
            const levelValue = parseFloat(newAlert.level);
            if (isNaN(levelValue)) {
                setErrorMessage('El nivel debe ser un número válido.');
                setAddingAlert(false);
                return;
            }
            const response = await axios.post(
                `${con.rest_base_url}${con.transactions_api}${con.usdcop_market_alerts}`,
                {
                    user: userId,
                    alert_type: newAlert.alert_type,
                    level: levelValue,
                },
                {
                    headers: {
                        Authorization: `Token ${token}`,
                    },
                }
            );
            const updatedAlerts = [...alerts, response.data];
            setAlerts(updatedAlerts);
            localStorage.setItem('alerts', JSON.stringify(updatedAlerts)); 
            setAddingNewAlert(false);
            setErrorMessage('');
            setNewAlert({ alert_type: 'above', level: '' });
        } catch (error) {
            console.error('Error al agregar alerta:', error);
            setErrorMessage('Error al agregar alerta.');
        } finally {
            setAddingAlert(false);  
        }
    };

    const handleCancelAdd = () => {
        setAddingNewAlert(false);
        setNewAlert({ alert_type: 'above', level: '' });
        setErrorMessage('');
    };

    const handleDeleteAlert = async (alertId) => {
        setDeletingAlert(true); 
        try {
            await axios.delete(
                `${con.rest_base_url}${con.transactions_api}${con.usdcop_market_alerts}${alertId}/`,
                {
                    headers: {
                        Authorization: `Token ${token}`,
                    },
                }
            );
            const updatedAlerts = alerts.filter((alert) => alert.id !== alertId);
            setAlerts(updatedAlerts);
            localStorage.setItem('alerts', JSON.stringify(updatedAlerts)); 
        } catch (error) {
            console.error('Error al eliminar alerta:', error);
            setErrorMessage('Error al eliminar alerta.');
        } finally {
            setDeletingAlert(false); 
        }
    };

    // Paginación
    const indexOfLastAlert = currentPage * alertsPerPage;
    const indexOfFirstAlert = indexOfLastAlert - alertsPerPage;
    const currentAlerts = alerts.slice(indexOfFirstAlert, indexOfLastAlert);
    const totalPages = Math.ceil(alerts.length / alertsPerPage);

    const handleFirstPage = () => setCurrentPage(1);
    const handlePreviousPage = () => setCurrentPage(prevPage => Math.max(prevPage - 1, 1));
    const handleNextPage = () => setCurrentPage(prevPage => Math.min(prevPage + 1, totalPages));
    const handleLastPage = () => setCurrentPage(totalPages);

    const alertTypeLabels = {
        'above': 'Alza',
        'below': 'Baja',
    };

    return (
        <Modal show={isOpen} doAccept={null} showCancel={false} doCancel={handleClose} style={{ padding: '20px' }}>
            <div style={{ position: 'relative', textAlign: 'center', paddingBottom: '10px' }}>
                <h2 style={{ margin: 0 }}>ALERTAS</h2>
                <Button 
                    icon="plus" 
                    text="Agregar alerta" 
                    style={{ position: 'absolute', top: '0', right: '0' }} 
                    onClick={() => setAddingNewAlert(true)} 
                    disabled={!userId || !token} 
                />
            </div>

            {alerts.length === 0 && !addingNewAlert ? (
                <div style={{ textAlign: 'center', color: 'white', marginTop: "20px" }}>¡No tienes alertas creadas!</div>
            ) : (
                <>
                    {/* Controles de paginación */}
                    <div 
                        className="pagination" 
                        style={{ 
                            textAlign: 'center', 
                            marginTop: '20px',  
                            fontSize: '16px',  
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center'
                        }}
                    >
                        <button 
                            onClick={handleFirstPage} 
                            disabled={currentPage === 1} 
                            style={{
                                background: 'none',
                                border: 'none',
                                cursor: 'pointer',
                                fontSize: 'inherit',
                                color: 'white',
                                margin: '0 5px',
                            }}
                        >
                            <img src={doubleChevron} alt="Primera página" style={{ width: '16px', height: '16px', transform: 'scaleX(-1)', filter: 'invert(100%)' }} />
                        </button>
                        <button 
                            onClick={handlePreviousPage} 
                            disabled={currentPage === 1} 
                            style={{
                                background: 'none',
                                border: 'none',
                                cursor: 'pointer',
                                fontSize: 'inherit',
                                color: 'white',
                                margin: '0 5px',
                            }}
                        >
                            <img src={chevron} alt="Página anterior" style={{ width: '16px', height: '16px', transform: 'scaleX(-1)', filter: 'invert(100%)' }} />
                        </button>
                        <span style={{ margin: '0 10px', color: 'white' }}>
                            Página {currentPage} de {totalPages}
                        </span>
                        <button 
                            onClick={handleNextPage} 
                            disabled={currentPage === totalPages} 
                            style={{
                                background: 'none',
                                border: 'none',
                                cursor: 'pointer',
                                fontSize: 'inherit',
                                color: 'white',
                                margin: '0 5px',
                            }}
                        >
                            <img src={chevron} alt="Siguiente página" style={{ width: '16px', height: '16px', filter: 'invert(100%)' }} />
                        </button>
                        <button 
                            onClick={handleLastPage} 
                            disabled={currentPage === totalPages} 
                            style={{
                                background: 'none',
                                border: 'none',
                                cursor: 'pointer',
                                fontSize: 'inherit',
                                color: 'white',
                                margin: '0 5px',
                            }}
                        >
                            <img src={doubleChevron} alt="Última página" style={{ width: '16px', height: '16px', filter: 'invert(100%)' }} />
                        </button>
                    </div>

                    {/* tabla alertas */}
                    <div className="contenedor-tabla" style={{ fontSize: '18px', marginTop: '5px' }}>
                        <div className="flex-tabla">
                            <div className="flex-fila header-row">
                                <div className="flex-celda" style={{ width: '40%', textAlign: 'center' }}>Nivel</div>
                                <div className="flex-celda" style={{ width: '40%', textAlign: 'center' }}>Supera</div>
                                <div className="flex-celda" style={{ width: '5%', textAlign: 'center' }}></div>
                            </div>

                            {currentAlerts.map((alert, index) => (
                                <div className={`flex-fila ${(index % 2 === 0) ? "even-row" : "uneven-row"}`} key={alert.id}>
                                    <div className="flex-celda" style={{ width: '40%', textAlign: 'center' }}>{alert.level}</div>
                                    <div className="flex-celda" style={{ width: '40%', textAlign: 'center' }}>
                                        {alertTypeLabels[alert.alert_type] || alert.alert_type}
                                    </div>
                                    <div className="flex-celda" style={{ width: '5%', textAlign: 'center' }}>
                                        <FaTrash 
                                            style={{ cursor: 'pointer', height: '16px' }} 
                                            onClick={() => handleDeleteAlert(alert.id)} 
                                        />
                                    </div>
                                </div>
                            ))}

                            {/* Mostrar EditableRow si se está agregando una nueva alerta */}
                            {addingNewAlert && (
                                <EditableRow
                                    newAlert={newAlert}
                                    setNewAlert={setNewAlert}
                                    handleAddAlert={handleAddAlert}
                                    handleCancelAdd={handleCancelAdd}
                                />
                            )}
                        </div>
                    </div>
                </>
            )}

            {addingAlert && (
                <div style={{ textAlign: 'center', marginTop: '10px' }}>
                    <Loader /> 
                </div>
            )}

            {deletingAlert && (
                <div style={{ textAlign: 'center', marginTop: '10px' }}>
                    <Loader /> 
                </div>
            )}

            {errorMessage && (
                <div style={{ color: 'red', marginTop: '10px', textAlign: 'center' }}>
                    {errorMessage}
                </div>
            )}
        </Modal>
    );
}

export default AlertasModal;
