// Download Functions

import axios from 'axios';
import * as XLSX from 'xlsx';
import { handleRequestError } from './errorFunctions';

/**
 * Method that downloads a file from a server's response
 * @param {object} resp - Server's response 
 * @param {string} fileName - Filename. Should include the file ending type. 
 */
export const downloadFileFromResponse = (resp, fileName) =>{

    const url = window.URL.createObjectURL(new Blob([resp.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();

}

/**
 * Download a given array as an Excel worksheet. This method uses the XLSX library.
 * @param {array[object]} array - The array of objects to download. All columns will be included 
 * @param {string} filename - Filename. Should include the file ending type (.xlsx)
 */
export const downloadExcelFromArray = (array, filename = "Resumen") =>{

    const worksheet = XLSX.utils.json_to_sheet(array);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, filename);
    XLSX.writeFile(workbook, filename);
}


/**
 * Downloads an Excel workbook. This method uses the XLSX library.
 * @param {XLSX.workbook} workbook 
 * @param {string} name - File name. Should include the file ending type (.xlsx) 
 */
export const downloadWorkBook = (workbook, name) =>{
    XLSX.writeFile(workbook, name);
}

/**
 * Method that downloads file from a given link.
 * @param {string} url - Download URL  
 * @param {string} fileName - Filename. Should include the file ending type. 
 */
export const downloadFromLink = (url, fileName) =>
{

    axios.get(url, {responseType: 'blob'})
    .then(resp => {
        downloadFileFromResponse(resp, fileName) 
        
    })
    .catch((err) => {
        handleRequestError(err)        
    })


}
