// TODO - Documentar
import React from 'react'
import { BandPositionSummary } from '../components/bandPositionSummary/BandPositionSummary'
import BandPositionParameters from '../components/parameters/BandPositionParameters'


function BandPositionPage() {
    return (
        <div > 
            <h3>Posición en Bandas </h3>  
            <BandPositionParameters />           
            <div style={{ paddingTop : "20px", paddingLeft : '15px', paddingRight : '15px'}}>
                <BandPositionSummary />
            </div>
        </div>
    )
}

export default BandPositionPage
