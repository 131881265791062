// TODO - Documentar
import { useSelector } from 'react-redux';

import * as con from "../../GlobalConstants"

/**
 * Hook for a single user attriburte in the store read only
 */
export function RefUserAttributesHook(attributeName) {

    const value = useSelector(state => state[con.STORE][con.REDUCER_USER_PROFILE][attributeName])

    return(value);
}




/**
 * Hook for all model user attributes in the store 
 */
 export function AllUserAttributesHook() {

    const value = useSelector(state => state[con.STORE][con.REDUCER_USER_PROFILE])

    return(value);
}





