// TODO - Documentar
import React, {useEffect, useState} from 'react'
import { formatAccordingToType, getClassByType } from '../Types'

import { ASCENDING, COMPRESS, DESCENDING, MOBILE_MIN_FONT, MOBILE_MAX_FONT, EDITABLE, ID, MAX_FONT, NAME, SET_VALUE, TYPE } from '../../GlobalConstants';
import { sortTransactionKeysByColumn } from '../../utils/transactionFunctions';
import { Icon } from '@blueprintjs/core';
import { filterObject } from '../../GlobalFunctions';
import { GenericCell } from './Cells';
import * as con from"../../GlobalConstants"
import { getApplicationParameter } from '../../store/actions/applicationParameters';

export const fontCalculator = (targetSize,totalRows) =>{


    const isMobile = getApplicationParameter(con.IS_MOBILE)

    let size = null

    if(isMobile)
    {
        size = 0.55*targetSize/totalRows**1.01
        size = Math.max(MOBILE_MIN_FONT,Math.min(MOBILE_MAX_FONT,size))

        return( `calc(8px + ${size}vw)`)
    }
    else
    {
        size = 0.64*targetSize/totalRows**1.01
        return( `${Math.min(MAX_FONT,size)}vh`)
    }
        
}


// SemiStatic table
// Builds over regular table
export const SemiStaticTable = ({columnArray, dataDictionary, summaryRow, fixedSize, filterFunction, onclickFunction, sortable, compress, staticTable, smallFont, selectedColumn}) => {
    
    const [localData, setLocalData] = useState(() => dataDictionary)
    const [localSummaryData, setLocalSummaryData] = useState(() => summaryRow)
    const [totalRows, setTotalRows] = useState(() => Math.max(1, Object.keys(dataDictionary).length))

    const [sortColId, setSortColId] = useState(() => null)
    const [order, setOrder] = useState(() => ASCENDING)
    

    const changeSortingCol = (col) =>
    {
        if(sortable)
        {

            let newSortDirection = order
            if(col === sortColId)
                newSortDirection = order === ASCENDING ? DESCENDING : ASCENDING
            
            setSortColId(col)
            setOrder(newSortDirection)
        }

       
    }

    useEffect(() => {
        setLocalData(dataDictionary)
        setLocalSummaryData(summaryRow)
        let totalRowsTemp = 1 + Object.keys(dataDictionary).length
        if(summaryRow!=null)
            totalRowsTemp += 1

        setTotalRows(totalRowsTemp)
        

    }, [dataDictionary, summaryRow]) 

    const StaticRow = ({idFil, numRow}) =>
    {        
        
        return(
            <div className={`flex-fila noCarret hoverable-row static-row ${onclickFunction !== null ? "clickable-row" : ""} ${numRow%2 === 0 ? "even-row" : "uneven-row"}`} 
                onClick={onclickFunction === null ? () => null : () => onclickFunction(localData[idFil])}>
            {  
                columnArray.map((col) => 
                <div key={col[ID]} className={`flex-celda ${col[ID] === selectedColumn ? "selected-cell" : ""} ${getClassByType(col[TYPE], localData[idFil][col[ID]], col)}`}>
                    {
                    staticTable || !col[EDITABLE] ? formatAccordingToType(col[TYPE], compress && col[COMPRESS] !== undefined? col[COMPRESS](localData[idFil][col[ID]], col) : localData[idFil][col[ID]], col)
                            : <GenericCell {...col} cellType={col[TYPE]} value={localData[idFil][col[ID]]} setValue={col[SET_VALUE] === undefined ? (val) => true : (val) => col[SET_VALUE](idFil, val)}  editing={true} editable={true}/>
                    }
                
                </div>)
            }
            </div>
        )
    }

    const SummaryRow = () =>
    {
        return(
            <div className="flex-fila noCarret summary-row">
            {  
                columnArray.map((col) => 
                <div key={col[ID]} className={"flex-celda summary-cell " + getClassByType(col[TYPE], localSummaryData[col[ID]], col)}>
                    {formatAccordingToType(col[TYPE], compress && col[COMPRESS] !== undefined? col[COMPRESS](localSummaryData[col[ID]], col) : localSummaryData[col[ID]], col)}
                </div>)
            }
            </div>
        )

    }

    return (
    <div className="contenedor-tabla noCarret" style={ fixedSize == null ?{} : {fontSize : fontCalculator(fixedSize, totalRows)}}>         
            <div className={ `flex-tabla ${smallFont ? "small-font" :""} ${fixedSize ? "" : "nonFixedTable"}`}> 
                <div className="flex-fila header-row sticky" style={{top : con.NAV_BAR + "vh"}}>
                    {  
                        // Iterates over the headers
                        columnArray.map((col) =>                 
                        <div key={col[ID]} onClick={() => changeSortingCol(col[ID])} className={`flex-celda ${ col[ID] === sortColId?"selected-header-cell":""}`}>
                        {col[NAME].toUpperCase()}                  
                        { col[ID] === sortColId && order === DESCENDING && <span><Icon style={{marginLeft : "2px"}} icon="arrow-up"/></span> }
                        { col[ID] === sortColId && order === ASCENDING && <span><Icon style={{marginLeft : "2px"}} icon="arrow-down"/></span> }                          
                        </div>
                        )
                        
                    }                                                           
                </div>
           
                {  
                    // Iterates over the data
                    sortTransactionKeysByColumn(filterObject(localData, filterFunction), sortColId, order).map((k,i) => <StaticRow key={k} idFil={k} numRow={i}/>)
                    
                }
                {  
                    // Adds summary Row
                    localSummaryData != null && Object.keys(localSummaryData).length > 0 &&
                    <SummaryRow/>
                    
                }
            </div>    
        </div>
    )
}

SemiStaticTable.defaultProps = {
    summaryRow: null,
    fixedSize : null,
    filterFunction : (ob) => true,
    onclickFunction : null,
    sortable : true,
    compress : false,
    staticTable : true,
    smallFont : false,
    selectedColumn : null
  };



// Static Table
export const StaticTable = (parameters) => {
  
    return(<SemiStaticTable {...parameters}  staticTable={true}/>)
}



