import React from 'react';
import { useSelector } from 'react-redux';
import * as con from "../../GlobalConstants";
import GlobalViewComponent from './GlobalViewComponent';
import MarketChart from '../charts/MarketChart';

function MarketViewGlobalViewComponent({ alerts }) {

  const marketValues = useSelector(state => state[con.STORE]['market_values']['historic_dollar_info']);

  return (
    <GlobalViewComponent 
      pageLink={con.PAGE_MARKETS_TECHNICAL_ANALYSIS}
      title={"MERCADO"}
    >
      <MarketChart data={marketValues} alerts={alerts} />
    </GlobalViewComponent>
  );
}

export default MarketViewGlobalViewComponent;
