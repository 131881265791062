import axios from "axios"
import * as con from "../GlobalConstants"
import { standardNormalDistribution, sum } from "../GlobalFunctions"
import { daysBetweenDates } from "./dateFunctions"
import { getClosestTRM } from "./marketFunctions"
import { getFinanceRequestConfig } from "../store/logic/supportFunctions"

/**
 * Method that computes the Option Assessment Columns
 * @param {object} op - Option Coverage Object
 * @param {date} referenceDate - Reference date for assesment
 * @param {number} currentOptionRate - Current rate to be used. Should correspond to the SPOT or to the TRM
 * @param {number} hist_volatility - USDCOP Historical volatility 
 * @param {function} sofrFunction - Function that returns the sofr given the day
 * @param {function} ibrFunction - Function that returns the Incremental Borrowing Rate given the day
 * @returns {object} New object including the Assessment columns
 */
export const computeOptionAssessmentColumns = (op, referenceDate, currentOptionRate, hist_volatility, sofrFunction, ibrFunction, volatilitySurface) =>
{

    // Computes Expiry Days
    op[con.EXPIRY_DAYS] = daysBetweenDates(referenceDate, op[con.EXPIRATION_DATE], false)

    if(op[con.EXPIRY_DAYS] < 0)
    {
        op[con.EXPIRY_DAYS] = 0
        op[con.EXPIRE_YEARS] = 0
        op[con.FOREIGN_RATE] = 0
        op[con.DISCOUNT_RATE] = 0
        op[con.FREE_RISK_RATE] = 0
        op[con.OPTION_D1_HIST] = 0
        op[con.OPTION_D2_HIST] = 0
        op[con.OPTION_N_D1_HIST] = 0
        op[con.OPTION_N_D2_HIST] = 0
        op[con.OPTION_N_NEG_D1_HIST] = 0
        op[con.OPTION_N_NEG_D2_HIST] = 0
        op[con.DELTA_CALL] = 0
        op[con.DELTA_PUT] = 0
        op[con.DELTA] = 0
        op[con.VOLATILITY_CURVE] = 0
        op[con.OPTION_D1_VOL] = 0
        op[con.OPTION_D2_VOL] = 0
        op[con.OPTION_N_D1_VOL] = 0
        op[con.OPTION_N_D2_VOL] = 0

        // Assessment Rate
        op[con.ASSESSMENT_RATE] = getClosestTRM(op[con.EXPIRATION_DATE])
        
        // Calculate Option Value
        if (op[con.STRIKE] <= op[con.ASSESSMENT_RATE]){
            if(op[con.OPTION_TYPE] === con.CALL){
                op[con.OPTION_VALUE] = op[con.ASSESSMENT_RATE] - op[con.STRIKE]
            }
            else {
                op[con.OPTION_VALUE] = 0
            }
        } else if (op[con.STRIKE] > op[con.ASSESSMENT_RATE]){
            if(op[con.OPTION_TYPE] === con.PUT){
                op[con.OPTION_VALUE] = op[con.STRIKE] - op[con.ASSESSMENT_RATE]
            }else {
                op[con.OPTION_VALUE] = 0
            }
        }

    }
    else
    {
        // Calculate EXPIRE TIME IN YEAR TERM
        op[con.EXPIRE_YEARS] = op[con.EXPIRY_DAYS] / 365

        // Devaluation Value
        op[con.FOREIGN_RATE] = sofrFunction(op[con.EXPIRY_DAYS])

        //Discount rate
        op[con.DISCOUNT_RATE] = ibrFunction(op[con.EXPIRY_DAYS])

        // Free Risk Rate
        op[con.FREE_RISK_RATE] = op[con.DISCOUNT_RATE] - op[con.FOREIGN_RATE]

        // Historical D1
        op[con.OPTION_D1_HIST] = (Math.log(currentOptionRate / op[con.STRIKE]) + (op[con.FREE_RISK_RATE] + Math.pow(hist_volatility, 2) / 2) * op[con.EXPIRE_YEARS] ) / (hist_volatility * Math.sqrt(op[con.EXPIRE_YEARS]));

        // Historical D2
        op[con.OPTION_D2_HIST] = op[con.OPTION_D1_HIST] - (hist_volatility * Math.sqrt(op[con.EXPIRE_YEARS]))

        // Historical D1 Standard Distribution
        op[con.OPTION_N_D1_HIST] = standardNormalDistribution(op[con.OPTION_D1_HIST])

        // Historical D2 Standard Distribution
        op[con.OPTION_N_D2_HIST] = standardNormalDistribution(op[con.OPTION_D2_HIST])

        // Historical Inverse D1 Standard Distribution
        op[con.OPTION_N_NEG_D1_HIST] = standardNormalDistribution(-op[con.OPTION_D1_HIST])

        // Historical Inverse D2 Standard Distribution
        op[con.OPTION_N_NEG_D2_HIST] = standardNormalDistribution(-op[con.OPTION_D2_HIST])

        // Delta CALL
        op[con.DELTA_CALL] = Math.exp(-op[con.FOREIGN_RATE] * op[con.EXPIRE_YEARS]) * op[con.OPTION_N_D1_HIST]

        // Delta PUT
        op[con.DELTA_PUT] = op[con.DELTA_CALL] - Math.exp(-op[con.FOREIGN_RATE] * op[con.EXPIRE_YEARS])

        // Delta
        op[con.DELTA] = op[con.OPTION_TYPE] === con.CALL ? op[con.DELTA_CALL] : op[con.DELTA_PUT]

        // Volatility Curve
        op[con.VOLATILITY_CURVE] = getCurrentVolatilityCurve(volatilitySurface, op[con.EXPIRY_DAYS], op[con.DELTA])

        // Volatility D1
        op[con.OPTION_D1_VOL] = (Math.log(currentOptionRate / op[con.STRIKE]) + (op[con.FREE_RISK_RATE] + Math.pow(op[con.VOLATILITY_CURVE], 2) / 2) * op[con.EXPIRE_YEARS] ) / (op[con.VOLATILITY_CURVE] * Math.sqrt(op[con.EXPIRE_YEARS]));

        // Volatility D2
        op[con.OPTION_D2_VOL] = op[con.OPTION_D1_VOL] - (op[con.VOLATILITY_CURVE] * Math.sqrt(op[con.EXPIRE_YEARS]))

        // Volatility D1 Standard Distribution
        op[con.OPTION_N_D1_VOL] = standardNormalDistribution(op[con.OPTION_D1_VOL])

        // Volatility D2 Standard Distribution
        op[con.OPTION_N_D2_VOL] = standardNormalDistribution(op[con.OPTION_D2_VOL])

        if(op[con.OPTION_TYPE] === con.CALL){
            op[con.OPTION_VALUE] = Math.exp(-op[con.FOREIGN_RATE] * op[con.EXPIRE_YEARS]) * op[con.OPTION_N_D1_VOL] * currentOptionRate - op[con.STRIKE] * Math.exp(-op[con.DISCOUNT_RATE] * op[con.EXPIRE_YEARS]) * op[con.OPTION_N_D2_VOL]
        } else {
            op[con.OPTION_VALUE] = op[con.STRIKE] * Math.exp(-op[con.DISCOUNT_RATE] * op[con.EXPIRE_YEARS]) * (1 - op[con.OPTION_N_D2_VOL]) - currentOptionRate * (1 - op[con.OPTION_N_D1_VOL]) * Math.exp(-op[con.FOREIGN_RATE] * op[con.EXPIRE_YEARS])
        }

        // Assessment Rate
        op[con.ASSESSMENT_RATE] = currentOptionRate
    }

    // P&G Premium
    op[con.PREMIUM_BALANCE] = op[con.COVERAGE_TYPE] === con.BUY ? -1*op[con.PREMIUM] : op[con.PREMIUM]
    op[con.PREMIUM_GAINS] = op[con.PREMIUM_BALANCE] * op[con.AMOUNT]

    // To Position calculations
    op[con.COVERAGES] = Math.abs(op[con.DELTA]) * op[con.AMOUNT]

    // Assessment
    op[con.ASSESSMENT] = op[con.AMOUNT] * op[con.OPTION_VALUE]
    op[con.ASSESSMENT] = op[con.COVERAGE_TYPE] === con.BUY ? op[con.ASSESSMENT] : -1*op[con.ASSESSMENT] 

    op[con.TOTAL_ASSESSMENT] = op[con.ASSESSMENT] + op[con.PREMIUM_GAINS]

    return(op)
}


const getCurrentVolatilityCurve = (volatilitySurface, date, delta) => {

    // Iterate over each volatility data in the volatility surface
    for(const vol of volatilitySurface) {

        // Check if the provided date is present in the volatility data
        if (date in vol) {
            // Extract deltas and volatilities from the volatility data
            const deltas = vol[date][con.DELTAS]
            const volatilities = vol[date][con.VOLATILITIES]

            // Check if the lengths of deltas and volatilities arrays are equal
            if(deltas.length !== volatilities.length)
            throw new Error(`Day array (length ${deltas.length}) must be the same length as the values array (length ${volatilities.length})`)

            // Initialize variables to store the closest deltas and their positions
            let min_delta = Math.min(...deltas); // Initialize min_delta with the min element of the list
            let max_delta = Math.max(...deltas); // Initialize max_delta with the max element of the list
            let min_volatility = volatilities[deltas.indexOf(min_delta)]; // Find the volatility corresponding to the minimum delta
            let max_volatility = volatilities[deltas.indexOf(max_delta)]; /// Find the volatility corresponding to the maximum delta
            
            // Iterate over the list of deltas to find the closest values and their positions
            for (let i = 0; i < deltas.length; i++) {
                const d = deltas[i];
                // Update min_delta and min_delta_index if the current delta is less than the target delta and greater than the current min_delta
                if (d < delta && d > min_delta) {
                    min_delta = d;
                    min_volatility = volatilities[i];
                }
                // Update max_delta and max_delta_index if the current delta is greater than the target delta and less than the current max_delta
                if (d > delta && d < max_delta) {
                    max_delta = d;
                    max_volatility = volatilities[i];
                }
            }

            // Calculate the current volatility based on linear interpolation
            let volatility;
            if (max_delta - min_delta === 0) {
                // If the difference between max_delta and min_delta is zero, set volatility to min_volatility
                volatility = min_volatility;
            } else {
                // Otherwise, calculate the volatility using linear interpolation
                volatility = ((min_volatility - max_volatility) / (max_delta - min_delta)) * Math.abs(delta) + min_volatility;
            }
            return volatility
        } 
    }
}


/**
 * Method that computes the Forward Assessment Summary
 * @param {array} assessedOptionArray - All assessed Forward Coverages array
 * @param {function} option_assessment_filter_function - Option assessment filter function to determinate active option coverage
 * @param {number} currentOptionRate - Current rate to be used. Should correspond to the SPOT or to the TRM
 * @returns {object} An object with the summary values
 */
export const computeOptionAssessmentSummary = (assessedOptionArray, option_assessment_filter_function, currentOptionRate) =>
{

   let result = {[con.ACTIVE_OPTION_AMOUNT] : 0,
                 [con.ASSESSMENT_RATE] : 0,
                 [con.PREMIUM_GAINS] : 0,
                 [con.PORTFOLIO_ASSESSMENT] : 0,
                 [con.TOTAL_ASSESSMENT] : 0}

   // Filters by expiry days
   assessedOptionArray = assessedOptionArray.filter( op => option_assessment_filter_function(op))
   if(assessedOptionArray.length === 0)
       return(result)
   
   // Computes Amount
   result[con.ACTIVE_OPTION_AMOUNT] = sum(assessedOptionArray.map(op => op[con.AMOUNT]))

    // Assessment Rate
    result[con.ASSESSMENT_RATE] = currentOptionRate

   // Computes Premium
   result[con.PREMIUM_GAINS] = sum(assessedOptionArray.map(op => op[con.PREMIUM_BALANCE]))
   
   // Portfolio Assessment
   result[con.PORTFOLIO_ASSESSMENT] = sum(assessedOptionArray.map(op => op[con.ASSESSMENT]))

   // Total Assessment
   result[con.TOTAL_ASSESSMENT] = result[con.PREMIUM_GAINS] + result[con.PORTFOLIO_ASSESSMENT]

   return(result)
}


export const getOptionAssessmentData = async (data) => {
    try {
        const url = con.rest_finance_url + con.reports_finance + con.option_assessment_info_url
        const config = getFinanceRequestConfig()
        return await axios.post(url , data, config);
    } catch (error) {
        console.error('Error fetching Option Assessment data:', error);
        return null;
    }
};
