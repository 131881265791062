// TODO - Documentar
import React from 'react'
import CoverageProfileParameters from '../components/parameters/CoverageProfileParameters'
import CoverageProfileScenarios from '../components/scenarios/CoverageProfileScenarios'
import Bands from '../components/bands/Bands'
import BandParameters from '../components/parameters/BandParameters'
import { USABLE_SCREEN_SIZE } from '../GlobalConstants'

function CoverageProfilePage() {
    return (
        <div >
        <div className="gridBox" style={{height : USABLE_SCREEN_SIZE + "vh"}}>
            <div style={{flexBasis : "63%"}}>
                
            <div className="columnParameterBox">
                <div style={{width : "100%"}}>
                    <CoverageProfileParameters />
                </div>
                <div style={{width : "100%"}}>
                    <CoverageProfileScenarios/>   
                </div>
            </div>                              
            </div>    
            <div style={{flexBasis : "37%"}}>                                
                <BandParameters />
                <div style={{marginLeft : "3%", marginRight : "3%"}}>
                    <Bands fixedSize="45"/>
                </div>                                 
            </div>
        </div>
        </div>
    )
}

export default CoverageProfilePage
