

// TODO - Documentar
import React, { useEffect, useState } from 'react'
import { setSpecificBand } from '../../store/actions/modelParameters'
import { getHigherBand, getLowerBand, getMidHigherBand, getMidLowerBand } from '../../utils/bandFunctions'

// Global constants
import * as con from "../../GlobalConstants"
import { CellPercentage, CellText } from '../../templates/tables/Cells';
import { RefModelParameterHook, RefNestedModelParameterHook, StateModelParameterHook } from '../hooks/ModelParameterHooks'

// Simple module for the bands rows
function BandRow({rowId, initialValue, dateString}) {



    // Model Parameters
    // -----------------
    const bandWidth = RefModelParameterHook([con.BAND_WIDTH])  
    const parameterValue = RefNestedModelParameterHook(con.MID_BAND_VALUES, rowId)   
    const periodicity = RefModelParameterHook(con.PERIODICITY)

    let periodicityLabel = ""
    switch (periodicity) {
    case con.PERIODICITY_WEEK:
        periodicityLabel = "Sem."
        break;
    case con.PERIODICITY_DAY:
        periodicityLabel = "Día"
        break;
    default:
        periodicityLabel = "Mes"
    }
    
    const [value, setValue] = useState(() => initialValue)
    const [lowerBand, setLowerBand] = useState(() => getLowerBand(initialValue, bandWidth))
    const [midLowerBand, setMidLowerBand] = useState(() => getMidLowerBand(initialValue, bandWidth))
    const [midHigherBand, setMidHigherBand] = useState(() => getMidHigherBand(initialValue, bandWidth))
    const [higherBand, setHigherBand] = useState(() => getHigherBand(initialValue, bandWidth))

    const [isAutomatic, setIsAutomatic] = StateModelParameterHook(con.AUTOMATIC_BANDS)
    
    useEffect(() => {
        
        setLowerBand(getLowerBand(value, bandWidth))
        setMidLowerBand(getMidLowerBand(value, bandWidth))
        setMidHigherBand(getMidHigherBand(value, bandWidth))
        setHigherBand(getHigherBand(value, bandWidth))

    }, [bandWidth,value])


    useEffect(() => {
        
        setValue(parameterValue);

    }, [parameterValue])


    const updateFunction = (new_val) =>
    {
        
        setValue(new_val);
        setSpecificBand(rowId, new_val);

        setLowerBand(getLowerBand(new_val, bandWidth))
        setMidLowerBand(getMidLowerBand(new_val, bandWidth))
        setMidHigherBand(getMidHigherBand(new_val, bandWidth))
        setHigherBand(getHigherBand(new_val, bandWidth))
        
    }

    return (
        <div className={`flex-fila hoverable-row ${(rowId % 2 === 0)? "even-row":"uneven-row"}`}>            
            <div className="flex-celda"><CellText value={`${periodicityLabel} ${rowId}`} editing={false}/></div>
            <div className="flex-celda"><CellText value={ dateString } editing={false}/></div>
            <div className="flex-celda"><CellPercentage fixedRange={true} value={lowerBand} editing={false}/></div>
            <div className="flex-celda"><CellPercentage fixedRange={true} value={midLowerBand} editing={false}/></div>
            <div className="flex-celda" onSelect={() => isAutomatic && setIsAutomatic(false)}><CellPercentage fixedRange={true}  value={value} setValue={updateFunction} editing={true}/></div>
            <div className="flex-celda"><CellPercentage fixedRange={true} value={midHigherBand} editing={false}/></div>
            <div className="flex-celda"><CellPercentage fixedRange={true} value={higherBand} editing={false}/></div>
        </div>
    )
}

export default BandRow
