// Colection of date related functions
// TODO - Documentar
import React from 'react';

// Global constants
import * as con from "../GlobalConstants"

// The entire is using Moment instead of the native Date to handle the multiple zones bug
import {DateTime, Settings} from "luxon"



import 'react-day-picker/lib/style.css';

import { DesktopDatePicker } from '@mui/x-date-pickers';
import { TextField } from '@mui/material';

// MUI X Components
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { isInt } from '../GlobalFunctions';

Settings.defaultLocale = 'es';
// Theme for the day picker
const darkTheme = createTheme({
    components: {
        MuiPaper : 
        {
            styleOverrides : {
                root : {
                    backgroundColor : "var(--background-color-2)",
                    color : "var(--text-color)"
                    
                }
            }
        },
        MuiPickersDay : 
        {
            styleOverrides : {
                root : {
                    backgroundColor : "var(--primary-color)"
                    
                }
            }
        },
        MuiPickersArrowSwitcher: 
        {
            styleOverrides : {
                button : {
                    color : "var(--text-color)"                      
                }
            }
        },
        MuiDayPicker : 
        {
            styleOverrides : {
                weekDayLabel : {
                    color : "var(--text-color)"               
                }
            }
        },
        MuiPickersCalendarHeader : 
        {
            styleOverrides : {
                switchViewButton : {
                    color : "var(--text-color)"               
                }
            }
        },        
    }
});


const invalidDateTime = DateTime.fromISO("----")

 /**
  * Function that reads a date as a String
  */
  export const parseDate = (string) =>
  {
      
    let d = DateTime.fromISO(string)
 
    if(d.invalid !== null)
        return(string)
 
    return(d)
  }
  

// Function that checks if a string is valid for a date
export const isStingValidForDate = (string) =>
{
    let d = DateTime.fromISO(string)
 
    return(d.invalid === null)

}

export const minDate = (a,b) =>
{
    if(b < a)
        return(b)

    return(a)
}

export const maxDate = (a,b) =>
{
    if(b > a)
        return(b)

    return(a)
}

/**
 * 
 * Custom Date Input
 * Custom date input for the application
 */

export const CustomDateInput = ({shouldDisableDate = (d) => isWeekEnd(d), ...params}) =>{


    return(
    <ThemeProvider theme={darkTheme}>
        <LocalizationProvider dateAdapter={AdapterLuxon} adapterLocale={"es"}>       
            <DesktopDatePicker
                inputFormat="yyyy-LL-dd"
                shouldDisableDate={shouldDisableDate}
                renderInput={(params) => <TextField {...params}/>}
                {...params}/>
        </LocalizationProvider>
    </ThemeProvider>)
}

/**
 * Function to export the date as a string
 * The format is "YYYY-MM-DD"
 */
 export const formatDate = (date) =>
 {      

    if(date instanceof String)
        date = parseDate(date)

    if(date === null || date === undefined)
        return con.INVALID_DATE

    return(date.toFormat('yyyy-LL-dd'))
 }

 /**
 * Function to export the date as a string for the user
 * The format is "dd-mmm-yyyy"
 */
  export const formatDateForUser = (date) =>
  {   
    if(date instanceof String)
        date = parseDate(date)

    if(date === null || date === undefined)
        return con.INVALID_DATE

    return(date.setLocale('es').toFormat('dd LLL yyyy'));
  }

 /**
 * Function to export the date as a string including time
 * The format is "dd-mmm-yyyy HH:MM:SS"
 */
  export const formatDateAndTime = (date) =>
  {   

    if(date instanceof String)
        date = parseDate(date)

    return(date.toFormat('yyyy-LL-dd HH:mm:ss'))
  }

   /**
 * Function to export the date as a string including time for user
 * The format is "YYYY-MM-DD HH:MM:SS"
 */
    export const formatDateAndTimeForUser = (date) =>
    {   
  
        if(date instanceof String)
            date = parseDate(date)

        return(date.toFormat('yyyy-LL-dd HH:mm:ss'))

        
    }

 /**
 * Function to export the date as a string with only the month
 * The format is "MM-YY"
 */
  export const formatDateOnlyMonth = (date) =>
  {
    if(date instanceof String)
        date = parseDate(date)

    return(date.toFormat('LLL-yyyy'))
  }
 

// Checks if is weekend
export const isWeekEnd = (date) =>
{
    return date.weekday > 5
}

// Check if it's a working day
export const isWorkDay = (date) =>
{
    return !isWeekEnd(date)
}

// Checks if is after today
export const afterToday = (date, excludeToday=true) =>
{
    if(excludeToday){
        return date > getToday()
    } else {
        return date >= getToday()
    }
    
}

export const afterADate = (date, targetDate, excludeTargetDate=true) =>
{
    if(excludeTargetDate){
        return date > targetDate
    } else {
        return date >= targetDate
    }
}


 /**
  * Function that returns the current date
  */
 export const getToday = (inBaseZone = false) =>
 {  
    let date = DateTime.local()

    if(inBaseZone)
        date = date.setZone(con.BASE_ZONE)

    return(date)
    
 }

  /**
  * Function that returns the previous working day
  */
   export const getPreviousWorkingDay = (date) =>
   {  

     if(date === null || date === undefined)
        date = getToday()

      let previousDay = addDays(date, -1);

      while(!isWorkDay(previousDay))
        previousDay = addDays(previousDay, -1);
  
      return(date)
      
   }

// Function that returns the next business day
export const getNextWorkingDay = (date) => {

     if(date === null || date === undefined)
        date = getToday()
        
    let nextDay = addDays(date, 1);
    
    // Check if it's a working day
    while (!isWorkDay(nextDay)) {
      nextDay = addDays(nextDay, 1);
    }    
    return nextDay;
  };

 /**
  * Function that returns the current date
  */
  export const getTomorrow = (inBaseZone = false) =>
  {  
     let date = DateTime.local()

     if(inBaseZone)
        date = date.setZone(con.BASE_ZONE)

     date = date.plus({ days: 1});

     return(date)
     
  }

  /**
  * Function that returns the current moment
  */
   export const getNow = (inBaseZone = false) =>
   {  
        let date = DateTime.local()

        if(inBaseZone)
            date = date.setZone(con.BASE_ZONE)
        
        return(date)
      
   }
 

 // Function that returns the days between dates (only working days)
 export const daysBetweenDates = (date1, date2, onlyWorkingDays = true) =>
 {


    date1 = parseDate(date1).startOf("day")
    date2 = parseDate(date2).startOf("day")


    // Gets the total days
    let days = date2.diff(date1,"days").days

    if(!onlyWorkingDays)
        return(days)
    
    // Subtract two weekend days for every week in between
    var weeks = Math.floor(days / 7);
    days -= weeks * 2;

    // Gets days
    // Converts to monday = 0 
    let day1 = date1.weekday -1
    let day2 = date2.weekday -1

    // Final weekend in between
    // Remove weekend not previously removed.   
    days -= (day1 > day2 && day1 < 5 && day2 < 5) ? 2: 0 

    // Checks if start date is in weekend
    days -= day1 === 6 ? 1 : day1 === 5 ? 2 : 0
    days -= day2 === 6 ? 2 : day2 === 5 ? 1 : 0

    return(days)
 }

  // Function that returns the months between dates
  export const monthsBetweenDates = (date1, date2) =>
  {
    
    date1 = parseDate(date1).startOf("day")
    date2 = parseDate(date2).startOf("day")
    
    return(Math.floor(date2.diff(date1,"months").months))

  }

   // Function that returns the hours between dates 
 export const hoursBetweenDates = (date1, date2) =>
 {


    date1 = parseDate(date1)
    date2 = parseDate(date2)


    // Gets the total hours
    return(date2.diff(date1,"hours").hours)


 }

// Function that returns the minutes between dates 
export const minutesBetweenDates = (date1, date2) =>
{


    date1 = parseDate(date1)
    date2 = parseDate(date2)


    // Gets the total minutes
    return(date2.diff(date1,"minutes").minutes)


}


  
 // Function that takes the given date to the end of the given periodicity
 export const takeDateToEndOfPeriodicity = (date, periodicity) =>
 {
    
    if (typeof date === 'string' || date instanceof String)
        date = parseDate(date)


    if(periodicity === con.PERIODICITY_DAY)    
        return(date)    
        
    if(periodicity === con.PERIODICITY_WEEK)
    {
        date = date.endOf('week').minus({days : 2})        
        return(date)
    }

    if(periodicity === con.PERIODICITY_MONTH)
    {
        
        date = date.endOf('month')  
        
        // Checks if Saturday or Sunday
        if(date.weekday > 5)
            date = date.minus({days : date.weekday - 5})

        return(date)
    }

    throw new Error(`There is no support for ${periodicity} periodicity`)
 }

 // Gets an array with the dates, including periodicity, between two given dates
 export const getDatesBetween = (start_date, end_date, periodicity) =>
 {
    // Converts
    let current_date = takeDateToEndOfPeriodicity(start_date, periodicity)
    end_date = parseDate(end_date)

    let addFun = null

    // Add function
    if(periodicity === con.PERIODICITY_DAY)
        addFun = (d) => addDays(d,1)
    else if(periodicity === con.PERIODICITY_WEEK)
        addFun = (d) => addWeeks(d,1)
    else if(periodicity === con.PERIODICITY_MONTH)
        addFun = (d) => addMonths(d,1)
    else
        throw new Error(`There is no support for ${periodicity} periodicity`)

    let resp = [];
    while(current_date <= end_date)
    {   
        resp.push(current_date)
        current_date = addFun(current_date)
    }

    return(resp)
 }

 // Add months
 // Works with negative signs
 export const addMonths = (date, months, onlyWorkingDays = false) =>
 {
    if(!isInt(months))
        return invalidDateTime

    const newDate = date.plus({ months: parseInt(months)})

    if (onlyWorkingDays)
        return getNextWorkingDay(newDate)

    return(newDate)

 }

// Add Days
// Works with negative signs
export const addDays = (date, days, onlyWorkingDays = false) =>
  {
    if(!isInt(days))
        return invalidDateTime

    const newDate = date.plus({ days: parseInt(days)})
    
    if (onlyWorkingDays){
        let newBDate = date;
        let nDays = 0;
        while (nDays < days){
            newBDate = newBDate.plus({ days: parseInt(1)})
            if (!isWeekEnd(newBDate)) nDays += 1
        }
        return newBDate
    } 
    return(newDate)
  }

// Add Weeks
// Works with negative signs
export const addWeeks = (date, weeks, onlyWorkingDays = false) =>
{
    if(!isInt(weeks))
        return invalidDateTime

    const newDate = date.plus({ days: parseInt(weeks)*7})

    if (onlyWorkingDays)
        return getNextWorkingDay(newDate)        

    return(newDate)        


}

