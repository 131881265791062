// TODO - Documentar
import { useEffect, useState } from "react"
import { getSummaryBands } from "../../utils/bandFunctions"
import { RefModelParameterHook } from "./ModelParameterHooks"

import * as con from "../../GlobalConstants"

/**
 * Hook for band summary
 */
 export function BandSummaryHook() {


  const CONSTANT_ID = 1
  
const [summaryData, setSummaryData] = useState(() => {return({
    [con.ID] : CONSTANT_ID,
    [con.LOWER_BAND] : 0,
    [con.MID_LOW_BAND] : 0,
    [con.MID_BAND] : 0,
    [con.MID_HIGH_BAND] : 0,
    [con.HIGHER_BAND] : 0,
  })})
    

  // Parameters
  const bandWidth = RefModelParameterHook(con.BAND_WIDTH)  
  const bandValues = RefModelParameterHook(con.MID_BAND_VALUES)  


  useEffect(() => {
              
      let [lBand, mlBand, mBand, mhBand,  hBand] = getSummaryBands(bandValues, bandWidth)
      
      setSummaryData({
          [con.ID] : CONSTANT_ID,
          [con.LOWER_BAND] : lBand,
          [con.MID_LOW_BAND] : mlBand,
          [con.MID_BAND] : mBand,
          [con.MID_HIGH_BAND] : mhBand,
          [con.HIGHER_BAND] : hBand,
        })
  

  }, [bandValues, bandWidth])


  return(summaryData)

}


