import { Button } from '@blueprintjs/core'
// TODO - Documentar
import React, { useEffect, useState } from 'react'
import { makeCommitteeReport } from '../../utils/reportFunctions'


// Global constants
import * as con from '../../GlobalConstants'

// Style
import '../ComponentsGlobalStyle.css'


import { GenericElement } from '../../templates/FormElement';
import { formatDate, getToday, parseDate, minDate, maxDate, afterToday, isWeekEnd, addMonths} from '../../utils/dateFunctions';
import LoadingIconSmall from '../../templates/loading/LoadingIconSmall';
import { RefApplicationParameterHook } from '../hooks/ApplicationParametersHooks';
import { RefTransactionsSummaryTableHook } from '../hooks/TransactionsSummaryTableHook'
import { RefModelParameterHook } from '../hooks/ModelParameterHooks'
import { getSummaryBands } from '../../utils/bandFunctions'

function CommitteeReport({columnLayout = false }) {

    // Transactions Summary Hook
    const [transactionSummaryObject, summaryRow] = RefTransactionsSummaryTableHook()

    // Application Parameters
    const committeeReport = RefApplicationParameterHook(con.COMMITTEE_REPORT)
    
    // Start date
    const [startDate, setStartDate] = useState(() => formatDate(addMonths(getToday(), -3, true)));

    // End date
    const [endDate, setEndDate] = useState(() => formatDate(getToday()));   

    // Bands 
    // Declares the five states
    const [lowerBand, setLowerBand] = useState(() => 0)
    const [midLowerBand, setMidLowerBand] = useState(() => 0)
    const [midBand, setMidBand] = useState(() => 0)
    const [midHigherBand, setMidHigherBand] = useState(() => 0)
    const [higherBand, setHigherBand] = useState(() => 0)


    // Parameters
    const bandWidth = RefModelParameterHook(con.BAND_WIDTH)  
    const bandValues = RefModelParameterHook(con.MID_BAND_VALUES)  


    useEffect(() => {
                
        let [lBand, mlBand, mBand, mhBand,  hBand] = getSummaryBands(bandValues, bandWidth)
        setLowerBand(lBand)
        setMidLowerBand(mlBand)
        setMidBand(mBand)
        setMidHigherBand(mhBand)
        setHigherBand(hBand)
    

    }, [bandValues, bandWidth])

    const bandsInfo = {[con.POSITION_IN_BANDS_DATA] : transactionSummaryObject,
                     [con.POSITION_IN_BANDS] : summaryRow, 
                     [con.AVERAGE_FOR_PERCENTAGE] : [lowerBand, midLowerBand, midBand, midHigherBand,  higherBand]}

    return (
        <div className="columnParameterBoxTop">

            <h3>Descargar Comité</h3> 

            <div className={columnLayout ? "columnParameterBox" :"smallParameterBox"} style={{marginTop : "0px"}}>
                <div style={{marginRight : "5px"}}> 
                < GenericElement type={con.DATE}
                            name={'Fecha Inicio'}
                            id={'start_date'} 
                            shouldDisableDate={(d) => afterToday(d) || isWeekEnd(d)}
                            value={startDate} 
                            setValue={(val) => setStartDate(formatDate(minDate(parseDate(val), parseDate(endDate))))}                                                    
                            /> 
                </div>
                <div style={{marginLeft : "5px"}}> 
                < GenericElement type={con.DATE}
                                        name={'Fecha Fin'}
                                        id={'end_date'} 
                                        shouldDisableDate={(d) => afterToday(d) || isWeekEnd(d)}
                                        value={endDate} 
                                        setValue={(val) => setEndDate(formatDate(maxDate(parseDate(val), parseDate(startDate))))} 
                                        /> 
                </div>
            </div>                            


            <div>
              <Button   disabled={committeeReport[con.GENERATING]} 
                        onClick={() => makeCommitteeReport({startDate : startDate, endDate: endDate, bandsInfo : bandsInfo})}
                        icon="download" text="Descargar PowerPoint"></Button>  
            </div>
            <div style={{width : "20px", marginTop: "15px"}}>
            {
                committeeReport[con.GENERATING]
                && <LoadingIconSmall/> 
            }
            </div>
        </div>
    )
}

export default CommitteeReport