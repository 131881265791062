// Support functions for the DB logic.
// Methods declared here should be used by actions if tehy need to be used by other parts of the app

import store from '../store';

// Global constants
import * as con from "../../GlobalConstants"
import { max } from '../../GlobalFunctions';

// Parameters
// -----------------
// Offline Parameter
export const isOffline = () =>
{
    return(store.getState()[con.STORE][con.REDUCER_APPLICATION_PARAMETERS][con.IS_OFFLINE])
}


// Token Parameter
export const getToken = () => {
    // Gets the token
    return(store.getState()[con.STORE][con.REDUCER_AUTHENTICATION][con.TOKEN])
}

// Get User ID
export const getUser = () => {
    return(store.getState()[con.STORE][con.REDUCER_AUTHENTICATION][con.USER])
} 

// Get Surrogate User ID
export const getSurrogateUser = () => {
    return(store.getState()[con.STORE][con.REDUCER_AUTHENTICATION][con.SURROGATE_USER])
} 

// Get Current Line
export const getCurrentLine = () =>
{
    return(store.getState()[con.STORE][con.REDUCER_APPLICATION_PARAMETERS][con.SELECTED_LINE])
}

// Surrogate active
export const surrogateActive = () =>
{   
    let auth = store.getState()[con.STORE][con.REDUCER_AUTHENTICATION]
    return(auth[con.USER][con.IS_STAFF] && auth[con.SURROGATE_USER] !== null && auth[con.USER][con.ID] !== auth[con.SURROGATE_USER][con.ID])
}

// Visualized user
export const getVisualizedUser = () =>
{
    return(surrogateActive() ? getSurrogateUser() : getUser())
}


// Transactions
// ------------
export const getAllTransactions = (trasactionsName) =>
{
    return(store.getState()[con.STORE][getTransactionrReducer(trasactionsName)])
}

// Account methods
// -----------------
export const getCompensationAccount = () =>
{
    let accounts = store.getState()[con.STORE][con.REDUCER_ACCOUNTS]
    return(Object.values(accounts).filter(ob => ob[con.ACCOUNT_TYPE] === con.COMPENSATION_ACCOUNT)[0])
}

export const getCompensationAccountAmount = () =>
{
    return(getCompensationAccount()[con.AMOUNT])
}


// Gets transaction
export const getTransaction = (transactionType, transId) =>
{
    let reducer = getTransactionrReducer(transactionType)

    return(store.getState()[con.STORE][reducer][transId])
}

// ID Generation
// -----------------

// Function that creates a new transaction ID given the transaction ID
export const getNewTransactionId = (transactionType) =>
{

    let reducer = getTransactionrReducer(transactionType)

    let data = store.getState()[con.STORE][reducer]

    let id =  Object.keys(data).length > 0 ? Math.max(...Object.keys(data)) : -1
    
    // Adds
    id++

    return(id)
}

// Function that creates a new annotation ID given the transaction type
export const getNewAnnotationId = (transactionType) =>
{

    let reducer = getAnnotationReducer(transactionType)

    let data = store.getState()[con.STORE][reducer]

    let id = -1
    if(Object.keys(data).length > 0)
        id = max(Object.values(data).map((ann_arr) => max(ann_arr.map(ob => ob[con.ID]))))
    
    // Adds
    id++

    return(id)
}

export const getRequestConfig = () => {

    const token = getToken()

    return({
        headers : {
            "Authorization" : `Token ${token}`
        }
    })
}

export const getFinanceRequestConfig = () => {

    return({
        headers : {
            "Authorization" : `Token ${con.tk_finance}`
        }
    })
}




// Reducer Maps
const TRANSACTION_REDUCER_MAP = {
    [con.EXPOSURES] : con.REDUCER_EXPOSURES,
    [con.COVERAGES_FWD] : con.REDUCER_FWD_COVERAGES,
    [con.COVERAGES_OPTION] : con.REDUCER_OPTION_COVERAGES,
    [con.COVERAGES_SPOT] : con.REDUCER_SPOT_COVERAGES,
    [con.ACCOUNTS] : con.REDUCER_ACCOUNTS

}

const getTransactionrReducer = (transactionType) => {

    if(transactionType in TRANSACTION_REDUCER_MAP)
        return(TRANSACTION_REDUCER_MAP[transactionType])

    throw new Error(`Unkown transaction type ${transactionType}`)

}

const ANNOTATION_REDUCER_MAP = {
    [con.EXPOSURES] : con.REDUCER_EXPOSURES_ANNOTATIONS,
    [con.COVERAGES_FWD] : con.REDUCER_FWD_COVERAGES_ANNOTATIONS,
    [con.COVERAGES_OPTION] : con.REDUCER_OPTION_COVERAGES_ANNOTATIONS,
    [con.COVERAGES_SPOT] : con.REDUCER_SPOT_COVERAGES_ANNOTATIONS,
    [con.ACCOUNTS] : con.REDUCER_ACCOUNTS_ANNOTATIONS

}

const getAnnotationReducer = (transactionType) => {

    if(transactionType in ANNOTATION_REDUCER_MAP)
        return(ANNOTATION_REDUCER_MAP[transactionType])

    throw new Error(`Unkown transaction type ${transactionType}`)

}

