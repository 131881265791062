// TODO - Documentar
import React from 'react';
import * as con from "../../GlobalConstants"


import "../../templates/Templates.css"
import "../../templates/tables/Table.css"
import { EDIT_COMMAND } from './TransactionTableConstants';
import { CellCommands, GenericCellFromRow, StaticGenericCellFromRow } from './TransactionCells';


// Module for a row of the table
export const TransactionRow = ({numRow, columnArray, dataDictionary, idRow, deleteRow, getAnnotations, commands, editing, setValueOnEditingRow, startEditingRow, saveEditingRow}) => {

    const startEditRowLocal = () => {
        commands.includes(EDIT_COMMAND) && startEditingRow({...dataDictionary[idRow]})
    }

    const stopEditingRowLocal = () => {
        commands.includes(EDIT_COMMAND) && saveEditingRow()
    }

    const toggleEditing = () => {
        editing ? stopEditingRowLocal() : startEditRowLocal()
    }

    const setEditingRowValue = (idCol, value) =>
    {        
        setValueOnEditingRow(idCol, value)
    }
        

    return (
        <div className={`flex-fila hoverable-row ${numRow%2 === 0 ? "even-row" : "uneven-row"} ${editing?"fila-editando":"static-row"}`} onDoubleClick={toggleEditing}>
            { columnArray.map((col) =>
            {                   
                return(editing ? <GenericCellFromRow    {...col}
                                                        key={`${idRow}-${col[con.ID]}`} 
                                                        cellType={col.type} 
                                                        idCol={col[con.ID]}                                  
                                                        initialValue={dataDictionary[idRow][col[con.ID]]}
                                                        setValueLocalRow={(val) => setEditingRowValue(col[con.ID], val)} 
                                                        editing={editing}/>
                                : <StaticGenericCellFromRow {...col} 
                                                        key={`${idRow}-${col[con.ID]}`} 
                                                        cellType={col.type} 
                                                        value={dataDictionary[idRow][col[con.ID]]}/>)
            })
            }
            {
              commands && commands.length > 0 ?               
                                    < CellCommands 
                                            idRow={idRow}
                                            state={dataDictionary[idRow][con.STATE]}
                                            comands={commands} 
                                            editing={editing} 
                                            startEditRow={startEditRowLocal}
                                            stopEditingRow={stopEditingRowLocal}                                            
                                            deleteRow={() => deleteRow(idRow)}
                                            getAnnotations={() => getAnnotations(idRow)}/>
                                    : null
            }
        </div>
    )
}
