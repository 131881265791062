import React from 'react'
import StatusIconSmall from '../../templates/loading/StatusIconSmall'

import * as con from "../../GlobalConstants"
import { AllApplicationParametersHook } from '../hooks/ApplicationParametersHooks'


function TransactionExtractionLoadingIcons() {

    // Application Parameters
  // ----------------- 
  const applicationParameters = AllApplicationParametersHook()

  return (
    <div>
    <h4>Extrayendo las transacciones de la base de datos.</h4>
    <div className="mediumParameterBox">
      <StatusIconSmall status={applicationParameters[con.TRANSACTIONS_LOADED][con.EXPOSURES][con.STATUS]} title={"Exposiciones"}/>
      <StatusIconSmall status={applicationParameters[con.TRANSACTIONS_LOADED][con.COVERAGES_SPOT][con.STATUS]} title={"Coberturas SPOT"}/> 
      <StatusIconSmall status={applicationParameters[con.TRANSACTIONS_LOADED][con.COVERAGES_OPTION][con.STATUS]} title={"Coberturas Opciones"}/> 
      <StatusIconSmall status={applicationParameters[con.TRANSACTIONS_LOADED][con.COVERAGES_FWD][con.STATUS]} title={"Coberturas FWD"}/>                                       
      <StatusIconSmall status={applicationParameters[con.TRANSACTIONS_LOADED][con.ACCOUNTS][con.STATUS]} title={"Cuentas"}/> 
    </div>
  </div>
  )
}

export default TransactionExtractionLoadingIcons