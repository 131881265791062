// TODO - Documentar
import React from 'react'
import "./Pages.css"

import CustomMarketOverviewWidget from '../components/tradingViewComponentes/CustomMarketOverviewWidget'
import CustomEconomicCalendarWidget from '../components/tradingViewComponentes/CustomEconomicCalendarWidget'
import { USABLE_SCREEN_SIZE } from '../GlobalConstants'

function MarketsFundamentalAnalysis() {
    
    return (
        <div>
              
          <div className="gridBox" style={{height : USABLE_SCREEN_SIZE+"vh", paddingLeft : "1vh", paddingRight : "1vh"}}>
            <div style={{flexBasis : "50%", marginRight : "0.5vh"}}>               
                <CustomMarketOverviewWidget/>
            </div>
            <div style={{flexBasis : "50%", marginLeft : "0.5vh"}}>                   
                <CustomEconomicCalendarWidget/>
            </div>               
        </div>  
      
        </div>
    )
}

export default MarketsFundamentalAnalysis
