// Actions for the model parameters reducer
import * as types from "./types"
import store from '../store';
import * as con from "./../../GlobalConstants"
import axios from "axios";
import { handleRequestError } from "../../utils/errorFunctions";
import { arrayToObjectByAttribute } from "../../GlobalFunctions";
import { setModelParameter } from "./modelParameters";
import { parseDate } from "../../utils/dateFunctions";
import { getFinanceRequestConfig } from "../logic/supportFunctions";
// import { setApplicationParameter } from "./applicationParameters";
// import { parseBetasFromReport } from "../../utils/orionFunctions";


// Set Market Value 
export const setMarketValue = (id, value) =>
{    

    let payload = {[con.ID]: id, [con.VALUE] : value}

    store.dispatch({
        type : types.SET_MARKET_VALUE,
        payload : payload
    })
}


// Get a Market values 
export const getMarketValue = (id) =>
{
    return(store.getState()[con.STORE][con.REDUCER_MARKET_VALUES][id])
}

const config = getFinanceRequestConfig()


export const fetchAllMarketData = () =>
{

    // Gets all market data
    axios.get(con.rest_finance_url + con.get_all_market_data_url, config)
    .then(res => {
        
        

        // Get historic data
        let data = res.data[con.HISTORIC_DOLLAR_INFO] 
        if(con.STATUS in data && data[con.STATUS] === con.OK)
        {   
            data = data[con.HISTORIC_DOLLAR_INFO] 
            setMarketValue(con.HISTORIC_DOLLAR_INFO, {...data, [con.DATES] : data['dates'].map((d) => parseDate(d))})
        }
        else // Retries
        {
            console.warn("Fetching Historic dollar information failed. Retrying")
            fetchHistoricDollarInfo()
        }


        // Gets Market Summaries
        data = res.data[con.MARKET_SUMMARIES] 
        if(con.STATUS in data && data[con.STATUS] === con.OK)
        {   
            data = data[con.MARKET_SUMMARIES] 
            // Model Parameter
            setModelParameter(con.MARKET_VOLATILITY, data[con.DEFAULT_MARKET_PERIOD][con.VOLATILITY])

            // Market Value
            setMarketValue(con.MARKET_SUMMARIES, data)
        }
        else // Retries
        {
            console.warn("Fetching Market Summaries failed. Retrying")
            fetchMarketSummaries()
        }
     

        // Current Forward Curve
        data = res.data[con.MARKET_FORWARD_CURVE] 
        if(con.STATUS in data && data[con.STATUS] === con.OK)
        {   
            data = data[con.MARKET_FORWARD_CURVE] 
            setMarketValue(con.MARKET_FORWARD_CURVE, data)
        }
        else // Retries
        {
            console.warn("Fetching Forward Curve Failed. Retrying")
            fetchForwardCurve()
        }
        

        // IBR
        data = res.data[con.MARKET_IBR]
        if(con.STATUS in data && data[con.STATUS] === con.OK)
        {   
            data = data[con.MARKET_IBR] 
            setMarketValue(con.MARKET_IBR, data)
        }
        else // Retries
        {
            console.warn("Fetching IBR Failed. Retrying")
            fetchIBRCurve()
        }        

    })
    .catch(err => handleRequestError(err))


    // Market assets
    axios.get(con.rest_base_url + con.market_assets_url)
    .then(res => {

        // Market Value
        setMarketValue(con.MARKET_ASSETS, arrayToObjectByAttribute(res.data, con.ID))

    })
    .catch(err => handleRequestError(err))


}

// Fetch the cop historic data
export const fetchHistoricDollarInfo = () =>
{
    
    axios.get(con.rest_finance_url + con.historic_dollar_info_url, config)
    .then(res => {
        
        setMarketValue(con.HISTORIC_DOLLAR_INFO, {...res.data, [con.DATES] : res.data['dates'].map((d) => parseDate(d))})
    })
    .catch(err => handleRequestError(err))

}


// Fetch Market Summaries
export const fetchMarketSummaries = () =>
{

    axios.get(con.rest_finance_url + con.market_summaries_url, config)
            .then(res => {

                // Model Parameter
                setModelParameter(con.MARKET_VOLATILITY, res.data[con.MARKET_SUMMARIES][con.DEFAULT_MARKET_PERIOD][con.VOLATILITY])

                // Market Value
                setMarketValue(con.MARKET_SUMMARIES, res.data[con.MARKET_SUMMARIES])
            })
            .catch(err => handleRequestError(err))
}



// Fetch the forward curve
export const fetchForwardCurve = () =>
{

    axios.get(con.rest_finance_url + con.market_forward_curve_url, config)
            .then(res => {

                // Market Value
                setMarketValue(con.MARKET_FORWARD_CURVE, res.data)
            })
            .catch(err => handleRequestError(err))
}

// Fetch the all forward curves
export const fetchAllForwardCurves = () =>
{

    axios.get(con.rest_finance_url + con.market_all_forward_curve_url, config)
            .then(res => {

                // Market Value
                setMarketValue(con.ALL_FORWARD_CURVES, res.data)
            })
            .catch(err => handleRequestError(err))
}


// Fetch the IBR values LEGACY
export const fetchIBRCurve = () =>
{

    axios.get(con.rest_finance_url + con.market_ibr_url, config)
            .then(res => {

                // Market Value
                setMarketValue(con.MARKET_IBR, res.data)
            })
            .catch(err => handleRequestError(err))
}

// Fetch the all ibr
export const fetchAllIBR = () =>
{

    axios.get(con.rest_finance_url + con.market_all_ibr_url, config)
            .then(res => {

                // Market Value
                setMarketValue(con.ALL_IBR, res.data)
            })
            .catch(err => handleRequestError(err))
}

// Fetch the all Sofr Curves
export const fetchAllSofrCurves = () =>
{

    axios.get(con.rest_finance_url + con.market_all_sofr_url, config)
            .then(res => {

                // Market Value
                setMarketValue(con.ALL_SOFR_CURVES, res.data)
            })
            .catch(err => handleRequestError(err))
}

// Fetch the Volatility Surface by a given date
export const fetchVolatilitySurfaceByDate = (date) =>
{

    axios.get(con.rest_finance_url + con.market_volatility_surface_by_date_url + `?${con.DATE}=${date}`, config)
            .then(res => {
                // Market Value
                setMarketValue(con.VOLATILITY_SURFACE, res.data[con.SURFACE])
            })
            .catch(err => handleRequestError(err))
}


// // Gets the Orion model
// export const fetchOrionSummary = () =>
// {        
//     setApplicationParameter(con.ORION_SUMMARY_FETCHED, {[con.STATUS] : con.LOADING})

//     let url = con.rest_base_url + con.orion_summary_url

//     axios.get(url)
//             .then(res => {
                
//                 // Market Value
//                 setMarketValue(con.ORION_SUMMARY, res.data)
                
//                 setApplicationParameter(con.ORION_SUMMARY_FETCHED, {[con.STATUS] : con.OK})
//             })
//             .catch(err => {
//                 setApplicationParameter(con.ORION_SUMMARY_FETCHED, {[con.STATUS] : con.ERROR})
//                 handleRequestError(err)})
// }


// // Gets the Orion Betas
// export const fetchOrionBetas = () =>
// {        
//     setApplicationParameter(con.ORION_BETAS_FETCHED, {[con.STATUS] : con.LOADING})

//     let url = con.rest_base_url + con.orion_betas_url

//     axios.get(url)
//             .then(res => {
                
//                 // Market Value
//                 let orionBetas = {...res.data, [con.ORION_REPORT] : parseBetasFromReport(res.data[con.ORION_REPORT])}
//                 if(orionBetas[con.ORION_REPORT] !== null)
//                 {
//                     setMarketValue(con.ORION_BETAS, orionBetas)
//                     setApplicationParameter(con.ORION_BETAS_FETCHED, {[con.STATUS] : con.OK})
//                 }
//                 else
//                     setApplicationParameter(con.ORION_BETAS_FETCHED, {[con.STATUS] : con.ERROR})
                
//             })
//             .catch(err => {
//                 setApplicationParameter(con.ORION_BETAS_FETCHED, {[con.STATUS] : con.ERROR})
//                 handleRequestError(err)})
// }




