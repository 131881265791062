import React, { useEffect, useState, useMemo } from 'react';
import ReactApexChart from 'react-apexcharts';
import { DateTime } from 'luxon';
import { Button, Switch } from '@blueprintjs/core';

const CandleStickChart = ({ data, alerts }) => {
    const [series, setSeries] = useState([]);
    const [interval, setInterval] = useState(15); // Valor por defecto: 15 minutos
    const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth <= 768);
    const [isMediumScreen, setIsMediumScreen] = useState(window.innerWidth > 768 && window.innerWidth <= 1440);
    const [showAlerts, setShowAlerts] = useState(false);
    const [yLimits, setYLimits] = useState({ min: null, max: null });
    const [chartHeight, setChartHeight] = useState(window.innerHeight * 0.6);

    const yAxisPaddingPercentage = 0.1; // 10% 

    useEffect(() => {
        const handleResize = () => {
            const width = window.innerWidth;
            setIsSmallScreen(width <= 768);
            setIsMediumScreen(width > 768 && width <= 1440);
            setChartHeight(Math.floor(window.innerHeight * (isSmallScreen ? 0.22 : isMediumScreen ? 0.3 : 0.4)));
        };
        window.addEventListener('resize', handleResize);
        handleResize();
        return () => window.removeEventListener('resize', handleResize);
    }, [isSmallScreen, isMediumScreen]);

    useEffect(() => {
        const formatDataForCandleStick = (data, interval) => {
            const intervals = [];
            let currentInterval = null;
            let maxPrice = -Infinity;
            let minPrice = Infinity;

            for (let i = 0; i < data.dates.length; i++) {
                const date = DateTime.fromISO(data.dates[i]).minus({ hours: 5 });
                const intervalStart = Math.floor(date.toMillis() / (interval * 60 * 1000)) * (interval * 60 * 1000);
                const intervalEnd = intervalStart + (interval * 60 * 1000);

                if (!currentInterval || currentInterval.intervalStart !== intervalStart) {
                    if (currentInterval) {
                        const open = currentInterval.closes[currentInterval.closes.length - 1];
                        const close = currentInterval.closes[0];
                        intervals.push({
                            x: new Date(currentInterval.intervalStart),
                            y: [open, Math.max(...currentInterval.closes), Math.min(...currentInterval.closes), close],
                            intervalStart: currentInterval.intervalStart,
                            intervalEnd: currentInterval.intervalEnd,
                        });
                        maxPrice = Math.max(maxPrice, Math.max(...currentInterval.closes));
                        minPrice = Math.min(minPrice, Math.min(...currentInterval.closes));
                    }
                    currentInterval = { intervalStart, intervalEnd, closes: [data.close[i]] };
                } else {
                    currentInterval.closes.push(data.close[i]);
                }
            }

            if (currentInterval) {
                const open = currentInterval.closes[currentInterval.closes.length - 1];
                const close = currentInterval.closes[0];
                intervals.push({
                    x: new Date(currentInterval.intervalStart),
                    y: [open, Math.max(...currentInterval.closes), Math.min(...currentInterval.closes), close],
                    intervalStart: currentInterval.intervalStart,
                    intervalEnd: currentInterval.intervalEnd,
                });
                maxPrice = Math.max(maxPrice, Math.max(...currentInterval.closes));
                minPrice = Math.min(minPrice, Math.min(...currentInterval.closes));
            }

            let dataMin = minPrice;
            let dataMax = maxPrice;

            if (showAlerts && alerts && alerts.length > 0) {
                const alertLevels = alerts.map(alert => parseFloat(alert.level));
                dataMin = Math.min(dataMin, ...alertLevels);
                dataMax = Math.max(dataMax, ...alertLevels);
            }

            const yRange = dataMax - dataMin;
            const padding = yRange * yAxisPaddingPercentage;

            setYLimits({
                min: dataMin - padding,
                max: dataMax + padding,
            });

            return intervals;
        };

        const formattedData = formatDataForCandleStick(data, interval);
        setSeries([{ data: formattedData }]);
    }, [data, interval, showAlerts, alerts, yAxisPaddingPercentage]);

    const alertTypeLabels = useMemo(() => ({
        'above': 'Alza',
        'below': 'Baja',
    }), []);

    const annotations = useMemo(() => {
        if (showAlerts && alerts && alerts.length > 0) {
            return {
                yaxis: alerts.map(alert => {
                    const level = parseFloat(alert.level);
                    const alertType = alert.alert_type;
                    const color = alertType === 'above' ? '#54aaff' : '#ff4800';
                    const labelText = `Alerta: ${alertTypeLabels[alertType] || alertType} ${alert.level}`;
                    return {
                        y: level,
                        borderColor: color,
                        strokeDashArray: 5,
                        label: {
                            borderColor: color,
                            style: { color: '#fff', background: color },
                            text: labelText,
                        },
                    };
                }),
            };
        } else {
            return { yaxis: [] };
        }
    }, [showAlerts, alerts, alertTypeLabels]);

    const options = useMemo(() => ({
        chart: { type: 'candlestick', height: chartHeight, background: 'transparent' },
        xaxis: { type: 'datetime' },
        yaxis: { min: yLimits.min, max: yLimits.max, tooltip: { enabled: true } },
        theme: { mode: 'dark' },
        tooltip: {
            theme: 'dark',
            custom: ({ seriesIndex, dataPointIndex, w }) => {
                const { y, intervalStart, intervalEnd } = w.config.series[seriesIndex].data[dataPointIndex];
                const open = y[0];
                const high = y[1];
                const low = y[2];
                const close = y[3];
                const startTime = DateTime.fromMillis(intervalStart).plus({ hours: 5 }).toFormat('HH:mm');
                const endTime = DateTime.fromMillis(intervalEnd).plus({ hours: 5 }).toFormat('HH:mm');

                return `
                    <div style="padding: 10px; color: white;">
                        <strong>Intervalo: ${startTime} - ${endTime}</strong><br/>
                        <strong>Open:</strong> ${open} <br/>
                        <strong>High:</strong> ${high} <br/>
                        <strong>Low:</strong> ${low} <br/>
                        <strong>Close:</strong> ${close} <br/>
                    </div>
                `;
            },
        },
        grid: { borderColor: '#555' },
        dataLabels: { enabled: false },
        annotations: annotations,
    }), [annotations, yLimits, chartHeight]);

    return (
        <div id="chart" style={{ marginTop: '20px', textAlign: 'center', padding: '0 20px' }}>
            <ReactApexChart
                options={options}
                series={series}
                type="candlestick"
                height={chartHeight}
                width={isSmallScreen ? '100%' : isMediumScreen ? '100%' : '100%'}
            />
            <div style={{
                marginTop: '10px',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center'
            }}>
                <div style={{
                    display: 'flex',
                    justifyContent: 'center',
                    gap: '5px',
                    marginBottom: '10px'
                }}>
                    <Button
                        text="5 Minutos"
                        onClick={() => setInterval(5)}
                        style={{
                            backgroundColor: 'rgb(238, 172, 24)',
                            color: 'black',
                            fontSize: isSmallScreen ? '10px' : '16px',
                            padding: isSmallScreen ? '5px 10px' : '10px 20px',
                        }}
                    />
                    <Button
                        text="15 Minutos"
                        onClick={() => setInterval(15)}
                        style={{
                            backgroundColor: 'rgb(238, 172, 24)',
                            color: 'black',
                            fontSize: isSmallScreen ? '10px' : '16px',
                            padding: isSmallScreen ? '5px 10px' : '10px 20px',
                        }}
                    />
                    <Button
                        text="1 Hora"
                        onClick={() => setInterval(60)}
                        style={{
                            backgroundColor: 'rgb(238, 172, 24)',
                            color: 'black',
                            fontSize: isSmallScreen ? '10px' : '16px',
                            padding: isSmallScreen ? '5px 10px' : '10px 20px',
                        }}
                    />
                </div>
                <Switch
                    checked={showAlerts}
                    onChange={() => setShowAlerts(!showAlerts)}
                    label="Mostrar Alertas"
                    style={{
                        color: 'white',
                        fontSize: isSmallScreen ? '10px' : '16px'
                    }}
                />
            </div>
        </div>
    );
};

export default CandleStickChart;
