// TODO - Documentar
import React from 'react';
import * as con from "../../GlobalConstants"
import LoadingIconSmall from './LoadingIconSmall';
import { FaCheck, FaTimes } from 'react-icons/fa'

import "./Loading.css"


export const StatusIconSmall = ({title, status, inline}) => {
    return( 
        <div className={(inline)? "inlineDisplay" : "columnDisplay"}>
            {!inline && <p>{title}</p>}
            <div>    
                {status === con.LOADING && <LoadingIconSmall/>}
                {status === con.OK && <div className="stausLogo"><FaCheck/></div>}
                {status === con.ERROR &&  <div className="stausLogo"><FaTimes /></div>}
            </div> 
            {inline && <p>{title}</p>}
        </div>)
}


  StatusIconSmall.defaultProps = {
    inline: false 
  }



export default StatusIconSmall;
