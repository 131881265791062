// TODO - Documentar
import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';

import * as con from "../../GlobalConstants"
import { filterObjectByKeys } from '../../GlobalFunctions';
import { setApplicationParameter } from '../../store/actions/applicationParameters';

/**
 * Hook for a single application parameter in the store read only
 */
export function RefApplicationParameterHook(parameterName) {

    const value = useSelector(state => state[con.STORE][con.REDUCER_APPLICATION_PARAMETERS][parameterName])

    return(value);
}



/**
 * Hook for a single application parameter in the store read and write
 */
 export function StateApplicationParameterHook(parameterName) {

    const value = useSelector(state => state[con.STORE][con.REDUCER_APPLICATION_PARAMETERS][parameterName])

    const setParameter = (newVal) => setApplicationParameter(parameterName, newVal)

    return([value, setParameter]);
}


/**
 * Hook for a single application parameter in the store. Only write
 */
 export function EditApplicationParameterHook(parameterName) {

    const setParameter = (newVal) => setApplicationParameter(parameterName, newVal)

    return(setParameter);
}

/**
 * Hook for all application parameter in the store 
 */
 export function AllApplicationParametersHook() {

    const value = useSelector(state => state[con.STORE][con.REDUCER_APPLICATION_PARAMETERS])


    return(value);
}

/**
 * Hook for a single nested application parameter in the store read only
 */
 export function RefNestedApplicationParameterHook(parameterName, attributeName) {

    const value = useSelector(state => state[con.STORE][con.REDUCER_APPLICATION_PARAMETERS][parameterName][attributeName])

    return(value);
}



/**
 * Hook for a loading parameter
 */
 export function RefApplicationLoadingParameterHook(loadingParameterName) {

    const value = RefNestedApplicationParameterHook(loadingParameterName, con.STATUS)

    return(value);
}


/**
 * Hook for a single nested application parameter in the store
 */
 export function SetNestedApplicationParameterHook(parameterName, attributeName) {

    const value = useSelector(state => state[con.STORE][con.REDUCER_APPLICATION_PARAMETERS][parameterName])
    const setValue = (newValue) => setApplicationParameter(parameterName, {...value, [attributeName]: newValue})

    return(setValue);
}


/**
 * Hook for a single nested application parameter in the store
 */
 export function StateNestedApplicationParameterHook(parameterName, attributeName) {

    const allValues = useSelector(state => state[con.STORE][con.REDUCER_APPLICATION_PARAMETERS][parameterName])
    const value = useSelector(state => state[con.STORE][con.REDUCER_APPLICATION_PARAMETERS][parameterName][attributeName])

    const setValue = (newValue) => setApplicationParameter(parameterName, {...allValues, [attributeName]: newValue})

    return([value,setValue]);
}



/**
 * Hook for Application Parameters used in model
 */
export function RefModelApplicationParameterHook()
{
    
    const applicationParameters = AllApplicationParametersHook()

    const [modelApplicationParameters, setModelApplicationParameters] = useState(() => filterObjectByKeys(applicationParameters, con.MODEL_APPLICATION_PARAMETERS))
    
    const lastValue = useRef(() => modelApplicationParameters)

    useEffect(() => {

        let newValues = filterObjectByKeys(applicationParameters, con.MODEL_APPLICATION_PARAMETERS)

        if(JSON.stringify(newValues) !== JSON.stringify(lastValue.current))
        {
            setModelApplicationParameters(newValues)
            lastValue.current = newValues

        }
            

    }, [applicationParameters])
    

    return(modelApplicationParameters)

}

/**
 * Hook for the SPOT
 */
export const RefSPOT = () =>
{   
    const spot = RefApplicationParameterHook(con.SPOT)
    
    return spot
}