// TODO - Documentar
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import * as con from "../../GlobalConstants"
import { sum } from '../../GlobalFunctions';
import { setMarketValue } from '../../store/actions/market';
import { quoteAssets } from '../../utils/marketFunctions';
import { getScore, processCustomCustomBetas } from '../../utils/orionFunctions';
import {  RefSPOT } from './ApplicationParametersHooks';

/**
 * Hook for a single market value in the store read only
 */
export function RefMarketValueHook(parameterName) {

    const value = useSelector(state => state[con.STORE][con.REDUCER_MARKET_VALUES][parameterName])

    return(value);
}



/**
 * Hook for a single market value in the store read and write
 */
 export function StateMarketValueHook(parameterName) {

    const value = useSelector(state => state[con.STORE][con.REDUCER_MARKET_VALUES][parameterName])

    const setParameter = (newVal) => setMarketValue(parameterName, newVal)

    return([value, setParameter]);
}


/**
 * Hook for a single market value in the store. Only write
 */
 export function EditMarketValueHook(parameterName) {

    const setParameter = (newVal) => setMarketValue(parameterName, newVal)

    return(setParameter);
}

/**
 * Hook for all market value in the store 
 */
 export function AllMarketValuesHook() {

    const value = useSelector(state => state[con.STORE][con.REDUCER_MARKET_VALUES])


    return(value);
}

/**
 * Hook for a single nested market value in the store read only
 */
 export function RefNestedMarketValueHook(parameterName, attributeName) {

    const value = useSelector(state => state[con.STORE][con.REDUCER_MARKET_VALUES][parameterName][attributeName])

    return(value);
}



/**
 * Hook for a loading parameter
 */
 export function RefApplicationLoadingParameterHook(loadingParameterName) {

    const value = RefNestedMarketValueHook(loadingParameterName, con.STATUS)

    return(value);
}


/**
 * Hook for a single nested market value in the store
 */
 export function SetNestedMarketValueHook(parameterName, attributeName) {

    const value = useSelector(state => state[con.STORE][con.REDUCER_MARKET_VALUES][parameterName])
    const setValue = (newValue) => setMarketValue(parameterName, {...value, [attributeName]: newValue})

    return(setValue);
}


/**
 * Hook for a single nested market value in the store
 */
 export function StateNestedMarketValueHook(parameterName, attributeName) {

    const allValues = useSelector(state => state[con.STORE][con.REDUCER_MARKET_VALUES][parameterName])
    const value = useSelector(state => state[con.STORE][con.REDUCER_MARKET_VALUES][parameterName][attributeName])

    const setValue = (newValue) => setMarketValue(parameterName, {...allValues, [attributeName]: newValue})

    return([value,setValue]);
}


/**
 * Hook for the orion summary
 */
export function RefOrionSummary() {

    const value = RefMarketValueHook(con.ORION_SUMMARY)

    return(value);
}

/**
 * Hook for the Orion real time value 
 */
 export function RefOrionRealTimeValueHook() {

    // Betas
    const orionBetas = RefMarketValueHook(con.ORION_BETAS)

    // Spot
    const spot = RefSPOT()

    // Orion value
    const [orionValue, setOrionValue] = useState(() => null)


    useEffect(() => {
     
        const updateBetas = async () =>
        {
            if(orionBetas !== null)
            {
                const report = orionBetas[con.ORION_REPORT]

                // Assets
                const assetsCodes = Object.keys(report[con.ORION_BETAS_VALUES])
                const assetConstantValue = assetsCodes.map(k => report[con.ORION_BETAS_VALUES][k])

                // Gets the codes
                const assetVariableValue = await quoteAssets(assetsCodes)

                if(assetsCodes === null)
                {
                    console.warn("Asset quoting is down. Please try again later")
                    return
                }

                
                // Computes the beta summand
                const betasSummand =  sum(assetConstantValue.map((val, i) => val*assetVariableValue[i]))

                
                // Computes custom summand
                let customSummand = 0
                for(let k in report[con.ORION_CUSTOM_BETAS]) 
                {
                    let customBetaVal = await processCustomCustomBetas(k)
                    
                    if(customBetaVal === null)
                        return
                    customSummand += customBetaVal*report[con.ORION_CUSTOM_BETAS][k]
                }

                const finalValue = betasSummand + customSummand + report[con.ORION_INTERCEPT]

                // Computes score
                const score = 100*getScore(finalValue, spot, report[con.ORION_MEAN], report[con.ORION_STD])

                setOrionValue({
                    [con.ORION_ESTIMATED_RATE] : finalValue,
                    [con.ORION_SCORE] : score,
                })
                    
            }

        }

        updateBetas();

    }, [orionBetas, spot])





    return(orionValue)
    


}



