// TODO - Documentar
import React from 'react'
import "./MobilePages.css"

import OrionHistoricChart from '../components/orion/OrionHistoricChart'
import OrionSpeedometerChart from '../components/orion/OrionSpeedometerChart'
import OrionHistoricTable from '../components/orion/OrionHistoricTable'
import { PROPORTIONAL_HEIGHT, PROPORTIONAL_WIDTH } from '../templates/charts/ChartConstants'
import * as con from "../GlobalConstants"


function MobileMarketsOrionOverview() {
    
    return (
        <div style={{height : con.MOBILE_USABLE_SCREEN_SIZE +"vh" }} >            
            <div style={{marginLeft : "15%", marginRight : "15%", marginTop : "15px"}}>
                <OrionHistoricTable />
            </div>
          <div style={{paddingLeft : "1vh", paddingRight : "1vh", marginTop : "3vh"}}>                                
                    <OrionHistoricChart chartParameters={{[PROPORTIONAL_WIDTH] : 700,
                                                          [PROPORTIONAL_HEIGHT] : 500}}/>

                    <div style={{marginTop : "-4vh"}}>
                    <OrionSpeedometerChart chartParameters={{[PROPORTIONAL_WIDTH] : 600,
                                                             [PROPORTIONAL_HEIGHT] : 600}}/>     
                    </div>                                              
          </div>  
        </div>
      

    )
}

export default MobileMarketsOrionOverview
