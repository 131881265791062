import React from 'react'
import { MediumLabel } from '../../templates/label/Labels'
import { RefSetCurrentPage } from '../hooks/NavegationHook'

const GlobalViewComponent = ({children, title, pageLink, controls=null}) =>
{
    
    // Current Page
    const setCurrentPage = RefSetCurrentPage()

    return(<div className="grid-2 global-view-component">
                <div style={{display : "flex"}}>
                    <MediumLabel text={title} tight={true} onClick={() => setCurrentPage(pageLink)}/> 
                    {controls}   
                </div>     
                    {children}
            </div>)


}

export default GlobalViewComponent