import { NavbarDivider } from '@blueprintjs/core'
// TODO - Documentar
import React from 'react'

function CustomNavbarDivider() {
  return (      
    <NavbarDivider className="noCarret" style={{margin : '2px'}}/> 
  )
}

export default CustomNavbarDivider