// Module with Excel functions
import * as XLSX from 'xlsx';


export const setTypesToColumns = (ws, types) => {
    // TODO
    // Not working

   let range = XLSX.utils.decode_range(ws['!ref']);
   for(let colNum=range.s.c; colNum<=range.e.c; colNum++){
        
        for(let rowNum = range.s.r + 1; rowNum <= range.e.r; rowNum++){
            let  cell_code = XLSX.utils.encode_cell({r: rowNum, c: colNum})

            ws[cell_code].t = 'n'
            ws[cell_code].z = "'dd/mm/yyyy'"
            
       }
   }
    


    return(ws)
}


export const formatWorkSheet = (worksheet) =>{

    worksheet["!cols"] = [ { wch: 15 } ];

    return(worksheet)

}