// TODO - Documentar
import React from 'react'
import {StaticTable} from '../../templates/tables/StaticTable'

import * as con from "../../GlobalConstants"
import { TransactionColumnsHook } from '../hooks/TransactionsHooks'



export const CoveragesGridBandPositionSummary = ({selectedColumns, summaryRow, computing, hasSummaryTable}) => {


  const transactionsCol = TransactionColumnsHook(selectedColumns, true)
                          

    return ( 
            <div > 
              { 

               computing ? <h5>Computando...</h5> 
                         : !hasSummaryTable ? <h4>{con.NO_TRANSACTIONS_MESSAGE}</h4> 
                          : <div>
                              <StaticTable columnArray={transactionsCol} 
                                        dataDictionary={{0 : summaryRow}} />
                            </div> 
              }
            </div> 

       
    )
}

CoveragesGridBandPositionSummary.defaultProps = {
  selectedColumns: [{ [con.ID] : con.EXPOSURE_AMOUNT},
                    { [con.ID] : con.COVERAGE_AMOUNT},                      
                    { [con.ID] : con.RATE},
                    { [con.ID] : con.COVERAGE_PERCENTAGE},   
                    { [con.ID] : con.POSITION_IN_BANDS}]    
};



