import React from 'react'
import { Button } from '@blueprintjs/core'

/**
 * Component that renders the button in charge of displaying or not a given password
 * @param {boolean} showPassword - Boolean indicating if the password should be showed or not
 * @param {(boolean) => void} setShowPassword - Function that updates the show password value.
 * @returns {JSX.Element} - The corresponding component
 */
function LockEyePasswordButton({showPassword, setShowPassword}) {
  return (
    <Button          
            icon={showPassword ? "eye-off" : "eye-open"}                
            minimal={true}
            color={"black"}
            onClick={() => setShowPassword(!showPassword)}
        /> 
  )
}

export default LockEyePasswordButton