import React, { useEffect } from 'react'
import { fetchAllSurrogateTransactions } from '../../store/actions/surrogateTransactions'
import { AllSurrogateTransactionsSummariesHook } from '../hooks/SurrogateTransactionsHooks'
import { AdminDashboardTableSummary } from './AdminDashboardTableSummary'


/**
 * Component that renders the admins dashboard for the Vinco advisors
 * @returns {JSX.Element} - The corresponding component
 */
function AdminDashboard() {

  // Refreshes all Surrogate transactions
  useEffect(() => {
    fetchAllSurrogateTransactions()
  }, [])
  
  // Hook for the surrogate transaction summaries
  const [allSurrogateTransactionsSummaries, computing] = AllSurrogateTransactionsSummariesHook()
 
  return (
    <div>
      <AdminDashboardTableSummary allSurrogateTransactionsSummaries={allSurrogateTransactionsSummaries}
                                  computing={computing}/>
    </div>
  )
}

export default AdminDashboard