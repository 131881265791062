// Component for handeling the interaction with bands

// Component for All parameters
// TODO - Documentar
import React, { useEffect, useState } from 'react';

// Global constants
import * as con from "../../GlobalConstants"


// Style
import "../../templates/Templates.css"
import "../../templates/tables/Table.css"
import "../ComponentsGlobalStyle.css"

import { addDays, addMonths, addWeeks, formatDate, formatDateOnlyMonth, takeDateToEndOfPeriodicity} from '../../utils/dateFunctions';
import BandRow from './BandRow';
import { RefModelParameterHook } from '../hooks/ModelParameterHooks';
import { fontCalculator } from '../../templates/tables/StaticTable';
import { useEventListener } from '../hooks/EventListener';
import { TabNavegationHandler } from '../hooks/NavegationHook';
import { getSummaryBands } from '../../utils/bandFunctions';
import { CellPercentage, CellText } from '../../templates/tables/Cells';




function Bands({fixedSize}) {

    useEventListener("keydown", TabNavegationHandler);

    // Parameters
    let currentDate =  RefModelParameterHook(con.CURRENT_DATE)
    const periodicity = RefModelParameterHook(con.PERIODICITY)
    const numPeriods = RefModelParameterHook(con.TERM_PERIODICITY)

    let formatDateFunction;
    let addPeriodicityFunction;
    switch (periodicity) {
    case con.PERIODICITY_WEEK:
        formatDateFunction = formatDate
        addPeriodicityFunction = addWeeks
        currentDate = takeDateToEndOfPeriodicity(currentDate, con.PERIODICITY_WEEK)
        break;
    case con.PERIODICITY_DAY:
        formatDateFunction = formatDate;
        addPeriodicityFunction = (date, days) => addDays(date, days, true);
        break;

    default:
        formatDateFunction = formatDateOnlyMonth;
        addPeriodicityFunction = addMonths
    }

    // Declares the five states
    const [lowerBand, setLowerBand] = useState(() => 0)
    const [midLowerBand, setMidLowerBand] = useState(() => 0)
    const [midBand, setMidBand] = useState(() => 0)
    const [midHigherBand, setMidHigherBand] = useState(() => 0)
    const [higherBand, setHigherBand] = useState(() => 0)
    
    // Parameters
    const bandWidth = RefModelParameterHook(con.BAND_WIDTH)
    const bandValues = RefModelParameterHook(con.MID_BAND_VALUES)

    useEffect(() => {

        let [lBand, mlBand, mBand, mhBand,  hBand] = getSummaryBands(bandValues, bandWidth)
        setLowerBand(lBand)
        setMidLowerBand(mlBand)
        setMidBand(mBand)
        setMidHigherBand(mhBand)
        setHigherBand(hBand)

    }, [bandValues, bandWidth])

    return (
    <div>
        <div className="contenedor-tabla" style={ fixedSize == null ?{} : {fontSize : fontCalculator(fixedSize, numPeriods+2)}}>

            <div className="flex-tabla">
                <div className="flex-fila header-row">
                    <div className="flex-celda ">PERIODO</div>
                    <div className="flex-celda">FECHA</div>
                    <div className="flex-celda">BAJA</div>
                    <div className="flex-celda">MEDIA BAJA</div>
                    <div className="flex-celda">MEDIA</div>
                    <div className="flex-celda">MEDIA ALTA</div>
                    <div className="flex-celda">ALTA</div>
                </div>
            </div>

           <div className="flex-tabla">
                {
                    [...Array(numPeriods+1).keys()].map((i) => {
                        return(<BandRow key={i} rowId={i} dateString={formatDateFunction(addPeriodicityFunction(currentDate, i)) } />)
                })}
                <div className="flex-fila">
                        <div className="flex-celda-double summary-cell"><CellText value={ "Promedio" } editing={false}/></div>
                        <div className="flex-celda summary-cell"><CellPercentage value={lowerBand} editing={false}/></div>
                        <div className="flex-celda summary-cell"><CellPercentage value={midLowerBand} editing={false}/></div>
                        <div className="flex-celda summary-cell"><CellPercentage value={midBand} editing={false}/></div>
                        <div className="flex-celda summary-cell"><CellPercentage value={midHigherBand} editing={false}/></div>
                        <div className="flex-celda summary-cell"><CellPercentage value={higherBand} editing={false}/></div>
                </div>
            </div>

        </div>
    </div>
    )
}

Bands.defaultProps = {
    fixedSize : null,
  };



export default Bands
