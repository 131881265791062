// Bar and Line Chart Hook based on D3

/**
 * Data must be passed with the following structure
 * Dictionary of objects:
 *  - xValues : numeric array with values 
 *  - bars : numeric array with values 
 *  - lines : Array of objects. Each object must include:
 *              - name : string with name
 *              - yValues : array of y values
 */

 import * as d3 from "d3";

import "../Charts.css"
import * as cc from '../ChartConstants';
import { MultiLineChart } from "./MultiLineChart";

const DEFAULT_PARAMETERS = {[cc.MARGIN] : { [cc.TOP]: 40, [cc.RIGHT]: 60, [cc.BOTTOM]: 15, [cc.LEFT]: 60 },                            
                            [cc.NORMAL_WIDTH] : 2.5,
                            [cc.BARS_MIDPOINT] : 50,
                            [cc.BARS_NAME] : "",
                            [cc.BARS_POSITIVE_COLOR] : "#0571b0",
                            [cc.BARS_NEGATIVE_COLOR] : "#ca0020",
                            [cc.BARS_LABEL] : "",
                            [cc.LEGEND_MARGIN_FROM_CHART] : 0.02, // Percentage
                            [cc.Y_AXIS_ON_LEFT] : false}


export class ContinuousBarAndMultiLineChart extends MultiLineChart
 {

    constructor(parameters, objectReference){        
        super({...DEFAULT_PARAMETERS, ...parameters}, objectReference)
    }

    initialize()
    {
        super.initialize()

        this.barsMidPoint = this.parameters[cc.BARS_MIDPOINT]
    }



    build(initialData)
    {


          // Variable for the bars
          this.numBars = initialData[cc.X_VALUES].length
          this.barWidth = 0.75*(this.width/this.numBars)

          this.numLines = initialData[cc.LINES].length

          

        // Creates the scales
        this.xScale = d3.scaleTime()
                      .domain([d3.min( initialData[cc.X_VALUES]), d3.max(initialData[cc.X_VALUES])])
                      .range([0, this.width])   
  
          this.min_bar_y = d3.min(initialData[cc.BARS])
          this.max_bar_y = d3.max(initialData[cc.BARS])
          this.final_bar_padding = (this.max_bar_y - this.min_bar_y)*this.parameters[cc.Y_PADDING]
          this.yBarScale = d3.scaleLinear()
                          .domain([this.min_bar_y - this.final_bar_padding, this.max_bar_y + this.final_bar_padding])
                          .range([this.height, 0])
  
  
          // Adds bars with initial value
          this.mainComponent.selectAll(".bar")
              .data(initialData[cc.BARS])
              .enter()
              .append("rect")
              .attr("class", "bar")
              .attr("fill", (val) => val >= this.barsMidPoint ? this.parameters[cc.BARS_POSITIVE_COLOR] : this.parameters[cc.BARS_NEGATIVE_COLOR])
              .attr("x", (_,i) => this.xScale(initialData[cc.X_VALUES][i]))
              .attr("width", this.barWidth)
              .attr("height", (val) => val >= this.barsMidPoint ? Math.abs(this.yBarScale(this.barsMidPoint) - this.yBarScale(val)) : Math.abs(this.yBarScale(this.barsMidPoint) - this.yBarScale(val)))//this.yBarScale(this.barsMidPoint)  - this.yBarScale(val))
              .attr("y", (val) => val >= this.barsMidPoint ? this.yBarScale(Math.max(this.min_bar_y,val)) : this.yBarScale(this.barsMidPoint))
  
  
          this.mainComponent.append('g')
              .attr('id', 'yBarAxis')
              .attr('class', 'axis axis--y')
              .call(d3.axisLeft(this.yBarScale));

              if(this.parameters[cc.BARS_LABEL] !== "")
              {
                 
                      this.svgEl.append("text")
                      .attr("transform", "rotate(-90)")
                      .style("font-size", this.parameters[cc.Y_LABEL_FONT_SIZE] + "px")
                      .style('fill', 'var(--text-color)')
                      .attr("y", -1*this.parameters[cc.Y_LABEL_FONT_SIZE] )
                      .attr("x", -1*this.chart_middle_height)
                      .attr("dy", "2.5em")
                      .style("text-anchor", "middle")
                      .text(this.parameters[cc.BARS_LABEL]); 

                  
              }


        let multiLineData = transformDataForMultiLineCart(initialData)
        super.build(multiLineData)


        this.constructLegend(multiLineData)      
    }

    
    // Overwrite
    // ---------------
    // Function that updates the chart
    updateData(newData){

        super.updateData(transformDataForMultiLineCart(newData))
        
    }

    getColorScheme(numElements)
    {
        // Builds default color scheme
        return(d3.scaleOrdinal().domain([0,numElements])
                          .range(["#00ffff", "var(--color-2)","var(--color-1)", ...d3.schemeSet1]))
    }

 }
 
 const transformDataForMultiLineCart = (data) =>{

    data = data[cc.LINES].map(ob => {return({...ob, [cc.X_VALUES] : data[cc.X_VALUES]})})
    return(data)

 }

  