// TODO - Documentar
import React, { useEffect, useState } from 'react'

import "./TradingViewComponents.css"

import * as con from "../../GlobalConstants"
import { Boundary, Button, Menu, MenuItem, OverflowList, Popover, Position } from '@blueprintjs/core'
import { RefApplicationParameterHook } from './../hooks/ApplicationParametersHooks'
import { setApplicationParameter } from '../../store/actions/applicationParameters'
import { RefUserAttributesHook } from '../hooks/UserAttributesHook'
import { RefMarketValueHook } from '../hooks/MarketValuesHook'


export const assetSelectionBarHeight = 5


export const AssetSelectionBar = () => {

    // All Available assets
    const marketAssets = RefMarketValueHook(con.MARKET_ASSETS)

    // Current selected Asset
    const selecetdAsset = RefApplicationParameterHook(con.SELECTED_MARKET_ASSET)

    // User Selected Assets
    const userSelectedAssets = RefUserAttributesHook(con.USER_SELECTED_ASSETS)

    // Local selected Assets
    const [localSelectedMarketAssets, setLocalSelectedMarketAssets] = useState(() => [con.COP_ASSET])

    
    useEffect(() => {

        if(userSelectedAssets !== undefined)
        {

            let finalAssets = [con.COP_ASSET]

            userSelectedAssets.forEach(id => {
                if(id in marketAssets && marketAssets[id][con.VINCO_CODE] !== con.COP_ASSET[con.VINCO_CODE])
                    finalAssets.push(marketAssets[id])})
         
            setLocalSelectedMarketAssets(finalAssets)
        }


    }, [marketAssets, userSelectedAssets])
    

  return (
    <div style={{maxWidth : "96vw", margin : "0px", paddingLeft : "1vh", paddingTop : "2px", height : assetSelectionBarHeight + "vh"}}>
        <OverflowList collapseFrom={Boundary.END}                                  
                        items={localSelectedMarketAssets} 
                        overflowRenderer={(elements) => 
                            <Popover content={<Menu >                                      
                            {
                                elements.map((elem) =>
                                <MenuItem key={elem[con.VINCO_CODE]} text={elem[con.NAME_SHORT]} title={elem[con.NAME]}  className={`bp3-minimal ${elem[con.ID] ===  selecetdAsset ? "selectedButton" :""}`} onClick={() => setApplicationParameter(con.SELECTED_MARKET_ASSET, elem[con.ID])}/>)
                                
                            }
                            </Menu>} position={Position.RIGHT_TOP}>
                        <Button icon={"menu"} 
                                className={`bp3-minimal`} />
                        </Popover> }
                        visibleItemRenderer={(elem) => <Button text={elem[con.NAME_SHORT]}  title={elem[con.NAME]} className={`bp3-minimal assetSelectionButton ${elem[con.ID] ===  selecetdAsset ? "selectedButton" :""}`} onClick={() => setApplicationParameter(con.SELECTED_MARKET_ASSET, elem[con.ID])}/>}
                        minVisibleItems={1}
                        />  
     </div>
  )
}

