import "./Pages.css"


import CustomRealTimeWidget from '../components/tradingViewComponentes/CustomRealTimeWidget'
import CustomTechnicalAnalysisWidget from '../components/tradingViewComponentes/CustomTechnicalAnalysisWidget'
import * as con from "../GlobalConstants"
import { AssetSelectionBar, assetSelectionBarHeight } from "../components/tradingViewComponentes/AssetSelectionBar"


function MarketsTechnicalAnalysisPage() {

    return (             
            <div>
                <AssetSelectionBar />
                <div className="gridBox" style={{height : (con.USABLE_SCREEN_SIZE - assetSelectionBarHeight)+"vh", paddingLeft : "1vh", paddingRight : "1vh"}}>                
                    <div style={{flexBasis : "70%", height : "100%", marginRight : "0.5vh"}}>                
                        <CustomRealTimeWidget/>
                    </div>
                    <div style={{flexBasis : "30%", marginLeft : "0.5vh"}}>
                        <div className="columnParameterBox">                     
                        <CustomTechnicalAnalysisWidget/>
                        </div>
                    </div>               
                </div>  
            </div>            
    )
}

export default MarketsTechnicalAnalysisPage
