import React, { useEffect, useState } from 'react'
import { getLastUserInteraction, updateUserInteraction } from '../../store/actions/applicationParameters';
import * as con from "../../GlobalConstants"
import { getNow, minutesBetweenDates } from '../../utils/dateFunctions';
import { getAuthorizationParameter, inactivityLogOut, refreshToken, userLogOut } from '../../store/actions/authentication';
import { getLocalStorageItem } from '../../utils/localStorageWrapper';
import Modal from '../../templates/popups/Modal';
import { Button } from '@blueprintjs/core';
import CustomProgressBar from '../../templates/progresBar/CustomProgressBar';


function InactivityCheck() {

  // Inactivity Dialog
  const [showInactivityDialog, setShowInactivityDialog] = useState(() => false)
  
  // Inactivity ProgressBar Value
  const [timeLeft, setTimeLeft] = useState(() => con.INACTIVITY_PROGRESS_BAR_START_SECONDS)
  

  // Check for inactivity
  const checkForInactivity = () =>
  {
    // If user is not authenticated skips
    if(!getAuthorizationParameter(con.IS_AUTHENTICATED))
        return

    let lastTokenRefreshed = getLocalStorageItem(con.LOCAL_LAST_TOKEN_REFRESHED_TIMESTAMP)
    let lastUserInteraction = getLastUserInteraction()
    
    // Checks for inactivity
    if(lastUserInteraction !== null && minutesBetweenDates(lastUserInteraction, getNow()) > con.MAX_IDLE_TIME_MINUTES)            
      setShowInactivityDialog(true)

    // Checks for token refresh
    else if(lastTokenRefreshed !== null && minutesBetweenDates(lastTokenRefreshed, getNow()) > con.MAX_MINUTES_SINCE_TOKEN_REFRESH)
        refreshToken()

      
  }


  // User Interaction Update
  useEffect(() => {
  
    window.addEventListener('click', () => updateUserInteraction());
  
    return () => {
      window.removeEventListener('click', updateUserInteraction);
    }
  }, [])


    // Checks if needs to logout or refresh token
    useEffect(() => {
                     
      let activityInterval = setInterval(() => {
          checkForInactivity()                      
          }, con.ACTIVITY_INTERVAL_CHECK_MINUTES*60*1000);        
    
        return() => { clearInterval(activityInterval)}

        }, [])

  // ProgressBar
  useEffect(() => {


    setTimeLeft(con.INACTIVITY_PROGRESS_BAR_START_SECONDS)

    const progressBarInterval = setInterval(() => {
      setTimeLeft(prevTimeLeft => prevTimeLeft - con.INACTIVITY_PROGRESS_BAR_MILLISECONDS_CHECK/1000)                      
      }, con.INACTIVITY_PROGRESS_BAR_MILLISECONDS_CHECK);  
    
    
    if(!showInactivityDialog)
      clearInterval(progressBarInterval);

    return () => {
      clearInterval(progressBarInterval);
    }
  }, [showInactivityDialog])


  // Checks logout
  useEffect(() => {
    
    if(timeLeft <= 0)
    {
      setShowInactivityDialog(false)
      inactivityLogOut()
    }

  }, [timeLeft])
  
  


  return (
    <Modal show={showInactivityDialog} doAccept={null}  doCancel={(e) => setShowInactivityDialog(false)} showCancel={false}>
      <h3>Su sesión va a terminar por inactividad</h3>
      <CustomProgressBar value={timeLeft/con.INACTIVITY_PROGRESS_BAR_START_SECONDS} />
      <div className="confirmCancelParameterBox">
        <Button text="Seguir Navegando" onClick={(e) => setShowInactivityDialog(false)} />
        <Button text="Terminar Sesión" onClick={(e) => {setShowInactivityDialog(false);userLogOut()}} />
      </div>
      
</Modal>
    
  )
}

export default InactivityCheck