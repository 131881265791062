// TODO - Documentar
import { useEffect, useRef } from "react";

export const useEventListener = (eventName, handler, element = window) => {
    const savedHandler = useRef();
  
    useEffect(() => {
      savedHandler.current = handler;
    }, [handler]);
  
    useEffect(() => {
      const eventListener = (event) => savedHandler.current(event);
      element.addEventListener(eventName, eventListener);
      return () => {
        element.removeEventListener(eventName, eventListener);
      };
    }, [eventName, element]);
  };