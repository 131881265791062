// Style
import '../ComponentsGlobalStyle.css'

import TransactionsDownload from '../transactions/TransactionsDownload'

function InputsSummary() {

    return (
        <div className="columnParameterBoxTop">

            <h3>Entradas</h3>
            <TransactionsDownload />
        </div>
    )
}

export default InputsSummary
