// Exposures
// TODO - Documentar
import React from 'react'
import * as con from "../../GlobalConstants"


import LoadingIcon from '../../templates/loading/LoadingIcon'

// Style
import "../ComponentsGlobalStyle.css"
import { RefApplicationParameterHook } from '../hooks/ApplicationParametersHooks'

function TransactionTableVisualizer({children, transactionType}) {
  
  // Transactions Loaded
  const transStatus = RefApplicationParameterHook(con.TRANSACTIONS_LOADED)


  // Loading
  const loading = RefApplicationParameterHook(con.LOADING_CURRENT_TRANSACTIONS)

 

  return (
    <div>        
        {
            transStatus[transactionType][con.STATUS] === con.LOADING ?
                <div>
                    <h5>Extrayendo de la base de datos.</h5>
                    <LoadingIcon/> 
                </div> :    
                  loading ? <h4>Cargando...</h4>
                          : children                                 
        }
    </div>
  )
}

export default TransactionTableVisualizer