// TODO - Documentar

import { useLocation, useNavigate } from 'react-router';
import { emulateTab } from 'emulate-tab';



/**
 * Hook for navegation
 */
 export function NavigationHook() {

    const location = useLocation();
    const navigate = useNavigate();    

    return([location.pathname, navigate])

 }


/**
 * Ref for current page
 */
 export function RefCurrentPage() {

    const location = useLocation();  

    return(location.pathname)

}

/**
 * Ref for set current page
 */
 export function RefSetCurrentPage() {

   const navigate = useNavigate();   

   return(navigate)


}


export const TabNavegationHandler = (event) =>
{
   if(event.keyCode === 13 )// Enter
   {            
       emulateTab();
       event.preventDefault();

   }
   if(event.keyCode === 24 )// Arrow up
   {            
      console.log('Arrow Up')
       emulateTab.backwards();
       event.preventDefault();

   }
   if(event.keyCode === 25 )// Enter
   {            
       emulateTab();
       event.preventDefault();

   }
}
