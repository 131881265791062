// TODO - Documentar
import React, { useEffect, useState } from 'react'
import { Checkbox, FormGroup, InputGroup, Text } from "@blueprintjs/core";


import { userLogin } from '../../store/actions/authentication';
import * as con from "../../GlobalConstants"
import LoadingIcon from '../../templates/loading/LoadingIcon';
import { RefNestedApplicationParameterHook } from '../hooks/ApplicationParametersHooks';
import LockEyePasswordButton from '../../templates/password/LockEyePasswordButton';
import { downloadFromLink } from '../../utils/downloadFunctions';




// Component for login in 
const Login = () => {
    

    // Application Parameters
    const loggedIn = RefNestedApplicationParameterHook(con.LOGGED_IN, con.STATUS)


    // Credentials
    const [username, setUsername] = useState()
    const [password, setPassword] = useState()


    // Terms of Use
    const [acceptedTermsOfUse, setAcceptedTermsOfUse] = useState(() => false)


    // State for pwd and login entered
    const [isFormFilled, setIsFormFilled] = useState(() => false)
    
    useEffect(() => {
        
        setIsFormFilled(username !== null && username !== "" && username !== undefined  && password !== null && password !== "" && password !== undefined)
      
    }, [username, password])
    

    const login = () =>
    {
        userLogin(username, password)
    }

    // To show/hide password
    const [showPassword, setShowPassword] = useState(() => false)


    const handleKeyPress = (event) => {
        if(isFormFilled && acceptedTermsOfUse && event.key === 'Enter'){
            login()
        }
      }

    return(
            <div className="stackingBox noCarret" style={{width : "350px"}} onKeyPress={handleKeyPress}>
                <div className="stackedBox-initial">
                    <div className="loginBox primary-background">                
                        <FormGroup label="Usuario" className={"inputUser"} style={{width : "100%"}}>
                            <InputGroup id="text-input"                
                                value={username} 
                                    onChange={(e) => {setUsername(e.target.value)}} />
                        </FormGroup> 
                        
                        <FormGroup label="Contraseña" className={"inputPwd"} style={{width : "100%"}}>
                            <InputGroup id="password"  
                                className="noCarret" 
                                type={ showPassword ? "text" :"password"}             
                                value={password} 
                                rightElement={<LockEyePasswordButton showPassword={showPassword} setShowPassword={setShowPassword} />}
                                onChange={(e) => {setPassword(e.target.value)}} />
                        </FormGroup>

                        <div style={{display : "flex", marginBottom : "10px", whiteSpace: "pre"}}>
                            <Checkbox checked={acceptedTermsOfUse} label={"Acepto los"} onChange={(ob) => setAcceptedTermsOfUse(!acceptedTermsOfUse)}/>
                            <Text className='clickableText' onClick={()=> downloadFromLink( con.rest_base_url +  con.terms_of_use_api, con.TERMS_OF_USE_NAME)}> Términos y Condiciones</Text>
                        </div> 

                        <button onClick={login} disabled={!acceptedTermsOfUse || !isFormFilled || loggedIn === con.LOADING}>
                            Enviar    
                        </button>
                    </div>
                </div>
                {   loggedIn === con.LOADING && 
                    <>
                        <div className="stackedBox-next">
                            <div className="loginBox primary-background" style={{opacity : 0.85}}>            
                            </div> 
                        </div>
                        <div className="stackedBox-next">
                            <div className="loginBox">            
                                <LoadingIcon />
                            </div> 
                        </div>
                    </>
                }
            </div>
                 
            

    )
}

export default Login;