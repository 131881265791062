// TODO - Documentar
// TradingViewWidget.jsx

import React, { useEffect, useRef } from 'react';
import { COP_ASSET, MARKET_ASSETS, SELECTED_MARKET_ASSET, TV_CODE } from '../../GlobalConstants';
import { RefApplicationParameterHook } from '../hooks/ApplicationParametersHooks';
import { RefMarketValueHook } from '../hooks/MarketValuesHook';

let tvScriptLoadingPromise;

export default function CustomRealTimeWidgetInitialView({fixedAsset = true, asset = COP_ASSET}) {
  const onLoadScriptRef = useRef();


  const marketAssetId = RefApplicationParameterHook(SELECTED_MARKET_ASSET)

  // All Available assets
  const marketAssets = RefMarketValueHook(MARKET_ASSETS)

  useEffect(
    () => {
      onLoadScriptRef.current = createWidget;

      if (!tvScriptLoadingPromise) {
        tvScriptLoadingPromise = new Promise((resolve) => {
          const script = document.createElement('script');
          script.id = 'tradingview-widget-loading-script';
          script.src = 'https://s3.tradingview.com/tv.js';
          script.type = 'text/javascript';
          script.onload = resolve;

          document.head.appendChild(script);
        });
      }

      tvScriptLoadingPromise.then(() => onLoadScriptRef.current && onLoadScriptRef.current());

      return () => onLoadScriptRef.current = null;

      function createWidget() {
        if (document.getElementById('tradingview_baaad') && 'TradingView' in window) {
          new window.TradingView.widget({
            autosize: true,
            symbol: fixedAsset ? asset[TV_CODE] : marketAssets[marketAssetId][TV_CODE],
            interval: "D",
            timezone: "America/Bogota",
            theme: "dark",
            style: "1",
            locale: "es",
            toolbar_bg: "#f1f3f6",
            enable_publishing: false,
            hide_top_toolbar: true,
            hide_legend: true,
            save_image: false,
            allow_symbol_change : false,
            container_id: "tradingview_baaad"
          });
        }
      }
    },
    [asset, fixedAsset, marketAssetId, marketAssets]
  );

  return (
    <div className='tradingview-widget-container' style={{height : "100%", width : "100%"}}>
      <div id='tradingview_baaad' style={{height : "80%", width : "100%"}}/>
      <div className="tradingview-widget-copyright">
        <a href="https://es.tradingview.com/" rel="noopener nofollow noreferrer" target="_blank"><span className="blue-text">Siga los mercados en TradingView</span></a>
      </div>
    </div>
  );
}

