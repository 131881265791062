// TODO - Documentar
import React, { useEffect, useRef, useState } from 'react'
import { VALUE_CHANGE_TIMEOUT } from '../../GlobalConstants'

function NumericVisualizer({title, value, formatValue, inverse}) {

    const lastValue = useRef(() => null)

    const [direction, setDirection] = useState(() => 0)

    const [justUpdated, setJustUpdated] = useState(() => false)



    useEffect(() => {
      
        
        let direction = 0
        if(lastValue.current < value)
            direction = 1
        else if(lastValue.current > value)
            direction = -1 

        
        if(lastValue.current !== null)
        {
            setDirection(inverse ? -1*direction: direction)
            setJustUpdated(true)        
    
            setTimeout(() => {
                setJustUpdated(false)
            }, VALUE_CHANGE_TIMEOUT)
        }

        lastValue.current = value
                

    }, [value, inverse])

        

  return (
    <p className={!justUpdated ? "" : direction === 0 ? "neutral" : direction > 0 ? "ascending" : "descending"}>
        <strong>{title} </strong>{ formatValue(value)}</p>
  )
}

NumericVisualizer.defaultProps = {
    inverse : false
}

export default NumericVisualizer