// TODO - Documentar
import React from 'react';
import { ForwardsTable } from "./ForwardsTable"



export const Calculator = ({numberOfColumns = 3, shortColumnNames=false}) => {
    return (
        <div>
            <ForwardsTable numberOfColumns={numberOfColumns} shortColumnNames={shortColumnNames}/>
        </div>
    )
}
