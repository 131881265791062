// TODO - Documentar
import React from 'react'
import Profile from '../components/profile/Profile'
import { USABLE_SCREEN_SIZE } from '../GlobalConstants'
import "./Pages.css"

function UserProfilePage() {
    return (
        <div style={{height : USABLE_SCREEN_SIZE + "vh"}}>
            <Profile />           
        </div>
    )
}

export default UserProfilePage
