// TODO - Documentar
import React, { useEffect, useState } from 'react';


import * as con from "../../GlobalConstants"

// Style
import "../../templates/Templates.css"
import "../../templates/tables/Table.css"
import { EDIT_COMMAND,  REMOVE_COMMAND, VIEW_ANNOTATIONS_COMMAND } from './TransactionTableConstants';
import RowAdditionElement from './RowAdditionElement';
import { TransactionRow } from './TransactionRow';
import { ClickOutsideAction } from '../../templates/hooks/ClickOutiseAction';
import { filterObject, size } from '../../GlobalFunctions';
import { allTransactionsCol, buildDefaultRow, selectTransactionsByPage, sortTransactionKeysByColumn } from '../../utils/transactionFunctions';
import { RefApplicationParameterHook, RefNestedApplicationParameterHook } from '../hooks/ApplicationParametersHooks';
import { Icon } from '@blueprintjs/core';
import { setTotalTransactionsBeingDisplayed } from '../../store/actions/applicationParameters';
import { RefUser } from '../hooks/AuthenticationHook';
import {  getNow } from '../../utils/dateFunctions';
import { isValueValid } from '../../templates/Types';


/**
 * Custom table component to display and edit transactions
 */
export const TransactionTable = ({transactionType, columnArray, dataDictionary, setRow, deleteRow, getAnnotations, commands, includeAddition, addRow, filterFunction, includeLastEdited, noResultsWhenFilteredMessage, searchFunction}) => {


  // User
  const user = RefUser()

  // Used columns
  const [usedColumnArray, setUsedColumnArray] = useState(() =>{

      if(includeLastEdited && user[con.IS_STAFF] && columnArray[0][con.ID] !== con.LAST_EDITED)
          return([{...allTransactionsCol[con.LAST_EDITED], [con.EDITABLE] : false, [con.DEFAULT_VALUE] : getNow()}].concat(columnArray))
        
      else
          return(columnArray)

  })

  // Last Updated col
  useEffect(() => {
    
    if(includeLastEdited && user[con.IS_STAFF] && columnArray[0][con.ID] !== con.LAST_EDITED)
    {      
        columnArray.unshift({...allTransactionsCol[con.LAST_EDITED], [con.EDITABLE] : false, [con.DEFAULT_VALUE] : getNow()})
    }

    setUsedColumnArray(columnArray)

  }, [columnArray, user, includeLastEdited])
  


  // Maps the global methods to the local methods
  // Update
  const setRowLocal = setRow

  // Delete
  const deleteRowLocal = deleteRow

  // SortColumn
  const [sortColId, setSortColId] = useState(() => usedColumnArray[0][con.ID])
  const [order, setOrder] = useState(() => user[con.IS_STAFF] ? con.DESCENDING : con.ASCENDING)
  const [sorting, setSorting] = useState(() => false)

  // CurrentPage
  const currentPage = RefNestedApplicationParameterHook(con.CURRENT_TRANSACTION_NAVIGATION_PAGE, transactionType)

  // Transaction change
  const transactionChange = RefApplicationParameterHook(con.INTERNAL_TRANSACTION_CHANGE)


  const updateSortingCol = (col) =>
  {
      // Checks table size
      if(Object.keys(filteredDataDic).length > con.MIN_ROWS_FOR_LOADING)
        setSorting(true)

      if(col === sortColId)      
        setOrder(order === con.ASCENDING ? con.DESCENDING : con.ASCENDING)
      else
        setSortColId(col)

        // Checks table size
      if(Object.keys(filteredDataDic).length > con.MIN_ROWS_FOR_LOADING)
        {
            setTimeout(() => {              
                setSorting(false)
              }, con.TABLE_SORTING_TIME);

        }

  }

  useEffect(() => {
    setSortColId(usedColumnArray[0][con.ID])
  }, [usedColumnArray])
  

  // Editing Row  
  const [rowBeenEditing, setRowBeenEditing] = useState(() => null)
  const [rowBeingEditingId, setRowBeingEditingId] = useState(() => null)

    const startEditingRow = (row) =>{

      setRowBeenEditing(row)
      setRowBeingEditingId(row[con.ID])

    }
    
    const setValueOnEditingRow = (idCol, val) => 
    {
      rowBeenEditing[idCol] = val
      setRowBeenEditing(rowBeenEditing)
    }

    const saveEditingRow = () => {
        if(rowBeenEditing !== null)
        {
            // Checks if changed
            if(JSON.stringify(dataDictionary[rowBeenEditing[con.ID]]) !== JSON.stringify(rowBeenEditing))
            {    
              // Initial values for the Popup scheme
              const initialNewRowValues = buildDefaultRow(columnArray)

              // If the field to edit is null or invalid, the default value is assigned
              columnArray.forEach(col => {
                if (col[con.ID] === null || !(isValueValid(col[con.TYPE], rowBeenEditing[col[con.ID]], col[con.VALUE_CHECKER]))) {
                  rowBeenEditing[col[con.ID]] = initialNewRowValues[col[con.ID]];
                }
              })       
              setRow(rowBeenEditing[con.ID], rowBeenEditing)
            }
                
        }
                    
        setRowBeenEditing(null)
        setRowBeingEditingId(null)
    }

  // Editing outside Hook
  const tableRef = ClickOutsideAction(() => saveEditingRow(), new RegExp(".*Mui.*|.*PrivatePickersYear.*",'i'))

  // Local Data
  const [filteredDataDic, setFilteredDataDic] = useState(() => filterObject(dataDictionary, filterFunction))
  const [localData, setLocalData] = useState(() => searchFunction === con.DEFAULT_SEARCH_FUNCTION ? dataDictionary :  filterObject(filteredDataDic, searchFunction))


  useEffect(() => {

    setFilteredDataDic(filterObject(dataDictionary, filterFunction))
    
  }, [dataDictionary, filterFunction, transactionChange])


  // Search
  useEffect(() => {
    
    setLocalData(filterObject(filteredDataDic, searchFunction))
    
  }, [filteredDataDic, searchFunction])
  
  
  // Updates total being displayed
  useEffect(() => {
  
    setTotalTransactionsBeingDisplayed(transactionType, size(localData))

  }, [localData, transactionType])
  
       
    return (
    <div style={{marginBottom : "2vh"}}>
        {   
            includeAddition && 
            <div style={{marginTop : "1vh", marginBottom : "3vh"}}>
                    <RowAdditionElement columnArray={usedColumnArray} addRow={addRow} />
            </div>
        }   
        {   
            Object.keys(dataDictionary).length === 0 
                ? <h4>{"El usuario no tiene registros agregados"}</h4>
                : Object.keys(localData).length === 0 ?
                  <h4>{noResultsWhenFilteredMessage}</h4>
                : <div ref={tableRef} className="contenedor-tabla">
                <div className="flex-tabla nonFixedTable">
                    <div className="flex-fila header-row sticky" style={{top : con.NAV_BAR + "vh"}}>
                        {  
                            // Iterates over values
                            Object.values(usedColumnArray).map((col) =>                 
                            <div key={col[con.ID]} className={`flex-celda ${ col[con.ID] === sortColId?"selected-header-cell":""}`} onClick={() => updateSortingCol(col[con.ID])}>
                              {col.name.toUpperCase()}
                              { col[con.ID] === sortColId && order === con.DESCENDING && <span><Icon style={{marginLeft : "2px"}} icon="arrow-up"/></span> }
                              { col[con.ID] === sortColId && order === con.ASCENDING && <span><Icon style={{marginLeft : "2px"}} icon="arrow-down"/></span> }                          
                              </div>
                            )
                            
                        }
                        
                        {
                          commands && commands.length > 0 && <div className="flex-celda">COMANDOS</div>                                         
                        }
                        
                               
                    </div>
                
                {sorting ? <h4>Ordenando...</h4> :
                             
                    
                        // Itera sobre los datos
                        selectTransactionsByPage(sortTransactionKeysByColumn(localData, sortColId, order), currentPage).map((k,i) =>                 
                        <TransactionRow numRow={i}
                                        key={`${transactionType}-${k}`} 
                                        columnArray={usedColumnArray}
                                        dataDictionary={localData} 
                                        idRow={k}                            
                                        setRow={setRowLocal}
                                        deleteRow = {deleteRowLocal}  
                                        getAnnotations={getAnnotations}                  
                                        commands={commands}
                                        editing={rowBeingEditingId !== null && rowBeingEditingId.toString() === k.toString()} 
                                        setValueOnEditingRow={setValueOnEditingRow}
                                        startEditingRow={startEditingRow}
                                        saveEditingRow={saveEditingRow}/>)
                        
                    
                } 
                </div>
            </div>
    
        }
        
    </div>
    )
}


TransactionTable.defaultProps = {
    commands: [EDIT_COMMAND, REMOVE_COMMAND, VIEW_ANNOTATIONS_COMMAND],
    includeAddition : true,
    filterFunction : (ob) => true,
    searchFunction : (ob) => true,
    includeLastEdited : true,
    noResultsWhenFilteredMessage : "Ningún registro coincide con los parámetros de búsqueda"
  };

