// TODO - Documentar
import { Text } from '@blueprintjs/core'
import React from 'react'
import { TechnicalAnalysis } from 'react-ts-tradingview-widgets'
import { MARKET_ASSETS, SELECTED_MARKET_ASSET, TV_CODE } from '../../GlobalConstants'
import { RefApplicationParameterHook } from '../hooks/ApplicationParametersHooks'
import { RefMarketValueHook } from '../hooks/MarketValuesHook'

function CustomTechnicalAnalysisWidget({parameters}) {
    

    const marketAssetId = RefApplicationParameterHook(SELECTED_MARKET_ASSET)

    // All Available assets
    const marketAssets = RefMarketValueHook(MARKET_ASSETS)
    
    return (
        <>
           { marketAssetId in marketAssets 
            ?<TechnicalAnalysis symbol={marketAssets[marketAssetId][TV_CODE]} interval="1h" locale="es" {...parameters} colorTheme="dark"/>
            :<div>
            <Text>Activo no Encontrado</Text>
           </div>
        }
            </>
    )
}

export default CustomTechnicalAnalysisWidget
