import React, { useState, useEffect } from 'react';
import "../../templates/Templates.css";
import "../../templates/tables/Table.css";
import "../ComponentsGlobalStyle.css";
import { useSelector } from 'react-redux';
import FirstLeftRow from './FirstLeftRow';

function FirstLeftTable({ data }) {
    const spot = useSelector(state => state['store']['applicationParameters']['spot']);
    const [styles, setStyles] = useState({ fontSize: '12px', padding: '5px 10px' });
    const [isSmallScreen, setIsSmallScreen] = useState(false);

    const adjustStyles = () => {
        const width = window.innerWidth;
        const newStyles = {
            fontSize: width < 400 ? '9px' : width < 768 ? '11px' : '14px',
            padding: width < 400 ? '3px 5px' : width < 768 ? '4px 8px' : '5px 10px',
        };
        setStyles(newStyles);
        setIsSmallScreen(width < 768);
    };

    useEffect(() => {
        adjustStyles();
        window.addEventListener('resize', adjustStyles);
        return () => window.removeEventListener('resize', adjustStyles);
    }, []);

    const formatNumberWithCommas = (number) => {
        if (number === null || number === undefined || isNaN(number)) return '-';
        return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    };

    return (
        <div className="contenedor-tabla" style={{ fontSize: styles.fontSize, padding: '0 10px', marginBottom: '15px' }}>
            <div className="flex-tabla">
                <div className="flex-fila header-row" style={{ padding: styles.padding }}>
                    <div className="flex-celda">APERTURA</div>
                    <div className="flex-celda">ÚLTIMO</div>
                    <div className="flex-celda">PROMEDIO</div>
                    {!isSmallScreen && <div className="flex-celda">MAX</div>}
                    {!isSmallScreen && <div className="flex-celda">MIN</div>}
                    <div className="flex-celda">VOLUMEN</div>
                </div>
            </div>

            <div className="flex-tabla">
                <FirstLeftRow 
                    data={{
                        apertura: data.apertura === 0 ? '0' : `${formatNumberWithCommas(data.apertura)} ${data.apertura_time ? `(${data.apertura_time})` : ''}`,
                        ultimo: spot,
                        cierre_anterior: data.cierre_anterior,
                        promedio: data.promedio === 0 ? '0' : `${formatNumberWithCommas(data.promedio)} ${data.promedio_percentage !== null && data.promedio_percentage !== undefined ? `(${data.promedio_percentage}%)` : ''}`,
                        maximo: data.maximo === 0 ? '0' : `${formatNumberWithCommas(data.maximo)} ${data.max_time ? `(${data.max_time})` : ''}`,
                        minimo: data.minimo === 0 ? '0' : `${formatNumberWithCommas(data.minimo)} ${data.min_time ? `(${data.min_time})` : ''}`,
                        volumen: data.volumen === 0 ? '0' : `${formatNumberWithCommas(data.volumen)} ${data.trm_volumen !== null && data.trm_volumen !== undefined ? `(TRM: ${formatNumberWithCommas(data.trm_volumen)})` : ''}`,
                    }}
                    styles={styles}
                    isSmallScreen={isSmallScreen}
                />
            </div>
        </div>
    );
}

export default FirstLeftTable;
