import React, { useState, useEffect } from 'react';
import { CellText } from '../../templates/tables/Cells';

function FirstLeftRow({ data, styles, isSmallScreen }) {
    const [currentTime, setCurrentTime] = useState('');

    useEffect(() => {
        const time = new Date().toLocaleTimeString('es-CO', {
            timeZone: 'America/Bogota',
            hour12: false,
        });
        setCurrentTime(time);
    }, [data]); 

    const calculatePercentage = (currentValue, previousValue) => {
        if (!previousValue || previousValue === 0 || !currentValue || currentValue === 0) {
            return '0.00';
        }
        const percentage = ((currentValue - previousValue) / previousValue) * 100;
        return percentage.toFixed(2);
    };

    const spot = parseFloat(data.ultimo);
    const cierreAnterior = parseFloat(data.cierre_anterior);
    const ultimoPercentage = calculatePercentage(spot, cierreAnterior);

    return (
        <div
            className="flex-fila"
            style={{ padding: `1px 10px`, textAlign: 'center', fontSize: styles.fontSize }}
        >
            <div className="flex-celda" style={{ padding: styles.padding }}>
                <CellText value={data.apertura} editing={false} />
            </div>
            <div className="flex-celda" style={{ padding: styles.padding }}>
                <CellText
                    value={`${spot.toFixed(2)}\n(${currentTime}, ${ultimoPercentage}%)`}
                    editing={false}
                />
            </div>
            <div className="flex-celda" style={{ padding: styles.padding }}>
                <CellText value={data.promedio} editing={false} />
            </div>
            {!isSmallScreen && (
                <div className="flex-celda" style={{ padding: styles.padding }}>
                    <CellText value={data.maximo} editing={false} />
                </div>
            )}
            {!isSmallScreen && (
                <div className="flex-celda" style={{ padding: styles.padding }}>
                    <CellText value={data.minimo} editing={false} />
                </div>
            )}
            <div className="flex-celda" style={{ padding: styles.padding }}>
                <CellText value={data.volumen} editing={false} />
            </div>
        </div>
    );
}

export default FirstLeftRow;
