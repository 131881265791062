// TODO - Documentar
import React, { useEffect, useState } from 'react'
import { RefApplicationParameterHook } from '../hooks/ApplicationParametersHooks'
import * as con from "../../GlobalConstants"
import { constructGidTable } from '../../utils/orionFunctions'
import { StaticTable } from '../../templates/tables/StaticTable'
import { allTransactionsCol } from '../../utils/transactionFunctions'
import { getPositionInBands } from '../../utils/bandFunctions'
import { BandSummaryHook } from '../hooks/BandsHooks'


const columnArray = [{ [con.NAME] : 'NIVEL',                         
                        [con.ID] : con.LEVEL,
                        [con.TYPE] : con.MONEY,  
                        [con.CURRENCY] : con.MONEY_COP,
                        },
                        {[con.NAME] : 'MONTO', 
                        [con.ID] : con.AMOUNT,
                        [con.TYPE] : con.MONEY,  
                        [con.CURRENCY] : con.MONEY_COP},
                        {
                          [con.NAME] : 'COBERTURA', 
                          [con.ID] : con.COVERAGE_PERCENTAGE,
                          [con.TYPE] : con.PERCENTAGE,                          
                        },
                        {
                        [con.NAME] : 'COBERTURA ACUMULADA', 
                        [con.ID] : con.COVERAGE_PERCENTAGE_ACCUMULATED,
                        [con.TYPE] : con.PERCENTAGE,                          
                        },                    
                        {...allTransactionsCol[con.POSITION_IN_BANDS]}]

function CoveragesGridTable({ summaryRow, computing, hasSummaryTable}) {

    // Grid Variables
    const clientType = RefApplicationParameterHook(con.COVERAGES_GRID_CLIENT_TYPE)
    const stopLoss = RefApplicationParameterHook(con.COVERAGES_GRID_STOP_LOSS)
    const initialLevel = RefApplicationParameterHook(con.COVERAGES_GRID_INITIAL_LEVEL)
    const finalLevel = RefApplicationParameterHook(con.COVERAGES_GRID_FINAL_LEVEL)
    const spread = RefApplicationParameterHook(con.COVERAGES_GRID_SPREAD)
    const amountToPay = RefApplicationParameterHook(con.COVERAGES_GRID_AMOUNT_TO_BUY)

    // Bands
    const bandsSummary = BandSummaryHook()

    const [tableData, setTableData] = useState(() => [])


    useEffect(() => {
      
        if(!computing && hasSummaryTable)
        {
            let newTableData = constructGidTable(clientType, summaryRow[con.EXPOSURE_AMOUNT], amountToPay, stopLoss, initialLevel, finalLevel, spread)
            
            
            newTableData  = newTableData.map(ob => {return({...ob, [con.POSITION_IN_BANDS] : getPositionInBands(ob[con.COVERAGE_PERCENTAGE_ACCUMULATED] + summaryRow[con.COVERAGE_PERCENTAGE], bandsSummary)})})
            
            
            setTableData(newTableData)
        }
    }, [computing, hasSummaryTable, summaryRow, amountToPay, stopLoss, initialLevel, finalLevel, spread, bandsSummary, clientType])
    
    
    

  return (
    <div>
        { 
            computing ? <h5>Computando...</h5> 
                    : !hasSummaryTable ? <h4>{con.NO_TRANSACTIONS_MESSAGE}</h4> 
                    : <StaticTable  columnArray = {columnArray} 
                                dataDictionary={tableData}                      
                                sortable={false}/>
                     
        }
    </div>
  )
}

export default CoveragesGridTable