// TODO - Documentar
import React from 'react'
import "./MobilePages.css"

import CustomMarketOverviewWidget from '../components/tradingViewComponentes/CustomMarketOverviewWidget'
import { USABLE_SCREEN_SIZE } from '../GlobalConstants'
import SimpleFillFooter from '../templates/footer/SimpleFillFooter'

function MobileMarketsFundamentalAnalysis() {
    
    return (
        <div>              
            <div  style={{height : USABLE_SCREEN_SIZE+"vh", padding:  "1vh"}}>                   
                <CustomMarketOverviewWidget/>                
            </div>  
            <SimpleFillFooter/>
        </div>
    )
}

export default MobileMarketsFundamentalAnalysis
