import axios from 'axios';
import * as types from "./types"
import store from '../store';
import * as con from "../../GlobalConstants"
import { getApplicationParameter, setApplicationParameter } from './applicationParameters';
import * as sf from "./../logic/supportFunctions"
import {  handleRequestError } from '../../utils/errorFunctions';
import { createNotification } from '../../templates/notifications/Notifications';


// Login
export const userLogin = (username, password) =>
{

    // Sets Logging in to true
    setApplicationParameter(con.LOGGED_IN, {[con.STATUS] : con.LOADING})

    // Declares the headers
    const config = {
        "Content-Type":"application/json"
    }

    axios.post(con.rest_base_url + con.login_api, {username, password}, config)
            .then(res => {
                
                store.dispatch({
                    type : types.LOGIN,
                    payload : res.data
                })

                setApplicationParameter(con.LOGGED_IN, {[con.STATUS] : con.OK})
                
            })
            .catch((error) => {
                                
                // Check for credentials Error
                if(error.response && error.response.data.non_field_errors && error.response.data.non_field_errors[0] === con.WRONG_CREDENTIALS)
                    setApplicationParameter(con.LOGGED_IN, {[con.STATUS] : con.WRONG_CREDENTIALS})
                else
                {
                    handleRequestError(error)
                    setApplicationParameter(con.LOGGED_IN, {[con.STATUS] : con.ERROR})
                }
                })
}



// User Logout
export const logOutFromServer = () =>
{

     const token = getToken()
 
     const config = {
         headers : {
             "Authorization" : `Token ${token}`
         }
     }
 
     axios.get(con.rest_base_url + con.logout_api,  config)
             .then(res => {                 
                console.log("User Logged out from Server")                 
             })
             .catch((error) => {
                
                console.warn("Error while logging out")
                handleRequestError(error)


                 })
}


// Refresh Token
export const refreshToken = () =>
{

     const token = getToken()
 
     const config = {
         headers : {
             "Authorization" : `Token ${token}`
         }
     }
 
     axios.get(con.rest_base_url + con.refresh_token_api,  config)
             .then(res => {
                 
                store.dispatch({
                    type : types.REFRESH_TOKEN,
                    payload : res.data
                })

                console.log("Token Refreshed")
                 
             })
             .catch((error) => {
                
                console.warn("Error while refreshing token")
                handleRequestError(error)


                 })
}


// CheckPassword
export const checkPassword = (password) =>
{

    // Sets Logging in to true
    setApplicationParameter(con.CHECK_PASSWORD, {[con.STATUS] : con.LOADING})

    const username = sf.getUser()[con.USERNAME]

    const token = getToken()

    const config = {
        headers : {
            "Authorization" : `Token ${token}`
        }
    }

    axios.post(con.rest_base_url + con.check_password_api, {username, password}, config)
            .then(res => {
                
                setApplicationParameter(con.CHECK_PASSWORD, {[con.STATUS] : con.OK})
                
            })
            .catch((error) => {
                                
                // Check for credentials Error
                if(error.response && error.response.data.non_field_errors && error.response.data.non_field_errors[0] === con.WRONG_CREDENTIALS)
                    setApplicationParameter(con.CHECK_PASSWORD, {[con.STATUS] : con.WRONG_CREDENTIALS})
                else
                {
                    handleRequestError(error)
                    setApplicationParameter(con.CHECK_PASSWORD, {[con.STATUS] : con.ERROR})
                }
                })
}



// Change Password
export const changePassword = (password, newPassword, callBackFunction = () => true) =>
{

    // Sets Logging in to true
    setApplicationParameter(con.CHANGE_PASSWORD, {[con.STATUS] : con.LOADING})

    const user = sf.getUser()
    const surrogateUser = sf.getSurrogateUser()

    // Adds values
    const data = {}
    let url = con.rest_base_url
    if(sf.surrogateActive())
    {
        url += con.staff_change_password_api
        data[con.USERNAME] = surrogateUser[con.USERNAME]
        data[con.NEW_PASSWORD] = newPassword

    }
    else
    {
        url += con.change_password_api
        data[con.USERNAME] = user[con.USERNAME]
        data[con.PASSWORD] = password
        data[con.NEW_PASSWORD] = newPassword
    }
    


    const token = getToken()

    const config = {
        headers : {
            "Authorization" : `Token ${token}`
        }
    }

    axios.post(url, data, config)
            .then(res => {
                
                setApplicationParameter(con.CHANGE_PASSWORD, {[con.STATUS] : con.OK})
                createNotification("Contraseña guardada correctamente.")
                callBackFunction()
                
            })
            .catch((error) => {
                                
                // Check for credentials Error
                if(error.response && error.response.data.non_field_errors && error.response.data.non_field_errors[0] === con.WRONG_CREDENTIALS)
                    setApplicationParameter(con.CHANGE_PASSWORD, {[con.STATUS] : con.WRONG_CREDENTIALS})
                else
                {
                    handleRequestError(error)
                    setApplicationParameter(con.CHANGE_PASSWORD, {[con.STATUS] : con.ERROR})
                }
                })
}



// Get model parameters
export const fetchUserAttributes = () =>
{



    const token = getToken()

    const config = {
        headers : {
            "Authorization" : `Token ${token}`
        }
    }

    let url = con.rest_base_url + con.user_profile_api
    if(sf.surrogateActive())
        url = con.rest_base_url + con.staff_user_profile_api + `/${sf.getSurrogateUser()[con.ID]}`

    axios.get(url, config)
            .then(res => {

                // Changes the avatar image
                if(con.AVATAR_IMAGE in res.data && res.data[con.AVATAR_IMAGE]!=null)
                {
                    if(res.data[con.AVATAR_IMAGE].startsWith("/static/"))
                        res.data[con.AVATAR_IMAGE] = con.rest_base_url + res.data[con.AVATAR_IMAGE]
                }

                store.dispatch({
                                type : types.REPLACE_USER_PROFILE,
                                payload : res.data
                                })
            })
            .catch(err => handleRequestError(err))
}




// Get model parameters
export const fetchUserLines = () =>
{

    const token = getToken()

    const config = {
        headers : {
            "Authorization" : `Token ${token}`
        }
    }

    let url = con.rest_base_url + con.user_lines_api
    if(sf.surrogateActive())
        url = con.rest_base_url + con.staff_user_lines_api + `/${sf.getSurrogateUser()[con.ID]}`

    axios.get(url, config)
            .then(res => {

                
                
                store.dispatch({
                                type : types.REPLACE_USER_LINES,
                                payload : res.data
                                })
            })
            .catch(err => handleRequestError(err))
}


export const setSurrogateUserById = (surrogateUserId) => {

    let user = sf.getUser()
    if(surrogateUserId in getApplicationParameter(con.SURROGATE_USER_DICT))
        user = getApplicationParameter(con.SURROGATE_USER_DICT)[surrogateUserId]

    store.dispatch({
                    type : types.SET_SURROGATE_USER,
                    payload : user
                    })
}

// Get surrogate user list
export const fetchSurrogateUserList = async () =>
{

    const token = getToken()

    const config = {
        headers : {
            "Authorization" : `Token ${token}`
        }
    }

    let surrogateUsers = {}
    await axios.get(con.rest_base_url + con.surrogate_user_list_api, config)
            .then(res => {

                // Converts to dictionary
                surrogateUsers = Object.assign({}, ...res.data.map((ob) => ({[ob[con.ID]]: ob})));
                
                setApplicationParameter(con.SURROGATE_USER_DICT, surrogateUsers)
            })
            .catch(err => handleRequestError(err))

    return(surrogateUsers)

}


export const getToken = () => {
    return(sf.getToken())    
}


// Logs out by user request
export const userLogOut = () =>
{
    logOutFromServer()
    store.dispatch({
        type : types.USER_LOGOUT,
        payload : {}
    })


}

// Logs out by inactivity
export const inactivityLogOut = () =>
{

    logOutFromServer()
    store.dispatch({
        type : types.LOGOUT_FOR_INACTIVITY,
        payload : {}
    })

}


// Resets User lines
export const resetUserLines = () =>
{
    store.dispatch({
        type : types.REPLACE_USER_LINES,
        payload : []
    })

}

export const getUserName = (userID) => {

    let userDict = getApplicationParameter(con.SURROGATE_USER_DICT)
    return(userDict[userID][con.USERNAME])
}

export const getLine = (userID, lineId) => {

    let userDict = getApplicationParameter(con.SURROGATE_USER_DICT)
    let line = userDict[userID][con.LINES].filter( ob => `${ob[con.ID]}` === `${lineId}`)[0]
    return(line)
}


export const getAuthorizationParameter = (key) => {
    return(store.getState()[con.STORE][con.REDUCER_AUTHENTICATION][key])
}