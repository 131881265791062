import React from 'react'
import * as con from "../../GlobalConstants"
import { TransactionsSummary } from '../transactionsSummary/TransactionsSummary'
import GlobalViewComponent from './GlobalViewComponent'



function CurrentPositionGlobalViewComponent({componentHeight}) {
  return (
    <GlobalViewComponent pageLink={con.PAGE_CURRENT_POSITION}
                                         title={"POSICIONAMIENTO"}>                                                
                        <TransactionsSummary selectedColumns={
                            [{ [con.ID] : con.DATE},
                            { [con.ID] : con.EXPOSURE_AMOUNT},
                            { [con.ID] : con.COVERAGE_AMOUNT},
                            { [con.ID] : con.COVERAGE_PERCENTAGE}, 
                            { [con.ID] :  con.POSITION_IN_BANDS},
                            { [con.ID] : con.VAR, [con.NAME] : 'VAR (MILLONES)'}]}
                                            fixedSize = {componentHeight*0.90}
                                            forcedVisualizationPeriodicity={con.PERIODICITY_MONTH}
                                            includeAllRows={true}
                                            filterByBands={false}
                                            includeDownloadButton={false}
                                            compress={true}
                                            />
                        
                    </GlobalViewComponent>   
  )
}

export default CurrentPositionGlobalViewComponent