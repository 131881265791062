import React, { useState } from 'react'
import { ScenariosHook } from '../hooks/ScenariosHook'
import * as con from "../../GlobalConstants"

import { Button, Checkbox } from '@blueprintjs/core'
import Modal from '../../templates/popups/Modal'
import { StateUserLineApplicationParameterHook } from '../hooks/UserLineApplicationParametersHooks'


function BandComparisonScenariosSelector() {

    // Selected Scenarios
    const [selectedScenarios, setSelectedScenarios] = StateUserLineApplicationParameterHook(con.SELECTED_BAND_COMPARISON_SCENARIOS)


    // Scenarios
    // -----------------
    const scenarios = ScenariosHook() 

    // Controls
    const [showDialog, setShowDialog] = useState(() => false)

    const localUpdateSelectedScenarios = (scenariosID) =>
    {

        let newSelectedScenarios = selectedScenarios
        if(newSelectedScenarios.includes(scenariosID))
          newSelectedScenarios = newSelectedScenarios.filter(val => val !== scenariosID)
        else
          newSelectedScenarios.push(scenariosID)

            setSelectedScenarios([...newSelectedScenarios])

    }


  return (

    <div>
        <Modal show={showDialog} doAccept={() => setShowDialog(false)} showCancel={false} doCancel={() => setShowDialog(false)}>      
           
           <h2>Selección de Escenarios</h2>
        
           <div className='columnParameterBoxLeft'>
               {
                 Object.values(scenarios).map(sce => 
                   <Checkbox key={sce[con.ID]} checked={selectedScenarios.includes(sce[con.ID]) } label={ sce[con.NAME]} onChange={() => localUpdateSelectedScenarios(sce[con.ID])}/>
                   )
               }
           </div>                                             
        </Modal> 

        <Button icon={"add-to-artifact"}                               
                    className={`bp3-minimal`}                  
                    onClick={() => setShowDialog(true)}/> 

    </div>
  )
}

export default BandComparisonScenariosSelector