// MobileIntradayDollarInfoPage.js
import React, { useEffect, useState } from 'react';
//import React, { useEffect, useState, useCallback } from 'react';
import axios from 'axios';
import { Button, Radio, RadioGroup } from '@blueprintjs/core'; 
import IntradayRecordsTable from '../components/tables/IntradayRecordsTable';
import FirstLeftTable from '../components/tables/FirstLeftTable';
import SecondLeftTable from '../components/tables/SecondLeftTable';
import CandleStickChart from '../components/charts/CandleStickChart';
// import AlertasModal from '../components/modales/AlertasModal'; // 
import * as con from "./../GlobalConstants";
//import { RefToken } from '../components/hooks/AuthenticationHook';
//import { RefUserAttributesHook } from '../components/hooks/UserAttributesHook';
import { getFinanceRequestConfig } from "../store/logic/supportFunctions";

const config = getFinanceRequestConfig();

function MobileIntradayDollarInfoPage() {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [selectedPage, setSelectedPage] = useState('resumen'); 
    // const [isModalOpen, setIsModalOpen] = useState(false); // 
    // const [alerts, setAlerts] = useState([]); // 

    //const user = RefUserAttributesHook('user');
    //const token = RefToken();

    const fetchIntradayData = async () => {
        setLoading(true);
        try {
            const response = await axios.get(`${con.rest_finance_url}${con.intraday_dollar_info}`, config);
            setData(response.data);
        } catch (error) {
            console.error('Error fetching data:', error);
            setData(null);
        } finally {
            setLoading(false);
        }
    };

    // 
    /*
    const fetchAlerts = useCallback(async () => {
        if (user && token) {
            try {
                const response = await axios.get(
                    `${con.rest_base_url}${con.transactions_api}${con.usdcop_alerts_null_reached}${user}/`,
                    {
                        headers: {
                            Authorization: `Token ${token}`,
                        },
                    }
                );
                setAlerts(response.data);
                localStorage.setItem('alerts', JSON.stringify(response.data));
            } catch (error) {
                console.error('Error fetching alerts:', error);
            }
        } else {
            setAlerts([]);
            localStorage.removeItem('alerts');
        }
    }, [user, token]);
    */

    useEffect(() => {
        fetchIntradayData();
    }, []);

    // 
    /*
    useEffect(() => {
        fetchAlerts();
    }, [fetchAlerts]);
    */

    //const handleOpenModal = () => {  
    //    // setIsModalOpen(true); // 
    //};
//
    //const handleCloseModal = () => {
    //    // setIsModalOpen(false); // 
    //};

    if (loading) {
        return <div>Cargando...</div>;
    }

    if (!data) {
        return <div>No se pudo cargar la información intradía del dólar.</div>;
    }

    const isMarketClosed = data.market_status === 'closed';

    return (
        <div style={{ padding: '10px', width: '100%' }}>
            {/* Banner de Mercado Cerrado */}
            {isMarketClosed && (
                <div style={{ 
                    position: 'relative', 
                    top: '10px', 
                    backgroundColor: 'rgba(255, 0, 0, 0.8)',
                    color: 'white',
                    padding: '5px',
                    fontSize: '14px',
                    textAlign: 'center',
                    borderRadius: '5px',
                    marginBottom: '20px'  // Espacio adicional entre el banner y los botones
                }}>
                    El mercado está cerrado
                </div>
            )}

            {/* Botones de Acciones */}
            <div style={{ display: 'flex', justifyContent: 'space-around', marginBottom: '10px' }}>
                <Button 
                    text="Actualizar" 
                    onClick={fetchIntradayData} 
                    style={{ fontSize: '14px', padding: '8px 16px' }}
                />
                {/* Botón de alertas comentado */}
                {/*
                <Button 
                    text="Alertas" 
                    icon="notifications" 
                    onClick={handleOpenModal} 
                    disabled={!user || !token}
                    style={{ fontSize: '14px', padding: '8px 16px' }}
                /> 
                */}
            </div>

            {/* Selector Principal */}
            <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '10px' }}>
                <RadioGroup
                    selectedValue={selectedPage} 
                    onChange={(e) => setSelectedPage(e.currentTarget.value)}
                    style={{ fontSize: '12px', flexDirection: 'column', alignItems: 'center' }}
                >
                    <Radio value="resumen" label="Resumen del dólar actual" />
                    <Radio value="cotizaciones" label="Cotizaciones intraday" />
                    <Radio value="alertas" label="Alertas" /> {/* Nueva opción de alertas */}
                </RadioGroup>
            </div>

            {/* Contenido Condicional */}
            {selectedPage === 'resumen' && (
                <div>
                    <FirstLeftTable data={data} />
                    <SecondLeftTable data={data} />
                    <CandleStickChart data={data.intraday_data} />
                </div>
            )}  

            {selectedPage === 'cotizaciones' && (
                <div>
                    {data.intraday_data ? (
                        <IntradayRecordsTable data={data.intraday_data} />
                    ) : (
                        <div>No hay datos intradía disponibles.</div>
                    )}
                </div>
            )}

            {/* Modal Alertas Comentado */}
            {/*
            <AlertasModal 
                isOpen={isModalOpen} 
                handleClose={handleCloseModal} 
                alerts={alerts} 
                setAlerts={setAlerts} 
            />
            */}
        </div>
    );
}

export default MobileIntradayDollarInfoPage;
