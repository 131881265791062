// TODO - Documentar
import React, { useEffect } from 'react'
import {  BARS, BARS_LABEL, BARS_NAME, LABEL_GENERATING_FUNCTION, LEGEND_MARGIN_FROM_CHART, LEGEND_NUM_ROWS, LINES, NAME, PROPORTIONAL_HEIGHT, PROPORTIONAL_WIDTH, X_LABEL, X_VALUES, Y_LABEL, Y_VALUES } from '../../templates/charts/ChartConstants'
import * as con from "../../GlobalConstants"
import { RefMarketValueHook } from '../hooks/MarketValuesHook'
import { ContinuosBarAndMultiLineChartHook } from '../../templates/charts/hooks/ContinuosBarAndMultiLineChartHook'
import LoadingIcon from '../../templates/loading/LoadingIcon'
import { formatDateForUser, parseDate } from '../../utils/dateFunctions'
import { getMinOrionSummaryDate } from '../../utils/orionFunctions'
import { formatMoney } from '../../utils/moneyFunctions'
import { round } from '../../GlobalFunctions'


function OrionHistoricChart({chartParameters}) {

    // Orion Parameters
    // -----------------
    const orionSummary = RefMarketValueHook(con.ORION_SUMMARY)

    
    // ContinuousBarAndMultipleLineChart   
    const [objectReference, chart] = ContinuosBarAndMultiLineChartHook({parameters : { 
                                                                        [PROPORTIONAL_WIDTH] : 1100,
                                                                        [PROPORTIONAL_HEIGHT] : 450,
                                                                        [Y_LABEL] : "COP", 
                                                                        [X_LABEL] : "Fecha",                           
                                                                        [BARS_NAME] : "Score",
                                                                        [BARS_LABEL] : "Score (%)",
                                                                        [LEGEND_NUM_ROWS] : 1,  
                                                                        [LEGEND_MARGIN_FROM_CHART] : 0.09,                                                                      
                                                                        ...chartParameters}})

    
    useEffect(() => {

        if(orionSummary !== null)
        {
                // Adds historic values
                let orionFilteredSummary = orionSummary.map(ob => {return({...ob, [con.DATE] : parseDate(ob[con.DATE])})})            
                orionFilteredSummary = orionFilteredSummary.filter( ob => ob[con.DATE] >= getMinOrionSummaryDate())

                let dates = orionFilteredSummary.map(ob => parseDate(ob[con.DATE]))
                let scores = orionFilteredSummary.map(ob => ob[con.ORION_SCORE])

                let data = {
                    [X_VALUES] : dates,
                    [BARS] : scores,
                    [LINES] : [
                        {
                            [NAME] : "USDCOP Real",
                            [Y_VALUES] : orionFilteredSummary.map(ob => ob[con.ORION_REAL_RATE]),
                            [LABEL_GENERATING_FUNCTION] : (selObj,j) => {return(`${formatDateForUser(dates[j])}: ${formatMoney(selObj[Y_VALUES][j], con.MONEY_COP)} (Score ${round(scores[j],1)} %)`)},
                        },
                        {
                            [NAME] : "USDCOP Estimado",
                            [Y_VALUES] : orionFilteredSummary.map(ob => ob[con.ORION_ESTIMATED_RATE]),
                            [LABEL_GENERATING_FUNCTION] : (selObj,j) => {return(`${formatDateForUser(dates[j])}: ${formatMoney(selObj[Y_VALUES][j], con.MONEY_COP)} (Score ${round(scores[j],1)} %)`)},
                        },
                    ]
                }

                // Checks if structural change happened
                if(!chart.initialized)
                {
                    chart.initialize()
                    chart.build(data)
                }
                else
                {
                    chart.updateData(data)
                }
        }
  
            

    }, [orionSummary, chart])

    
    

    return (     
        <div>
            {
                orionSummary === null
                ? <div style={{marginTop : "20%"}}><LoadingIcon /> </div>
                : <svg ref={objectReference}/> 
            }
            

        </div>   
                   
    )
}


export default OrionHistoricChart
