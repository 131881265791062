// TODO - Documentar

import React, { useEffect, useState } from 'react'
import * as con from "../../GlobalConstants"


import { RefApplicationParameterHook, RefNestedApplicationParameterHook } from '../hooks/ApplicationParametersHooks'
import { IsStaff } from '../hooks/AuthenticationHook'
import { Button, Checkbox } from '@blueprintjs/core'
import { downloadExcelFromArray } from '../../utils/downloadFunctions'
import { allOptionCoverageColumns, prepareForExport } from '../../utils/transactionFunctions'
import Modal from '../../templates/popups/Modal'
import { createNotification } from '../../templates/notifications/Notifications'
import { translateTransactionColumnName } from '../../utils/translateFunctions'
import { filterObject } from '../../GlobalFunctions'
import { constructFileName, makeOptionAssessmentReport } from '../../utils/reportFunctions'
import LoadingIcon from '../../templates/loading/LoadingIcon'
import LoadingIconSmall from '../../templates/loading/LoadingIconSmall'


// Download Columns
const fixedDownloadColumns = [{ [con.ID] : con.OPENING_DATE},
                            { [con.ID] : con.EXPIRATION_DATE},
                            { [con.ID] : con.AMOUNT},                          
                            { [con.ID] : con.STRIKE},                                                 
                            { [con.ID] : con.ASSESSMENT},
                            { [con.ID] : con.COUNTERPARTY}]    

const optionalDownloadColumns = [{ [con.ID] : con.OPENING_SPOT},                          
                                 { [con.ID] : con.EXPIRY_DAYS},                                                                              
                                 { [con.ID] : con.PREMIUM},
                                 { [con.ID] : con.OPTION_TYPE},                        
                                 { [con.ID] : con.COVERAGE_TYPE},
                                 { [con.ID] : con.FOREIGN_RATE},
                                 { [con.ID] : con.DISCOUNT_RATE},
                                 { [con.ID] : con.VOLATILITY_CURVE}]

    const staffDownloadColumns = [{ [con.ID] : con.DELTA}]     


function OptionAssessmentDownload({assessmentSummary, assessedOptionDictionary}) {


    // Hidden columns
    const isStaff = IsStaff()

    // Option coverages loaded
    const optionCoveragesLoadingStatus = RefNestedApplicationParameterHook(con.TRANSACTIONS_LOADED, con.COVERAGES_OPTION)

    // Download Dialog
    const [showDownloadDialog, setShowDownloadDialog] = useState(() => false)

    // Const included Columns
    const [includedColumns, setIncludedColumns] = useState(() => optionalDownloadColumns.map(col => {return(  {...col, [con.SELECTED] : false})})) 

    // Option assessment filter function
    const option_assessment_filter_function = RefApplicationParameterHook(con.OPTION_ASSESSMENT_FILTER_FUN)

    // Generating Report
    const generatingReportStatus = RefNestedApplicationParameterHook(con.OPTION_ASSESSMENT_REPORT, con.GENERATING)

    // Has Coverages
    const [hasCoverages, setHasCoverages] = useState(() => true)

    useEffect(() => {
      
      setHasCoverages(Object.values(assessedOptionDictionary).filter((op) => option_assessment_filter_function(op)).length > 0)

    }, [assessedOptionDictionary, option_assessment_filter_function])
    


    const toggleSelectedColumn = (idCol) =>
    {
      setIncludedColumns(includedColumns.map(col => {return({...col, [con.SELECTED] : col[con.ID] === idCol ? !col[con.SELECTED] : col[con.SELECTED]})}))
    }

    const downloadOptionAssessment = () =>
    {

      let columns = fixedDownloadColumns.concat(includedColumns.filter(col => col[con.SELECTED]))

      if(isStaff)
        columns = columns.concat(staffDownloadColumns)

      // Adds all the info
      columns = columns.map(col => translateTransactionColumnName(allOptionCoverageColumns[col[con.ID]]))

      // Filters
      let finalDict = filterObject(assessedOptionDictionary, (op) => option_assessment_filter_function(op))
      

      downloadExcelFromArray(prepareForExport(finalDict, columns), constructFileName(con.OPTION_ASSESSMENT_SHEET_NAME, con.XLSX_FILE_TYPE))

    }

    // Loading
    const loading = RefApplicationParameterHook(con.LOADING_CURRENT_TRANSACTIONS)

    if(loading){
      return (
        <div>
            <LoadingIcon/> 
        </div>
      )
    }
    

  return (
    <div>

          <Modal show={showDownloadDialog} doAccept={null} showCancel={false} doCancel={() => setShowDownloadDialog(false)}>      
           
                    <h2>Descargar Valoración</h2>

                    <h3>Columnas Extras a Incluir</h3>
                    <div className='columnParameterBoxLeft'>
                        {
                          includedColumns.map(col => 
                            <Checkbox key={col[con.ID]} checked={col[con.SELECTED]} label={ translateTransactionColumnName(allOptionCoverageColumns[col[con.ID]])[con.NAME]} onChange={() => toggleSelectedColumn(col[con.ID])}/>
                            )
                        }
                    </div>
                  
                    <div>
                      <Button icon="download" text="Descargar" onClick={() => {downloadOptionAssessment()
                                                                              createNotification("Valoración descargada") 
                                                                              setShowDownloadDialog(false)}} />
                    </div>
                                                  
            </Modal>   
    
            <div style={{marginTop : "10px"}}>
              {
                optionCoveragesLoadingStatus[con.STATUS] === con.OK 
                ? <div>
                    <div>
                      <Button icon="download" text="Descargar Excel" disabled={!hasCoverages} onClick={() => setShowDownloadDialog(true)}/> 
                    </div>
                    <div style={{marginTop : "10px"}}>
                    { generatingReportStatus 
                      ? <div className='smallParameterBox'><LoadingIconSmall /></div>
                      : <Button text="Generar Reporte" disabled={!hasCoverages} onClick={() => makeOptionAssessmentReport(assessmentSummary, Object.values(assessedOptionDictionary).filter((op) => option_assessment_filter_function(op)))}/> 

                    }
                    </div>
                 </div>
                : <LoadingIcon />
              }
              
            </div>  
    </div>
  )

}

export default OptionAssessmentDownload