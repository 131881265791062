// TODO - Documentar
import React from 'react'
import { useSelector } from 'react-redux'

import { GenericElement } from '../../templates/FormElement';
import { setModelParameter } from '../../store/actions/modelParameters';


// Global constants
import * as con from "../../GlobalConstants"


import "../ComponentsGlobalStyle.css"

function ScenariosParameters() {


    // Model Parameters
    // -----------------
    const modelParameters = useSelector((state) => state[con.STORE][con.REDUCER_MODEL_PARAMETERS])

    
    return (

        <div className="mediumParameterBox" >

                    < GenericElement type={con.NUMERIC}
                            name={"Plazo en Meses"}
                            id={"term_periodicity"} 
                            value={modelParameters[con.TERM_PERIODICITY]} 
                            setValue={(val) => setModelParameter(con.TERM_PERIODICITY, val)} /> 
                                 

                    < GenericElement type={con.PERCENTAGE} 
                                    name={"Cambio Porcentual (Esc.)"}
                                    id={"esc_percentual_change"} 
                                    value={modelParameters[con.ESC_PERCENTUAL_CHANGE]} 
                                    setValue={(val) => setModelParameter(con.ESC_PERCENTUAL_CHANGE, val)} 
                                    min={-100}
                                    max={100} />                                
                    
                    < GenericElement type={con.PERCENTAGE}
                                    name={"Confianza (Esc.)"}
                                    id={"esc_confidence_level"} 
                                    value={modelParameters[con.ESC_CONFIDENCE_LEVEL]} 
                                    setValue={(val) => setModelParameter(con.ESC_CONFIDENCE_LEVEL, val)}
                                    max={99} />

                < GenericElement type={con.PERCENTAGE}
                                name={"Volatilidad del Mercado"}
                                id={"market_volatilty"} 
                                value={modelParameters[con.MARKET_VOLATILITY]} 
                                setValue={(val) => setModelParameter(con.MARKET_VOLATILITY, val)} /> 
                                

                                

        </div>
           
    )
}

export default ScenariosParameters
