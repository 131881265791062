import { Button, FormGroup, InputGroup, Text } from '@blueprintjs/core'
// TODO - Documentar
import React, { useState } from 'react'
import LoadingIcon from '../../templates/loading/LoadingIcon'
import LockEyePasswordButton from '../../templates/password/LockEyePasswordButton'
import { CHANGE_PASSWORD, LOADING, STATUS } from '../../GlobalConstants'
import { changePassword } from '../../store/actions/authentication'
import { RefNestedApplicationParameterHook } from '../hooks/ApplicationParametersHooks'
import { RefIsSurrogateActive } from '../hooks/AuthenticationHook'


function ChangePassword({closeDialog}) {

    // Password
    const [password, setPassword] = useState(() => null)
    const [showPassword, setShowPassword] = useState(() => false)


   // New Password 1
   const [newPassword1, setNewPassword1] = useState(() => null)
   const [showNewPassword1, setShowNewPassword1] = useState(() => false)


   // New Password 2
   const [newPassword2, setNewPassword2] = useState(() => null)
   const [showNewPassword2, setShowNewPassword2] = useState(() => false)

   // Passwords are equal
   const [passwordsAreEqual, setPasswordsAreEqual] = useState(() => true)

   // Change Password Status
   const changePasswordStatus = RefNestedApplicationParameterHook(CHANGE_PASSWORD, STATUS)

   const isActive = RefIsSurrogateActive()

  return (
    <div>
         <h2>Cambiar Contraseña</h2>

         <div className="columnParameterBox">             
            { !isActive &&
                <FormGroup label="Contraseña Actual">                    
                    <InputGroup id="password"   
                        className="input-editing"
                        type={ showPassword ? "text" :"password"}             
                        value={password} 
                        rightElement={<LockEyePasswordButton showPassword={showPassword} setShowPassword={setShowPassword} />}
                        onChange={(e) => {setPassword(e.target.value)}} />
                </FormGroup>
            }    
            <FormGroup label="Nueva Contraseña">                    
                <InputGroup id="password1"   
                    className="input-editing"
                    type={ showNewPassword1 ? "text" :"password"}             
                    value={newPassword1} 
                    rightElement={<LockEyePasswordButton showPassword={showNewPassword1} setShowPassword={setShowNewPassword1} />}
                    onChange={(e) => {setNewPassword1(e.target.value)}} />
            </FormGroup> 
            <FormGroup label="Repita la Contraseña">                    
                <InputGroup id="password2"   
                    className="input-editing"
                    type={ showNewPassword2 ? "text" :"password"}             
                    value={newPassword2} 
                    rightElement={<LockEyePasswordButton showPassword={showNewPassword2} setShowPassword={setShowNewPassword2} />}
                    onChange={(e) => {setNewPassword2(e.target.value)}} />
            </FormGroup>  

            <Text style={{marginBottom : "15px"}}>{passwordsAreEqual ? "" : "Las contraseñas no coinciden"}</Text>

            { changePasswordStatus === LOADING ?
                        <LoadingIcon />
                    : <Button text="Enviar" 
                    disabled={newPassword1 === "" || newPassword1 === null}
                    onClick={() => {
                                        
                if(newPassword1 === newPassword2)
                {
                    changePassword(password, newPassword1, () => closeDialog())

                }
                else
                    setPasswordsAreEqual(false)
            }}/>}

        </div>

    </div>
  )
}

export default ChangePassword