// TODO - Documentar
import React from 'react'


// Style
import '../ComponentsGlobalStyle.css'

import { AverageForPercentage, BandSelect, NonZeroRowsCheckBox, ScenariosSelect, VisualizationPeriodicity } from './SingleParameters';


function BandPositionParameters() {


    return (
    <div>
        <div className='bigParameterBox' >                                                                                                                               
            <AverageForPercentage />
            <NonZeroRowsCheckBox />                      
            <VisualizationPeriodicity/>   
            <ScenariosSelect />             
        </div>
        <div className="gridBox" style={{ paddingLeft : '15px', paddingRight : '15px'}}>      
            <div style={{paddingLeft : "30%"}}>  
              <BandSelect/>
            </div>
        </div>
      </div>
    )
}

export default BandPositionParameters
