// TODO - Documentar
import React from 'react'

// Global constants
import * as con from '../../GlobalConstants'

// Style
import '../ComponentsGlobalStyle.css'

import { GenericElement } from '../../templates/FormElement';
import { setApplicationParameter } from '../../store/actions/applicationParameters';
import { translateBands } from '../../utils/translateFunctions';
import { AllApplicationParametersHook } from '../hooks/ApplicationParametersHooks';


function BandComparisonParameters () {


    // Application Parameters
    // -----------------
    const applicationParameters = AllApplicationParametersHook()
    
    return (
        
        <div className='columnParameterBox' >
             < GenericElement type={con.MULTIPLE_SELECTION}
                         name={'Banda'}
                         id={con.SELECTED_VAR} 
                         value={applicationParameters[con.SELECTED_VAR]} 
                         setValue={(val) => setApplicationParameter(con.SELECTED_VAR, val)} 
                         values={[con.VAR].concat(con.BANDS.map(b => `${b}_${con.VAR}`))}
                         valueLabels={["Oportunístico"].concat(con.BANDS.map((b) => translateBands(b)))}
                         />           

        </div>
    )
}

export default BandComparisonParameters 
