// Constants for action types

// Transactions
export const RESET_ALL_TRANSACTIONS = "RESET_ALL_TRANSACTIONS" // Action that resets all transactions to their default (no transactions) value
export const SET_TRANSACTIONS_FROM_SERVER = "SET_TRANSACTIONS_FROM_SERVER" // Action that sets the transactions from the server
export const REPLACE_TRANSACTIONS = "REPLACE_TRANSACTIONS" // Action that replaces all the transactions of a single type. This is usually generated by an excel upload of transactions 
export const SET_TRANSACTION = "SET_TRANSACTION"  // Action that sets (updates) a single transaction. 
export const ADD_TRANSACTION = "ADD_TRANSACTION"  // Action that adds a single transaction 
export const ADD_TRANSACTIONS_BULK = "ADD_TRANSACTIONS_BULK"  // Action that adds several transactions (bulk) of a single type.
export const DELETE_TRANSACTION = "DELETE_TRANSACTION" // Action that deletes a single transaction. This cannot be undone

// Prepay exposures
export const PREPAY_EXPOSURE = "PREPAY_EXPOSURE" // Action that prepays an  exposure

// Annotations
export const ADD_ANNOTATION = "ADD_ANNOTATION" // Action that adds a single transaction
export const SET_ANNOTATIONS_FROM_SERVER = "SET_ANNOTATIONS_FROM_DB" // Action that sets the annotation from the values obtained in the server



// Model Parameters
export const REPLACE_MODEL_PARAMETERS = "REPLACE_MODEL_PARAMETERS" // Action thar replaces all the model parameters (usually from the server).
export const SET_MODEL_PARAMETER = "SET_MODEL_PARAMETER" // Action thar replaces a singe model parameter.

// Uer Line Application Parameters
export const REPLACE_USER_LINE_APPLICATION_PARAMETER = "REPLACE_USER_LINE_APPLICATION_PARAMETER" // Action that replaces all user lin parameters
export const SET_USER_LINE_APPLICATION_PARAMETER = "SET_USER_LINE_APPLICATION_PARAMETER" // Action that sets a specific user line parameter

// User Attributes
export const REPLACE_USER_PROFILE = "REPLACE_USER_PROFILE" // Actions tha replaces a user profile form one fetched by the server or passed for surrogate behavior   

// User Lines
export const REPLACE_USER_LINES = "REPLACE_USER_LINES" // Action that replaces the user lines (can be by loading or by surrogate user activation)
export const SET_USER_LINE = "SET_USER_LINE" // Action that selects a user line to display
export const DELETE_USER_LINE = "DELETE_USER_LINE"  // Action that delete a user line (this action cannot be undone and can only be done by an admin level user)
export const ADD_USER_LINE = "ADD_USER_LINE" // Action that adds a user line (this can only be done by an admin level user)
export const ADD_USER_LINE_COMMENT = "ADD_USER_LINE_COMMENT"  // Action that adds a comment to a user line line to be displayed in the admin panel summary
export const SET_ALL_USER_LINE_COMMENTS = "SET_ALL_USER_LINE_COMMENTS" // Action that sets all the user line comments from the server



// Application Parameters
export const SET_APPLICATION_PARAMETER = "SET_APPLICATION_PARAMETER" // Sets a specific application parameter. If that change has ripple effects they should be included here.
export const SET_LOADING_PARAMETER = "SET_LOADING_PARAMETER" // Action that sets a specific transaction loading parameter 
export const SET_INSERTED_PARAMETER = "SET_INSERTED_PARAMETER"  // Actions  that sets the status of a transactions inserted parameter
export const SET_LOADING_SURROGATE_PARAMETER = "SET_LOADING_SURROGATE_PARAMETER" // Action that sets a specific transaction loading parameter for the surrogate user 

// Navegation
export const NEXT_PAGE = "NEXT_PAGE" // Action that navigates to the next page in the transaction visualization page (inputs)
export const PREVIOUS_PAGE = "PREVIOUS_PAGE"  // Action that navigates to the previous page in the transaction visualization page (inputs)
export const FIRST_PAGE = "FIRST_PAGE" // Action that navigates to the first page in the transaction visualization page (inputs)
export const FINAL_PAGE = "FINAL_PAGE"  // Action that goes to the final navigation page on the transaction visualization page (inputs)



// Scenarios
export const REPLACE_SCENARIOS = "REPLACE_SCENARIOS" // Action that replaces all scenarios from server
export const SET_SCENARIO = "SET_SCENARIO"  // Action that sets a single scenario

// User
export const LOGIN = "LOGIN" // User login case
export const REFRESH_TOKEN = "REFRESH_TOKEN" // Action that refreshes the user's token before it expires so they can continue using the application
export const USER_LOGOUT = "USER_LOGOUT" // Action that logs out at the user's request 
export const LOGOUT_FOR_INACTIVITY = "LOGOUT_FOR_INACTIVITY" // Action that logs out due to inactivity from the user. Activity means mouse clicks
export const SET_SURROGATE_USER = "SET_SURROGATE_USER" // Actions that sets another user as surrogate user. This is only available to admin level users and allows them to manipulate the application exactly as the surrogate user would.

// Errors
export const PUSH_ERROR = "PUSH_ERROR" // Action that pushes an Error to the stack

// All Surrogate transactions
export const SET_ALL_SURROGATE_USER_TRANSACTIONS = "SET_ALL_SURROGATE_USER_TRANSACTIONS"  // Action that sets all the surrogate transactions of a single type from the server 


// Advisors
export const SET_ALL_ADVISORS = "SET_ALL_ADVISORS" // Action that sets all the advisors from the server
export const ADD_ADVISOR = "ADD_ADVISOR" // Action that adds an advisor
export const DELETE_ADVISOR = "DELETE_ADVISOR"// Action that deletes a single advisor
export const SET_MAIN_ADVISOR = "SET_MAIN_ADVISOR" // Action that sets the main advisor


// Market
export const SET_MARKET_VALUE = "SET_MARKET_VALUE" // Action that sets a single market Value