// TODO - Documentar
import React from 'react'
import { Calculator } from '../components/forwardsCalculator/Calculator'
import SimpleFillFooter from '../templates/footer/SimpleFillFooter'
import "./MobilePages.css"

function MobileCalculatorPage() {
    return (
        <div className='centered-component' style={{paddingTop: "20px"}}>                            
            <Calculator numberOfColumns={1} shortColumnNames={true}/>     
            <SimpleFillFooter />                                                                                       
        </div>                      
  
    )
}

export default MobileCalculatorPage
