// Actions for the model parameters reducer
import * as types from "./types"
import store from '../store';
import * as con from "./../../GlobalConstants"
import axios from "axios";
import { filterObjectByKeys } from "../../GlobalFunctions";
import { getNow } from "../../utils/dateFunctions";
import { getLocalStorageItem, setLocalStorageItem } from "../../utils/localStorageWrapper";



// Set Application Parameter 
export const setApplicationParameter = (id, value) =>
{    

    let payload = {[con.ID]: id, [con.VALUE] : value}

    store.dispatch({
        type : types.SET_APPLICATION_PARAMETER,
        payload : payload
    })
}

export const setLoadingParameter = (transactionType, value) =>
{

    let payload = {[con.TRANSACTION_TYPE]: transactionType, [con.VALUE] : value}

    store.dispatch({
        type : types.SET_LOADING_PARAMETER,
        payload : payload
    })
}

export const setLoadingSurrogateParameter = (transactionType, value) =>
{

    let payload = {[con.TRANSACTION_TYPE]: transactionType, [con.VALUE] : value}

    store.dispatch({
        type : types.SET_LOADING_SURROGATE_PARAMETER,
        payload : payload
    })
}


export const setInsertedParameter = (transactionType, value) =>
{

    let payload = {[con.TRANSACTION_TYPE]: transactionType, [con.VALUE] : value}

    store.dispatch({
        type : types.SET_INSERTED_PARAMETER,
        payload : payload
    })
}


export const checkServer = () =>
{

    axios.get(con.rest_base_url + con.server_check)
    .then(res => {        
        setApplicationParameter(con.SERVER, {[con.STATUS] : con.OK})
    })
    .catch(err =>
        setApplicationParameter(con.SERVER, {[con.STATUS] : con.ERROR})
    )

}


export const getApplicationParameter = (key) => {
    return(store.getState()[con.STORE][con.REDUCER_APPLICATION_PARAMETERS][key])
}

export const getModelApplicationParameters = () =>
{
    return(filterObjectByKeys(store.getState()[con.STORE][con.REDUCER_APPLICATION_PARAMETERS], con.MODEL_APPLICATION_PARAMETERS))
}


export const nextPage = (currentTransaction) =>
{    

    store.dispatch({
        type : types.NEXT_PAGE,
        payload : currentTransaction
    })
}

export const finalPage = (currentTransaction) =>
{    

    store.dispatch({
        type : types.FINAL_PAGE,
        payload : currentTransaction
    })
}

export const previousPage = (currentTransaction) =>
{    

    store.dispatch({
        type : types.PREVIOUS_PAGE,
        payload : currentTransaction
    })
}

export const firstPage = (currentTransaction) =>
{    

    store.dispatch({
        type : types.FIRST_PAGE,
        payload : currentTransaction
    })
}

export const setTotalTransactionsBeingDisplayed = (transactionType, newVal) =>
{
    const tot = getApplicationParameter(con.TOTAL_TRANSACTIONS_DISPLAYED)
    setApplicationParameter(con.TOTAL_TRANSACTIONS_DISPLAYED, {...tot, [transactionType] : newVal})

}

export const updateUserInteraction = () =>
{
    setLocalStorageItem(con.LOCAL_LAST_INTERACTION, getNow())
}

export const getLastUserInteraction = () =>
{
    return(getLocalStorageItem(con.LOCAL_LAST_INTERACTION))
}