
// TODO - Documentar
import React from 'react'
//import { EconomicCalendar } from "react-ts-tradingview-widgets";
import { Text } from '@blueprintjs/core';
import { USABLE_SCREEN_SIZE } from '../../GlobalConstants';


function CustomEconomicCalendarWidget({parameters}) {
    return (
        <div>   
            {/* <EconomicCalendar colorTheme="dark" 
                              autosize
                               importanceFilter="0,1,"
                               currencyFilter="COP,USD,EUR"
                              {...parameters} /> */}
            <div style={{height :  (USABLE_SCREEN_SIZE - 5) + "vh"}}>
            <iframe title="Calendario" rel="noreferrer" src="https://sslecal2.investing.com?ecoDayBackground=%23222838&defaultFont=%23000000columns=exc_flags,exc_currency,exc_importance,exc_actual,exc_forecast,exc_previous&category=_employment,_economicActivity,_inflation,_credit,_centralBanks,_confidenceIndex,_balance,_Bonds&importance=3&features=datepicker,timezone&countries=122,5,72&calType=week&timeZone=42&lang=4" 
                width={"100%"} height={"100%"} ></iframe>
                <div height="5vh">
                    <Text>Calendario económico en tiempo real proporcionado por <a href="https://es.investing.com/" rel="noreferrer" target="_blank" style={{fontSize: "11px", color:" #06529D", fontWeight: "bold"}} className="underline_link">Investing.com España</a>.</Text>
                </div>
            </div>
        </div>
    )
}

export default CustomEconomicCalendarWidget
