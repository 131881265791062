// TODO - Documentar
import React from 'react'
import { TickerTape } from 'react-ts-tradingview-widgets'
import { TICKER_TAPE } from '../../GlobalConstants'

function CustomTickerTape() {

    return (        
        <div style={{height : `${TICKER_TAPE}vh`}} className="customTickerTape">
            <TickerTape colorTheme="dark" displayMode="regular"/>
        </div>
    )
}

export default CustomTickerTape
