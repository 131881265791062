// Actions for the model parameters reducer

import axios from 'axios';
import * as types from "./types"
import store from '../store';
import * as con from "./../../GlobalConstants"
import { handleParametersSavedError, handleRequestError } from '../../utils/errorFunctions';
import { setApplicationParameter } from './applicationParameters';
import { getCurrentLine, getSurrogateUser, getToken, getUser, surrogateActive } from '../logic/supportFunctions';


// Get model parameters
export const fetchModelParameters = () =>
{

    setApplicationParameter(con.MODEL_PARAMETERS_FETCHED, {[con.STATUS] : con.LOADING})

    const token = getToken()

    const config = {
        headers : {
            "Authorization" : `Token ${token}`
        }
    }

    let url = con.rest_base_url + con.transactions_api + con.model_parameters_api
    if(surrogateActive())
        url = con.rest_base_url + con.transactions_api + con.STAFF_API + con.model_parameters_api 

    url = url + `${getCurrentLine()}/`

    axios.get(url, config)
            .then(res => {
                
                // Only excecutes if the user has saved model parameters 
                if(Object.keys(res.data).length > 0)
                {
                    store.dispatch({
                        type : types.REPLACE_MODEL_PARAMETERS,
                        payload : res.data
                    })
                }    
                
                setApplicationParameter(con.MODEL_PARAMETERS_FETCHED, {[con.STATUS] : con.OK})
            })
            .catch(err => {
                setApplicationParameter(con.MODEL_PARAMETERS_FETCHED, {[con.STATUS] : con.ERROR})
                handleRequestError(err)})
}

// Exports model parameters
export const exportModelParameters = async () =>
{
    // Gets Token
    const token = getToken()
    
    // Gets user 
    const user = getUser()

    setApplicationParameter(con.MODEL_PARAMETERS_SAVED, {[con.STATUS] : con.LOADING})


    const config = {
        headers : {
            "Authorization" : `Token ${token}`
        }
    }

    var data = store.getState()[con.STORE][con.REDUCER_MODEL_PARAMETERS]

    // Adds user and line
    data[con.USER] = user[con.ID]
    data[con.USER_LINE] = getCurrentLine()

    let staff = ""

    if(surrogateActive())
    {
        data[con.SURROGATE_USER] = getSurrogateUser()[con.ID]
        data[con.USER] = getSurrogateUser()[con.ID]
        staff = "/staff"

    }
        
    let url = con.rest_base_url + con.transactions_api + staff + con.model_parameters_api
    url = url + `${getCurrentLine()}/`

    try {
            await axios.put(url, data, config);
            setApplicationParameter(con.MODEL_PARAMETERS_SAVED, {[con.STATUS] : con.OK});
            return true;
      } catch (err) {
            setApplicationParameter(con.MODEL_PARAMETERS_SAVED, {[con.STATUS] : con.ERROR});
            handleParametersSavedError(err);
            return false;
      }
}

// Set Model Parameter 
export const setModelParameter = (id, value) =>
{

    let payload = {[con.ID]: id, [con.VALUE] : value}

    store.dispatch({
        type : types.SET_MODEL_PARAMETER,
        payload : payload
    })
}


// Set a specific Band 
export const setSpecificBand = (i, value) =>
{
    
    var new_bands = store.getState()[con.STORE][con.REDUCER_MODEL_PARAMETERS][con.MID_BAND_VALUES]
    new_bands[i] = value

    store.dispatch({
        type : types.SET_MODEL_PARAMETER,
        payload : {[con.ID] : con.MID_BAND_VALUES, [con.VALUE]: [...new_bands]}
    })
}



// Get a MOdel Parameter 
export const getModelParameter = (id) =>
{
    
    return(store.getState()[con.STORE][con.REDUCER_MODEL_PARAMETERS][id])
}

// Get a MOdel Parameter 
export const getAllModelParameters = () =>
{
    
    return(store.getState()[con.STORE][con.REDUCER_MODEL_PARAMETERS])
}





