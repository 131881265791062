// TODO - Documentar
import React, { useEffect } from 'react'

import * as con from "../../GlobalConstants"


import { GenericElement, MoneyElement } from "../../templates/FormElement"
import { RefApplicationParameterHook, RefSPOT, StateApplicationParameterHook } from '../hooks/ApplicationParametersHooks'
import { IsStaff } from '../hooks/AuthenticationHook'
import { Checkbox } from '@blueprintjs/core'
import { GenericCell, StaticSingleCell } from '../../templates/tables/Cells'
import {  addDays, afterADate, afterToday, isStingValidForDate, isWeekEnd, parseDate } from '../../utils/dateFunctions'
import ForwardAssessmentDownload from './ForwardAssessmentDownload'
import { FormGroup } from '@mui/material'
import LoadingIcon from '../../templates/loading/LoadingIcon'




function ForwardAssessmentControls({assessmentSummary, assessedFwdDictionary}) {

    // Hidden columns
    const isStaff = IsStaff()
    const [showHiddenColumns, setShowHiddenColumns] = StateApplicationParameterHook(con.FORWARD_ASSESSMENT_SHOW_HIDDEN_COLUMNS)
    const [showExpiredCoverages, setShowExpiredCoverages] = StateApplicationParameterHook(con.FORWARD_ASSESSMENT_SHOW_EXPIRED_COVERAGES)


  return (
      <div className='bigParameterBox'>
          <ForwardAssessmentSummaryTable assessmentSummary={assessmentSummary}/>
          <div>
            <ForwardAssessmentRate />
            <ForwardAssessmentFilter />
          </div>
          <div>
            <h3>Comandos</h3>
            <div className="columnParameterBoxLeft">
                <Checkbox checked={showExpiredCoverages} label="Incluir Coberturas Vencidas" onChange={() => setShowExpiredCoverages(!showExpiredCoverages)}/>
                {isStaff ? <Checkbox checked={showHiddenColumns} label="Columnas Ocultas" onChange={() => setShowHiddenColumns(!showHiddenColumns)}/>  : null }
            </div>
            <ForwardAssessmentDownload assessmentSummary={assessmentSummary} assessedFwdDictionary={assessedFwdDictionary}/>
          </div>
      </div>
  )
}

export default ForwardAssessmentControls


export const ForwardAssessmentSummaryTable = ({assessmentSummary}) =>
{

    const [showExpiredCoverages] = StateApplicationParameterHook(con.FORWARD_ASSESSMENT_SHOW_EXPIRED_COVERAGES)
    // Reference Date
    const [referenceDate, setReferenceDate] = StateApplicationParameterHook(con.FORWARD_ASSESSMENT_REFERENCE_DATE)
    const [startFwdAssessmentDate, setStartFwdAssessmentDate] = StateApplicationParameterHook(con.FORWARD_ASSESSMENT_START_DATE)

    // Loading
    const loading = RefApplicationParameterHook(con.LOADING_CURRENT_TRANSACTIONS)
    
    // Assessment Rate
    const rate = RefApplicationParameterHook(con.FORWARD_ASSESSMENT_RATE)

    if(loading){
      return (
        <div>
            <LoadingIcon/> 
        </div>
      )
    }

  return(
    <div>
      <h3>Resumen de Valoración de Derivados</h3>
      <div className="tightTable">
        <div className="tightColumn">
          {showExpiredCoverages && <StaticSingleCell content={"Fecha Inicio de Valoración"} isTitle={true}/>}
          <StaticSingleCell content={"Fecha de Valoración"} isTitle={true}/>
          <StaticSingleCell content={"Monto Forwards Activos"} isTitle={true}/>
          <StaticSingleCell content={"Tasa Forward Promedio"} isTitle={true}/>
          <StaticSingleCell content={"Tasa de Valoración"} isTitle={true}/>
          <StaticSingleCell content={"Valoración Portafolio"} isTitle={true}/>
        </div>
        <div className="tightColumn">
          {showExpiredCoverages &&
          <GenericCell cellType={con.DATE} value={startFwdAssessmentDate} setValue={(val) => isStingValidForDate(val) ? setStartFwdAssessmentDate(parseDate(val)) : false}
            editing={true} minDate={parseDate(con.MIN_ASSESSMENT_DATE)} shouldDisableDate={(d) => afterADate(d, addDays(referenceDate, -1)) || isWeekEnd(d)}/>}
          <GenericCell cellType={con.DATE} value={referenceDate} setValue={(val) => isStingValidForDate(val) ? setReferenceDate(parseDate(val)) : false}
            editing={true} minDate={parseDate(con.MIN_ASSESSMENT_DATE)} shouldDisableDate={(d) => afterToday(d) || isWeekEnd(d)}/>
          <GenericCell evenRow={true} cellType={con.MONEY} value={assessmentSummary[con.ACTIVE_FWD_AMOUNT]} currency={con.MONEY_USD} editing={false}/>
          <GenericCell evenRow={false} cellType={con.MONEY} allowDecimals={true} value={assessmentSummary[con.AVERAGE_FWD_RATE]} currency={con.MONEY_COP} editing={false}/>
          <GenericCell evenRow={true} cellType={con.MONEY} allowDecimals={true} value={rate} currency={con.MONEY_COP} editing={false}/>
          <GenericCell evenRow={false} cellType={con.MONEY} value={assessmentSummary[con.PORTFOLIO_ASSESSMENT]} currency={con.MONEY_COP} editing={false}/>
        </div>
    </div>
  </div>
  )
}

export const ForwardAssessmentRate = () => {

  // Rate
  const [rate, setRate] = StateApplicationParameterHook(con.FORWARD_ASSESSMENT_RATE)

  // Spot
  const spot = RefSPOT()

  // Reference TRM and Close
  const trm = RefApplicationParameterHook(con.FORWARD_ASSESSMENT_REFERENCE_TRM)
  const trm1 = RefApplicationParameterHook(con.FORWARD_ASSESSMENT_REFERENCE_NEXT_DAY_TRM)
  const closeRate = RefApplicationParameterHook(con.FORWARD_ASSESSMENT_REFERENCE_RATE_CLOSE)

  // Rate Origin
  const [origin, setOrigin] = StateApplicationParameterHook(con.FORWARD_ASSESSMENT_RATE_ORIGIN)

  // Updates the rate
  useEffect(() => {

    if(origin === con.SPOT)
      setRate(spot)
    else if(origin === con.TRM)
      setRate(trm)
    else if(origin === con.TRM_NEXT_DAY)
      setRate(trm1)
    else if (origin === con.CLOSE)
      setRate(closeRate)

  }, [origin,spot,trm, trm1, closeRate, setRate])

  const rateOptionsLabels = ["Manual", "TRM", "TRM+1", "Cierre", "Spot"]
  const rateOptionsValues = [con.MANUAL, con.TRM, con.TRM_NEXT_DAY,con.CLOSE, con.SPOT]
  return(
    <div className='columnParameterBox' style={{display:"flex", flexDirection:"row", gap : "50px"}}>
      <MoneyElement name="Tasa" value={rate} setValue={setRate} currency={con.MONEY_COP} disabled={origin !== con.MANUAL} allowDecimals={true}/>

      < GenericElement type={con.MULTIPLE_SELECTION}
                                name={'Esquema'}
                                // id={}               // Id not necessary
                                value={origin}
                                setValue={setOrigin}
                                values={rateOptionsValues}
                                valueLabels={rateOptionsLabels}
                                />
    </div>
  )

}

export const ForwardAssessmentFilter = () => {

  const [includePurchases, setIncludePurchases] = StateApplicationParameterHook(con.FORWARD_ASSESSMENT_INCLUDE_PURCHASES)
  const [includeSales, setIncludeSales] = StateApplicationParameterHook(con.FORWARD_ASSESSMENT_INCLUDE_SALES)

  return (
    <>
      <h2>Filtrar</h2>
      <FormGroup>
        <div className='bigParameterBox'>
          <Checkbox
            label="Compras"
            checked={includePurchases}
            onChange={() => setIncludePurchases(!includePurchases)}
          />
          <Checkbox
            label="Ventas"
            checked={includeSales}
            onChange={() => setIncludeSales(!includeSales)}
          />
        </div>
      </FormGroup>
    </>
  );
};
