import { Button } from '@blueprintjs/core'


// Global constants
import * as con from '../../GlobalConstants'

import { downloadExcelFromArray } from '../../utils/downloadFunctions'
import { prepareForExport, selectedTransactionCols } from '../../utils/transactionFunctions'

// Style
import '../ComponentsGlobalStyle.css'


import { RefTransactionsSummaryTableHook } from '../hooks/TransactionsSummaryTableHook'
import { TransactionColumnsHook } from '../hooks/TransactionsHooks'
import { BandSelect } from '../parameters/SingleParameters'
import { buildTransactionsSummaryDownloadFileName } from '../../GlobalFunctions'
import { RefApplicationParameterHook } from '../hooks/ApplicationParametersHooks'
import { createNotification } from '../../templates/notifications/Notifications'
import { constructFileName } from '../../utils/reportFunctions'

function ResultsSummary() {


    // Transactions Object
    // eslint-disable-next-line
    const [transactionSummaryObject, summaryRow, computing, hasSummaryTable] = RefTransactionsSummaryTableHook()
    
    const transactionsCol = TransactionColumnsHook(selectedTransactionCols, true)

    // Band
    const selectedBand = RefApplicationParameterHook(con.SELECTED_BAND)

    return (
        <div className="columnParameterBoxTop">

            <h3>Resumen Banda</h3>
            <BandSelect/>
            {
                computing ? <h5>Computando</h5> : 
                (!hasSummaryTable) ? <h5>{con.NO_TRANSACTIONS_MESSAGE}</h5>  : <Button disabled={computing || !hasSummaryTable} icon="download" text="Descargar Excel" onClick={() => {downloadExcelFromArray(prepareForExport(transactionSummaryObject, transactionsCol), constructFileName(buildTransactionsSummaryDownloadFileName(selectedBand),con.XLSX_FILE_TYPE)) ;createNotification("Resumen Descargado")}}/>         
            }
            
            
        </div>
    )
}

export default ResultsSummary
