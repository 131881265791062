// TODO - Documentar
import React, { useEffect, useState } from 'react'
import { RefApplicationParameterHook } from '../hooks/ApplicationParametersHooks'

import * as con from "../../GlobalConstants"
import TransactionTableVisualizer from '../transactions/TransactionTableVisualizer'
import { TransactionTable } from '../transactionTable/TransactionTable'
import { CoverageFWD, getTransactionsAnnotationFunction } from '../../store/actions/transactions'
import { allForwardCoverageColumns } from '../../utils/transactionFunctions'
import { IsStaff } from '../hooks/AuthenticationHook'
import TransactionTableNavegation from '../transactions/TransactionTableNavegation'
import { EDIT_COMMAND, REMOVE_COMMAND, VIEW_ANNOTATIONS_COMMAND } from '../transactionTable/TransactionTableConstants'


// User Columns
const selectedUserColumns = [{ [con.ID] : con.OPENING_DATE},
                            { [con.ID] : con.EXPIRATION_DATE},
                            { [con.ID] : con.AMOUNT},
                            { [con.ID] : con.OPENING_SPOT},
                            { [con.ID] : con.RATE},
                            { [con.ID] : con.COMMENT},                      
                            { [con.ID] : con.COVERAGE_TYPE},
                            { [con.ID] : con.ASSESSMENT}]    

// Staff Columns
const selectedStaffColumns = [{ [con.ID] : con.OPENING_DATE},
                            { [con.ID] : con.EXPIRATION_DATE},
                            { [con.ID] : con.AMOUNT},
                            { [con.ID] : con.OPENING_SPOT},
                            { [con.ID] : con.RATE},                      
                            { [con.ID] : con.COVERAGE_TYPE},
                            { [con.ID] : con.EXPIRY_DAYS},
                            { [con.ID] : con.DEVALUATION_VALUE},
                            { [con.ID] : con.NEW_FORWARD_RATE},                            
                            { [con.ID] : con.FORWARD_RATE_DIFFERENCE},
                            { [con.ID] : con.DISCOUNT_RATE},
                            { [con.ID] : con.FORWARD_RATE_DIFFERENCE_PRESENT_VALUE},                        
                            { [con.ID] : con.ASSESSMENT}]    

function ForwardAssessmentTable({assessedFwdDictionary, includeCommands = true, includedColumns = selectedUserColumns}) {


  // Is Staff and Hidden Columns
  const isStaff = IsStaff()
  const showHiddenColumns = RefApplicationParameterHook(con.FORWARD_ASSESSMENT_SHOW_HIDDEN_COLUMNS)

  const [selectedColumns, setSelectedColumns] = useState(() => includedColumns)

  // FWD filter Function
  const fwd_assessment_filter_fun = RefApplicationParameterHook(con.FWD_ASSESSMENT_FILTER_FUN)


  useEffect(() => {
    
    if(isStaff && showHiddenColumns)
      setSelectedColumns(selectedStaffColumns)
    else
      setSelectedColumns(includedColumns)

  }, [isStaff, showHiddenColumns, includedColumns])
  

  return (

    <div>
        <div>
        <h3>Coberturas</h3>
        <TransactionTableNavegation forcedSelectedTransaction={con.COVERAGES_FWD} />
      </div>
 
      <TransactionTableVisualizer transactionType={con.COVERAGES_FWD}>           
                  <TransactionTable transactionType={con.COVERAGES_FWD}
                                    columnArray={selectedColumns.map(col => allForwardCoverageColumns[col[con.ID]])}
                                    dataDictionary={assessedFwdDictionary} 
                                    setRow={(id, vals) => CoverageFWD.set(id, vals)}                                   
                                    deleteRow={(id) => CoverageFWD.delete(id)}
                                    getAnnotations={getTransactionsAnnotationFunction(con.COVERAGES_FWD)}
                                    includeAddition={false}
                                    includeLastEdited={false}
                                    commands={includeCommands ?  [EDIT_COMMAND, REMOVE_COMMAND, VIEW_ANNOTATIONS_COMMAND] : false}
                                    filterFunction={fwd_assessment_filter_fun}
                                    noResultsWhenFilteredMessage="No hay coberturas activas para la fecha seleccionada."/>
      </TransactionTableVisualizer>     
    </div>   

  )
}


export default ForwardAssessmentTable