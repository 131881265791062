// TODO - Documentar
import React from 'react'
import { BandComparisonSummary } from '../components/bandComparisonSummary/BandComparisonSummary'
import BandComparisonParameters from '../components/parameters/BandComparisonParameters '
import TransactionExtractionLoadingIcons from '../components/transactions/TransactionExtractionLoadingIcons'
import { PROPORTIONAL_HEIGHT, PROPORTIONAL_WIDTH, TITLE } from '../templates/charts/ChartConstants'
import * as con from "../GlobalConstants"
import { RefApplicationLoadingParameterHook, RefApplicationParameterHook } from '../components/hooks/ApplicationParametersHooks'


function BandComparisonPage() {

    const loadingLineApplicationParameters = RefApplicationLoadingParameterHook(con.USER_LINE_APPLICATION_PARAMETERS_FETCHED)
    const transactionsLoaded = RefApplicationParameterHook(con.ALL_TRANSACTIONS_LOADED)

    return (
        <div>
            <BandComparisonParameters />
            <div style={{ paddingLeft : '5%', paddingRight : '5%'}}>
                {

            (loadingLineApplicationParameters === con.NOT_STARTED || loadingLineApplicationParameters === con.LOADING ||
                !transactionsLoaded) ?
                <div style={{marginTop : "25px"}}>
                    <TransactionExtractionLoadingIcons />                        
                </div>
                : <BandComparisonSummary chartParameters={{[TITLE] : "Valor en Riesgo x Banda de Coberturas",
                                                          [PROPORTIONAL_WIDTH] : 1200,
                                                          [PROPORTIONAL_HEIGHT] : 405}}/>

                }
            </div>
        </div>
    )
}

export default BandComparisonPage
