// TODO - Documentar
import React from 'react'
import { Action, Fab } from 'react-tiny-fab'
import logo from "./../../img/whatsapp_logo.png"
import advisorLogo from "./../../img/advisor_logo.png"
import * as con from "../../GlobalConstants"
import { buildWhatsAppContactFunction } from '../../utils/whatsAppFunctions'
import { RefSelectedAdvisors } from '../hooks/AdvisorsHooks'
import 'react-tiny-fab/dist/styles.css';
import './WhatsAppIcon.css';

function WhatsAppIcon() {

   // Advisor 
  const advisors = RefSelectedAdvisors()
  
  return (    
    <div className="wa-fab" >  
    <Fab          
        style = {{ bottom: -20, right: -15, zIndex : 1 }}
        alwaysShowTitle={true} 
        icon= {<img src={logo} alt="Logo" width="auto" height={62}/> }>

      {
        advisors.map((advisor,i) =>
          <Action
            key={i}
            text={advisor[con.NAME]}
            onClick={buildWhatsAppContactFunction(advisor[con.PHONE_NUMBER])}
          >
            <img src={advisorLogo} alt="Logo" width="auto" height={30}/>
            </Action>
          )
      }


    </Fab>
    </div>
  )
}



export default WhatsAppIcon