import React from 'react'
import { Button } from '@blueprintjs/core'
import ReactDom from 'react-dom'

import './Modal.css'


/**
 * Component that renders a modal on top of the current screen. 
 * @param {JSX.Element} children - The content of the modal
 * @param {boolean} show - Boolean indicating if the modal should be showed or not.
 * @param {() => void} doAccept - Function that should be executed if the modal is accepted.
 * @param {() => void} doCancel - Function that should be executed if the modal is canceled. This function is also called when the user clicks outside of the modal.
 * @param {boolean} [showAccept = true] - Boolean indicating if the modal should include the accept button.
 * @param {boolean} [showCancel = true] - Boolean indicating if the modal should include the cancel button.
 * @param {number} [widthPercentage = 50] - Number between 0 and 100 indicating the width of the modal.
 * @returns {JSX.Element} - The corresponding component
 */
const Modal = ({children, show, doAccept, doCancel, showAccept = true, showCancel = true, widthPercentage = 50}) => {
    
    if(!show)
        return null

    return ReactDom.createPortal(
        <div className="bp3-dark">
            <div className="overlay" onClick={() => doCancel()}></div>
            <div className="modal" style={{width : widthPercentage + "%"}}>
                <div className="modalContent"> 
                    {children}
                </div>
                <div className="modalButtons">
                    {doAccept !== null && showAccept && <Button onClick={() => showAccept && doAccept()} icon="tick"></Button>}
                    {doCancel !== null && showCancel && <Button onClick={() => showCancel && doCancel()} icon="cross"></Button>}
                </div>
            </div>
        </div>
    , document.getElementById('portal'))
}


export default Modal
