// General functions for all transactions: exposures, fwds and accounts

import * as types from "./types"
import store from '../store';
import * as con from "./../../GlobalConstants";
import { getApplicationParameter, setApplicationParameter, setInsertedParameter, setLoadingParameter } from './applicationParameters';
import { handleInternalError, handleRequestError } from '../../utils/errorFunctions';
import { formatDate, getNow } from '../../utils/dateFunctions';

// Default accounts
import deafultAccounts from "../../config/default_accounts.json"
import { DB } from '../logic/databaseLogic';
import * as sf from "./../logic/supportFunctions"
import { cleanObject, filterObject } from '../../GlobalFunctions';
import { translateParameter } from '../../utils/translateFunctions';
import { createNotification } from '../../templates/notifications/Notifications';
import { createFromCalculatorAnnotation } from "../logic/annotation";
import { allAlertMarketColumns, allExposureColumns, allForwardCoverageColumns, allOptionCoverageColumns, allSpotCoverageColumns, buildDefaultRow } from "../../utils/transactionFunctions";


// Generic Transaction Class
/**
 * Ment to be as an abstract class
 */
class Transaction {

    // Static Attributes
    // Default Transactions
    static DEFAULT_TRANSACTIONS = []
    // Default Transaction Values
    static DEFAULT_TRANSACTIONS_VALUES = {}
    // Transaction Type
    static TRANSACTION_TYPE = null
    // Export columns
    static EXPORT_COLUMNS = []


    
    
    /**
     * Method that fetchs the transaction from the database and stores them in redux
     */
    static async fetch() {
        

        // Sets the loading variable to loading
        setLoadingParameter(this.TRANSACTION_TYPE, {[con.STATUS]: con.LOADING})

        try
        {
            let res = await DB.fetchTransactions(this.TRANSACTION_TYPE)

            // If nothing is received, uses default (ment for accounts)
            if(res.length === 0)
                res = this.DEFAULT_TRANSACTIONS

            store.dispatch({
                type : types.SET_TRANSACTIONS_FROM_SERVER,
                payload : { [con.TRANSACTION_TYPE] : this.TRANSACTION_TYPE,
                            [con.VALUE] : Object.assign({}, ...res.map((x) => ({[x[con.ID]]: x})))}
            })

            setLoadingParameter(this.TRANSACTION_TYPE, {[con.STATUS]: con.OK})
            
        }
        catch(err)
        {
            setLoadingParameter(this.TRANSACTION_TYPE, {[con.STATUS]: con.ERROR})
            handleRequestError(err)
        }

    }

    /**
     * Method that adds a single transaction
     */    
    static async add(vals, cleanUpFunction = (trans) => true) 
    {

        // Sets insertion variable to loading
        setInsertedParameter(this.TRANSACTION_TYPE, {[con.STATUS]: con.LOADING})
        
        // Gets user 
        const user = sf.surrogateActive() ? sf.getSurrogateUser() : sf.getUser() 

        // Sets the default values and userId
        vals = {...this.DEFAULT_TRANSACTIONS_VALUES, 
                ...vals, 
                [con.USER] : user[con.ID],
                [con.USER_LINE] : sf.getCurrentLine() }

        try
        {
            let trans = await DB.addTransaction(this.TRANSACTION_TYPE, vals)
            

            store.dispatch({
                type : types.ADD_TRANSACTION,
                payload : { [con.TRANSACTION_TYPE] : this.TRANSACTION_TYPE,
                            [con.TRANSACTION] : trans}
            })

            cleanUpFunction(trans)

            
            setInsertedParameter(this.TRANSACTION_TYPE, {[con.STATUS]: con.OK})
        }
        catch (err)
        {
            handleRequestError(err)
            setInsertedParameter(this.TRANSACTION_TYPE, {[con.STATUS]: con.ERROR})
        }

    }


    /**
     * Method that adds multiple transactions
     */    
     static async bulkAdd(vals) 
     {
         
         setLoadingParameter(this.TRANSACTION_TYPE, {[con.STATUS]: con.LOADING})
         // Gets user Id 
         const user = sf.surrogateActive() ? sf.getSurrogateUser() : sf.getUser() 

         // Sets the default values and userId
         vals = vals.map(ob => {
            return({...this.DEFAULT_TRANSACTIONS_VALUES, ...ob, [con.USER] : user[con.ID]  })
         })
         
 
         try
         {
             let resp = await DB.addBulkTransactions(this.TRANSACTION_TYPE, vals) 
             
             let transactions = Object.assign({}, ...resp.map((x) => ({[x[con.ID]]: x})))
 
             store.dispatch({
                 type : types.ADD_TRANSACTIONS_BULK,
                 payload : { [con.TRANSACTION_TYPE] : this.TRANSACTION_TYPE,
                             [con.VALUE] : transactions}
             })

             setLoadingParameter(this.TRANSACTION_TYPE, {[con.STATUS]: con.OK})
         }
         catch (err)
         {
             handleRequestError(err)
             setLoadingParameter(this.TRANSACTION_TYPE, {[con.STATUS]: con.ERROR})
         }
 
     }

    /**
     * Method that sets a single transaction
     */
     static async set(id, vals)
     { 

        // Sets the default values and userId
        vals = {...this.DEFAULT_TRANSACTIONS_VALUES, ...vals, [con.ID] : id }

        try
        {
            let res = await DB.setTransaction(this.TRANSACTION_TYPE, id, vals)
  
            store.dispatch({
                
                type : types.SET_TRANSACTION,
                payload : { [con.TRANSACTION_TYPE] : this.TRANSACTION_TYPE,
                            [con.TRANSACTION] : res[con.TRANSACTION],
                            [con.ANNOTATION] : res[con.ANNOTATION]}
            })

        }
        catch (err)
        {
            handleRequestError(err)
        }

    }


    /**
     * Method that deletes a single transaction
     */
     static async delete(id)
     { 
        let trans = sf.getTransaction(this.TRANSACTION_TYPE, id)

        try
        {

            store.dispatch({
                type : types.DELETE_TRANSACTION,
                payload : { [con.TRANSACTION_TYPE] : this.TRANSACTION_TYPE,
                            [con.TRANSACTION_ID] : id}
            })

            await DB.deleteTransaction(this.TRANSACTION_TYPE, id)
            

        }
        catch (err)
        {
            // If error adds the transaction again
            store.dispatch({
                type : types.ADD_TRANSACTION,
                payload : { [con.TRANSACTION_TYPE] : this.TRANSACTION_TYPE,
                            [con.TRANSACTION] : trans}
            })
            handleRequestError(err)
        }

     }

     /**
     * Method that deletes all the transactions
     */    
      static async deleteAll() 
      {
          
         setLoadingParameter(this.TRANSACTION_TYPE, {[con.STATUS]: con.LOADING})
        
         let currentTransactions = sf.getAllTransactions(this.TRANSACTION_TYPE)
  
          try
          {                                        
              store.dispatch({
                  type : types.REPLACE_TRANSACTIONS,
                  payload : { [con.TRANSACTION_TYPE] : this.TRANSACTION_TYPE,
                              [con.VALUE] : {}}
              })

              // TODO
              await DB.deleteAllTransactions(this.TRANSACTION_TYPE)
              setLoadingParameter(this.TRANSACTION_TYPE, {[con.STATUS]: con.OK})
          }
          catch (err)
          {
                // Adds again the transactions
                store.dispatch({
                    type : types.REPLACE_TRANSACTIONS,
                    payload : { [con.TRANSACTION_TYPE] : this.TRANSACTION_TYPE,
                                [con.VALUE] : currentTransactions}
                })

                handleRequestError(err)
                setLoadingParameter(this.TRANSACTION_TYPE, {[con.STATUS]: con.OK})
          }
  
      }


     /**
     * Method that adds replaces the transactions
     */    
      static async replaceAll(vals) 
      {
          
         setLoadingParameter(this.TRANSACTION_TYPE, {[con.STATUS]: con.LOADING})

          // Gets user 
          const user = sf.surrogateActive() ? sf.getSurrogateUser() : sf.getUser() 
 
          // Sets the default values and userId
          vals = vals.map(ob => {
             return({...this.DEFAULT_TRANSACTIONS_VALUES, ...ob, [con.USER] : user[con.ID]  })
          })
          
  
          try
          {
              let resp = await DB.replaceTransactions(this.TRANSACTION_TYPE, vals)              
              
              let transactions = Object.assign({}, ...resp.map((x) => ({[x[con.ID]]: x})))
  
              store.dispatch({
                  type : types.REPLACE_TRANSACTIONS,
                  payload : { [con.TRANSACTION_TYPE] : this.TRANSACTION_TYPE,
                              [con.VALUE] : transactions}
              })

              setLoadingParameter(this.TRANSACTION_TYPE, {[con.STATUS]: con.OK})
          }
          catch (err)
          {
              handleRequestError(err)
              setLoadingParameter(this.TRANSACTION_TYPE, {[con.STATUS]: con.ERROR})
          }
  
      }

      /**
     * Method that adds resets the transactions
     */    
       static async resetTranstactions() 
       {
            store.dispatch({
                type : types.REPLACE_TRANSACTIONS,
                payload : { [con.TRANSACTION_TYPE] : this.TRANSACTION_TYPE,
                            [con.VALUE] : {}}
            })
   
       }



    // Annotation Methods
    // ---------------------

    /**
     * Method that fetchs the transaction from the database and stores them in redux
     */
     static async fetchAnnotations() {
        

        try
        {
            let data = await DB.fetchAnnotations(this.TRANSACTION_TYPE)

            let annotations = {}
            data.forEach((an) => {

                if(!(an[con.TRANSACTION_ID] in annotations))
                    annotations[an[con.TRANSACTION_ID]] = []
                
                annotations[an[con.TRANSACTION_ID]].push(an)
            })

            store.dispatch({
                type : types.SET_ANNOTATIONS_FROM_SERVER,
                payload : {[con.VALUE] : annotations, [con.TRANSACTION_TYPE] : this.TRANSACTION_TYPE}
            })
        }
        catch(err)
        {
            handleRequestError(err)
        }

    }


    static async addAnnotation(transId, annotation){
        
        try
        {
            let createdAnnotation = await DB.addAnnotation(this.TRANSACTION_TYPE, transId, annotation)

            store.dispatch({
                type : types.ADD_ANNOTATION,
                payload : {[con.ID] : transId, [con.VALUE] : createdAnnotation, [con.TRANSACTION_TYPE] : this.TRANSACTION_TYPE}
            })
        }
        catch(err)
        {
            handleRequestError(err)
        }

    }

    // Export methods
    // --------------------------
    static exportToObject(vals, clientType = con.IMPORTER)
    {        

        let export_array = Object.values(vals).map((ob) => {
            let final_ob = Object.fromEntries(this.EXPORT_COLUMNS.map(col => [col[con.NAME], ob[col[con.ID]]]))
            
            return(final_ob)
        })

        return(export_array)


    } 


}

export class Exposure extends Transaction {

    static TRANSACTION_TYPE = con.EXPOSURES
    static EXPORT_COLUMNS = con.EXPOSURES_EXPORT_COLUMNS
    static DEFAULT_TRANSACTIONS_VALUES = buildDefaultRow(Object.values(allExposureColumns))
    

    // Prepay an Exposure
    static async prepayExposure(expId, amountToPrepay, paymentMethod, prepayementRate, cleanUpFunction)
    {
        try{

            setApplicationParameter(con.TRANSACTION_PREPAYED, {[con.STATUS] : con.LOADING})
                        
            let data = await DB.prepayExposure(expId, amountToPrepay, paymentMethod, prepayementRate)

            store.dispatch({
                type : types.PREPAY_EXPOSURE,
                payload : data
            })

            setApplicationParameter(con.TRANSACTION_PREPAYED, {[con.STATUS] : con.OK})

            createNotification("Exposición prepagada correctamente", con.NOTIFICATION_TIME_SHORT)


        }catch(err)
        {
            handleRequestError(err)
            setApplicationParameter(con.TRANSACTION_PREPAYED, {[con.STATUS] : con.ERROR})
        }

        cleanUpFunction()
    }
    

    static canPrepay(expId, amountToPrepay, payementMethod, prepayementRate)
    {

        let exposure = store.getState()[con.STORE][con.REDUCER_EXPOSURES][expId]

        // Expired exposure
        if(exposure[con.STATE] !== con.ACTIVE)
        {
            handleInternalError(`Exposure must be active: ${exposure[con.STATE]}`)
            return([false, amountToPrepay, payementMethod, prepayementRate])
        }
    
        // Expired exposure
        if(exposure[con.AMOUNT] <= 0)
        {
            handleInternalError(`Exposure amount must be larger than zero: ${exposure[con.AMOUNT]}`)
            return([false, amountToPrepay, payementMethod, prepayementRate])
        }
    
        // Positive Amount
        if(amountToPrepay <= 0)
        {
            handleInternalError(`Amount to prepay should be positive: ${amountToPrepay}`)
            return([false, amountToPrepay, payementMethod, prepayementRate])
        }
    
        // Exceeding amount
        if(exposure[con.AMOUNT] < amountToPrepay)
        {   
            console.log(`Prepay amount (${amountToPrepay}) exceeds the amount of exposure: ${exposure[con.AMOUNT]}. Will adjust`)
            amountToPrepay = exposure[con.AMOUNT]
        }

        let compAccount = Account.getCompensationAccount()
        // Checks the method
        if(payementMethod === con.PREPAY_WITH_ACCOUNT)
        {
            compAccount = Account.getCompensationAccount()
            if(amountToPrepay > compAccount[con.AMOUNT])
            {
                handleInternalError(`Not enough amount in compensation account to prepay: ${amountToPrepay}`)
                return([false, amountToPrepay, payementMethod, prepayementRate])
            }

        }
        else if(payementMethod !== con.PREPAY_WITH_COVERAGE)
        {
            handleInternalError(`No support for payment type: ${payementMethod}`)
            return([false, amountToPrepay, payementMethod, prepayementRate])   
        }


        // ALl is OK
        return([true, amountToPrepay, payementMethod, prepayementRate])

    }


}


export class CoverageFWD extends Transaction {

    static TRANSACTION_TYPE = con.COVERAGES_FWD

    static DEFAULT_TRANSACTIONS_VALUES = buildDefaultRow(Object.values(allForwardCoverageColumns))

    static EXPORT_COLUMNS = con.COVERAGES_FWD_EXPORT_COLUMNS


    static exportToObject(vals, clientType = con.IMPORTER){

        Object.values(vals).forEach(ob =>{
            ob[con.COVERAGE_TYPE] = translateParameter(ob[con.COVERAGE_TYPE])
        })

        return(super.exportToObject(vals))
    }

    static addFromCalculatorAnnotation(transId){

        let annotation = createFromCalculatorAnnotation(transId)
        this.addAnnotation(transId, annotation)

    }

}

export class CoverageSPOT extends Transaction {

    static TRANSACTION_TYPE = con.COVERAGES_SPOT

    static EXPORT_COLUMNS = null // Overrides Export method

    static DEFAULT_TRANSACTIONS_VALUES = buildDefaultRow(Object.values(allSpotCoverageColumns))


    // Edits the add method
    static async add(vals, cleanUpFunction = () => true) 
    {

        // Gets user ID
        const user = sf.surrogateActive() ? sf.getSurrogateUser() : sf.getUser() 
        const addDB = getApplicationParameter(con.ADD_SPOT_COVERAGE_TO_COMPENSATION_ACCOUNT)

        try
        {
            // Sets the default values and userId
            vals = {...this.DEFAULT_TRANSACTIONS_VALUES, 
                    ...vals, 
                    [con.USER] : user[con.ID],
                    [con.USER_LINE] : sf.getCurrentLine(),
                    [con.ADD_SPOT_COVERAGE_TO_COMPENSATION_ACCOUNT] : addDB }

            // Saves Coverage
            let data = await DB.addTransaction(this.TRANSACTION_TYPE, vals)
            
            let cvg = data[con.TRANSACTION]
            let compAccount = addDB === true ? data[con.COMPENSATION_ACCOUNT] : null //Checks if addDB is true 
            let annotation = addDB === true ? data[con.ANNOTATION] : null            //Checks if addDB is true 
            
            
            store.dispatch({
                type : types.ADD_TRANSACTION,
                payload : { [con.TRANSACTION_TYPE] : this.TRANSACTION_TYPE,
                            [con.TRANSACTION] : cvg,
                            [con.COMPENSATION_ACCOUNT] : compAccount,
                            [con.ANNOTATION] : annotation,
                            [con.ADD_SPOT_COVERAGE_TO_COMPENSATION_ACCOUNT] : addDB}
            })


            cleanUpFunction()


        }
        catch(err)
        {
            handleRequestError(err)
        }        

    }    


    // Create prepa spot coverage
    static createPrepaySpotCoverage(amount, rate){

        return({
                [con.OPENING_DATE] : formatDate(getNow()),
                [con.AMOUNT]: amount,
                [con.RATE]: rate,
                [con.COMMENT]: "Prepago de Exposición",
                [con.SPOT_COVERAGE_TYPE]: con.CASH_REGISTER
            })

    }

    static exportToObject(vals, clientType = con.IMPORTER){

        let exportColumns = clientType === con.IMPORTER ? con.COVERAGES_SPOT_EXPORT_COLUMNS_IMPORTER : con.COVERAGES_SPOT_EXPORT_COLUMNS_EXPORTER
        let export_array = Object.values(vals).map((ob) => {
            let final_ob = Object.fromEntries(exportColumns.map(col => [col[con.NAME], ob[col[con.ID]]]))
            
            return(final_ob)
        })

        return(export_array)
    }

}

export class CoverageOption extends Transaction {

    static TRANSACTION_TYPE = con.COVERAGES_OPTION

    static DEFAULT_TRANSACTIONS_VALUES = buildDefaultRow(Object.values(allOptionCoverageColumns))
    static EXPORT_COLUMNS = con.COVERAGES_OPTION_EXPORT_COLUMNS

}

export class MarketAlerts extends Transaction {

    static TRANSACTION_TYPE = con.MARKET_ALERTS

    static DEFAULT_TRANSACTIONS_VALUES = buildDefaultRow(Object.values(allAlertMarketColumns))

}


export class Account extends Transaction {

    static DEFAULT_TRANSACTIONS = Object.assign({}, ...deafultAccounts.map((x) => ({[x[con.ID]]: x})))
    static TRANSACTION_TYPE = con.ACCOUNTS

    static EXPORT_COLUMNS = con.ACCOUNTS_EXPORT_COLUMNS



    // Overwrite 
    static async replaceAll(vals) 
    {
        
        let cleanVals = [
            vals.find(ob => ob[con.ACCOUNT_TYPE] === con.COMPENSATION_ACCOUNT),
            vals.find(ob => ob[con.ACCOUNT_TYPE] === con.NATURAL_COVERAGE)
        ]

        // Checks if are missing
        if(cleanVals[0] === undefined)
            cleanVals[0] = {}
        if(cleanVals[1] === undefined)
            cleanVals[1] = {}

       // Assigns by default the account types
       cleanVals[0]= { ...deafultAccounts[0], ...cleanObject(cleanVals[0]), [con.ACCOUNT_TYPE] : con.COMPENSATION_ACCOUNT}
       cleanVals[1]= { ...deafultAccounts[1], ...cleanObject(cleanVals[1]), [con.ACCOUNT_TYPE] : con.NATURAL_COVERAGE}
       
       super.replaceAll(cleanVals)

    }

    // Overwrite 
    static async deleteAll()
    {
       let vals = []
       vals[0]= { ...deafultAccounts[0], [con.ACCOUNT_TYPE] : con.COMPENSATION_ACCOUNT}
       vals[1]= { ...deafultAccounts[1], [con.ACCOUNT_TYPE] : con.NATURAL_COVERAGE}

       super.replaceAll(vals)
    }
        
    
    static getCompensationAccount = () => sf.getCompensationAccount()
    
    static getCompensationAccountAmount = () => sf.getCompensationAccountAmount()


    static exportToObject(vals, clientType = con.IMPORTER){

        if(clientType === con.EXPORTER)        
            vals = filterObject(vals, ob => ob[con.ACCOUNT_TYPE] === con.NATURAL_COVERAGE)


        Object.values(vals).forEach(ob =>{
            ob[con.ACCOUNT_TYPE] = translateParameter(ob[con.ACCOUNT_TYPE])
        })

        return(super.exportToObject(vals))
    }

}


// Other methods
export const resetAllTransactions = ()=>
{
    store.dispatch({
        type : types.RESET_ALL_TRANSACTIONS,
        payload : {}
    })

}





// Legacy

// Save Transactions
export const insertTransactions = (token) =>
{

    //insertExposures(token)
    //insertForwardCoverages(token)
    //insertSpotCoverages(token)
    //insertAccounts(token)

}

// View annotations
export const getTransactionsAnnotationFunction = (transID) =>
{

    return((rowID) => {        
        
        let annotations = store.getState()[con.STORE][con.getTransactionAnnotationReducer(transID)][rowID]
        return(annotations === undefined ? [] : annotations)
    })
}


export const getSearchCompareFunction = (id, colType, targetValue) =>
{

    let fun = (ob) => ob[id] === targetValue    

    if(colType === con.MONEY)
        fun = (ob) => ob[id] === targetValue
    else if(colType === con.TEXT)
        fun = (ob) => ob[id].toUpperCase().includes(targetValue.toUpperCase())

    return(fun)

}

export const getExposures = () =>
{
    return(store.getState()[con.STORE][con.REDUCER_EXPOSURES])
}
