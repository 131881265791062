// TODO - Documentar
import React, { useEffect, useState } from 'react'
import { Y_LABEL, BAR_VALUE, NAME, CONTINUES_LINE_VALUE, SECOND_Y_LABEL, SECOND_Y_AXIS, CONTINUOS_LINE_NAME, CATEGORICAL_LINE_NAME, CATEGORCIAL_LINE_VALUE, CATEGORICAL_LABEL_GENERATOR_FUNCTION, INTERACTIVE_LEGEND } from '../../templates/charts/ChartConstants'
import { CategoricalBarAndLineChartHook } from '../../templates/charts/hooks/CategoricalBarAndLineChartHook'

import * as con from "../../GlobalConstants"

import { AllScenariosTransactionsSummaryHook } from '../hooks/AllScenariosTransactionsSummaryHook'
import { RefApplicationParameterHook, RefSPOT } from '../hooks/ApplicationParametersHooks'
import { ScenariosHook } from '../hooks/ScenariosHook'
import { formatMoney } from '../../utils/moneyFunctions'


// Line Column
const LINE_COLUMN = con.EFFECTIVE_RATE

export const BandComparisonSummary = ({selectedScenarios, chartParameters}) => {

  
  // Parameters
  // -----------------    
  const spot = RefSPOT()
  const selectedVar = RefApplicationParameterHook(con.SELECTED_VAR)

  
  const scenarios = ScenariosHook()

  const [transactionsSummaryData, computing, hasSummary] = AllScenariosTransactionsSummaryHook(selectedScenarios)

  const [chartData, setChartData] = useState(() => constructInitialChartData(selectedScenarios, scenarios))
  
  // BarAndLinePlot hook  
  const [barAndLinePlot, chart] = CategoricalBarAndLineChartHook({parameters : {[Y_LABEL] : "VALOR EN RIESGO (MILES DE COP)",                                                                                                                             
                                                                                       [SECOND_Y_AXIS] : true,
                                                                                       [SECOND_Y_LABEL] : "COP",
                                                                                       [CONTINUOS_LINE_NAME] : "SPOT Actual",
                                                                                       [CATEGORICAL_LINE_NAME] : "Tasa Dolar",
                                                                                       [INTERACTIVE_LEGEND] : false,
                                                                                       [CATEGORICAL_LABEL_GENERATOR_FUNCTION] : (val) => formatMoney(val, con.MONEY_COP),
                                                                                      ...chartParameters}})


  


    // Transaction summary changed from outside the model
    useEffect(() => {
            
      if(!computing && Object.keys(transactionsSummaryData).length > 0)
        setChartData(constructChartData(selectedScenarios, scenarios, transactionsSummaryData, selectedVar, spot))

    }, [computing, selectedVar, transactionsSummaryData, selectedScenarios, scenarios, spot])
    


    useEffect(() => {


      if(!chart.initialized)
      {

          // Initialize Chart Data
          chart.initialize()       

          // Builds chart Data
          chart.build(chartData)
      }

      
      // If number of scenarios has changed
      if(chartData.length > 0)
      {          
          chart.updateData(chartData)
      }
    }, [chart, chartData])


  
    return (
            <div className='container-with-overlay'> 
              <div className={!hasSummary ? 'dimmed' : ""}>                             
                <svg ref={barAndLinePlot}/>
              </div>
              {!hasSummary 
              ? <h4 className='overlay-over-container'>{con.NO_TRANSACTIONS_MESSAGE}</h4> 
              : null
              }
            </div>
    )
}

BandComparisonSummary.defaultProps = {
  selectedScenarios: null,
  chartParameters : {}
};


// Support functions

// Chart Data
// ---------------------

const constructChartData = (selectedScenarios, scenarios, transactionSummaryData, selected_var, spot) => {
  
  let chartData = Object.keys(transactionSummaryData)
                        .filter((k) => selectedScenarios == null || selectedScenarios.includes(k))
                        .map((k) => {return({[NAME] : scenarios[k][NAME],
                                                                  [BAR_VALUE] : transactionSummaryData[k][selected_var]/con.MILLION,
                                                                  [CATEGORCIAL_LINE_VALUE] : transactionSummaryData[k][LINE_COLUMN],
                                                                  [CONTINUES_LINE_VALUE] :  spot})});

  
  return(chartData)

}


const constructInitialChartData = (selectedScenarios, scenarios) => {

  let chartData = Object.keys(scenarios)
                        .filter((k) => selectedScenarios == null || selectedScenarios.includes(k))
                        .map((k,i) => {return({[NAME] : scenarios[k][NAME],
                                      [BAR_VALUE] : 1000,
                                      [CATEGORCIAL_LINE_VALUE] : 3500,
                                      [CONTINUES_LINE_VALUE] :  4000})});

    return(chartData)

}

