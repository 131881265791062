// TODO - Documentar
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { MultiLineChartHook } from '../../templates/charts/hooks/MultiLineChartHook'
import * as cf from "../../templates/charts/ChartConstants"
import * as con from "../../GlobalConstants"
import { round } from '../../GlobalFunctions'
import { addDays, addMonths, daysBetweenDates, formatDateForUser, getToday } from '../../utils/dateFunctions'
import { getGeneratorScenarioFunctionByVolatility } from '../../utils/scenarioFunctions'
import { RefApplicationParameterHook, RefSPOT } from '../hooks/ApplicationParametersHooks'
import { RefModelParameterHook } from '../hooks/ModelParameterHooks'
import { RefMarketValueHook } from '../hooks/MarketValuesHook'



function CoverageProfileScenarios({chartParameters}) {

    
    // Model Parameters
    // -----------------
    const spot = RefSPOT()
    const termInMonths = RefApplicationParameterHook(con.TERM_IN_MONTHS)
    const marketVolatility = RefModelParameterHook(con.MARKET_VOLATILITY)
    const confidenceLevel = RefModelParameterHook(con.ESC_CONFIDENCE_LEVEL)


    // Scenarios
    // -----------------
    const scenarios = useSelector((state) => state[con.STORE][con.REDUCER_SCENARIOS])

    // Application Parameters
    const historicPricesAndDates = RefMarketValueHook(con.HISTORIC_DOLLAR_INFO)
    const currentDate = RefApplicationParameterHook(con.CURRENT_DATE)

    // Multiline hook
    const [chartData, setChartData] = useState(() => [])
    const [objectReference, chart] = MultiLineChartHook({parameters : {[cf.TITLE] : "VALOR EN RIESGO DEL DOLAR", [cf.Y_LABEL] : "Tasa de Cambio", [cf.X_LABEL]: "Fecha", [cf.LEGEND_NUM_ROWS] : 2, ...chartParameters}})





    useEffect(() => {

        if(!chart.initialized)
            chart.initialize()
                
        const finalDate =  addMonths(currentDate,termInMonths)  
        
        const numPoints = daysBetweenDates(currentDate, finalDate)
        const dates = [...Array(numPoints).keys()].map((i) => {
            return(addDays(getToday(),i))            
        })

        // Historic Values
        const historicLength = historicPricesAndDates[con.DATES].length
        const historicDates = historicPricesAndDates[con.DATES].slice(historicLength - con.HISTORIC_SCENARIO_DAYS_TO_DISPLAY).concat([currentDate])
        const historicPrices = historicPricesAndDates[con.TRM].slice(historicLength - con.HISTORIC_SCENARIO_DAYS_TO_DISPLAY).concat([spot])

        // Politic Var functions
        const politic_up = getGeneratorScenarioFunctionByVolatility(con.POLITIC_PERCENTAGE*marketVolatility, confidenceLevel, 1)   
        const politic_down = getGeneratorScenarioFunctionByVolatility(con.POLITIC_PERCENTAGE*marketVolatility, confidenceLevel, -1)
        
        const labelFun = (selObj,j) => {return(`${formatDateForUser(selObj.x[j])}: ${round(selObj.y[j])} COP`)}

        let data = [{[cf.NAME] : "Dolar Escenario Alcista Sin Politica",
                        [cf.X_VALUES] : dates,
                        [cf.Y_VALUES] : [...Array(numPoints).keys()].map((i) => scenarios[con.SCENARIO_VAR_UP].gen_fun(spot,i)),
                        [cf.LABEL_GENERATING_FUNCTION] : labelFun},
                     {[cf.NAME] : "Dolar Escenario Bajista Sin Politica",
                        [cf.X_VALUES] : dates,
                        [cf.Y_VALUES] : [...Array(numPoints).keys()].map((i) => scenarios[con.SCENARIO_VAR_DOWN].gen_fun(spot,i)),
                        [cf.LABEL_GENERATING_FUNCTION] : labelFun},
                    {[cf.NAME] : "TRM",
                        [cf.X_VALUES] : historicDates,
                        [cf.Y_VALUES] : historicPrices,
                        [cf.LABEL_GENERATING_FUNCTION] : labelFun},
                    {[cf.NAME] : "Dolar Escenario Alcista Con Politica",
                        [cf.X_VALUES] : dates,
                        [cf.Y_VALUES] : [...Array(numPoints).keys()].map((i) => politic_up(spot,i)),
                        [cf.LABEL_GENERATING_FUNCTION] : labelFun},
                    {[cf.NAME] : "Dolar Escenario Bajista Con Politica",
                        [cf.X_VALUES] : dates,
                        [cf.Y_VALUES] : [...Array(numPoints).keys()].map((i) => politic_down(spot,i)),
                        [cf.LABEL_GENERATING_FUNCTION] : labelFun}                  
                    
                    ]

        
            // Checks if structural change happened
            if(data.length !== chartData.length)
                chart.build(data)
            else
                chart.updateData(data)
    
            setChartData(data)
        

    }, [historicPricesAndDates, 
        currentDate,
        scenarios,         
        spot, 
        termInMonths,
        marketVolatility,
        confidenceLevel,
        chart,
        chartData.length])

    

    

    return (
        <div>
            <svg ref={objectReference}/>
        </div>
    )
}


CoverageProfileScenarios.defaultProps = {
    chartParameters : {}
  };

export default CoverageProfileScenarios
