// Functions for checking if is in mobile enviorment

import * as con from "../../GlobalConstants"
import { getApplicationParameter, setApplicationParameter } from "./applicationParameters"


export const checkIfMobile = () =>
{
    //console.log(window.innerWidth)
    let isMobile = window.innerWidth <= con.MOBILE_MAX_WIDTH || window.innerHeight <= con.MOBILE_MAX_HEIGHT

    if(isMobile !== getApplicationParameter(con.IS_MOBILE))
        setApplicationParameter(con.IS_MOBILE, isMobile)
}