// Multiple Bar Chart Class (based on D3)

/**
 * Data must be passed with the following structure
 * Array of objects. Each object must include:
 *  - name : string with name
 *  - values : array of values. Must be the length of the numBars parameter.
 *  - labels : array of labels. Must be the length of the numBars parameter.  
 */


 import * as d3 from "d3";

import "../Charts.css"
import * as cc from '../ChartConstants';
import * as cf from '../ChartFunctions';
import { GenericChart } from "./GenericChart";


const DEFAULT_PARAMETERS = {
    [cc.MARGIN] : { top: 40, right: 20, bottom: 5, left: 70 },
    [cc.PROPORTIONAL_HEIGHT] : 450,
    [cc.PROPORTIONAL_WIDTH] : 400
    
}

export class MultiBarChart extends GenericChart
 {

    constructor(parameters, objectReference){
        
        super({...DEFAULT_PARAMETERS, ...parameters}, objectReference)


    }

    
    // Overwrite
    // -------------------------
    build(initialData)
    {
        super.build(initialData)

        this.groupNames = initialData.map((elem) => elem[cc.NAME])
        this.numBars = initialData[0][cc.VALUES].length 

        this.opacityJump = this.parameters[cc.MIN_OPACITY]/this.groupNames.length
                
        
        // Initial Variable Values
        this.barValues = Array(this.groupNames.length*this.numBars).fill(0)
        this.labelValues = Array(this.groupNames.length*this.numBars).fill(" ")

        this.y = d3.scaleLinear().range([this.height, 0])
                .domain([-10,10]);


        // Color pallette
        this.colorScheme = this.getColorScheme(this.numBars - 1)

        // Creates X and Y ranges
        const x = d3.scaleBand().range([0, this.width]).padding(0.1)
                .domain(this.barValues.map((_,i) => i));

        const xNames = d3.scaleBand().range([0, this.width]).padding(0.1)
                .domain(this.groupNames);            


    


        // Adds bars
        this.bars = this.mainComponent.selectAll(".bar")
                            .data(this.barValues)
                            .enter().append("rect")
                            .attr("class", "bar")
                            .attr("fill", (_,i) => this.colorScheme(this.getNumInsideGroup(i, this.numBars)))
                            .style("opacity", (_,i) => this.getOpacity( this.getNumGroup(i, this.numBars), this.opacityJump))
                            .attr("x", (_,i) => x(i))
                            .attr("width", x.bandwidth())
                            .attr("y", (_) => this.y(1))
                            .attr("height", (_) => this.y(0))


        // Adds x axis
        this.mainComponent.append("g")
                            .style("font-size", this.parameters[cc.X_LABEL_FONT_SIZE] + "px")
                            .attr("transform", "translate(0," + (this.height + 8) + ")")
                            .call(d3.axisBottom(xNames))
                            .selectAll(".tick text")
                            .call(cf.wrap, xNames.bandwidth());


        // Adds y axis
        this.mainComponent.append("g")
            .attr('id', 'yAxis')
            .style("font-size", this.parameters[cc.X_LABEL_FONT_SIZE] + "px")
            .call(d3.axisLeft(this.y));



        // Adds Hover    
        this.bars.each((_,i, nodes) => {

            d3.select(nodes[i]).on("mouseover", (_) => {
                d3.select(nodes[i]).transition()
                                    .duration('400')
                                    .style("opacity", 1);

            }).on("mouseout", (_) => {
                d3.select(nodes[i]).transition()
                                    .duration('400')
                                    .style("opacity", this.getOpacity( this.getNumGroup(i, this.numBars), this.opacityJump));

            })
            .append("svg:title")
            .text(`${this.labelValues[i]}`);
        })

    }


    // Function that updates the chart
    updateData(newData){

        super.updateData(newData)

        // Updates variables
        this.barValues = newData.map((d) => d[cc.VALUES]).flat()
        this.labelValues = newData.map((d) => d[cc.LABELS]).flat()

        this.y = d3.scaleLinear().range([this.height, 0])
                .domain([Math.min(-1,...this.barValues), Math.max(0,...this.barValues)]);

        this.mainComponent.select("#yAxis")
                .transition()
                .duration(this.parameters[cc.DATA_CHANGE_TRANSITION_TIME])
                .call(d3.axisLeft(this.y));


        // Removes Hover an refreshes label    
        this.bars.each((_,i, nodes) => {

            d3.select(nodes[i]).on("mouseover", null)
                               .on("mouseout", null)
                               .select("title")
                                .text(`${this.labelValues[i]}`);;
        })

        this.mainComponent.selectAll(".bar")
                .data(this.barValues)
                .transition()
                .duration(this.parameters[cc.DATA_CHANGE_TRANSITION_TIME])
                .attr("height", (val) => Math.abs(this.y(0) - this.y(val)))
                .attr("y", (val) => this.y(Math.max(0,val)))
                .on("end", () => {

                    // Adds Hover After animation is complete   
                    this.bars.each((_,i, nodes) => {

                        d3.select(nodes[i]).on("mouseover", (_) => {
                            d3.select(nodes[i]).transition()
                                                .duration('400')
                                                .style("opacity", 1);

                        }).on("mouseout", (_) => {
                            d3.select(nodes[i]).transition()
                                                .duration('400')
                                                .style("opacity", this.getOpacity( this.getNumGroup(i, this.numBars), this.opacityJump));

                        })                
                    })

                });    
                       
    }




    // Support Functions
    // --------------------------

    // Support functions
    getNumGroup = (i, numBars) =>
    {
        return( Math.floor(i / numBars))
    }


    getNumInsideGroup = (i, numBars) =>
    {
        return( i % numBars)
    }

    // Opacity jump
    getOpacity = (numInside, opacityJump) => {
        
        return(1 - ((numInside)*(opacityJump)))
    }

    getColorScheme(numElements)
    {
        // Builds default color scheme
        return(d3.scaleOrdinal().domain([0,numElements])
                                        .range(["var(--color-scenario-1)","var(--color-scenario-2)"]))
    }

 }
 

  