// TODO - Documentar
import React, { useState } from 'react'
import { IsStaff, RefVisualizedUser } from '../hooks/AuthenticationHook'
import * as con from "../../GlobalConstants"
import { Button, Card, Elevation, Text } from '@blueprintjs/core'
import { UserLinesHook } from '../hooks/UserLinesHook'
import EditableLine from './EditableLine'

// CSS
import "./Profile.css"
import { createUserLine, getNewLineName } from '../../store/actions/userLines'
import { RefNestedApplicationParameterHook } from '../hooks/ApplicationParametersHooks'
import ChangePassword from './ChangePassword'
import Modal from '../../templates/popups/Modal'
import AdvisorPage from './AdvisorPage'

function Profile() {

  // User
  const user = RefVisualizedUser()

  // Lines
  const lines = UserLinesHook()


  // Is Staff
  const isStaff = IsStaff()

  // Create Line status
  const createLineStatus = RefNestedApplicationParameterHook(con.USER_LINE_ADDED, con.STATUS)

  // Show PasswordChange
  const [showPasswordChangeDialog, setShowPasswordChangeDialog] = useState(() => false)
    
  return (
    <div className="mainProfileContainer">

        <Modal show={showPasswordChangeDialog} doAccept={null}  doCancel={(e) => setShowPasswordChangeDialog(false)} showCancel={false}>
          <ChangePassword closeDialog={() => setShowPasswordChangeDialog(false)} />
        </Modal>
      
      <div className="sideBySideCards">

      <div className="cardContainer">
        <Card elevation={Elevation.TWO}>
            <h2>Datos Personales</h2>   
            <div className="line-arrangement-spread">
              <div>       
                <Text><strong> Usuario: </strong> {user[con.USERNAME]} </Text>
              </div>
              <div>
                <Button text={"Cambiar Contraseña"} onClick={() => setShowPasswordChangeDialog(true)}/>
              </div>
            </div>

              <h2>Lineas</h2> 
             
              <div className="columnParameterBox">                
                  { lines.length === 0 ? <h4>Cargando las Lineas</h4>
                                       : lines.map((line, i) => <EditableLine key={i} line={line} singleLine={lines.length === 1}/>)}                
              </div>
              <div style={{marginTop : "3%"}}>
                <Button text={"Agregar Linea"} 
                        onClick={() => createUserLine({[con.NAME] : getNewLineName()})}
                        disabled={createLineStatus === con.LOADING
                                  || (!isStaff && lines.length >= con.MAX_LINES_PER_USER)}
                        title={(!isStaff && lines.length >= con.MAX_LINES_PER_USER) ?
                                `Solo se permite un máximo de ${con.MAX_LINES_PER_USER} lineas por usuario.` : null}/>
              </div>
            
        </Card>
      </div>

        <div className="cardContainer">
          <Card elevation={Elevation.TWO} style={{height : "100%"}}>
              <AdvisorPage />
          </Card>
        </div>

      </div>
    </div>
  )
}

export default Profile