// TODO - Documentar
// Selected Lines Hook

import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import * as con from "../../GlobalConstants"

export const UserLinesHook = () =>
{
    const lines = useSelector((state) => state[con.STORE][con.REDUCER_USER_LINES])
    return(lines)
}


export const StaffUserLineComments = (userLineId) =>
{
    const allComments = useSelector((state) => state[con.STORE][con.REDUCER_ADMIN_LINE_COMMENTS])
    
    const [userLineComments, setUserLineComments] = useState(() => [])


    useEffect(() => {
        setUserLineComments(allComments.filter(com => `${com[con.USER_LINE]}` === `${userLineId}`))
    }, [allComments, userLineId])
    
    return(userLineComments)
}