// Actions for the errors
import * as types from "./types"
import store from '../store';
import * as con from "../../GlobalConstants"


// Push Error
export const pushError = (id, userMessage, otherInfo = {}) =>
{    

    let payload = {[con.ID]: id, [con.MESSAGE] : userMessage, ...otherInfo}

    store.dispatch({
        type : types.PUSH_ERROR,
        payload : payload
    })
}
