// TODO - Documentar
import React from 'react'


export function BaseLabel({classType, text,  tight, onClick}) {
  return (
    <h4 className={tight ? classType + " tight noCarret" : classType + " noCarret" } onClick={onClick}>{text}</h4>
  )
}

BaseLabel.defaultProps = {
  text : "",
  tight : false,
  onClick : () => true
}

export function SmallLabel(parameters) {
  return (
    <BaseLabel classType={"smallLabel" } {...parameters}/>
  )
}


export function MediumLabel(parameters) {
  return (
    <BaseLabel classType={"mediumLabel" } {...parameters}/>
  )
}

export function LargeLabel(parameters) {
  return (
    <BaseLabel classType={"largeLabel" } {...parameters}/>
  )
}



