// TODO - Documentar
import { useEffect } from 'react'
import OptionAssessmentControls from '../components/OptionAssessment/OptionAssessmentControls'
import OptionAssessmentTable from '../components/OptionAssessment/OptionAssessmentTable'
import { AssessedOptionCoveragesHook } from '../components/hooks/TransactionsHooks'
import { USABLE_SCREEN_SIZE } from '../GlobalConstants'
import { fetchAllIBR, fetchAllSofrCurves } from '../store/actions/market'
import SimpleFillFooter from '../templates/footer/SimpleFillFooter'
import "./Pages.css"

function OptionAssessmentPage() {

    // Loads additional Market Data
    useEffect(() => {
        fetchAllSofrCurves()
        fetchAllIBR()
    }, [])

    const [assessedOptionsCoverages, assessmentSummary] = AssessedOptionCoveragesHook()

    return (
        <div style={{height : USABLE_SCREEN_SIZE + "vh"}}>

            <OptionAssessmentControls assessmentSummary={assessmentSummary} assessedOptionDictionary={assessedOptionsCoverages}/>
            <OptionAssessmentTable assessedOptionDictionary={assessedOptionsCoverages}/>         
            <SimpleFillFooter />   
        </div>
    )
}

export default OptionAssessmentPage