// Multiple Line Chart Hook
 import  {useMemo, useRef } from 'react'
import { MultiLineChart } from '../classes/MultiLineChart'

 /* Hook returns:
     - Reference for the rendered plot
     - Object of type ChartClass
 */
 export const MultiLineChartHook = ({parameters}) =>
 {
     
    const objectReference = useRef()
    const initialParameters = useRef(parameters)
    const chart = useMemo(() => new MultiLineChart(initialParameters.current, objectReference),[])    
 
    return([objectReference, chart])
 
 }

