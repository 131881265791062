import React, { useState, useEffect } from 'react';
import "../../templates/Templates.css";
import "../../templates/tables/Table.css";
import "../ComponentsGlobalStyle.css";
import IntradayRecordsRow from './IntradayRecordsRow';
import chevron from '../../img/icons/chevron.png';
import doubleChevron from '../../img/icons/double-chevron.png';
import { formatAccordingToType } from '../../templates/Types'; 
import * as con from '../../GlobalConstants'; 

const calculateFontSize = (width, height) => {
    if (width <= 768) {
        return '18px';  
    } else if (width <= 1024) {
        return '20px';  
    } else if (width <= 1480) {
        return '22px';  
    } else {
        return '25px';  
    }
};

const calculateRecordsPerPage = (width) => {
    if (width <= 1480) {
        return 7;  
    } else {
        return 12;  
    }
};

function IntradayRecordsTable({ fixedSize, data }) {
    const [currentPage, setCurrentPage] = useState(1);
    const [recordsPerPage, setRecordsPerPage] = useState(16); 
    const [fontSize, setFontSize] = useState('16px');
    const [paddingCell, setPaddingCell] = useState('5px 10px');
    const [minWidthDate, setMinWidthDate] = useState('250px'); 
    const [minWidthOther, setMinWidthOther] = useState('80px'); 

    const adjustStylesBasedOnResolution = () => {
        const width = window.innerWidth;
        const height = window.innerHeight;

        const newFontSize = calculateFontSize(width, height);
        setFontSize(newFontSize);

        if (width <= 1480 && height <= 720) {
            setPaddingCell('5px 8px');  
            setMinWidthDate('200px');  
            setMinWidthOther('60px');  
        } else {
            setPaddingCell('5px 10px');  
            setMinWidthDate('250px');  
            setMinWidthOther('80px'); 
        }

        const newRecordsPerPage = calculateRecordsPerPage(width);
        setRecordsPerPage(newRecordsPerPage);
    };

    useEffect(() => {
        adjustStylesBasedOnResolution();  
        window.addEventListener('resize', adjustStylesBasedOnResolution);  

        return () => {
            window.removeEventListener('resize', adjustStylesBasedOnResolution);
        };
    }, []);

    useEffect(() => {
        setCurrentPage(1);
    }, [data]);

    if (!data || data.dates.length === 0) {
        return <div>No hay datos disponibles</div>;
    }

    const indexOfLastRecord = currentPage * recordsPerPage;
    const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
    const currentRecords = data.dates.slice(indexOfFirstRecord, indexOfLastRecord);
    const totalPages = Math.ceil(data.dates.length / recordsPerPage);

    const handleFirstPage = () => setCurrentPage(1);
    const handlePreviousPage = () => setCurrentPage(prevPage => Math.max(prevPage - 1, 1));
    const handleNextPage = () => setCurrentPage(prevPage => Math.min(prevPage + 1, totalPages));
    const handleLastPage = () => setCurrentPage(totalPages);

    return (
        <div>
            <div 
                className="pagination" 
                style={{ 
                    textAlign: 'center', 
                    marginBottom: '14px',  
                    fontSize: fontSize,  
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                }}
            >
                <button 
                    onClick={handleFirstPage} 
                    disabled={currentPage === 1} 
                    style={{
                        background: 'none',
                        border: 'none',
                        cursor: 'pointer',
                        fontSize: 'inherit',
                    }}
                >
                    <img src={doubleChevron} alt="Primera página" style={{ width: '16px', height: '16px', transform: 'scaleX(-1)', filter: 'invert(100%)' }} />
                </button>
                <button 
                    onClick={handlePreviousPage} 
                    disabled={currentPage === 1} 
                    style={{
                        background: 'none',
                        border: 'none',
                        cursor: 'pointer',
                        fontSize: 'inherit',
                    }}
                >
                    <img src={chevron} alt="Página anterior" style={{ width: '16px', height: '16px', transform: 'scaleX(-1)', filter: 'invert(100%)' }} />
                </button>
                <span style={{ margin: '0 10px', color: 'white', lineHeight: '16px', marginTop: '-4px' }}>
                    Página {currentPage} de {totalPages}
                </span>
                <button 
                    onClick={handleNextPage} 
                    disabled={currentPage === totalPages} 
                    style={{
                        background: 'none',
                        border: 'none',
                        cursor: 'pointer',
                        fontSize: 'inherit',
                    }}
                >
                    <img src={chevron} alt="Siguiente página" style={{ width: '16px', height: '16px', filter: 'invert(100%)' }} />
                </button>
                <button 
                    onClick={handleLastPage} 
                    disabled={currentPage === totalPages} 
                    style={{
                        background: 'none',
                        border: 'none',
                        cursor: 'pointer',
                        fontSize: 'inherit',
                    }}
                >
                    <img src={doubleChevron} alt="Última página" style={{ width: '16px', height: '16px', filter: 'invert(100%)' }} />
                </button>
            </div>

            <div className="contenedor-tabla" style={{ fontSize }}>
                <div className="flex-tabla">
                    <div className="flex-fila header-row">
                        <div className="flex-celda" style={{ padding: paddingCell, textAlign: 'center', minWidth: minWidthDate }}>FECHA</div>
                        <div className="flex-celda" style={{ padding: paddingCell, textAlign: 'center', minWidth: minWidthOther }}>MAX</div>
                        <div className="flex-celda" style={{ padding: paddingCell, textAlign: 'center', minWidth: minWidthOther }}>MIN</div>
                        <div className="flex-celda" style={{ padding: paddingCell, textAlign: 'center', minWidth: minWidthOther }}>CIERRE</div>
                    </div>
                </div>

                <div className="flex-tabla">
                    {currentRecords.map((_, index) => (
                        <IntradayRecordsRow 
                            key={index} 
                            rowId={index}
                            data={{
                                fecha: data.dates[indexOfFirstRecord + index],
                                max: formatAccordingToType(con.MONEY, data.high[indexOfFirstRecord + index], { allowDecimals: true }), 
                                min: formatAccordingToType(con.MONEY, data.low[indexOfFirstRecord + index], { allowDecimals: true }), 
                                cierre: formatAccordingToType(con.MONEY, data.close[indexOfFirstRecord + index], { allowDecimals: true })
                            }}
                            padding={paddingCell} 
                            minWidthDate={minWidthDate} 
                            minWidthOther={minWidthOther} 
                        />
                    ))}
                </div>
            </div>

        </div>
    );
}

IntradayRecordsTable.defaultProps = {
    fixedSize: null,
};

export default IntradayRecordsTable;
