import { exportModelParameters } from "./modelParameters";
import { exportUserLineApplicationParameters } from "./userLineApplicationParameters";

import * as con from "./../../GlobalConstants"
import { setApplicationParameter } from "./applicationParameters";


// save user info
export const saveUserInformation = async (successCallBackFunction = () => true) =>
{

    setApplicationParameter(con.USER_INFORMATION_SAVED, {[con.STATUS] : con.LOADING})

    const respModelsParameters = await exportModelParameters()
    const respUserLineApplicationParameters =  await exportUserLineApplicationParameters()

    if(respModelsParameters && respUserLineApplicationParameters)
    {
        setApplicationParameter(con.USER_INFORMATION_SAVED, {[con.STATUS] : con.OK})
        successCallBackFunction()
    }
    else
    {
        setApplicationParameter(con.USER_INFORMATION_SAVED, {[con.STATUS] : con.ERROR})
    }
        


    
}