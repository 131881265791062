import { Button } from '@blueprintjs/core';
// TODO - Documentar
import React from 'react';
import { navigateToPageInNewTab } from '../../utils/navegationFunctions';
import { NavigationHook } from '../hooks/NavegationHook';


function CustomNavButton({icon, text, pageID}) {

  // Navigation
  const [currentPage, navigate] = NavigationHook();

  return(
  <div>
      <Button icon={icon} 
                    text={text}                  
                    className={`bp3-minimal customNavButton ${currentPage === pageID && "selected"}`}                  
                    onClick={(_) =>  {navigate(pageID)}}
                    onContextMenu={(event) => {event.preventDefault();navigateToPageInNewTab(pageID)}}/>
  </div>)
}

CustomNavButton.defaultProps = {
  text: ""
};

export function CustomActionNavButton({icon, text, onClick, title}) {


  return(<Button icon={icon} 
                 text={text} 
                 className={`bp3-minimal customNavButton`}                  
                 onClick={onClick}
                 title={title}/>)
}

CustomActionNavButton.defaultProps = {
  text: ""
  
};

export default CustomNavButton;
