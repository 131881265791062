// TODO - Documentar
import React from 'react'
import RiskComparisonForTwoScenarios from '../components/results/RiskComparisonForTwoScenarios'


function RiskComparison() {
    return (
        <div>
            <RiskComparisonForTwoScenarios />
            
{/*             <div className="gridBox">
                <div style={{flexBasis : "25%"}}>
                    <RiskComparisonParameters/>
                </div>
                <div style={{flexBasis : "75%"}}>
                    <RiskComparisonForSingleScenario />
                </div>

            </div> */}
           
        </div>
    )
}

export default RiskComparison
