import { Button } from '@blueprintjs/core'
// TODO - Documentar
import React, { useState } from 'react'
import { makePurchaseReport } from '../../utils/reportFunctions'


// Global constants
import * as con from '../../GlobalConstants'

// Style
import '../ComponentsGlobalStyle.css'


import { GenericElement } from '../../templates/FormElement';
import { formatDate, getToday, parseDate, minDate, maxDate, afterToday, isWeekEnd, addMonths} from '../../utils/dateFunctions';
import LoadingIconSmall from '../../templates/loading/LoadingIconSmall';
import { RefApplicationParameterHook } from '../hooks/ApplicationParametersHooks';
import { HasActivePurchases } from '../hooks/TransactionsHooks'
import { RefModelParameterHook } from '../hooks/ModelParameterHooks'

function PurchaseReport({columnLayout = false }) {


    // Application Parameters
    const purchaseReport = RefApplicationParameterHook(con.PURCHASE_REPORT)
    
    // Start date
    const [startDate, setStartDate] = useState(() => formatDate(addMonths(getToday(), -3, true)));

    // End date
    const [endDate, setEndDate] = useState(() => formatDate(getToday()));

    // Include Purchases
    const [includePurchases, setInncludePurchases] = useState(() => false);

    // Client Type
    const clientType = RefModelParameterHook(con.CLIENT_TYPE)
    
    const [purchases, setPurchases] = useState(() => clientType === con.IMPORTER ? true : false);
    const [sales, setSales] = useState(() => clientType === con.IMPORTER ? false : true);
    const [operation, setOperation] = useState(clientType === con.IMPORTER ? con.BUY : con.SELL);
    
    const changeOperation = () => {
        if (operation === con.BUY) {
            setOperation(con.SELL)
            setPurchases(false)
            setSales(true)
        } else {
            setOperation(con.BUY)
            setPurchases(true)
            setSales(false)
        }
    }

    // Has active purchases
    const hasActivePurchases = HasActivePurchases(startDate, endDate, operation, clientType) 


    return (
        <div className="columnParameterBoxTop">

            <h3>Reporte de Operaciones</h3> 

            <div className={columnLayout ? "columnParameterBox" :"smallParameterBox"}>
                <div>
                < GenericElement type={con.BOOLEAN}                                    
                                    id={'purchases'} 
                                    value={purchases} 
                                    setValue={changeOperation} 
                                    label={"Compras"}                                    
                                    /> 
                </div>
                <div style={{marginLeft:'30px' }}>  
                < GenericElement type={con.BOOLEAN}                                    
                            id={'sales'} 
                            value={sales} 
                            setValue={changeOperation} 
                            label={"Ventas"}                                    
                            /> 
                </div>
            </div>

            <div className={columnLayout ? "columnParameterBox" :"smallParameterBox"} style={{marginTop : "0px"}}>
                <div style={{marginRight : "5px"}}> 
                < GenericElement type={con.DATE}
                            name={'Fecha Inicio'}
                            id={'start_date'} 
                            shouldDisableDate={(d) => afterToday(d) || isWeekEnd(d)}
                            value={startDate} 
                            setValue={(val) => setStartDate(formatDate(minDate(parseDate(val), parseDate(endDate))))}                                                    
                            /> 
                </div>
                <div style={{marginLeft : "5px"}}> 
                < GenericElement type={con.DATE}
                                        name={'Fecha Fin'}
                                        id={'end_date'} 
                                        shouldDisableDate={(d) => afterToday(d) || isWeekEnd(d)}
                                        value={endDate} 
                                        setValue={(val) => setEndDate(formatDate(maxDate(parseDate(val), parseDate(startDate))))} 
                                        /> 
                </div>
            </div> 
            < GenericElement type={con.BOOLEAN}                                    
                                    id={'include_purchases'} 
                                    value={includePurchases} 
                                    setValue={setInncludePurchases} 
                                    label={"Incluir Detalle"}                                    
                                    />                                 

            <div>
              <Button disabled={!hasActivePurchases || purchaseReport[con.GENERATING]} 
                        onClick={() => makePurchaseReport({startDate : startDate, endDate: endDate, includePurchases : includePurchases, operation : operation})}
                        title={hasActivePurchases ? null : "No tiene operaciones entre las fechas seleccionadas"}>Generar Reporte</Button>  
            </div>
            <div style={{width : "20px", marginTop: "15px"}}>
            {
                purchaseReport[con.GENERATING]
                && <LoadingIconSmall/> 
            }
            </div>
        </div>
    )
}

export default PurchaseReport
