// TODO - Documentar
import React from 'react'
import "react-toastify/dist/ReactToastify.css";
import "./Notifications.css"
import { cssTransition, toast, ToastContainer } from 'react-toastify'
import { NOTIFICATION_TIME_LONG } from '../../GlobalConstants';


// Custom css transition
const swirl = cssTransition({
    enter: "slide-in-bottom",
    exit: "slide-out-bottom"
});

export const PlainNotification = ({message}) =>
{
    return(<div>
        {message}
    </div>)
}

export const Error = ({message}) =>
{
    return(<div>
        <h4 style={{margin : 0, padding: 0, marginBottom : "0.5vh"}}>Error</h4>
        <div style={{marginBottom : "2vh", height : "0.5vh", backgroundColor : "var(--warning-color-3)", opacity:0.7}}/>
            {message}
    </div>)
}

export const createNotification = (message, autoClose = NOTIFICATION_TIME_LONG) =>
{
    toast(<PlainNotification message={message} />, {
        position: "bottom-center",
        autoClose: autoClose,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        delay : 0, 
        transition : swirl                       
        });  
}

export const createError = (message) =>
{
    toast(<Error message={message} />, {
        position: "bottom-center",
        autoClose: false,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        delay : 0, 
        transition : swirl                       
        });  
}

function Notifications() {

    // Container
  return (
    <div>
        <ToastContainer
                position="bottom-center"
                autoClose={false}
                newestOnTop
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                limit={2}
                />
    </div>
  )
}

export default Notifications