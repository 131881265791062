// TODO - Documentar
import React, { useEffect } from 'react'
import { ForwardAssessmentSummaryTable } from '../components/forwardAssessment/ForwardAssessmentControls'
import ForwardAssessmentTable from '../components/forwardAssessment/ForwardAssessmentTable'
import { AssessedForwardCoveragesHook } from '../components/hooks/TransactionsHooks'
import { USABLE_SCREEN_SIZE } from '../GlobalConstants'
import { fetchAllForwardCurves, fetchAllIBR } from '../store/actions/market'
import SimpleFillFooter from '../templates/footer/SimpleFillFooter'
import * as con from "../GlobalConstants"

import "./MobilePages.css"

function MobileForwardAssessmentPage() {

    // Loads additional Market Data
    useEffect(() => {
        fetchAllForwardCurves()
        fetchAllIBR()
    }, [])
    

    const [assessedFwdDictionary, assessmentSummary] = AssessedForwardCoveragesHook()

    return (
        <div style={{height : USABLE_SCREEN_SIZE + "vh"}}>
            <div className='centered-component'>
                <ForwardAssessmentSummaryTable assessmentSummary={assessmentSummary} />
            </div>
            <div>
            <ForwardAssessmentTable assessedFwdDictionary={assessedFwdDictionary} includeCommands={false} includedColumns={[{ [con.ID] : con.OPENING_DATE},
                                                                                                                            { [con.ID] : con.EXPIRATION_DATE},
                                                                                                                            { [con.ID] : con.AMOUNT},                                                                                                                           
                                                                                                                            { [con.ID] : con.RATE},                   
                                                                                                                            { [con.ID] : con.COVERAGE_TYPE},
                                                                                                                            { [con.ID] : con.ASSESSMENT}] }/>         
            </div>           
            <SimpleFillFooter />   
        </div>
    )
}

export default MobileForwardAssessmentPage