// EditableRow.js
import React from 'react';
import { FaCheck, FaTimes } from 'react-icons/fa';
import Select from 'react-select';

function EditableRow({ newAlert, setNewAlert, handleAddAlert, handleCancelAdd }) {
    const options = [
        { value: 'above', label: 'Alza' },
        { value: 'below', label: 'Baja' },
    ];

    const customStyles = {
        control: (provided) => ({
            ...provided,
            backgroundColor: 'transparent',
            border: 'none',
            padding: '2px',
            color: 'white',
            textAlign: 'center',
            boxShadow: 'none',
            '&:hover': {
                borderColor: '#777',
            },
        }),
        menu: (provided) => ({
            ...provided,
            backgroundColor: '#333',
            color: 'white',
        }),
        option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isFocused ? '#555' : '#333',
            color: 'white',
            cursor: 'pointer',
        }),
        singleValue: (provided) => ({
            ...provided,
            color: 'white',
            marginLeft: '25px',
        }),
        dropdownIndicator: (provided) => ({
            ...provided,
            color: 'white',
        }),
        indicatorSeparator: (provided) => ({
            ...provided,
            backgroundColor: 'transparent',
        }),
    };

    return (
        <div className="flex-fila">
            <div className="flex-celda">
                <input 
                    type="text"
                    value={newAlert.level}
                    onChange={(e) => setNewAlert({ ...newAlert, level: e.target.value })}
                    placeholder="Nivel"
                    style={{
                        width: '100%',
                        backgroundColor: 'transparent',
                        border: 'none',
                        padding: '8px',
                        color: 'white',
                        outline: 'none',
                        textAlign: 'center',
                    }}
                />
            </div>
            <div className="flex-celda">
                <Select
                    value={options.find(option => option.value === newAlert.alert_type)}
                    onChange={(selectedOption) => setNewAlert({ ...newAlert, alert_type: selectedOption.value })}
                    options={options}
                    styles={customStyles}
                    isSearchable={false}
                    components={{ IndicatorSeparator: () => null }}
                />
            </div>
            <div className="flex-celda comandos" style={{ textAlign: 'center' }}>
                <FaCheck style={{ cursor: 'pointer', marginRight: '10px' }} onClick={handleAddAlert} />
                <FaTimes style={{ cursor: 'pointer' }} onClick={handleCancelAdd} />
            </div>
        </div>
    );
}

export default EditableRow;
