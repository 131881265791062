// TODO - Documentar
import { useSelector } from 'react-redux';

import * as con from "../../GlobalConstants"
import { setUserLineApplicationParameter } from '../../store/actions/userLineApplicationParameters';

/**
 * Hook for a single user line application parameter in the store read only
 */
export function RefUserLineApplicationParameterHook(parameterName) {

    const value = useSelector(state => state[con.STORE][con.REDUCER_USER_LINE_APPLICATION_PARAMETERS][parameterName])

    return(value);
}



/**
 * Hook for a single use line application parameter in the store read and write
 */
 export function StateUserLineApplicationParameterHook(parameterName) {

    const value = useSelector(state => state[con.STORE][con.REDUCER_USER_LINE_APPLICATION_PARAMETERS][parameterName])

    const setParameter = (newVal) => setUserLineApplicationParameter(parameterName, newVal)

    return([value, setParameter]);
}


/**
 * Hook for a single user line application parameter in the store. Only write
 */
 export function EditUserLineApplicationParameterHook(parameterName) {

    const setParameter = (newVal) => setUserLineApplicationParameter(parameterName, newVal)

    return(setParameter);
}
