// Multiple Line Chart Class (based on D3)

/**
 * Data must be passed with the following structure
 * Array of objects:
 *  - name : string with the name for the legend
 *  - barValue : numeric value for the bar
 *  - continuesLineValue : numeric value for the continuos line
 *  - categoricalLineValue
 */

 import * as d3 from "d3";

import "../Charts.css"
import * as cc from '../ChartConstants';
import { GenericChartWithLegend } from "./GenericChartWithLegend";

const DEFAULT_PARAMETERS = {[cc.MARGIN] : { [cc.TOP]: 40, [cc.RIGHT]: 60, [cc.BOTTOM]: 45, [cc.LEFT]: 75 },
                            [cc.NORMAL_WIDTH] : 2.5,
                            [cc.ENFASIS_WIDTH] : 7.5,
                            [cc.CONTINUOS_LINE_NAME] : "",
                            [cc.CATEGORICAL_LINE_NAME] : "",
                            [cc.INCLUDE_CATEGORCIAL_LINE] : true,
                            [cc.INCLUDE_CONTINOUS_LINE] : true,
                            [cc.CONTINUOS_LINE_COLOR] : "var(--color-2)",
                            [cc.CATEGORICAL_LINE_COLOR] : "var(--color-1)",
                            [cc.LEGEND_MARGIN_FROM_CHART] : 0.02, // Percentage
                            [cc.CATEGORICAL_LABEL_GENERATOR_FUNCTION] : (val) => val
                        }


export class CategoricalBarAndLineChart extends GenericChartWithLegend
 {

    constructor(parameters, objectReference){        
        super({...DEFAULT_PARAMETERS, ...parameters}, objectReference)
    }


    // Method for computing axis values
    computeAxis(data)
    {
        // Axis
        this.bars_min_y = d3.min([...data.map(ob => ob[cc.BAR_VALUE]), 0 ])
        this.bars_max_y = d3.max([...data.map(ob => ob[cc.BAR_VALUE]),0])

        this.lines_min_y = Infinity
        this.lines_max_y = -1*Infinity

        if(this.parameters[cc.INCLUDE_CONTINOUS_LINE])
        {
            this.lines_min_y = d3.min([this.lines_min_y, ...data.map(ob => ob[cc.CONTINUES_LINE_VALUE])])
            this.lines_max_y = d3.max([this.lines_max_y, ...data.map(ob => ob[cc.CONTINUES_LINE_VALUE])])
        }
        if(this.parameters[cc.INCLUDE_CATEGORCIAL_LINE])
        {
            this.lines_min_y = d3.min([this.lines_min_y, ...data.map(ob => ob[cc.CATEGORCIAL_LINE_VALUE])])
            this.lines_max_y = d3.max([this.lines_max_y, ...data.map(ob => ob[cc.CATEGORCIAL_LINE_VALUE])])
        }

        // Checks if are the same
        if(this.bars_min_y === this.bars_max_y)
        {
            this.bars_min_y -= Math.abs(this.bars_min_y*0.5)
            this.bars_max_y += Math.abs(this.bars_max_y*0.5)
        }

        if(this.lines_min_y === this.lines_max_y)
        {
            this.lines_min_y -= Math.abs(this.lines_min_y*0.5)
            this.lines_max_y += Math.abs(this.lines_max_y*0.5)
        }
        

        if(this.parameters[cc.SECOND_Y_AXIS] === true)
        {
            // First axis
            this.final_padding = (this.bars_max_y - this.bars_min_y)*this.parameters[cc.Y_PADDING]
            this.yBarScale = d3.scaleLinear().domain([this.bars_min_y - this.final_padding, this.bars_max_y + this.final_padding]).range([this.height, 0])
            
            // Second axis
            //this.final_padding = (this.lines_max_y - this.lines_min_y)*this.parameters[cc.Y_PADDING]
            this.yLineScale = d3.scaleLinear().domain([this.lines_min_y - this.final_padding, this.lines_max_y + this.final_padding]).range([this.height, 0])
            

        }
        else
        {
            // Single axis
            this.bars_min_y = d3.min([this.bars_min_y, this.lines_min_y])
            this.bars_max_y = d3.max([this.bars_max_y, this.lines_max_y])
            this.final_padding = (this.bars_max_y - this.bars_min_y)*this.parameters[cc.Y_PADDING]
            this.yBarScale = d3.scaleLinear().domain([this.bars_min_y - this.final_padding, this.bars_max_y + this.final_padding]).range([this.height, 0])
            
            // Replicates
            this.yLineScale = this.yBarScale
        }

    }

    build(initialData)
    {
        if(!this.initialized)
            this.initialize()  
                
        let self = this

        this.data = initialData

        // Legend Data
        let legendData = initialData.map(ob =>{return({[cc.NAME]: ob[cc.NAME]})})
        
        this.numBars = initialData.length

        // Updates the Color pallette
        this.colorScheme = d3.scaleOrdinal().domain([0,this.numBars])
                                            .range(d3.schemeSet1);

        this.barWidth = 0.75*(this.width/this.numBars)
        this.discreteLineBarWidth = this.barWidth*1.2


        this.xScale = d3.scaleBand().range([0, this.width]).padding(0.1)
                                        .domain([...initialData.keys()]);



        this.computeAxis(initialData)


        // Adds Axis (Skips X)
        this.mainComponent.append('g')
                        .attr('id', 'yBarAxis')
                        .attr('class', 'axis axis--y')
                        .call(d3.axisLeft(this.yBarScale));                
                
        if(this.parameters[cc.SECOND_Y_AXIS] === true)
        {
            this.mainComponent.append('g')
                            .attr('id', 'yLineAxis')
                            .attr('class', 'axis axis--y')
                            .attr("transform", `translate(${this.width},0)`)
                            .call(d3.axisRight(this.yLineScale));

        }

        // Adds bars with initial value
        this.mainComponent.selectAll(".bar")
            .data(initialData.map(ob => ob[cc.BAR_VALUE]))
            .enter()
            .append("rect")
            .attr("class", "bar")
            .attr("fill", (_,i) => this.colorScheme(i))
            .attr("x", (_,i) => this.xScale(i))
            .attr("width", this.barWidth)
            .attr("height", (val) => Math.abs(this.yBarScale(0) - this.yBarScale(val)))
            .attr("y", (val) => this.yBarScale(Math.max(0,val)))

        this.continuosLines = null
        if(this.parameters[cc.INCLUDE_CONTINOUS_LINE])
        {
            
            let vals = initialData.map(ob => ob[cc.CONTINUES_LINE_VALUE])
            let totalElements = vals.length
            vals.push(vals[totalElements-1])

            this.continuosLines = this.mainComponent.append("path")
                                    .datum(vals)  
                                    .attr("id", "line")                                                   
                                    .attr("fill", "none")
                                    .attr("stroke", this.parameters[cc.CONTINUOS_LINE_COLOR])
                                    .attr("stroke-width", this.parameters[cc.NORMAL_WIDTH])
                                    .attr("d", d3.line()
                                        .x((_,i) =>  i === 0 ? 0 : i < totalElements ? self.xScale(i) : this.width)
                                        .y((val) => self.yLineScale(val))                        
                                        ) 


            // Adds to legend
            legendData.push({[cc.NAME] : this.parameters[cc.CONTINUOS_LINE_NAME]})
        }

        if(this.parameters[cc.INCLUDE_CATEGORCIAL_LINE])
        {
            this.mainComponent.selectAll(".discreteLine")
                                .data(initialData.map(ob => ob[cc.CATEGORCIAL_LINE_VALUE]))
                                .enter()
                                .append("rect")
                                .attr("class", "discreteLine")
                                .attr("fill", this.parameters[cc.CATEGORICAL_LINE_COLOR])
                                .attr("x", (_,i) => this.xScale(i) + this.barWidth/2 -  this.discreteLineBarWidth/2)
                                .attr("width", this.discreteLineBarWidth)
                                .attr("height", this.parameters[cc.NORMAL_WIDTH])
                                .attr("y", (val) => this.yLineScale(val) - this.parameters[cc.NORMAL_WIDTH]/2)
            
            this.mainComponent.selectAll(".discretePoint")
                                .data(initialData.map(ob => ob[cc.CATEGORCIAL_LINE_VALUE]))
                                .enter()
                                .append("circle")
                                .attr("class", "discretePoint")
                                .attr("fill", this.parameters[cc.CATEGORICAL_LINE_COLOR])
                                .attr("r", 2.2*this.parameters[cc.NORMAL_WIDTH])
                                .attr("cx", (_,i) => this.xScale(i) + this.barWidth/2)
                                .attr("cy", (val) => this.yLineScale(val))

            this.mainComponent.selectAll(".discreteText")
                                .data(initialData.map(ob => ob[cc.CATEGORCIAL_LINE_VALUE]))
                                .enter()
                                .append("text")
                                .attr("class", "discreteText")
                                .style("font-size", this.parameters[cc.Y_LABEL_FONT_SIZE] + "px")
                                .style('fill', this.parameters[cc.CATEGORICAL_LINE_COLOR])
                                .attr("x", (_,i) => this.xScale(i) + this.barWidth/2 - this.parameters[cc.Y_LABEL_FONT_SIZE]*1.8)
                                .attr("y", (val, i) => this.yLineScale(val) + this.parameters[cc.Y_LABEL_FONT_SIZE]/2 - Math.sign(initialData[i][cc.BAR_VALUE])*this.parameters[cc.Y_LABEL_FONT_SIZE])   
                                .text((val) => this.parameters[cc.CATEGORICAL_LABEL_GENERATOR_FUNCTION](val));                               

        // Adds to legend
        legendData.push({[cc.NAME] : this.parameters[cc.CATEGORICAL_LINE_NAME]})

        }
        

        // Adds second Y Label
        if(this.parameters[cc.SECOND_Y_AXIS] === true && this.parameters[cc.SECOND_Y_LABEL] !== "")
        {
            this.svgEl.append("text")
                .attr("transform", "rotate(-90)")
                .style("font-size", this.parameters[cc.Y_LABEL_FONT_SIZE] + "px")
                .style('fill', 'var(--text-color)')
                //.attr("x", this.width  - 1.5*this.parameters[cc.Y_LABEL_FONT_SIZE] )
                //.attr("y",this.chart_middle_height)
                .attr("x", -1*this.chart_middle_height )
                .attr("y", this.svgWidth - 3.5*this.parameters[cc.Y_LABEL_FONT_SIZE])
                .attr("dy", "3em")
                .style("text-anchor", "middle")
                .text(this.parameters[cc.SECOND_Y_LABEL]);  
        }          
        
        

        // builds the legend
        this.constructLegend(legendData)
    
    }

    
    // Overwrite
    // ---------------
    // Function that updates the chart
    updateData(newData){

        if(newData.length !== this.data.length)
        {
            this.initialize()
            this.build(newData)
        }

        super.updateData(newData)

        let self = this

        this.computeAxis(newData)

        // Adds Axis (Skips X)
        this.mainComponent.select("#yBarAxis")
                            .transition()
                            .duration(this.parameters[cc.ANIMATION_TIME])
                            .call(d3.axisLeft(this.yBarScale));

            
        if(this.parameters[cc.SECOND_Y_AXIS] === true)
        {
            this.mainComponent.select("#yLineAxis")
                            .transition()
                            .duration(this.parameters[cc.ANIMATION_TIME])
                            .call(d3.axisRight(this.yLineScale));

        }

        this.mainComponent.selectAll(".bar")
                    .data(newData.map(ob => ob[cc.BAR_VALUE]))
                    .transition()
                    .duration(this.parameters[cc.DATA_CHANGE_TRANSITION_TIME])
                    .attr("height", (val) => Math.abs(this.yBarScale(0) - this.yBarScale(val)))
                    .attr("y", (val) => this.yBarScale(Math.max(0,val)))

        if(this.parameters[cc.INCLUDE_CONTINOUS_LINE]) 
        {

            let vals = newData.map(ob => ob[cc.CONTINUES_LINE_VALUE])
            let totalElements = vals.length
            vals.push(vals[totalElements-1])


            this.continuosLines.datum(vals)
                .transition()
                .duration(this.parameters[cc.DATA_CHANGE_TRANSITION_TIME])
                .attr("d", d3.line()
                    .x((_,i) =>  i === 0 ? 0 : i < totalElements ?  self.xScale(i) : this.width)
                    .y((val) => self.yLineScale(val))
                    )  
        }

        if(this.parameters[cc.INCLUDE_CATEGORCIAL_LINE])
        {
            
            this.mainComponent.selectAll(".discreteLine")
                                .data(newData.map(ob => ob[cc.CATEGORCIAL_LINE_VALUE]))
                                .transition()
                                .duration(this.parameters[cc.DATA_CHANGE_TRANSITION_TIME])
                                .attr("y", (val) => this.yLineScale(val) - this.parameters[cc.NORMAL_WIDTH]/2)

            this.mainComponent.selectAll(".discretePoint")
                                .data(newData.map(ob => ob[cc.CATEGORCIAL_LINE_VALUE]))
                                .transition()
                                .duration(this.parameters[cc.DATA_CHANGE_TRANSITION_TIME])
                                .attr("cy", (val) => this.yLineScale(val))

            this.mainComponent.selectAll(".discreteText")
                                .data(newData.map(ob => ob[cc.CATEGORCIAL_LINE_VALUE]))
                                .transition()
                                .duration(this.parameters[cc.DATA_CHANGE_TRANSITION_TIME])
                                .text((val) => this.parameters[cc.CATEGORICAL_LABEL_GENERATOR_FUNCTION](val))
                                .attr("y", (val,i) => this.yLineScale(val) + this.parameters[cc.Y_LABEL_FONT_SIZE]/3 - Math.sign(newData[i][cc.BAR_VALUE])*this.parameters[cc.Y_LABEL_FONT_SIZE]*1.2)                    
            
        }
        
    }

    // Overrides to make the legend match the plot
    getColorScheme(numElements)
    {
        const extras = []
        let numBars = numElements

        if(this.parameters[cc.INCLUDE_CONTINOUS_LINE]) 
        {
            numBars--;
            extras.push(this.parameters[cc.CONTINUOS_LINE_COLOR])

        }

        if(this.parameters[cc.INCLUDE_CATEGORCIAL_LINE])
        {
            numBars--;
            extras.push(this.parameters[cc.CATEGORICAL_LINE_COLOR])
        }

        const cs = super.getColorScheme(numElements)

        // Response
        const resp = (i) =>{
            if(i < numBars)
                return(cs(i))
            i -= numBars
            return(extras[i])
        }


        return(resp)
    }



 }
 

  