import { Button, FormGroup, InputGroup } from '@blueprintjs/core'
// TODO - Documentar
import React, { useEffect, useState } from 'react'
import LockEyePasswordButton from '../../templates/password/LockEyePasswordButton'
import * as con from "../../GlobalConstants"
import { setApplicationParameter } from '../../store/actions/applicationParameters'
import { checkPassword } from '../../store/actions/authentication'

import { RefNestedApplicationParameterHook } from '../hooks/ApplicationParametersHooks'

function TransactionsDeleteDialog({title, deleteAction, cancelAction}) {

  const passwordStatus = RefNestedApplicationParameterHook(con.CHECK_PASSWORD, con.STATUS)

    // To show/hide password
    const [password, setPassword] = useState(() => "")
    const [showPassword, setShowPassword] = useState(() => false)


    useEffect(() => {
    
      return () => {
        setApplicationParameter(con.CHECK_PASSWORD, {[con.STATUS] : con.NOT_STARTED})
      }
    }, [])
    

  return (
    <div>

        <h3>{title}</h3>
        <div className="columnParameterBox">
        {passwordStatus === con.OK? <h4>Contraseña Aceptada</h4> :
            <div className="confirmCancelParameterBox">             
                <FormGroup label="Ingrese su Contraseña">                    
                    <InputGroup id="password"   
                        className="input-editing"
                        type={ showPassword ? "text" :"password"}             
                        value={password} 
                        rightElement={<LockEyePasswordButton showPassword={showPassword} setShowPassword={setShowPassword} />}
                        onChange={(e) => {setPassword(e.target.value)}} />
                </FormGroup>
                <button onClick={() => checkPassword(password)} disabled={passwordStatus === con.LOADING}>
                            Revisar    
                </button>           
            </div>
            }  
            <h5>{passwordStatus === con.WRONG_CREDENTIALS ? "Contraseña Invalida" : ""}</h5>
              <div className="confirmCancelParameterBox">                  
                <Button text="Eliminar" disabled={passwordStatus !== con.OK} icon="tick" onClick={(e) => deleteAction()} />
                <Button text="Cancelar"  icon="cross" onClick={(e) => cancelAction()} />
              </div>
        </div>

    </div>
  )
}

export default TransactionsDeleteDialog