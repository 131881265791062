
// Different generic form elements
// TODO - Documentar
import React, { useEffect, useState } from 'react';



// Blueprint

import { Checkbox, FormGroup, InputGroup, Intent, NumericInput, TextArea } from "@blueprintjs/core";


// NumberFormat
import NumberFormat from "react-number-format";

// Fechas
import 'react-day-picker/lib/style.css';

// Custom Modules
import { CustomDateInput, formatDate, parseDate} from "../utils/dateFunctions"
import * as con from "../GlobalConstants"
 

import "./Templates.css"
import "./tables/Table.css"
import { round } from '../GlobalFunctions';
import { parseMoney } from '../utils/moneyFunctions';


/**
 * Component in charge of a generic form element across the application
 */
export const GenericElement = (parameters) => {
    
    const {type, id, ...otherParameters} = parameters

    return (
        <div id={id} className="form-element">
        {
            (type === con.TEXT) ?
                <TextElement {...otherParameters}/>
            : (type === con.NUMERIC) ?
                <NumericElement {...otherParameters}/>
            : (type === con.DATE) ? 
                <DateElement {...otherParameters}/>
            : (type === con.MONEY) ?
                <MoneyElement {...otherParameters}/>
            : (type === con.PERCENTAGE) ?
                <PercentageElement {...otherParameters}/>
            : (type === con.BOOLEAN) ? 
                <BooleanElement {...otherParameters}/>
            : (type === con.MULTIPLE_SELECTION) ? 
                <MultipleChoiceElement {...otherParameters}/>
            : <TextElement {...otherParameters}/>
        }
    </div>)


}


// -------------------------
// --- Element Types---
// -------------------------


/**
 * Element in charge of text input
 */
 export const TextElement = ({name, value, setValue, disabled, rightElement}) => {


    return (
        
        <FormGroup label={name}>
            <InputGroup id="text-input"
                onFocus={e => e.target.select()}
                className="input-editing"
                value={value} 
                onChange={(e) => {setValue(e.target.value)}}
                disabled={disabled}
                rightElement={rightElement} />
        </FormGroup> 
    )
}

/**
 * Element in charge of large text input
 */
 export const TextAreaElement = ({name, value, setValue, disabled}) => {


    return (
        
        <FormGroup label={name} style={{width : "98%"}}>
            <TextArea growVertically={false}
                        fill={true}
                        small={true}
                        intent={Intent.PRIMARY}
                        onChange={(e) => {setValue(e.target.value)}}
                        value={value}
                        disabled={disabled}/>
        </FormGroup> 
    )
}

/**
 * Element in charge of numeric input
 */
 export const NumericElement = ({name, value, setValue, disabled, min, max, heavyEffect}) => {

    const [localValue, setLocalValue] = useState(() => value)

    useEffect(() => {
      setLocalValue(value)
    }, [value])

    
    

    return (
            <FormGroup  label={name}>
                <NumericInput   value={localValue} 
                                onFocus={e => e.target.select()}
                                className="input-editing"
                                placeholder={name}
                                min={min}
                                max={max}
                                onBlur={(ob) => heavyEffect ? setValue(parseInt(ob.target.value)) : null} 
                                onValueChange={(val) => heavyEffect ? setLocalValue(val) : setValue(val)}
                                onButtonClick={(val) => setValue(val)}
                                disabled={disabled}
                                 />
            </FormGroup> 
    )
}

NumericElement.defaultProps = {
    min: 0,
    heavyEffect : true
  };


/**
 * Element in charge of date input
 */
 export const DateElement = ({name, value, setValue, ...params}) => {

    
    const onChangeFun = (fecha) => {
        
        return(setValue(formatDate(fecha)))
    }

    return (        
            <FormGroup  label={name}>
                    <CustomDateInput
                        className="input-editing-date"
                        formatDate={formatDate}
                        onChange={onChangeFun}
                        parseDate={parseDate}
                        value={parseDate(value)}                      
                        {...params}
                    />                 
            </FormGroup> 
    )
}


/**
 * Element in charge of money input
 */
 export const MoneyElement = ({name, value, setValue, currency, maxValue, disabled, inline, allowDecimals, onFocus}) => {

    value = (maxValue) ? Math.min(maxValue, value) : value
    
    let sufijo = ""
    
    if(currency === con.MONEY_COP)
        sufijo = con.SUFFIX_COLOMBIAN_PESOS
    if(currency === con.MONEY_USD)
        sufijo = con.SUFFIX_DOLLARS
    
    return (
        <div>
        {disabled ? 
        <FormGroup label={name} inline={inline} style={{width : "var(--input-width)"}}>
            <NumberFormat value={value} 
                         thousandSeparator={true} 
                         displayType={'text'} 
                         decimalScale={allowDecimals ? 2 : 0} 
                         fixedDecimalScale={allowDecimals === true} 
                         suffix={sufijo} />
        </FormGroup>
        : <FormGroup label={name} inline={inline}>
                <NumberFormat thousandSeparator={true} 
                                className="input-editing"                               
                              suffix={sufijo}  
                              onBlur={(ob) => (ob.target.value === "") ? setValue(0) : (maxValue) ? setValue(Math.min(maxValue,parseMoney(ob.target.value))) : setValue(parseMoney(ob.target.value))}                    
                              decimalScale={allowDecimals ? 2 : 0}
                              disabled={disabled}
                              onFocus={e => {onFocus(); e.target.select(); }} 
                              onClick={e => e.target.select()} 
                              value={value}
                              />
            </FormGroup> 
        }
        </div>
    )
}


MoneyElement.defaultProps = {
    onFocus : () => true
}



/**
 * Element in charge of percentage input
 */
 export const PercentageElement = ({name, value, setValue, min, max, disabled, rightElement,  heavyEffect}) => {


    const [localValue, setLocalValue] = useState(() => value)

    useEffect(() => {
      setLocalValue(value)
    }, [value])


    return (

        <FormGroup label={name}>
            <NumericInput leftIcon="percentage"
                        className="input-editing"
                        onFocus={e => e.target.select()}
                        value={round(100*localValue,2)} 
                        placeholder={name}
                        min={min}
                        max={max}
                        onBlur={(ob) => heavyEffect ? setValue(parseFloat(ob.target.value/100)) : null} 
                        onValueChange={(val) => heavyEffect ? setLocalValue(val/100) : setValue(val/100)}
                        onButtonClick={(val) => setValue(val/100)}
                        disabled={disabled}
                        rightElement={rightElement} />
        </FormGroup> 

    )
}



NumericElement.defaultProps = {
min: 0,
heavyEffect : true
};



PercentageElement.defaultProps = {
    min: 0,
    max : 100,
    heavyEffect : true
  };


/**
 * Element in charge of multiple choice input
 */
 export const MultipleChoiceElement = ({name, value, setValue, values, valueLabels, disabled, inline }) => {

    return (
                
                <FormGroup  label={name} inline={inline}>
                    <div className="bp3-html-select">
                        <select className="input-editing" disabled={disabled}
                                        onChange={(ob) => setValue(ob.target.value)}
                                        value={value}>
                                        {
                                            values.map((_, i) => 
                                            <option key={i} value={values[i]}>{valueLabels[i]}</option>)
                                        }
                                        
                        </select>
                        <span className="bp3-icon bp3-icon-double-caret-vertical"></span>
                    </div>
                </FormGroup> 
               
    )
}


/**
 * Element in charge of Boolean input
 */
 export const BooleanElement = ({name, value, setValue, label, disabled}) => {

    return (        
            <FormGroup label={name}>
                <Checkbox checked={value} label={label} onChange={(ob) =>                     
                        setValue(!value)} disabled={disabled}/>
            </FormGroup> 
    )
}