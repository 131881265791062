import { Button, Menu, MenuItem, Popover, Position } from '@blueprintjs/core';
// TODO - Documentar
import React from 'react';
import { navigateToPageInNewTab } from '../../utils/navegationFunctions';
import { NavigationHook } from '../hooks/NavegationHook';

import "./Nav.css"


function CustomPopOverMenu({menuIcon, menuText, linkTexts, linkPages}) {

  // Navegation
  const [currentPage, navigate] = NavigationHook();
  

  return(    
          <Popover content={<Menu >
                    {
                      linkTexts.map((lt,i) => 
                        <MenuItem key={i} text={lt} onClick={() => {navigate(linkPages[i])}} onContextMenu={(event) => {event.preventDefault();navigateToPageInNewTab(linkPages[i])}}/>
                      )
                    }
                </Menu>} position={Position.RIGHT_TOP}>
                <Button icon={menuIcon} 
                        text={menuText} 
                        className={`bp3-minimal customNavButton ${linkPages.includes(currentPage) && "selected"}`} />
          </Popover> )
}

export default CustomPopOverMenu;


