// Exposures
// TODO - Documentar
import React, { useEffect, useState } from 'react'
import * as con from "../../GlobalConstants"


// Style
import "../ComponentsGlobalStyle.css"
import { TransactionTable } from '../transactionTable/TransactionTable'
import TransactionTableVisualizer from './TransactionTableVisualizer'
import { SpotCoveragesHook } from '../hooks/TransactionsHooks'
import { CoverageSPOT, getTransactionsAnnotationFunction } from '../../store/actions/transactions'
import { RefModelParameterHook } from '../hooks/ModelParameterHooks'
import { allSpotCoverageColumns, selectedSpotCoverageColumns } from '../../utils/transactionFunctions'
import { RefApplicationParameterHook } from '../hooks/ApplicationParametersHooks'


function TableCoveragesSpot() {

  
  // Transaction
  const trans = SpotCoveragesHook()  

  // Client Type
  const clientType = RefModelParameterHook(con.CLIENT_TYPE)

  const searchFun = RefApplicationParameterHook(con.SEARCH_FUN)

  // Columns
  const [columns, setColumns] = useState(() => selectedSpotCoverageColumns.map(col =>  allSpotCoverageColumns[col[con.ID]]))

  useEffect(() => {
    
    let colsTemp = selectedSpotCoverageColumns.map(col =>  allSpotCoverageColumns[col[con.ID]])

    let openDate = colsTemp.find((col) => col[con.ID] === con.OPENING_DATE) 
    openDate[con.NAME] = `Fecha ${clientType === con.IMPORTER ? "Compra" : "Monetización"}`

    let rate = colsTemp.find((col) => col[con.ID] === con.RATE) 
    rate[con.NAME] = `Tasa ${clientType === con.IMPORTER ? "Compra" : "Monetización"}`

    setColumns(colsTemp)

  }, [clientType])
  
 
  return (
        <TransactionTableVisualizer transactionType={con.COVERAGES_SPOT}>           
                    <TransactionTable transactionType={con.COVERAGES_SPOT}
                            columnArray={columns} 
                            dataDictionary={trans} 
                            setRow={(id, vals) => CoverageSPOT.set(id, vals)} 
                            addRow={(vals) => CoverageSPOT.add(vals)}
                            deleteRow={(id) => CoverageSPOT.delete(id)}
                            getAnnotations={getTransactionsAnnotationFunction(con.COVERAGES_SPOT)}
                            searchFunction={searchFun}/>
        </TransactionTableVisualizer>        
  )
}

export default TableCoveragesSpot