// Component for handeling the interaction with bands

// Component for All parameters
// TODO - Documentar
import React from 'react';

// Global constants
import * as con from "../../GlobalConstants"


// Style
import "../../templates/Templates.css"
import "../../templates/tables/Table.css"
import "../ComponentsGlobalStyle.css"

import { translateBands } from '../../utils/translateFunctions';
import { StaticTable } from '../../templates/tables/StaticTable';
import { BandSummaryHook } from '../hooks/BandsHooks';


const COLUMNS = con.BANDS.map(col => {return({

    [con.ID] : col,
    [con.NAME] : translateBands(col),
    [con.TYPE] : con.PERCENTAGE

})})

const CONSTANT_ID = 1


function BandsSummaryTable({selectedBand}) {


    const summaryData = BandSummaryHook()

    return (
    <div> 
        <StaticTable columnArray={COLUMNS} dataDictionary={{ [CONSTANT_ID] : summaryData}} selectedColumn={selectedBand}/>
    </div>
    )
}



export default BandsSummaryTable
