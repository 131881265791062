import React from 'react'
import * as con from "../../GlobalConstants"
import { AssessedForwardCoveragesHook } from '../hooks/TransactionsHooks'
import { afterToday, isWeekEnd, parseDate } from '../../utils/dateFunctions';
import { GenericElement } from '../../templates/FormElement';
import { StateApplicationParameterHook } from '../hooks/ApplicationParametersHooks';
import { Checkbox } from '@blueprintjs/core'
import { ForwardAssessmentFilter, ForwardAssessmentRate } from '../forwardAssessment/ForwardAssessmentControls';
import ForwardAssessmentDownload from '../forwardAssessment/ForwardAssessmentDownload';


function ForwardAssessmentReport() {

  const [showExpiredCoverages, setShowExpiredCoverages] = StateApplicationParameterHook(con.FORWARD_ASSESSMENT_SHOW_EXPIRED_COVERAGES)
    // Reference Date
    const [referenceDate, setReferenceDate] = StateApplicationParameterHook(con.FORWARD_ASSESSMENT_REFERENCE_DATE)
    const [startFwdAssessmentDate, setStartFwdAssessmentDate] = StateApplicationParameterHook(con.FORWARD_ASSESSMENT_START_DATE)


    // Assessment
    const [assessedFwdDictionary, assessmentSummary] = AssessedForwardCoveragesHook()



  return (
      <div>
            <h3>Valoración de Forwards</h3>

            <div style={{marginRight : "5px"}}> 
                {showExpiredCoverages && <GenericElement type={con.DATE}
                            name={'Fecha Inicio de Valoración'}
                            id={'startFwdAssessmentDate'} 
                            shouldDisableDate={(d) => afterToday(d) || isWeekEnd(d)}
                            value={startFwdAssessmentDate} 
                            setValue={(val) => setStartFwdAssessmentDate(parseDate(val))}                                                    
                            />} 
                </div>
                <div style={{marginLeft : "5px"}}> 
                < GenericElement type={con.DATE}
                                        name={'Fecha de Valoración'}
                                        id={'end_date'} 
                                        shouldDisableDate={(d) => afterToday(d) || isWeekEnd(d)}
                                        value={referenceDate} 
                                        setValue={(val) => setReferenceDate(parseDate(val))} 
                                        /> 
                </div>

                <div>            
                  <Checkbox checked={showExpiredCoverages} label="Incluir Coberturas Vencidas" onChange={() => setShowExpiredCoverages(!showExpiredCoverages)}/>
                </div>

                <ForwardAssessmentRate/>
                <ForwardAssessmentFilter/>
              <ForwardAssessmentDownload assessmentSummary={assessmentSummary} assessedFwdDictionary={assessedFwdDictionary}/>
      </div>
  )

}

export default ForwardAssessmentReport