import { Button, Radio, RadioGroup } from '@blueprintjs/core'
// TODO - Documentar
import React, { useCallback, useEffect, useState } from 'react'
import { GenericElement } from '../../templates/FormElement'
import { getDefaultValue } from '../../GlobalFunctions'
import { EditApplicationParameterHook, RefApplicationParameterHook } from '../hooks/ApplicationParametersHooks'
import * as con from "../../GlobalConstants"
import { getSearchCompareFunction } from '../../store/actions/transactions'


// TODO
// Agregar los aradio button apara el tipo de busqueda
function Search({searchOptions}) {

  const [selectedTransactions] = RefApplicationParameterHook(con.SELECTED_TRANSACTIONS)
  const [initialSelectedTransactions, setInitialSelectedTransactions] = useState(selectedTransactions);
  
  const setSearchFunction =  EditApplicationParameterHook(con.SEARCH_FUN)

  // Search column object
  const [searchColumnObject, setSearchColumnObject] = useState(() => searchOptions.length > 0 ? searchOptions[0] : null)

  // local value
  const [value, setValue] = useState(() => searchColumnObject[con.DEFAULT_VALUE] !== undefined ? searchColumnObject[con.DEFAULT_VALUE] : getDefaultValue(searchColumnObject[con.ID]))



  useEffect(() => {
    // Reset Value
    setValue(searchColumnObject[con.DEFAULT_VALUE] !== undefined ? searchColumnObject[con.DEFAULT_VALUE] : getDefaultValue(searchColumnObject[con.ID]))
  
  }, [searchColumnObject])
  
  
  // Search
  const searchAction = () =>
  {
      setSearchFunction(getSearchCompareFunction(searchColumnObject[con.ID], searchColumnObject[con.TYPE], value))
  }

  // Clear Action
  const clearAction = useCallback(() => {
    setSearchFunction(con.DEFAULT_SEARCH_FUNCTION)
    setValue(searchColumnObject[con.DEFAULT_VALUE] !== undefined ? searchColumnObject[con.DEFAULT_VALUE] : getDefaultValue(searchColumnObject[con.ID]))
  
  }, [searchColumnObject, setSearchFunction, setValue]);
  

  useEffect(() => {
    // Reset Value if selectedTransactions changed
    if (selectedTransactions !== initialSelectedTransactions) {
      setSearchFunction(con.DEFAULT_SEARCH_FUNCTION);
      setValue(searchColumnObject[con.DEFAULT_VALUE] !== undefined ? searchColumnObject[con.DEFAULT_VALUE] : getDefaultValue(searchColumnObject[con.ID]));
      setInitialSelectedTransactions(selectedTransactions);
    }
  }, [selectedTransactions, initialSelectedTransactions, searchColumnObject, setSearchFunction]);

  

  return (
    <div className="columnParameterBox compact">  
      <GenericElement type={searchColumnObject.type}
                      value={value}
                      setValue={setValue}
                      
                        {...searchColumnObject}
                        name={null} />
      <div className="compact">
        <Button                
                icon={"cross"}                
                minimal={true}
                onClick={() => clearAction()}/>   
        <Button                
                icon={"search"}                
                minimal={true}
                onClick={() => searchAction()}/>   
      </div>               
    { searchOptions.length > 0 &&
    <div className="compact" style={{textAlign : "left"}}>  
      <RadioGroup              
                  onChange={(e) => setSearchColumnObject(searchOptions.filter(col => col[con.ID] === e.currentTarget.value)[0])}
                  selectedValue={searchColumnObject[con.ID]}
                  inline={false}>
                    { searchOptions.map((col) => <Radio key={col[con.ID]} label={col[con.NAME]} value={col[con.ID]} />)}
              </RadioGroup> 
    </div>
    }
    </div>  
  )
}



export default Search