// TODO - Documentar
import React from 'react'
import { MarketOverview} from 'react-ts-tradingview-widgets'

import "./TradingViewComponents.css"

const TABS = [
    {
      "title": "Monedas Emergentes",
      "symbols": [
        {
          "s": "FX_IDC:USDCOP",
          "d": "USDCOP"
        },
        {
            "s": "FX_IDC:USDBRL",
            "d": "USDBRL"
          },
          {
            "s": "FX_IDC:USDCLP",
            "d": "USDCLP"
          },
          {
            "s": "FX_IDC:USDMXN",
            "d": "USDMXN"
          },
          {
            "s": "FX_IDC:USDCNY",
            "d": "USDCNY"
          },
          {
            "s": "FX_IDC:USDRUB",
            "d": "USDRUB"
          },
          {
            "s": "FX_IDC:USDTRY",
            "d": "USDTRY"
          },
          {
            "s": "AMEX:CEW",            
          },
          {
            "s": "FX_IDC:USDCOP/AMEX:CEW",
            "d": "USDCOP/CEW"
          },
          {
            "s": "FX_IDC:USDCOP/USDCLP",
            "d": "COP/CLP"
          },
          {
            "s": "FX_IDC:USDCOP/USDBRL",
            "d": "COP/BRL"
          },
          {
            "s": "FX_IDC:USDCOP/USDMXN",
            "d": "COP/MXN"
          },
          {
            "s": "FX_IDC:EURCOP",
            "d": "EURCOP"
          },
        
      ],
      "originalTitle": "Otra"
    },
    {
      "title": "Monedas Desarroladas",
      "symbols": [
        {
          "s": "TVC:DXY",
        },
        {
            "s": "FX_IDC:USDEUR",
            "d": "USDEUR"
          },        
          {
            "s": "FX:USDJPY",
            "d": "USDJPY"
          },
          {
            "s": "FX_IDC:USDAUD",
            "d": "USDAUD"
          },
          {
            "s": "FX_IDC:USDCAD",
            "d": "USDCAD"
          },
          {
            "s": "FX_IDC:USDGBP",
            "d": "USDGBP"
          },
      ],
      "originalTitle": "Monedas Desarrolladas"
    },
    {
      "title": "Comodities",
      "symbols": [
        {
          "s": "NYMEX:BZ1!",
          
        },
        {
          "s": "COMEX:GC1!",
        },
        {
            "s": "COMEX:HG1!",
        },
        {
            "s": "COMEX:GC1!",
        },
        {
          "s":"MCX:ZINC1!/FX_IDC:USDINR*1000"
        }
      ],
      "originalTitle": "Comodities"
    },
    {
      "title": "Acciones",
      "symbols": [
        {
          "s": "CURRENCYCOM:US500"
        },
        {
          "s": "TVC:DJI"
        },
        {
          "s": "BVC:ICOLCAP"
        },
        {
          "s": "BMFBOVESPA:IBOV"
        },
        {
          "s": "CURRENCYCOM:UK100"
        },
        {
          "s": "TVC:NI225"
        }
      ],
      "originalTitle": "Acciones"
    },
    {
        "title": "Bonos",
        "symbols": [
          {
            "s": "TVC:US10"
          },
          {
            "s": "TVC:US02Y"
          },
          {
            "s": "TVC:US05Y"
          },
          {
            "s": "TVC:CO05"
          },
          {
            "s": "TVC:CO10Y"
          },
          {
            "s": "TVC:BR05Y"
          },
          {
            "s": "TVC:BR10Y"
          },
        ],
        "originalTitle": "Bonos"
      }
  ]

function CustomMarketOverviewWidget({parameters}) {
    return (     
            <div className="widget-container">         
                <MarketOverview  
                tabs={TABS}
                plotLineColorFalling={"rgba(33, 150, 243, 0.12)"}
                belowLineFillColorFallingBottom="rgba(41, 98, 255, 0)"
                scaleFontColor="rgba(41, 98, 255, 0)"
                colorTheme="dark" locale="es" autosize  {...parameters}/>
            </div>
    )   
}

export default CustomMarketOverviewWidget
