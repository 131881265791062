// TODO - Documentar
import React from 'react'
import { useSelector } from 'react-redux'

import { GenericElement } from '../../templates/FormElement';
import { setModelParameter } from '../../store/actions/modelParameters';


// Global constants
import * as con from "../../GlobalConstants"
import { Button } from '@blueprintjs/core';

import "../ComponentsGlobalStyle.css"
import { StateModelParameterHook } from '../hooks/ModelParameterHooks';

function BandParameters() {
    
    // Model Parameters
    // -----------------
    const modelParameters = useSelector((state) => state[con.STORE][con.REDUCER_MODEL_PARAMETERS])
    
    // Scheme Variable
    const [isAutomatic, setIsAutomatic] = StateModelParameterHook(con.AUTOMATIC_BANDS)


    
    return (
    <div>

        <div>
            <div className="columnParameterBox" >  
                        < GenericElement type={con.PERCENTAGE}
                                        name={"Ancho de Banda"}
                                        id={"band_width"} 
                                        value={modelParameters[con.BAND_WIDTH]} 
                                        setValue={(val) => setModelParameter(con.BAND_WIDTH, val)} 
                                        />      
        </div>
        
        <div className="smallParameterBox" >        
        
            <Button text="Automático" onClick={()=> setIsAutomatic(true)} outlined={!isAutomatic} className="selectedButton"/>
            <Button text="Manual"  onClick={()=> setIsAutomatic(false)} outlined={isAutomatic} className="selectedButton"/>

            
        </div>                                  
                    <div className="mediumParameterBox" >                                        
                        < GenericElement type={con.PERCENTAGE}
                                        name={"Cobertura Inicial"}
                                        id={"initial_coverage"} 
                                        value={modelParameters[con.INITIAL_COVERAGE]} 
                                        setValue={(val) => setModelParameter(con.INITIAL_COVERAGE, val)}
                                        disabled={!isAutomatic}  
                                        />                     

                        < GenericElement type={con.PERCENTAGE}
                                        name={"Gradiente"}
                                        id={"gradient"} 
                                        value={modelParameters[con.GRADIENT]} 
                                        setValue={(val) => setModelParameter(con.GRADIENT, val)}
                                        disabled={!isAutomatic} 
                                        />
                    </div>
                                        

        </div>

    </div>
    )
}

export default BandParameters
