// TODO - Documentar
import React, { useEffect, useState } from 'react'
import {  TITLE, X_LABEL, Y_LABEL, X_VALUES, Y_VALUES, LABEL_GENERATING_FUNCTION } from '../../templates/charts/ChartConstants'
import { MultiLineChartHook } from '../../templates/charts/hooks/MultiLineChartHook'
import * as con from "../../GlobalConstants"
import { addDays, addMonths, daysBetweenDates, formatDateForUser, getToday } from '../../utils/dateFunctions'
import { RefApplicationParameterHook, RefSPOT } from '../hooks/ApplicationParametersHooks'
import { RefMarketValueHook } from '../hooks/MarketValuesHook'
import { RefModelParameterHook } from '../hooks/ModelParameterHooks'
import { formatMoney } from '../../utils/moneyFunctions'
import { ScenariosHook } from '../hooks/ScenariosHook'


function Scenarios({selectedScenarios, chartParameters}) {

     // Model Parameters
    // -----------------
    const spot = RefSPOT()
    const termInMonths = RefApplicationParameterHook(con.TERM_IN_MONTHS)
    const marketVolatility = RefModelParameterHook(con.MARKET_VOLATILITY)


    // Scenarios
    // -----------------
    const scenarios = ScenariosHook()

    // Application Parameters
    const historicPricesAndDates = RefMarketValueHook(con.HISTORIC_DOLLAR_INFO)
    const currentDate = RefApplicationParameterHook(con.CURRENT_DATE)
    
    // Multiline hook    
    const [chartData, setChartData] = useState(() => [])
    const [objectReference, chart] = MultiLineChartHook({parameters : { [TITLE] : "COMPARACION ESCENARIOS EN LA TASA DE CAMBIO",
                                                                        [Y_LABEL] : "Tasa de Cambio", 
                                                                        [X_LABEL] : "Fecha",                                                                          
                                                                        ...chartParameters}})

    

    useEffect(() => {
        
        if(!chart.initialized)
            chart.initialize()
        
        const finalDate =  addMonths(currentDate,termInMonths)  
        
        const numPoints = daysBetweenDates(currentDate, finalDate)
    
            const dates = [...Array(numPoints).keys()].map((i) => {
                return(addDays(getToday(),i))             
            })

        // Historic Values
        const historicLength = historicPricesAndDates[con.DATES].length
        const historicDates = historicPricesAndDates[con.DATES].slice(historicLength - con.HISTORIC_SCENARIO_DAYS_TO_DISPLAY).concat([currentDate])
        const historicPrices = historicPricesAndDates[con.TRM].slice(historicLength - con.HISTORIC_SCENARIO_DAYS_TO_DISPLAY).concat([spot])

        // Adds historic Scenario
        let data = [{
            name : con.HISTORIC_PRICES_NAME,
            [X_VALUES] : historicDates,
            [Y_VALUES] : historicPrices,
            [LABEL_GENERATING_FUNCTION] : (selObj,j) => {return(`${formatDateForUser(selObj[X_VALUES][j])}: ${formatMoney(selObj[Y_VALUES][j], con.MONEY_COP)}`)}
        }]

        data = data.concat(Object.values(scenarios).filter((ob) => selectedScenarios == null || selectedScenarios.includes(ob[con.ID])).map( (ob,i) => {
            
            return({ name : ob.name,
                        [X_VALUES] : dates,
                        [Y_VALUES] : [...Array(numPoints).keys()].map((i) => ob.gen_fun(spot,i)),
                        [LABEL_GENERATING_FUNCTION] : (selObj,j) => {return(`${formatDateForUser(selObj[X_VALUES][j])}: ${formatMoney(selObj[Y_VALUES][j], con.MONEY_COP)}`)}})

        }))

        // Checks if structural change happened
        if(data.length !== chartData.length)
        {
            chart.initialize()
            chart.build(data)
        }
        else
        {
            chart.updateData(data)
            chart.updateLegend(data)
        }
            

        setChartData(data)

    }, [historicPricesAndDates, 
        currentDate,
        scenarios,         
        spot, 
        termInMonths,
        marketVolatility,
        chart,
        chartData.length,
        selectedScenarios])

    
    

    return (        
            <svg ref={objectReference}/>        
    )
}

Scenarios.defaultProps = {
    selectedScenarios: null,
    chartParameters : {}
  };


export default Scenarios
