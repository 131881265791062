// Actions for the model parameters reducer
import * as types from "./types"
import store from '../store';
import * as con from "../../GlobalConstants"

import axios from 'axios';

import { handleRequestError, handleUserLineApplicationParametersSavedError } from '../../utils/errorFunctions';
import { setApplicationParameter } from './applicationParameters';
import { getCurrentLine, getSurrogateUser, getToken, getUser, surrogateActive } from '../logic/supportFunctions';






// Set User Application Parameter 
export const setUserLineApplicationParameter = (id, value) =>
{    

    let payload = {[con.ID]: id, [con.VALUE] : value}

    store.dispatch({
        type : types.SET_USER_LINE_APPLICATION_PARAMETER,
        payload : payload
    })
}


export const getUserLineApplicationParameter = (key) => {
    return(store.getState()[con.STORE][con.REDUCER_USER_LINE_APPLICATION_PARAMETERS][key])
}



// Get model parameters
export const fetchUserLineApplicationParameters = () =>
{

    setApplicationParameter(con.USER_LINE_APPLICATION_PARAMETERS_FETCHED, {[con.STATUS] : con.LOADING})

    const token = getToken()

    const config = {
        headers : {
            "Authorization" : `Token ${token}`
        }
    }

    let url = con.rest_base_url + con.user_line_application_parameters_api
    if(surrogateActive())
        url = con.rest_base_url + con.staff_user_line_application_parameters_api

    url = url + `${getCurrentLine()}/`

    axios.get(url, config)
            .then(res => {
                
                // Only executes if the user has saved parameters 
                if(Object.keys(res.data).length > 0)
                {
                    store.dispatch({
                        type : types.REPLACE_USER_LINE_APPLICATION_PARAMETER,
                        payload : res.data
                    })
                }    
                
                setApplicationParameter(con.USER_LINE_APPLICATION_PARAMETERS_FETCHED, {[con.STATUS] : con.OK})
            })
            .catch(err => {
                setApplicationParameter(con.USER_LINE_APPLICATION_PARAMETERS_FETCHED, {[con.STATUS] : con.ERROR})
                handleRequestError(err)})
}



// Exports model parameters
export const exportUserLineApplicationParameters = async () =>
{
    // Gets Token
    const token = getToken()
    
    // Gets user 
    const user = getUser()

    setApplicationParameter(con.USER_LINE_APPLICATION_PARAMETERS_SAVED, {[con.STATUS] : con.LOADING})


    const config = {
        headers : {
            "Authorization" : `Token ${token}`
        }
    }

    var data = store.getState()[con.STORE][con.REDUCER_USER_LINE_APPLICATION_PARAMETERS]

    // Adds user and line
    data[con.USER] = user[con.ID]
    data[con.USER_LINE] = getCurrentLine()


    if(surrogateActive())
    {
        data[con.SURROGATE_USER] = getSurrogateUser()[con.ID]
        data[con.USER] = getSurrogateUser()[con.ID]

    }
        
    let url = con.rest_base_url + con.user_line_application_parameters_api
    if(surrogateActive())
        url = con.rest_base_url + con.staff_user_line_application_parameters_api

    url = url + `${getCurrentLine()}/`

    
    try {
        await axios.put(url, data, config);
        setApplicationParameter(con.USER_LINE_APPLICATION_PARAMETERS_SAVED, {[con.STATUS] : con.OK});
        return true;
    } catch (err) {
        setApplicationParameter(con.USER_LINE_APPLICATION_PARAMETERS_SAVED, {[con.STATUS] : con.ERROR});
        handleUserLineApplicationParametersSavedError(err);
        return false;
    }
}