// TODO - Documentar
import React from 'react'


import * as con from "../GlobalConstants"


import "./MobileLoginPage.css"
import logo from "./../img/logo-vinco-dark.png"
import { RefApplicationParameterHook } from '../components/hooks/ApplicationParametersHooks'

function MobileLoginPageNotSupported() {

    // Server
    const server = RefApplicationParameterHook(con.SERVER)


    return (
        

        <div style={{width : "100%"}} >         
            <div className="noCarret" style={{height : "100vh"}} >                          
                    <div style={{marginBottom : "80px", paddingTop: "30px"}}>                   
                        <img src={logo} alt="Logo" width="90%" height="auto"/> 
                    </div>
                  


                    <div style={{marginLeft : "10%", marginRight : "10%"}}>
                    <div className={`midBox ${(server[con.STATUS] === con.ERROR) && "serverDown"}`} style={{height : "250px"}}>
                    <h3>              
                        Actualmente la aplicación no se encuentra disponible para dispositivos mobiles, por favor acceda desde un computador y use la pantalla completa.
                    </h3>
                    </div>
                </div>
        </div>
        </div>

                    
    )
}

export default MobileLoginPageNotSupported
