import { Button, Radio, RadioGroup } from '@blueprintjs/core'
// TODO - Documentar
import React, { useState } from 'react'
import { MoneyElement } from '../../templates/FormElement'
import LoadingIcon from '../../templates/loading/LoadingIcon'
import {  formatAccordingToType} from '../../templates/Types'

import * as con from "../../GlobalConstants"
import { getApplicationParameter } from '../../store/actions/applicationParameters'
import { Account, Exposure } from '../../store/actions/transactions'

// Style
import "../ComponentsGlobalStyle.css"
import { RefNestedApplicationParameterHook } from '../hooks/ApplicationParametersHooks'
import { ExposuresHook } from '../hooks/TransactionsHooks'


function Prepay({expId, closeDialog}) {



    // Exposures
    // -----------------  
    const expos = ExposuresHook()


    // Local variables
    const [amountToPrepay, setAmountToPrepay] = useState(() => expos[expId][con.AMOUNT])
    const [payementMethod, setPayementMethod] = useState(() => con.PREPAY_WITH_COVERAGE)
    const [usedSpot, setUsedSpot] = useState(() => getApplicationParameter(con.SPOT))
    const [errorMessage, setErrorMessage] = useState(() => "")

    // Transaction Prepayed Status
    const transactionPrepayedStatus = RefNestedApplicationParameterHook(con.TRANSACTION_PREPAYED, con.STATUS)

    // Accept function
    const acceptFunction = () => {

        if(amountToPrepay === 0)
        {
            setErrorMessage("El valor a prepagar debe ser mayor a cero.")
        }
        else if(payementMethod === con.PREPAY_WITH_ACCOUNT &&
            Account.getCompensationAccountAmount() < amountToPrepay)
        {
            setErrorMessage("No hay suficiente dinero en la cuenta de compensación para prepagar el monto solicitado.")
        }
        else
        {
            // Makes the prepayment
            Exposure.prepayExposure(expId, amountToPrepay, payementMethod, usedSpot, closeDialog) 
            
        }


    }

    
    return (
        <div>
            <h2>Prepagar Exposición</h2>
            <p>Monto total exposición: <strong>{formatAccordingToType(con.MONEY,expos[expId][con.AMOUNT], {[con.CURRENCY] : con.MONEY_USD})}</strong></p>
            <div className="columnParameterBox" style={{justifyContent : "flex-start", margin : "3%"}}>                                
                <MoneyElement name={"Monto a Prepagar"} 
                              value={amountToPrepay} 
                              setValue={setAmountToPrepay} 
                              currency={con.MONEY_USD}
                              maxValue={expos[expId][con.AMOUNT]}/>
                <div style={{height : "22px"}}><p style={{color : "var(--danger-color)"}}>{errorMessage}</p></div>
                <div style={{marginTop : "4%", marginBottom : "5%"}}>
                    <RadioGroup inline={true}
                        label="Método para prepagar"
                        onChange={(e) => setPayementMethod(e.currentTarget.value)}
                        selectedValue={payementMethod}>
                        <Radio label="Cuenta de Compensación" value={con.PREPAY_WITH_ACCOUNT} disabled={Account.getCompensationAccountAmount() <= 0}/>
                        <Radio label="Cobertura en SPOT" value={con.PREPAY_WITH_COVERAGE} />                    
                    </RadioGroup>
                    <div className="cardBox" style={{marginTop : "5%", height : "55px"}}>             
                       
                        <div style={{paddingRight : "20px"}}>
                            <p>Monto en cuenta de compensacion:</p>
                            <p style={{color : (Account.getCompensationAccountAmount() <= 0)? "var(--danger-color)": "inherit"}}> <strong>{formatAccordingToType(con.MONEY, Account.getCompensationAccountAmount(), {[con.CURRENCY] : con.MONEY_USD})}</strong></p>
                        </div>
                        
                        <div style={{paddingLeft : "20px"}}>
                            <MoneyElement name={"SPOT"} 
                                            value={usedSpot} 
                                            setValue={setUsedSpot} 
                                            currency={con.MONEY_COP}
                                            />
                        </div>                        
                    </div>  
                    <div style={{marginTop : "10%"}}>
                        {
                            transactionPrepayedStatus !== con.LOADING ?
                            <Button text="Pagar" 
                            onClick={acceptFunction}/> :
                            <LoadingIcon/>
                        }            
                        
                    </div>                
                </div>
            </div>            
        </div>
    )
}

Prepay.defaultProps = {
    closeDialog : () => true
  };



export default Prepay
