// ColLection of authenticated functions

// Global constants
import * as con from "../GlobalConstants"
import { getNow, minutesBetweenDates } from './dateFunctions';
import { getLocalStorageItem, removeLocalStorageItem } from "./localStorageWrapper";

/**
 * Method that clears all the local stored items
 */
export const clearLocalAuthenticationStorage = () =>
{
    removeLocalStorageItem(con.LOCAL_TOKEN)
    removeLocalStorageItem(con.LOCAL_USER)
    removeLocalStorageItem(con.LOCAL_LAST_TOKEN_REFRESHED_TIMESTAMP)
}

/**
 * Gets the default authentication values. User is not authenticated
 * @returns Object with the authentication values: token, user, is authenticated, surrogate user and needs login
 */
export const getDefaultAuthenticationValues = () =>
{
    return({ [con.TOKEN] : null, 
            [con.USER] :  null, 
            [con.IS_AUTHENTICATED]: false,
            [con.SURROGATE_USER]: null,
            [con.NEEDS_TO_LOGIN_AGAIN] : false})

}

/**
 * Gets the default authentication values. User is authenticated if the time since last login does not exceed the max minutes since
 * token in the constants module
 * @returns Object with the authentication values: 
 * token: user identification token,
 * user: object with the general user information,
 * is_authenticated: boolean indicating if the current user is authenticated or not,
 * surrogate_user : object with the surrogate user info in case the user is admin and in surrogate mode,
 * needs_login  : boolean indicating if the user needs to login because of idle time
 */
export const getInitialAuthenticationValues = () =>
{

    let lastLogin = getLocalStorageItem(con.LOCAL_LAST_TOKEN_REFRESHED_TIMESTAMP)

    // First Time
    if(lastLogin === null)
        return(getDefaultAuthenticationValues())
            
    // Needs to login again
    if(minutesBetweenDates(lastLogin, getNow()) > con.MAX_MINUTES_SINCE_TOKEN_REFRESH)
    {
        clearLocalAuthenticationStorage()
        return({ [con.TOKEN] : null, 
            [con.USER] :  null, 
            [con.IS_AUTHENTICATED]: false,
            [con.SURROGATE_USER]: null,
            [con.NEEDS_TO_LOGIN_AGAIN] : true})
    }
        
    let token = getLocalStorageItem(con.LOCAL_TOKEN)
    let user = getLocalStorageItem(con.LOCAL_USER, con.OBJECT)

    // Not found
    if(token === null || user === null)
        return({ [con.TOKEN] : null, 
            [con.USER] :  null, 
            [con.IS_AUTHENTICATED]: false,
            [con.SURROGATE_USER]: null,
            [con.NEEDS_TO_LOGIN_AGAIN] : false})
    
    // From storage
    return({ [con.TOKEN] : token, 
             [con.USER] : user, 
             [con.IS_AUTHENTICATED]: true,
             [con.SURROGATE_USER]: null,
             [con.NEEDS_TO_LOGIN_AGAIN] : false})

}