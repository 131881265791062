// TODO - Documentar
import React from 'react'
import AdminDashboard from '../components/adminDashboard/AdminDashboard'
import SimpleFillFooter from '../templates/footer/SimpleFillFooter'
import { USABLE_SCREEN_SIZE } from '../GlobalConstants'
import "./Pages.css"

function AdminDashboardPage() {
    return (
        <div style={{height : USABLE_SCREEN_SIZE + "vh"}}>
            <AdminDashboard />
            <SimpleFillFooter />
        </div>
    )
}

export default AdminDashboardPage
