// TODO - Documentar
import React, { useEffect, useState } from 'react'
import * as con from "../../GlobalConstants"
import {StaticTable} from '../../templates/tables/StaticTable'
import { translateParameter } from '../../utils/translateFunctions'
import { setModelParameter } from '../../store/actions/modelParameters'
import { RefMarketValueHook } from '../hooks/MarketValuesHook'




function MarketSummaries() {

    const marketSummaries =  RefMarketValueHook(con.MARKET_SUMMARIES)

    const columnArray = [{ [con.NAME] : 'PERIODO', 
                            [con.TYPE] : con.TEXT,                         
                            [con.ID] : con.NAME
                        },
                        {
                           [con.NAME] : 'VOLATILIDAD ANUALIZADA', 
                            [con.TYPE] : con.PERCENTAGE,  
                            [con.ID] : con.VOLATILITY
                        },
                        {
                          [con.NAME] : 'TASA PROMEDIO', 
                           [con.TYPE] : con.MONEY,  
                           [con.ID] : con.AVERAGE,
                           [con.CURRENCY] : con.MONEY_COP,
                       }]


  const [dataDictionary, setDataDictionary] = useState(() => Object.assign({}, ...Object.keys(marketSummaries).map((k) => ({[k]: { ...marketSummaries[k],
                                                                                                                                    [con.NAME] : translateParameter(k)}}))))                                                                                   


useEffect(() => {
  

  let newDataDict = Object.assign({}, ...Object.keys(marketSummaries).map((k) => ({[k]: { ...marketSummaries[k],
                                                                                    [con.NAME] : translateParameter(k)}})))

  // Removes the historical average
  newDataDict[con.MARKET_TOTAL_DAYS][con.AVERAGE] = "-"

  setDataDictionary(newDataDict)

}, [marketSummaries])

      

  return (
    <div className="columnParameterBoxTop" style={{width : "90%"}}>      
        <h4>Resumen Mercado</h4>      
        <StaticTable  columnArray = {columnArray} 
                      dataDictionary={dataDictionary}                      
                      onclickFunction={(rowData) => setModelParameter(con.MARKET_VOLATILITY, rowData[con.VOLATILITY])}
                      sortable={false}
                      fixedSize={25}/>

    </div>
  )
}

export default MarketSummaries