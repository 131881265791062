import React from 'react'
import * as con from "../../GlobalConstants"
import * as cc from '../../templates/charts/ChartConstants'

import Scenarios from '../scenarios/Scenarios'
import GlobalViewComponent from './GlobalViewComponent'



function ScenariosGlobalViewComponent() {
  return (
    <GlobalViewComponent pageLink={con.PAGE_SCENARIOS} title={"ESCENARIOS"}>                                            
                        <Scenarios  selectedScenarios={[con.SCENARIO_VAR_UP, con.SCENARIO_VAR_DOWN, "HISTORICO_CAIDA_2007", "HISTORICO_COVID_2020"]}
                                    chartParameters = {{[cc.LEGEND_HEIGHT] : 0.1,
                                                       [cc.LEGEND_NUM_ROWS] : 2,
                                                       [cc.INCLUDE_BORDER] : false,
                                                       [cc.TITLE] : ""}}/>
                    </GlobalViewComponent>
  )
}

export default ScenariosGlobalViewComponent