// Component for handeling the interaction with the custom Scenario

// Component for All parameters
// TODO - Documentar
import React from 'react';


// Global constants
import * as con from "../../GlobalConstants"


import { RefModelParameterHook, StateModelParameterHook } from '../hooks/ModelParameterHooks';
import { fontCalculator } from '../../templates/tables/StaticTable';
import { StateSingleScenario } from '../hooks/ScenariosHook';

// Style
import "../../templates/Templates.css"
import "../../templates/tables/Table.css"
import "../ComponentsGlobalStyle.css"
import { GenericElement } from '../../templates/FormElement';
import { CellMoney, CellText } from '../../templates/tables/Cells';


function CustomScenario({fixedSize}) {



    // Parameters
    const numPeriods =  RefModelParameterHook(con.TERM_PERIODICITY)
    

    const scenario = StateSingleScenario(con.SCENARIO_VINCO)
    const [customScenarioValues, setCustomScenarioValues] = StateModelParameterHook(con.CUSTOM_SCENARIO_VALUES)  
    const [customScenarioActive, setCustomScenarioActive] = StateModelParameterHook(con.CUSTOM_SCENARIO_ACTIVE)


    const setSingleCustomScenarioValue = (i, val) =>
    {
        customScenarioValues[i] = val
        setCustomScenarioValues([...customScenarioValues])
    }
    

    return (
    <div className="compact" style={{width : "98%"}}>
        <h4>Valores Escenario</h4> 
        <div className="smallParameterBox" >        
        
            < GenericElement type={con.BOOLEAN}
                id={con.CUSTOM_SCENARIO_ACTIVE} 
                value={customScenarioActive} 
                setValue={setCustomScenarioActive} 
                label={'Activar Personalizado'}                                    
              />  
        
        </div>   
        <div className="contenedor-tabla" style={ fixedSize == null ?{} : {fontSize : fontCalculator(fixedSize, numPeriods+2)}}>

            <div className="flex-tabla">
                <div className="flex-fila header-row">
                    <div className="flex-celda ">PERIODO</div>
                    <div className={`flex-celda`}>VINCO</div>
                    <div className={`flex-celda ${customScenarioActive && "selected-header-cell"}`} >PERS.</div>
                </div>
            </div>

           <div className="flex-tabla"> 
                {
                    [...Array(numPeriods).keys()].map((i) => {
                        return(

                        <div key={i} className={`flex-fila hoverable-row ${(i % 2 === 0)? "even-row":"uneven-row"}`}>            
                            <div className="flex-celda"><CellText value={`Mes ${i+1}`} editing={false}/></div>
                            <div className={`flex-celda`}><CellMoney value={ scenario[con.GENERATING_FUNCTION](0, (i+1)*con.DAYS_IN_MONTH) } editing={false}/></div>
                            <div className={`flex-celda ${!customScenarioActive && "flex-celda-disabled"}`} ><CellMoney value={ customScenarioValues === null ? scenario[con.GENERATING_FUNCTION](0, (i+1)*con.DAYS_IN_MONTH) : customScenarioValues[i+1] } editing={customScenarioActive} setValue={val => setSingleCustomScenarioValue(i+1, val)}/></div>
                        </div>

                        )
                })}            
            </div>
            
        </div>
    </div>
    )
}

CustomScenario.defaultProps = {
    fixedSize : null,
  };



export default CustomScenario
