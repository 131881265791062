// TODO - Documentar
import React from 'react';


function CustomNavSelect({values, labels, onChange, selectedValue}) {

  return(<div className="bp3-html-select customNavSelect" style={{marginLeft : "0.5vw", marginRight : "0.5vw"}}>
            <select className="input-editing" 
                    onChange={onChange}
                    value={selectedValue}                                                                                    >
                    {
                        values.map((val, i) => 
                        <option key={val} value={val}>{labels[i]}</option>)
                    }
                            
            </select>
            <span className="bp3-icon bp3-icon-double-caret-vertical"></span>
            </div>)
}



export default CustomNavSelect;
