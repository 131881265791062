
// TODO - Documentar
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import * as con from "../../GlobalConstants"
import { RefUserAttributesHook } from './UserAttributesHook';

/**
 * Hook for the advisors
 */
export function AdvisorsHook() {

    const value = useSelector(state => state[con.STORE][con.REDUCER_ADVISORS])

    return(value);
}

export const RefSelectedAdvisors = () => 
{
    const selectedAdvisors = RefUserAttributesHook(con.ADVISORS)
    const mainAdvisorId = RefUserAttributesHook(con.MAIN_ADVISOR)

    const advisors = AdvisorsHook()

    const [currentAdvisors, setCurrentAdvisors] = useState(() => [])


    useEffect(() => {

        // Waits until advisors are loaded
        if(advisors.length > 0)
        {
            
            let finalAdvisors = []
            // Finds Main Advisor
            if(mainAdvisorId !== null && mainAdvisorId !== undefined)
            {
                let adv = advisors.find((adv) => `${adv[con.ID]}` === `${mainAdvisorId}`)
                if(adv !== null && adv !== undefined)
                    finalAdvisors.push(adv)
            }

            // Finds all other advisors
            selectedAdvisors.forEach(advisorId => {
                if(advisorId !== mainAdvisorId)
                {
                    let adv = advisors.find((adv) => `${adv[con.ID]}` === `${advisorId}`)
                    if(adv !== null && adv !== undefined)
                        finalAdvisors.push(adv)
                }
                    

            })

            setCurrentAdvisors(finalAdvisors)
        }

    }, [selectedAdvisors, mainAdvisorId, advisors])
    

    return(currentAdvisors)
    
}   
