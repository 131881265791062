// TODO - Documentar
import React from 'react'
import Transactions from '../components/transactions/Transactions'
import TransactionsControl from '../components/transactions/TransactionsControl'
import SimpleFillFooter from '../templates/footer/SimpleFillFooter'
import { USABLE_SCREEN_SIZE } from '../GlobalConstants'

function InputsPage() {
    return (
        <div style={{minHeight : USABLE_SCREEN_SIZE + "vh"}} className="pageWithFooter">
            <TransactionsControl />            
            <Transactions />
            <SimpleFillFooter />
        </div>
    )
}

export default InputsPage
