import { formatDateAndTime, getNow } from "../../utils/dateFunctions"

// Commands
export const EDIT_COMMAND = "EDIT"
export const REMOVE_COMMAND = "REMOVE"
export const VIEW_ANNOTATIONS_COMMAND = "VEW_ANNOTATIONS"
export const PREPAY_EXPOSURE_COMMAND = "PREPAY_EXPOSURE"



// Annotation Constants
export const ANNOTATIONS = "annotations"
export const constructEditAnnotation = () =>
{    
    return {'text' : 'Registro editado.', "date" : formatDateAndTime(getNow())}
}


// Generic Transactions 
export const ID_GENERIC_TRANSACTION = 0