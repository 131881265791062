// Exposures
// TODO - Documentar
import React from 'react'
import * as con from "../../GlobalConstants"



// Style
import "../ComponentsGlobalStyle.css"
import { TransactionTable } from '../transactionTable/TransactionTable'
import { CoverageFWD, getTransactionsAnnotationFunction } from '../../store/actions/transactions'
import { ForwardCoveragesHook } from '../hooks/TransactionsHooks'
import TransactionTableVisualizer from './TransactionTableVisualizer'
import { RefApplicationParameterHook } from '../hooks/ApplicationParametersHooks'
import { allForwardCoverageColumns, selectedForwardCoverageColumns } from '../../utils/transactionFunctions'

function TableCoveragesFwd() {

  // Transaction
  const trans = ForwardCoveragesHook()
    
  const filterFun = RefApplicationParameterHook(con.FILTER_FUN)
  const searchFun = RefApplicationParameterHook(con.SEARCH_FUN)

  // Columns
  const columns = selectedForwardCoverageColumns.map(col => allForwardCoverageColumns[col[con.ID]])

  return (
        <TransactionTableVisualizer transactionType={con.COVERAGES_FWD}>           
                    <TransactionTable transactionType={con.COVERAGES_FWD}
                                      columnArray={columns} 
                                      dataDictionary={trans} 
                                      setRow={(id, vals) => CoverageFWD.set(id, vals)} 
                                      addRow={(vals) => CoverageFWD.add(vals)}
                                      deleteRow={(id) => CoverageFWD.delete(id)}
                                      getAnnotations={getTransactionsAnnotationFunction(con.COVERAGES_FWD)}
                                      filterFunction={filterFun}
                                      searchFunction={searchFun}/>
        </TransactionTableVisualizer>        
  )
}

export default TableCoveragesFwd