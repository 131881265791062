/**
 * Module for the different cell elements in the application 
 */
import React from 'react';

// Style
import "../Templates.css"
import "./Table.css"

// Hooks
import { useState, useEffect } from 'react'
import { RefSPOT } from '../../components/hooks/ApplicationParametersHooks';
import { FaSave } from 'react-icons/fa';
import { FormGroup, InputGroup } from "@blueprintjs/core";
import NumberFormat from "react-number-format";
import * as con from "../../GlobalConstants"
import { CustomDateInput, formatDate, formatDateAndTimeForUser, formatDateForUser, parseDate} from "../../utils/dateFunctions"
import { createNotification } from '../notifications/Notifications';


// Static Cells
// ------------

/**
 * Single static cell
 * @param {string} content - The content of the cell 
 * @param {boolean} [isTitle=false] - A boolean inditing if the cell is title or not.
 * @param {boolean} [evenRow=null] - A boolean indicating if the cell belongs to an even or uneven row (for coloring purposes)
 * @returns {JSX.Element} - The corresponding static cell
 */
export const StaticSingleCell = ({content, isTitle=false, evenRow=null}) =>
{
    return(<div className={`tight-cell ${(evenRow === null)? "" : evenRow ? "even-row":"uneven-row"} ${isTitle ? "tight-header-cell" : ""}`}>{content}</div>)
}


/**
 * Double static cell. This is a cell with two columns and two rows, where the row in the left column have been  merged
 * @param {string} contentLeft - The content to be displayed in the left main cell. 
 * @param {string} contentUpperRight - Content for the upper right cell.
 * @param {string} contentBottomRight - Content for the bottom right cell.
 * @param {boolean} [evenRow=null] - A boolean indicating if the cell belongs to an even or uneven row (for coloring purposes)
 * @returns {JSX.Element} - The corresponding static double cell
 */
 export const StaticCompoundDoubleCell = ({contentLeft, contentUpperRight, contentBottomRight, evenRow=null}) =>
 {
     return(
        <div className="double-cell-container">
            <div className={`main-container-cell ${(evenRow === null)? "" : evenRow ? "even-row":"uneven-row"}`} >{contentLeft}</div>
            <div className="stacked-cell-container">
                <div className={`tight-cell stacked-cell ${(evenRow === null)? "" : evenRow ? "even-row":"uneven-row"}`}>{contentUpperRight}</div>
                <div className={`tight-cell stacked-cell ${(!evenRow)? "even-row":"uneven-row"}`}>{contentBottomRight}</div>
            </div>
        </div>)
 }

/**
 * Triple static cell. This is a cell with two columns and three rows, where the row in the left column have been  merged
 * @param {string} contentLeft - The content to be displayed in the left main cell. 
 * @param {string} contentUpperRight - Content for the upper right cell.
  * @param {string} contentMiddleRight - Content for the middle right cell.
 * @param {string} contentBottomRight - Content for the bottom right cell.
 * @param {boolean} [evenRow=null] - A boolean indicating if the cell belongs to an even or uneven row (for coloring purposes)
 * @returns {JSX.Element} - The corresponding static triple cell
 */
 export const StaticCompoundTripleCell = ({contentLeft, contentUpperRight, contentMiddleRight, contentBottomRight, evenRow=null}) =>
 {
     return(
        <div className="triple-cell-container">
            <div className={`main-container-cell ${(evenRow === null)? "" : evenRow ? "even-row":"uneven-row"}`} >{contentLeft}</div>            
            <div className="stacked-cell-container">
                <div className={`tight-cell stacked-cell ${(evenRow === null)? "" : evenRow ? "even-row":"uneven-row"}`}>{contentUpperRight}</div>
                <div className={`tight-cell stacked-cell ${(!evenRow)? "even-row":"uneven-row"}`}>{contentMiddleRight}</div>
                <div className={`tight-cell stacked-cell ${(evenRow === null)? "" : evenRow ? "even-row":"uneven-row"}`}>{contentBottomRight}</div>
            </div>
        </div>)
 }


// Non Static Cells
// ---------------
 
/**
 * Component that renders a cell with the given type. This is useful when building tables from predefined object that include their types.
 * @param {string} value - The text to be displayed
 * @param {boolean} editing - Boolean indicating if the cell is being edited or if its static
 * @param {string} cellType - The type of the cell
 * @param {boolean} [evenRow=null] - A boolean indicating if the cell belongs to an even or uneven row (for coloring purposes)
 * @param {object} otherParameters - Object containing the other parameters that will be passed directly into the specific cell element
 * @returns {JSX.Element} - The corresponding cell.
 */
 export const GenericCell = ({editing, cellType, evenRow=null, ...otherParameters}) => {
    
    // Check if cell is editable
    if(con.EDITABLE in otherParameters && !otherParameters[con.EDITABLE])
        editing = false

    const className = `flex-celda ${(evenRow === null)? "" : evenRow ? "even-row":"uneven-row"}`

    // Searches for type
    switch(cellType){
        case con.TEXT:
            return (
                <div className={className}>
                    <CellText {...otherParameters}
                              editing={editing}/>
                </div>
            )
    
        case con.NUMERIC:
            return (
                <div className={className}>
                    <CellNumeric {...otherParameters}
                              editing={editing}/>
                </div>
            )
        case con.DATE:
            return (
                <div className={className}>
                    <CellDate {...otherParameters}
                              editing={editing}/>
                </div>
            )
        case con.DATE_TIME:
                return (
                    <div className={className}>
                        <CellDateTime {...otherParameters}
                                      editing={editing}/>
                    </div>
                )
        case con.MONEY:
            return (
                <div className={className}>
                    <CellMoney {...otherParameters}
                               editing={editing}/>
                </div>
            )
        case con.PERCENTAGE:
            return (
                <div className={className}>
                    <CellPercentage {...otherParameters}
                                   editing={editing}/>
                </div>
            )
        case con.MULTIPLE_SELECTION:
            return (
                <div className={className}>
                    <CellMultipleValues {...otherParameters}
                                        editing={editing}/>
                </div>
            )
        case con.SPOT_TYPE:
                return (
                    <div className={className}>
                        <CellSpot {...otherParameters}
                                        editing={editing}/>
                    </div>
                )
        
        case con.ACTION_TYPE:
                return (
                    <div className={className}>
                        <CellAction {...otherParameters}/>
                    </div>
                )
        
        default:
        
            console.error(`No support for cellType: ${cellType}`)
            return (
                <div className={className}>
                    <CellText {...otherParameters}
                              editing={editing}/>
                </div>
            )
    }

}



/**
 * Component that renders a generic cell that includes a checkbox enabling its edition.
 * @param {boolean} editing - Boolean indicating if the cell is being edited or if its static
 * @param {(boolean) => void} [onEditingToggle = (isAutomatic) => true] -  Function that will be called after the checkbox has been toggled in case an extra action is needed to preform
 * @param {object} otherParameters - Object containing the other parameters that will be passed directly into the generic cell element.
 * @returns {JSX.Element} - The corresponding cell.
 */
 export const GenericCellWithCheckBox = ({editing, onEditingToggle = (isAutomatic) => true, ...otherParameters}) => {
    
    const [isAutomatic, setIsAutomatic] = useState(() => editing !== true)

    useEffect(() => {
        setIsAutomatic(editing !== true)
    }, [editing])
    

    return (
        <div className='cell-with-checkbox'>
            <div className='cell-with-checkbox-cell'>
                <GenericCell editing={!isAutomatic} {...otherParameters} />
            </div>
            <div className='cell-with-checkbox-command' title={ isAutomatic ? "Editar" : "Bloquear"  }>
                <input type={"checkbox"} checked={isAutomatic} onChange={e => {
                    setIsAutomatic(e.target.checked)
                    onEditingToggle(e.target.checked)}}/>
            </div>    
        </div>
    
    )
}




/**
 * Component that renders a cell from an object with values. This component calls generic cell directly and is created to avoid rerendering.
 * @param {object} dictValues - The object with the different values
 * @param {string} dictId - The id corresponding to the attribute that will be displayed
 * @param {boolean} [includeCheckBox = false] - Boolean indicating if the cell should include a checkbox
 * @param {object} otherParameters - Object containing the other parameters that will be passed directly into the generic cell element
 * @returns {JSX.Element} - The corresponding cell.
 */
 export const GenericCellFromDict = ({dictValues, dictId, includeCheckBox = false, ...otherParameters}) => {
    
    return (
        includeCheckBox  
        ? <GenericCellWithCheckBox value={dictValues[dictId]} {...otherParameters} /> 
        : <GenericCell value={dictValues[dictId]} {...otherParameters} />
    )
}



// ----- Cell Types --------
// -------------------------

/**
 * Component that renders a cell containing a text value
 * @param {string} value - The text to be displayed
 * @param {(string) => void} setValue - The function that updates the given value
 * @param {boolean} editing - Boolean indicating if the cell is being edited or if its static
 * @param {number} [maxLength = con.MAX_COMMENT_LENGTH] - Max length of the text
 * @param {boolean} [raiseNotification = true] - If the cell should raise a notification indicating that the max number of characters has been exceeded
 * @param {boolean} [evenRow=null] - A boolean indicating if the cell belongs to an even or uneven row (for coloring purposes in static mode). Set to null to ignore coloring. 
 * @param {object} otherParameters - Object containing the other parameters that will be passed directly into the InputGroup
 * @returns {JSX.Element} - The Cell with the corresponding text
 */
 export const CellText = ({value, setValue, editing, maxLength = con.MAX_COMMENT_LENGTH, raiseNotification = true, evenRow=null, ...otherParameters}) => {
     
    const [notificationShowed, setNotificationShowed] = useState(() => false)

    const onChangeFun = (e) => {
        
        let text = e.target.value
        if(maxLength !== undefined && maxLength !== null && text.length > con.MAX_COMMENT_LENGTH)
        {
            text = text.substring(0,con.MAX_COMMENT_LENGTH)
            if(raiseNotification && !notificationShowed)
            {
                createNotification(`El máximo número de caracteres admitido es ${con.MAX_COMMENT_LENGTH}`)
                setNotificationShowed(true)
            }
                
        }
        setValue(text)
    }

    

    return (
        <div className={`flex-celda ${(evenRow === null)? "" : evenRow ? "even-row":"uneven-row"} ${editing ? "" : "cell-static-content"}`}>
        {editing ? 
                    <FormGroup>
                        <InputGroup {...otherParameters}
                                    id="text-input"
                                    onFocus={e => e.target.select()}
                                    className="input-editing"
                                    value={value} 
                                    onChange={onChangeFun} />
                    </FormGroup> 
                  : value}
    </div>
    )
}



/**
 * Component that renders a cell containing a numeric value
 * @param {number} value - The number to be displayed
 * @param {(number) => void} setValue - The function that updates the given value
 * @param {boolean} editing - Boolean indicating if the cell is being edited or if its static
 * @param {() => void} [onFocus = () => true] - Function that will be executed on focus. After this function, the target.select() function is executed
 * @param {boolean} [heavyEffect = true] - If the change of the value of the cell has a heavy computational effect. If set to true, the setValue parameter will only be called on blur instead of onChange. 
 * @param {boolean} [evenRow=null] - A boolean indicating if the cell belongs to an even or uneven row (for coloring purposes in static mode). Set to null to ignore coloring. 
 * @param {object} otherParameters - Object containing the other parameters that will be passed directly into the InputGroup
 * @returns {JSX.Element} - The Cell with the corresponding numeric value
 */
export const CellNumeric = ({value, setValue, editing, onFocus = () => true, heavyEffect = true, evenRow=null, ...otherParameters}) => {
    

   
    // Clean function to avoid Nan numbers
    const cleanFun = (val) =>
    {
        val = parseFloat(val)
        if(Number.isNaN(val))
            val = 0
        
        val = parseInt(Math.round(val))
        return val
    }
    
    // Local Value for HeavyEffect
    const [localValue, setLocalValue] = useState(() => cleanFun(value))

    useEffect(() => {
        setLocalValue(cleanFun(value))
    }, [value])
    

    const onChangeFun = (e) => {

        if(!heavyEffect)
            setValue(cleanFun(e.target.value))
                
        setLocalValue(cleanFun(e.target.value))
    }

    
    return (
        <div className={`${(evenRow === null)? "" : evenRow ? "even-row":"uneven-row"} ${editing ? "" : "cell-static-content"}`}>
        {editing ? 
                    <FormGroup>
                        <InputGroup {...otherParameters}
                                    id="text-input"
                                    onFocus={e => {onFocus();e.target.select()}} 
                                    className="input-editing"
                                    value={localValue} 
                                    onChange={onChangeFun}
                                    onBlur={() => heavyEffect ? setValue(cleanFun(localValue)) : null} />
                    </FormGroup> 
                  : value}
    </div>
    )
}



/**
 * Component that renders a cell containing a date value. This components assumes the date time will be given as a string in a format the parseDate function can convert to date.
 * @param {number} value - The date to be displayed
 * @param {(string) => void} setValue - The function that updates the given value
 * @param {boolean} editing - Boolean indicating if the cell is being edited or if its static
 * @param {boolean} [evenRow=null] - A boolean indicating if the cell belongs to an even or uneven row (for coloring purposes in static mode). Set to null to ignore coloring. 
 * @param {object} otherParameters - Object containing the other parameters that will be passed directly into the InputGroup
 * @returns {JSX.Element} - The Cell with the corresponding date value
 */
 export const CellDate = ({value, setValue, editing, evenRow=null, ...otherParameters} ) => {
    
    const onChangeFun = (fecha) => {

        return(setValue(formatDate(fecha)))
    }

    return (
        <div className={`${(evenRow === null)? "" : evenRow ? "even-row":"uneven-row"} ${editing ? "" : "cell-static-content"}`}>
        {editing ? 
                <CustomDateInput
                        {...otherParameters}                        
                        className="input-editing-date"                        
                        onChange={onChangeFun}                                             
                        value={parseDate(value)}                        
                    />
                  : value === con.TOTAL ? con.TOTAL : formatDateForUser(parseDate(value))}
                    
    </div>
    )
}

/**
 * Component that renders a cell containing a date time value. This components assumes the date time will be given as a string in a format the parseDate function can convert to a date time.
 * @param {number} value - The date time to be displayed
 * @param {(string) => void} setValue - The function that updates the given value
 * @param {boolean} editing - Boolean indicating if the cell is being edited or if its static
 * @param {boolean} [evenRow=null] - A boolean indicating if the cell belongs to an even or uneven row (for coloring purposes in static mode). Set to null to ignore coloring. 
 * @param {object} otherParameters - Object containing the other parameters that will be passed directly into the InputGroup
 * @returns {JSX.Element} - The Cell with the corresponding date time value
 */
 export const CellDateTime = ({value, setValue, editing, evenRow=null, ...otherParameters}) => {


    const onChangeFun = (fecha) => {
        return(setValue(formatDate(fecha)))
    }

    return (
        <div className={`${(evenRow === null)? "" : evenRow ? "even-row":"uneven-row"} ${editing ? "" : "cell-static-content"}`}>
        {editing ? 
                    <CustomDateInput                        
                        {...otherParameters}
                        className="input-editing-date"
                        onChange={onChangeFun}                                             
                        value={parseDate(value)}                       
                    />
                  : value === con.TOTAL ? con.TOTAL :  formatDateAndTimeForUser(parseDate(value))}
                    
    </div>
    )
}


/**
 * Component that renders a cell containing a money (numeric) value
 * @param {number} value - The money (numeric) to be displayed
 * @param {(number) => void} setValue - The function that updates the given value
 * @param {boolean} editing - Boolean indicating if the cell is being edited or if its static
 * @param {string} currency - The currency of the money
 * @param {boolean} allowDecimals - Boolean indicating if the currency has decimals or not.
 * @param {(number) => void} [onBlur = () => true] - Function that will be executed on blur.
 * @param {() => true} [onFocus] - Function that will be executed on focus. After this function, the target.select() function is executed
 * @param {boolean} [heavyEffect = true] - If the change of the value of the cell has a heavy computational effect. If set to true, the setValue parameter will only be called on blur instead of onChange. 
 * @param {boolean} [evenRow=null] - A boolean indicating if the cell belongs to an even or uneven row (for coloring purposes in static mode). Set to null to ignore coloring. 
 * @param {object} otherParameters - Object containing the other parameters that will be passed directly into the NumberFormat
 * @returns {JSX.Element} - The Cell with the corresponding money (numeric) value
 */
 export const CellMoney = ({value, setValue, editing, currency, allowDecimals, onBlur = (val) => true, onFocus = () => true, heavyEffect = true, evenRow=null, ...otherParameters}) => {


    let sufijo = ""
    if(currency === con.MONEY_COP)
        sufijo = con.SUFFIX_COLOMBIAN_PESOS
    if(currency === con.MONEY_USD)
        sufijo = con.SUFFIX_DOLLARS


        // Local Value for HeavyEffect
        const [localValue, setLocalValue] = useState(() => value)

        useEffect(() => {
            setLocalValue(value)
        }, [value])
        

        const onChangeFun = (ob) => {

            let newVal = ob.floatValue
            
            if(!heavyEffect)
                setValue(newVal)
            else 
                setLocalValue(newVal)
            
        }
    
    
        
     return (
        <div className={`${(evenRow === null)? "" : evenRow ? "even-row":"uneven-row"} ${editing ? "" : "cell-static-content"}`}>
        {editing ? <NumberFormat  {...otherParameters} value={localValue} onFocus={e => {onFocus(); e.target.select(); }} onClick={e => e.target.select()} className="input-editing" decimalScale={allowDecimals ? 2 : 0} fixedDecimalScale={allowDecimals === true} thousandSeparator={true} suffix={sufijo}  onValueChange={onChangeFun} onBlur={() => {setValue(localValue);onBlur(localValue)}}/>
                  : <NumberFormat {...otherParameters} value={localValue} thousandSeparator={true} displayType={'text'} decimalScale={allowDecimals ? 2 : 0} fixedDecimalScale={allowDecimals === true} suffix={sufijo} />}
    </div>
    )
}



/**
 * Component that renders a cell containing the spot value. This cell enables the user to use the live spot or a custom one.
 * Note: to enable the cell to update when the global spot value changes, it needs to watch the setValue value. To avoid infinite rerendering, be sure to wrap the set value using callback.
 * @param {(number) => void} setValue - The function that updates the given value
 * @param {() => void} [onBlur = () => true] - Function that will be executed on blur.
 * @param {() => void} [onFocus = () => true] - Function that will be executed on focus. After this function, the target.select() function is executed
 * @param {boolean} [evenRow=null] - A boolean indicating if the cell belongs to an even or uneven row (for coloring purposes in static mode). Set to null to ignore coloring. 
 * @param {object} otherParameters - Object containing the other parameters that will be passed directly into the NumberFormat
 * @returns {JSX.Element} - The Cell with the corresponding spot value.
 */
 export const CellSpot = ({setValue, onBlur = () => true, onFocus = () => true, evenRow=null, ...otherParameters}) => {
    
   
    const spot = RefSPOT()

    const [isAutomatic, setIsAutomatic] = useState(() => true)
    const [localSpot, setLocalSpot] = useState(() => spot)
     
  
    useEffect(() => {
            if(isAutomatic)
            {
                setLocalSpot(spot)
                setValue(spot)
            }   
        }, [isAutomatic, spot, setValue])
        




    return (
        <div className={`${(evenRow === null)? "" : evenRow ? "even-row":"uneven-row"} ${isAutomatic ? "cell-static-content" : ""}`}>
            <div className='spot-cell'>
                    <div className={ isAutomatic ? 'cell-with-spot-input' : ""}>
                    { isAutomatic ? <NumberFormat {...otherParameters} value={localSpot} thousandSeparator={true} displayType={'text'} decimalScale={2} fixedDecimalScale={true} suffix={con.SUFFIX_COLOMBIAN_PESOS} />
                                : <NumberFormat 
                                    {...otherParameters}
                                    onFocus={e => {onFocus();e.target.select()}} 
                                    onClick={e => e.target.select()} 
                                    className="input-editing" 
                                    decimalScale={2} 
                                    fixedDecimalScale={2} 
                                    thousandSeparator={true} 
                                    suffix={con.SUFFIX_COLOMBIAN_PESOS}  
                                    onValueChange={(ob) => {setLocalSpot(ob.floatValue); setValue(ob.floatValue)}} 
                                    value={localSpot} 
                                    onBlur={() => onBlur(localSpot)}/>
                        }
                        </div>
                        <div className='cell-with-checkbox-command' title={ isAutomatic ? "SPOT automático, para manual deseleccione la caja" : "SPOT manual, para automático seleccione la caja"  }>
                            <input type={"checkbox"} checked={isAutomatic} onChange={e => setIsAutomatic(e.target.checked)}/>
                        </div>     
                    </div>                                 
                  
    </div>
    )
}



/**
 * Component that renders a cell containing a percentage value. Percentage values are assumed to be on a 0 (0%) to 1 (100%) scale.
 * @param {number} value - The number to be displayed
 * @param {(number) => void} setValue - The function that updates the given value
 * @param {boolean} editing - Boolean indicating if the cell is being edited or if its static
 * @param {boolean} [fixedRange = false] - Boolean indicating if the percentage will have a fix range that is, a value between 0 and 1
 * @param {() => void} [onFocus = () => true] - Function that will be executed on focus. After this function, the target.select() function is executed
 * @param {number} [decimalScale = 1] -  Number indicating the number of decimals the cell will show
 * @param {boolean} [heavyEffect = true] - If the change of the value of the cell has a heavy computational effect. If set to true, the setValue parameter will only be called on blur instead of onChange. 
 * @param {boolean} [evenRow=null] - A boolean indicating if the cell belongs to an even or uneven row (for coloring purposes in static mode). Set to null to ignore coloring. 
 * @param {object} otherParameters - Object containing the other parameters that will be passed directly into the InputGroup
 * @returns {JSX.Element} - The Cell with the corresponding percentage value
 */
 export const CellPercentage = ({value, setValue, editing, fixedRange = false, onFocus = () => true, decimalScale = 1, heavyEffect = true, evenRow=null,...otherParameters}) => {


    const cleanFun = (val) =>
    {
        val = fixedRange ? Math.max(0,Math.min(1, val/100)) : val/100        
        return val
    }

    // Local Value for HeavyEffect
    const [localValue, setLocalValue] = useState(() => cleanFun(100*value))

    useEffect(() => {
        setLocalValue(value)
    }, [value])
    


    const onChangeFun = (ob) => {

        if(!heavyEffect)
            setValue(cleanFun(ob.floatValue))
        else    
            setLocalValue(cleanFun(ob.floatValue))
    }

        
        return (
        <div className={`${(evenRow === null)? "" : evenRow ? "even-row":"uneven-row"} ${editing ? "" : "cell-static-content"}`}>
            {editing ? <NumberFormat {...otherParameters} value={100*localValue} 
                                                          onFocus={e => {onFocus(); e.target.select()}} 
                                                          onClick={e => e.target.select()} 
                                                          className="input-editing" 
                                                          thousandSeparator={true} 
                                                          suffix={con.SUFFIX_PERCENTAGE}  
                                                          onValueChange={onChangeFun} 
                                                          onBlur={() => heavyEffect ? setValue(localValue) : null} 
                                                          decimalScale={decimalScale}/>

                     : <NumberFormat {...otherParameters} value={100*localValue} 
                                                          thousandSeparator={true} 
                                                          displayType={'text'} 
                                                          suffix={con.SUFFIX_PERCENTAGE} 
                                                          decimalScale={decimalScale}/>}
         </div>
    )
}



/**
 * Component that renders a cell containing a an action (function) value
 * @param {() => void} [value = () => true] - The function to be linked
 * @param {string} [title = "Salvar"] - Boolean indicating if the cell is being edited or if its static
 * @returns {JSX.Element} - The Cell with the corresponding action value
 */
 export const CellAction = ({value = () => true, title="Salvar", ...otherParameters}) => {
    
    return (
    <div className={"cell-static-content"}>
        <FaSave {...otherParameters}
                style={{cursor: "pointer"}} 
                onClick={() => value()}
                title={title}/>
     </div>
    )
}



/**
 * Component that renders a cell containing a multiple option value
 * @param {number} value - The current option to be displayed
 * @param {(string) => void} setValue - The function that updates the given selected option
 * @param {boolean} editing - Boolean indicating if the cell is being edited or if its static
 * @param {array} options - An array with the unique options 
 * @param {(string) => string} [valueTranslator = (val) => val] - Function that receives an option and return the value that should be displayed.
 * @param {() => void} [onFocus = () => true] - Function that will be executed on focus. After this function, the target.select() function is executed
 * @param {boolean} [evenRow=null] - A boolean indicating if the cell belongs to an even or uneven row (for coloring purposes in static mode). Set to null to ignore coloring. 
 * @returns {JSX.Element} - The Cell with the corresponding multiple options value
 */
 export const CellMultipleValues = ({value, setValue, editing, options, valueTranslator = (val) => val, onFocus = () => true, evenRow=null}) => {
    
    const onChangeFun = (ob) => setValue(ob.target.value)

    return (
    <div className={`${(evenRow === null)? "" : evenRow ? "even-row":"uneven-row"} ${editing ? "" : "cell-static-content"}`}>
    {editing ? 
                <FormGroup className="multiple-choice-cell">
                    <div className="bp3-html-select" onFocus={onFocus}>
                        <select className="input-editing" 
                                        onChange={onChangeFun}
                                        value={value}>
                                         {options.map((op) => <option key={op} value={op}>{valueTranslator(op)}</option>)}
                                        
                        </select>
                        <span className="bp3-icon bp3-icon-double-caret-vertical"></span>
                    </div>
                </FormGroup> 


            : valueTranslator(value)}
</div>
)
}
