import { Alignment,Boundary,Button,Menu,MenuItem,Navbar, NavbarDivider, NavbarGroup, NavbarHeading, OverflowList, Popover, Position } from '@blueprintjs/core';
// TODO - Documentar
import React, {  useState } from 'react';

// Global constants
import * as con from "../../GlobalConstants"
import { formatDateForUser } from '../../utils/dateFunctions';
import { translateParameter } from '../../utils/translateFunctions';
import SimpleSpot from '../spot/SimpleSpot';


import "./Nav.css"

import logo from "./../../img/logo-vinco-dark.png"
import CustomNavButton, { CustomActionNavButton } from './CustomNavButton';
import { AuthenticationHook } from '../hooks/AuthenticationHook';
import { userLogOut } from '../../store/actions/authentication';
import { AllModelParameterHook } from '../hooks/ModelParameterHooks';
import CustomPopOverMenu from './CustomPopOverMenu';
import { RefSetCurrentPage } from '../hooks/NavegationHook';
import { RefUserAttributesHook } from '../hooks/UserAttributesHook';
import LoadingIconSmall from "../../templates/loading/LoadingIconSmall"
import { getVisualizedUser} from '../../store/logic/supportFunctions';
import Modal from '../../templates/popups/Modal';
import { formatAccordingToType} from '../../templates/Types'
import { RefTransactionsSummaryTableHook } from '../hooks/TransactionsSummaryTableHook';
import { UserLinesHook } from '../hooks/UserLinesHook';
import CustomUsersSelect from './CustomUsersSelect';
import CustomLineSelect from './CustomLineSelect';
import { RefApplicationParameterHook } from '../hooks/ApplicationParametersHooks';
import NumericVisualizer from './NumericVisualizer';
import CustomNavbarDivider from './CustomNavbarDivider';
import { saveUserInformation } from '../../store/actions/save';
import { createNotification } from '../../templates/notifications/Notifications';
import { navigateToPageInNewTab } from '../../utils/navegationFunctions';
import Clock from 'react-live-clock';


function Nav() {

    
    // Model Parameters
    // -----------------
    const modelParameters = AllModelParameterHook()

    // Authentication
    // -----------------
    const authentication = AuthenticationHook()

    // Current Page
    const setCurrentPage = RefSetCurrentPage()

    
    // Lines 
    const userLines = UserLinesHook()

    // User Avatar
    const avatarImage = RefUserAttributesHook(con.AVATAR_IMAGE)

    // Parameters saved
    const userInformationSaved = RefApplicationParameterHook(con.USER_INFORMATION_SAVED)

    // Show closing dialog
    const [showClosingDialog, setShowClosingDialog] = useState(() => false)
      
    // Transaction Summary
    // eslint-disable-next-line
    const [summaryRow, computing, hasSummaryTable] = RefTransactionsSummaryTableHook({onlySummary : true})

    // Navigation
    const navigate = RefSetCurrentPage();
   

  return(              
    <div>      
        <Modal show={showClosingDialog} doAccept={null}  doCancel={(e) => setShowClosingDialog(false)} showCancel={false}>
          <h3>¿Desea guardar los cambios antes de salir?</h3>
          <div className="confirmCancelParameterBox">
            {userInformationSaved[con.STATUS] === con.LOADING ? 
              <LoadingIconSmall/> : 
              <div>
                <Button text="Si" onClick={(e) => {
                  saveUserInformation(() => {
                    createNotification("Información Guardada");
                    localStorage.removeItem('alerts');
                    userLogOut();
                  })
                }} />
                <Button text="No" onClick={(e) => {
                  localStorage.removeItem('alerts');
                  userLogOut();
                }} />
              </div>
            }
          </div>
        </Modal>

      
        <Navbar fixedToTop={true} className="customNavBar noCarret" style={{height : `${con.NAV_BAR}vh`}}>            
            <div className="topContainer">
                <NavbarGroup align={Alignment.LEFT} className="customNavBarGroup">
                  <img src={logo} className="logoImage" alt="" width="auto" height={con.NAV_IMAGE_HEIGHT} onClick={() => setCurrentPage(con.PAGE_START)}/>    
                  <NavbarDivider />
                </NavbarGroup>                
                <NavbarGroup align={Alignment.LEFT} className="customNavBarGroup">
                  <div style={{maxWidth : "60vw"}}>                                         
                    <OverflowList collapseFrom={Boundary.END}                                  
                                  items={con.NAVIGATION_PAGES_ARRAY.filter(elem => 
                                        authentication[con.USER][con.IS_STAFF] || elem[con.NAV_LEVEL] !== con.STAFF
                                    )} 
                                  overflowRenderer={(elements) => 
                                    <Popover content={<Menu >                                      
                                      {
                                        elements.map((el,i) =>
                                        buildCollapsedNavigationElement(el,i, navigate))
                                        
                                      }
                                      </Menu>} position={Position.RIGHT_TOP}>
                                  <Button icon={"menu"} 
                                          className={`bp3-minimal customNavButton`} />
                                  </Popover> }
                                  visibleItemRenderer={(el,i) => buildNavigationElement(el, i)} 
                                  minVisibleItems={1}
                                  />                                                                                                                          
                </div> 
                </NavbarGroup>                
                <NavbarGroup align={Alignment.RIGHT} className="customNavBarGroup" >
                    <CustomNavbarDivider /> 
                    {userInformationSaved[con.STATUS] === con.LOADING ? 
                      <div style={{marginLeft : "4px", marginRight : "4px"}}>
                        <LoadingIconSmall/>
                      </div> :
                      <CustomActionNavButton icon="floppy-disk"  onClick={(ev) => saveUserInformation(()=> createNotification("Información Guardada"))} title="Guardar Parametros"/>
                    }
                    <CustomNavbarDivider /> 
                    { avatarImage != null ? <img src={avatarImage} alt="Logo" width="auto" style={{cursor:"pointer", marginLeft : "2px", marginRight : "2px"}} height={con.NAV_IMAGE_HEIGHT} onClick={() => navigate(con.PAGE_USER_PROFILE)}/>
                                           : <CustomActionNavButton icon="person" onClick={() => navigate(con.PAGE_USER_PROFILE)}/>
                     } 
                     {
                       !authentication[con.USER][con.IS_STAFF] ? <NavbarHeading style={{marginLeft : "1vh"}}> {authentication.isAuthenticated ? authentication.user.username : "Anónimo"}</NavbarHeading>
                                                              : <CustomUsersSelect />
                                                              
                     } 
                     <CustomNavbarDivider />
                     {
                       userLines.length === 0 ? <NavbarHeading style={{marginLeft : "1vh", fontSize : "1vw"}}> Cargando Lineas </NavbarHeading>
                                                              : <CustomLineSelect /> 
                                                              
                     }                               
                    <CustomNavbarDivider /> 
                    <CustomActionNavButton icon="power"  text="" onClick={() => setShowClosingDialog(true)} title="Salir"/>                
                </NavbarGroup>
            </div>
            <div style={{height : "0.2vh", backgroundColor : "var(--primary-color)", opacity:0.7}}></div>
            <NavbarGroup className="customNavBarGroup">      
              <div className="parameters">
                <p><strong>Tipo Cliente: </strong> {translateParameter(modelParameters[con.CLIENT_TYPE])}</p>
                <p><strong>Periodicidad: </strong> {translateParameter(modelParameters[con.PERIODICITY])}</p>
                <p><strong>Plazo en Meses: </strong> {modelParameters[con.TERM_PERIODICITY]}</p>
                <p><strong>Fecha: </strong> {formatDateForUser(modelParameters[con.CURRENT_DATE])}</p>
                <p><strong>Hora: </strong> <Clock format={'HH:mm:ss'} ticking={true} timezone={'America/Bogota'} /></p>
                <SimpleSpot />
              </div>                                
            </NavbarGroup>    
            <div style={{height : "0.1vh", backgroundColor : "var(--primary-color)", opacity:0.7}}></div>
            <NavbarGroup className="customNavBarGroup">      
              <div className="parameters">
                <NumericVisualizer title={"Exposición Total:"} value={hasSummaryTable ?  summaryRow[con.EXPOSURE_AMOUNT] : 0} formatValue={value => formatAccordingToType(con.MONEY_THOUSANDS, value, {[con.CURRENCY]: con.MONEY_USD})}/>                
                <NumericVisualizer title={"Cobertura Total:"} value={hasSummaryTable ?  summaryRow[con.COVERAGE_AMOUNT] : 0} formatValue={value => formatAccordingToType(con.MONEY_THOUSANDS, value, {[con.CURRENCY]: con.MONEY_USD})} inverse={true}/>
                <NumericVisualizer title={"Porcentaje Cobertura:"} value={hasSummaryTable ? summaryRow[con.COVERAGE_PERCENTAGE]: 1} formatValue={value => formatAccordingToType(con.PERCENTAGE, value)} inverse={true}/>              
                <p><strong>Posición en Banda: </strong>{ hasSummaryTable  ? summaryRow[con.POSITION_IN_BANDS]: ""}</p>
                <NumericVisualizer title={"VaR Oportunístico:"} value={hasSummaryTable ?  summaryRow[con.VAR] : 0} formatValue={value => formatAccordingToType(con.MONEY_MILLIONS, value, {[con.CURRENCY]: con.MONEY_COP})}/>
              </div>                                
            </NavbarGroup>   
            <div style={{height : "0.4vh", backgroundColor : "var(--primary-color)", opacity:0.7}}></div>

        </Navbar>
      </div>  )

    
}

export default Nav;


// Support Functions

 // Navigation element builder
 export const buildNavigationElement = (elem, key) =>
 {

   // Replaces Client Placeholder
   const user = getVisualizedUser()
   if(user !== null && user !== undefined)
   {
     if(elem[con.NAV_TYPE] === con.NAV_SINGLE)
       elem = {...elem, [con.NAV_TEXT] : elem[con.NAV_TEXT].replace(con.CLIENT_PLACEHOLDER, user[con.USERNAME])}
     else
       elem = {...elem, [con.NAV_MENU_TEXT] : elem[con.NAV_MENU_TEXT].replace(con.CLIENT_PLACEHOLDER, user[con.USERNAME]),
                        [con.NAV_MENU_TEXTS] : elem[con.NAV_MENU_TEXTS].map(text => text.replace(con.CLIENT_PLACEHOLDER, user[con.USERNAME]))}
   }

   
   if(elem.type === con.NAV_SINGLE)
     return(<CustomNavButton key={key} {...elem}/>)
   else
     return(<CustomPopOverMenu key={key} {...elem}/>)

 }

 export const buildCollapsedNavigationElement = (elem, key, navigate) =>
 {

   // Replaces Client Placeholder
   const user = getVisualizedUser()
   if(user !== null && user !== undefined)
   {
     if(elem[con.NAV_TYPE] === con.NAV_SINGLE)
       elem = {...elem, [con.NAV_TEXT] : elem[con.NAV_TEXT].replace(con.CLIENT_PLACEHOLDER, user[con.USERNAME])}
     else
       elem = {...elem, [con.NAV_MENU_TEXT] : elem[con.NAV_MENU_TEXT].replace(con.CLIENT_PLACEHOLDER, user[con.USERNAME]),
                        [con.NAV_MENU_TEXTS] : elem[con.NAV_MENU_TEXTS].map(text => text.replace(con.CLIENT_PLACEHOLDER, user[con.USERNAME]))}
   }


   if(elem[con.NAV_TYPE] === con.NAV_SINGLE)
     return(<MenuItem key={key} icon={elem[con.NAV_ICON]} text={elem[con.NAV_TEXT]} onClick={() => {navigate(elem.pageID)}} onContextMenu={(event) => {event.preventDefault();navigateToPageInNewTab(elem.pageID)}}/>)
   else
     return(
       <MenuItem key={key} icon={elem.menuIcon} text={elem.menuText}>
           {elem.linkTexts.map((text,i) => 
             <MenuItem key={`${key}-${i}`} text={text} onClick={() => {navigate(elem.linkPages[i])}} onContextMenu={(event) => {event.preventDefault();navigateToPageInNewTab(elem.linkPages[i])}}/>)}            
     </MenuItem>
     )

 }