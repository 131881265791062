// Continuos Bar and Multiple Line Chart Hook
import  {useMemo, useRef } from 'react'
import { SpeedometerChart } from '../classes/SpeedometerChart'

 /* Hook returns:
     - Reference for the rendered plot
     - Object of type ChartClass
 */
 export const SpeedometerChartHook = ({parameters}) =>
 {
     
    const objectReference = useRef()
    const initialParameters = useRef(parameters)
    const chart = useMemo(() => new SpeedometerChart(initialParameters.current, objectReference),[])    
 
    return([objectReference, chart])
 
 }

