import React from 'react';
import { CellText } from '../../templates/tables/Cells';

function SecondLeftRow({ data, styles, isSmallScreen }) {
    return (
        <div className="flex-fila" style={{ padding: `1px 10px`, textAlign: 'center', fontSize: styles.fontSize }}>
            {!isSmallScreen && (
                <div className="flex-celda" style={{ padding: styles.padding }}>
                    <CellText value={data.cierreAnterior} editing={false} />
                </div>
            )}
            {!isSmallScreen && (
                <div className="flex-celda" style={{ padding: styles.padding }}>
                    <CellText value={data.diffCierreAnterior} editing={false} />
                </div>
            )}
            <div className="flex-celda" style={{ padding: styles.padding }}>
                <CellText value={data.trmReal} editing={false} />
            </div>
            <div className="flex-celda" style={{ padding: styles.padding }}>
                <CellText value={data.volatilidadJornada} editing={false} />
            </div>
            <div className="flex-celda" style={{ padding: styles.padding }}>
                <CellText value={`${data.variacionSemanal.toFixed(2)} (${data.variacionSemanalPorcentaje.toFixed(2)}%)`} editing={false} />
            </div>
            <div className="flex-celda" style={{ padding: styles.padding }}>
                <CellText value={data.indicadorAgotamiento} editing={false} />
            </div>
        </div>
    );
}

export default SecondLeftRow;
