// TODO - Documentar
// Scenarios Hook

import { useSelector } from 'react-redux'

import * as con from "../../GlobalConstants"

export const ScenariosHook = () =>
{
    const sce = useSelector((state) => state[con.STORE][con.REDUCER_SCENARIOS])
    return(sce)
}


export const StateSingleScenario = (scenarioId) =>
{
    const sce = useSelector((state) => state[con.STORE][con.REDUCER_SCENARIOS][scenarioId])

    return(sce)
}