// TODO - Documentar

import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import * as con from "../../GlobalConstants"
import { surrogateActive } from '../../store/logic/supportFunctions';

/**
 * Hook for the authentication
 */
export function AuthenticationHook() {

    const value = useSelector(state => state[con.STORE][con.REDUCER_AUTHENTICATION])

    return(value);
}

/**
 * Hook for a specific authentication
 */
 export function RefAuthenticationParameterHook(key) {

    const value = useSelector(state => state[con.STORE][con.REDUCER_AUTHENTICATION][key])

    return(value);
}

/**
 * Ref for User
 */
 export function RefUser() {

    const value = useSelector(state => state[con.STORE][con.REDUCER_AUTHENTICATION][con.USER])

    return(value);
}


/**
 * Ref for Token
 */
 export function RefToken() {

    const value = useSelector(state => state[con.STORE][con.REDUCER_AUTHENTICATION][con.TOKEN])

    return(value);
}

/**
 * Ref for surrogate user
 */
 export function RefSurrogateUser() {

    const value = useSelector(state => state[con.STORE][con.REDUCER_AUTHENTICATION][con.SURROGATE_USER])

    return(value);
}


/**
 * Ref for the user being visualized
 */
 export function RefVisualizedUser() {

    // Users
    const user = RefUser()
    const surrogateUser = RefSurrogateUser()

    // Return value
    const [currentUser, setCurrentUser] = useState(() => surrogateActive() ? surrogateUser: user)

    useEffect(() => {
      
        setCurrentUser(surrogateActive() ? surrogateUser: user)

    }, [user, surrogateUser])
    
    
    return(currentUser)
}

export const IsAuthenticatedHook = () =>
{
    const value = useSelector(state => state[con.STORE][con.REDUCER_AUTHENTICATION][con.IS_AUTHENTICATED])

    return(value);
}


export const IsStaff = () =>
{
    // User
    const user = RefUser()

    return(user === null ? false : user[con.IS_STAFF])
}

/* Ref for if user is surrogate */
export const RefIsSurrogateActive = () =>
{
    const auth = AuthenticationHook()
    const [isActive, setIsActive] = useState(() => surrogateActive())

    useEffect(() => {
        setIsActive(surrogateActive())
    }, [auth])

    return(isActive)
    
}






