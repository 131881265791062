// TODO - Documentar
import React, { useState } from 'react'
import { StaffUserLineComments } from '../hooks/UserLinesHook'
import * as con from "../../GlobalConstants"
import { Button, Text } from '@blueprintjs/core'
import { StaticTable } from '../../templates/tables/StaticTable'
import { TextAreaElement } from '../../templates/FormElement'
import { createUserLineComment} from "../../store/actions/userLines"
import { getUser } from '../../store/logic/supportFunctions'
import { EditApplicationParameterHook, RefApplicationLoadingParameterHook } from '../hooks/ApplicationParametersHooks'
import LoadingIcon from '../../templates/loading/LoadingIcon'
import { RefSetCurrentPage } from '../hooks/NavegationHook'
import { setSurrogateUserById } from '../../store/actions/authentication'

function AdminUserLineComments({selectedLineSummary}) {

   const columns = [{
        [con.NAME] : 'FECHA', 
        [con.TYPE] : con.DATE_TIME,                         
        [con.ID] : con.DATE_CREATED
      },{
        [con.NAME] : 'USUARIO', 
        [con.TYPE] : con.TEXT,                         
        [con.ID] : con.COMMENTING_USER_USERNAME
      },{
        [con.NAME] : 'COMENTARIO', 
        [con.TYPE] : con.TEXT,                         
        [con.ID] : con.COMMENT
      }]

    // Surrogate Selected Line
    const setSelectedUserLine = EditApplicationParameterHook(con.SELECTED_LINE) 

    // Navigation
    const navigate = RefSetCurrentPage();

    // Navigate to Position Summary
    const navigateToPositionSummary = () =>
    {

        // Sets the surrogate user
        setSurrogateUserById(selectedLineSummary[con.USER_ID])
        
        // Sets The line
        setSelectedUserLine(selectedLineSummary[con.LINE_ID])
        
        // Sets the page
        navigate(con.PAGE_BAND_POSITION)
    }

    // Loading Parameter
    const loadingAddingComment = RefApplicationLoadingParameterHook(con.USER_LINE_COMMENT_ADDED)

    // All comments
    const comments = StaffUserLineComments(selectedLineSummary[con.LINE_ID])

    // New Comment
    const [newComment, setNewComment] = useState(null)

    // Add Comment
    const addComment = () => {

      if(newComment !== null && newComment !== "")
      {
        let userId = getUser()[con.ID]
        // Creates new Comment
        createUserLineComment(selectedLineSummary[con.LINE_ID], selectedLineSummary[con.USER_ID], userId, newComment, () => setNewComment(""))
  
      }

    }
    
    
  return (
    <div>
       
        <div className='columnParameterBox'>
          <Button text={"Ir a Posición"} onClick={navigateToPositionSummary}/>
          <h2>Comentarios</h2>
          <TextAreaElement value={newComment} setValue={setNewComment} name={"Nuevo Comentario"} disabled={loadingAddingComment === con.LOADING}/>
          {
            loadingAddingComment === con.LOADING 
            ? <LoadingIcon />                                     
            : <Button text={"Agregar"} disabled={newComment === null || newComment === ""} onClick={addComment}/>
          }
          
          <div style={{marginTop : "2vh", marginBottom : "5vh", width : "98%"}}>
          {comments.length > 0 ? <StaticTable columnArray={columns} dataDictionary={Object.assign({}, ...comments.map((x,i) => ({[x[con.ID]]: x})))}/>
                               : <Text>La linea no tiene comentarios</Text>}
          </div>
        </div>

        
    </div>
  )
}

export default AdminUserLineComments