// TODO - Documentar
import React, { useEffect } from 'react'
import * as con from "../GlobalConstants"
import "./Pages.css"
import { MediumLabel, SmallLabel } from '../templates/label/Labels'
import BandsSummaryTable from '../components/bands/BandsSummaryTable'
import { CoveragesGridBandPositionSummary } from '../components/coveragesGrid/CoveragesGridBandPositionSummary'
import CoveragesGridControls from '../components/coveragesGrid/CoveragesGridControls'
import { RefApplicationParameterHook } from '../components/hooks/ApplicationParametersHooks'
import { RefTransactionsSummaryTableHook } from '../components/hooks/TransactionsSummaryTableHook'
import CoveragesGridTable from '../components/coveragesGrid/CoveragesGridTable'
import { getApplicationParameter, setApplicationParameter } from '../store/actions/applicationParameters'
import { BandSummaryHook } from '../components/hooks/BandsHooks'
import { RefModelParameterHook } from '../components/hooks/ModelParameterHooks'

function CoveragesGridPage() {

    const desiredBand = RefApplicationParameterHook(con.COVERAGES_GRID_DESIRED_BAND)

    // Transactions Summary Hook
    // eslint-disable-next-line 
    const [_, summaryRow, computing, hasSummaryTable] = RefTransactionsSummaryTableHook()

    // Client Type
    const clientType = RefModelParameterHook(con.CLIENT_TYPE)



    // Bands
    const bandSummaryData = BandSummaryHook() 


    useEffect(() => {

        if(!computing && hasSummaryTable)
        {
            setApplicationParameter( con.COVERAGES_GRID_AMOUNT_TO_BUY, Math.max(0,bandSummaryData[desiredBand]*summaryRow[con.EXPOSURE_AMOUNT] - summaryRow[con.COVERAGE_AMOUNT]))
        }
        
    }, [desiredBand, bandSummaryData, summaryRow, computing, hasSummaryTable])


    useEffect(() => {

        if(clientType === con.IMPORTER)
        {
            setApplicationParameter(con.COVERAGES_GRID_STOP_LOSS, getApplicationParameter(con.SPOT)+100)
            setApplicationParameter(con.COVERAGES_GRID_INITIAL_LEVEL, getApplicationParameter(con.SPOT))
            setApplicationParameter(con.COVERAGES_GRID_FINAL_LEVEL, getApplicationParameter(con.SPOT)-100)
        }
        else if(clientType === con.EXPORTER)
        {
            setApplicationParameter(con.COVERAGES_GRID_STOP_LOSS, getApplicationParameter(con.SPOT)-100)
            setApplicationParameter(con.COVERAGES_GRID_INITIAL_LEVEL, getApplicationParameter(con.SPOT))
            setApplicationParameter(con.COVERAGES_GRID_FINAL_LEVEL, getApplicationParameter(con.SPOT)+100)
        }

    }, [clientType])
    


    return (
        <div style={{height : con.USABLE_SCREEN_SIZE + "vh"}}>               
            <div className='gridBox' >
                <div style={{flexBasis : "25%"}}>
                    <div style={{marginBottom : "2vh", marginLeft : "5%", marginRight : "5%"}}>
                        <CoveragesGridControls/>
                    </div>
                </div>
                <div style={{flexBasis : "75%"}}>                
                    <div>
                        <SmallLabel text="Banda de Coberturas"/>
                        <div style={{marginBottom : "2vh", marginLeft : "10%", marginRight : "10%"}}>
                            <BandsSummaryTable selectedBand={desiredBand}/>
                        </div>
                    </div>
                    <div>
                        <SmallLabel text="Posicionamiento Actual"/>
                        <div style={{marginBottom : "2vh", marginLeft : "10%", marginRight : "10%"}}>
                            <CoveragesGridBandPositionSummary summaryRow={summaryRow} computing={computing} hasSummaryTable={hasSummaryTable}/>
                        </div>
                    </div>
                </div>
            </div>
            <div style={{marginTop : "2vh", marginBottom : "5vh",marginLeft : "10%", marginRight : "10%"}}>
                <MediumLabel text="Grilla de Coberturas"/>
                <CoveragesGridTable summaryRow={summaryRow} computing={computing} hasSummaryTable={hasSummaryTable}/>
            </div>
        </div>
    )
}

export default CoveragesGridPage
