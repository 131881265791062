// Generic Chart class
import * as cc from "../ChartConstants";
import * as d3 from "d3";
import { GenericChart } from "./GenericChart";
import { round } from "../../../GlobalFunctions";



/**
 * Data must be passed with the following structure
 * Object with the following properties:
 *  number_of_segments : The number of segments to include,
 *  segment_ids : the segment ids for selection
 *  segment_labels : the segment labels to display
 *  selected_segment_id : the selected segment (for the central label)
 *  needle_percentage : The needle percentage to indicate its orientation Must be a value between 0 and 1. 0 indicates left anf 1 indicates right.      
 */


const DEFAULT_PARAMETERS = { [cc.MARGIN] : { [cc.TOP]: 30, [cc.RIGHT]: 30, [cc.BOTTOM]: 30, [cc.LEFT]: 20 },
                            [cc.PROPORTIONAL_WIDTH] : 600,
                            [cc.PROPORTIONAL_HEIGHT] : 600,
                            [cc.SPEEDOMETER_RADIUS_START] : 200,
                            [cc.SPEEDOMETER_RADIUS_END] : 180,     
                             [cc.SPEEDOMETER_START_ANGLE] : Math.PI,
                             [cc.SPEEDOMETER_ANGLE_WIDTH] : Math.PI,
                             [cc.SPEEDOMETER_MIDDLE_DOT_SIZE] : 10,
                             [cc.SPEEDOMETER_MIDDLE_DOT_COLOR] : "var(--text-color-highlighted)",
                             [cc.SPEEDOMETER_NEEDLE_PADDING] : 20,
                             [cc.SPEEDOMETER_NEEDLE_WIDTH] : 3,
                             [cc.SPEEDOMETER_LABELS_PADDING] : 35,
                             [cc.SPEEDOMETER_LABELS_SIZE] : 15,
                             [cc.SPEEDOMETER_LABELS_COLOR] : "var(--text-color-highlighted)",
                             [cc.SPEEDOMETER_MAIN_LABEL_PADDING] : 105,
                             [cc.SPEEDOMETER_MAIN_LABEL_SIZE] : 40,
                             [cc.SPEEDOMETER_SECOND_LABEL_PADDING] : 45,
                             [cc.SPEEDOMETER_SECOND_LABEL_SIZE] : 20}



export class SpeedometerChart extends GenericChart{

    // General constructor
    constructor(parameters, objectReference){

        super({...DEFAULT_PARAMETERS, ...parameters}, objectReference)

        // Computes mid points
        this.midPoint = [this.parameters[cc.MARGIN][cc.LEFT]+ this.width/2,this.parameters[cc.MARGIN][cc.TOP] +  2*this.height/3]

        // Angles
        this.speedometerStart = this.parameters[cc.SPEEDOMETER_START_ANGLE]
        this.speedometerWith = this.parameters[cc.SPEEDOMETER_ANGLE_WIDTH]

        // Radius
        this.radiusStart = this.parameters[cc.SPEEDOMETER_RADIUS_START]
        this.radiusEnd = this.parameters[cc.SPEEDOMETER_RADIUS_END]

        // Needle
        this.needlePadding = this.parameters[cc.SPEEDOMETER_NEEDLE_PADDING]
        this.needleRadiusStart = this.needlePadding
        this.needleRadiusEnd = this.radiusStart - 1.5*this.needlePadding

        // Text
        this.textRadius = this.radiusEnd + this.parameters[cc.SPEEDOMETER_LABELS_PADDING]


    }


    build(initialData)
    {
        super.build(initialData)

        // Number of segments
        this.numberOfSegments = initialData[cc.NUMBER_OF_SEGMENTS]


        this.colorScheme = this.getColorScheme(this.numberOfSegments)

        // Adjusts angles so that it behaves in the traditional trigonometric sense and goes from left to right
        var arc = d3.arc()
                    .padAngle(.05)
                    .innerRadius(this.radiusStart)
                    .outerRadius( this.radiusEnd)
                    .startAngle((i) => Math.PI/2 - this.getEndAngle(i)) 
                    .endAngle((i) => Math.PI/2 - this.getStartAngle(i)); 

        const ids = [...Array(this.numberOfSegments).keys()]
        
        // Arcs        
        const arcGroup = this.mainComponent
                        .append("g")
                        .attr("transform", `translate(${this.midPoint[0]}, ${this.midPoint[1]})`)
                        .selectAll("path")
                        .data(ids);

        arcGroup
                .join("path")
                .attr("fill", (d) => this.colorScheme(d))
                .attr("d", arc);

        // Labels
        this.mainComponent
                .append("g")           
                .selectAll("text")
                .data(ids)
                .join('text')                
                .text((i) => initialData[cc.SEGMENT_LABELS][i])    
                .attr('x', 0)
                .attr('y', 0)
                .attr("fill", this.parameters[cc.SPEEDOMETER_LABELS_COLOR])
                .attr('text-anchor', 'middle')
                .style('font-size', this.parameters[cc.SPEEDOMETER_LABELS_SIZE]+ "px")
                .attr("transform", i => `translate(${this.midPoint[0] + this.textRadius*Math.cos(this.getMiddleAngle(i))}, ${this.midPoint[1] - this.textRadius*Math.sin(this.getMiddleAngle(i))})rotate(${90 - this.getMiddleAngle(i)*180/Math.PI})`)
          
                
        // Main Label
        let selectedLabelId = initialData[cc.SEGMENT_IDS][Math.floor(initialData[cc.SEGMENT_IDS].length/2)]
        let selectedLabelPos = initialData[cc.SEGMENT_IDS].findIndex(id => id === selectedLabelId)

        this.mainLabel = this.mainComponent.append("g")
                            .attr("transform", `translate(${this.midPoint[0]}, ${this.midPoint[1]})`)
                            .append("text")
                            .text(initialData[cc.SEGMENT_LABELS][selectedLabelPos])
                            .attr('x', 0)
                            .attr('y', this.parameters[cc.SPEEDOMETER_MAIN_LABEL_PADDING])
                            .attr("fill", this.colorScheme(selectedLabelPos))
                            .attr('text-anchor', 'middle')
                            .style('font-size', this.parameters[cc.SPEEDOMETER_MAIN_LABEL_SIZE]+ "px")


        // Second Label
        this.secondLabel = this.mainComponent.append("g")
                            .attr("transform", `translate(${this.midPoint[0]}, ${this.midPoint[1]})`)
                            .append("text")
                            .text(`${round(100*initialData[cc.NEEDLE_PERCENTAGE])}%`)
                            .attr('x', 0)
                            .attr('y', this.parameters[cc.SPEEDOMETER_SECOND_LABEL_PADDING])
                            .attr("fill", this.colorScheme(selectedLabelPos))
                            .attr('text-anchor', 'middle')
                            .style('font-size', this.parameters[cc.SPEEDOMETER_SECOND_LABEL_SIZE]+ "px")


        // Center Dot
        this.centerDot = this.mainComponent.append("g")
                          .attr("transform", `translate(${this.midPoint[0]}, ${this.midPoint[1]})`)
                          .append("circle")
                          .attr('cx', 0)
                          .attr('cy', 0)
                          .style("fill", this.colorScheme(selectedLabelPos))
                          .attr("r", this.parameters[cc.SPEEDOMETER_MIDDLE_DOT_SIZE])

        let needlePath = d3.path();

        needlePath.moveTo(0, -1*this.needleRadiusStart);
        needlePath.lineTo(0, -1*this.needleRadiusEnd);

        // needle
        this.needle = this.mainComponent.append("g")
                          .attr("transform", `translate(${this.midPoint[0]}, ${this.midPoint[1]})`)
                          .append("path")
                          .attr("stroke",this.colorScheme(selectedLabelPos))
                          .attr("fill", "none")
                          .attr('stroke-width', this.parameters[cc.SPEEDOMETER_NEEDLE_WIDTH])
                          .attr("d", needlePath)

        this.updateData(initialData)
        
    }

    updateData(newData)
    {
        super.updateData(newData)   

        let selectedLabelPos = newData[cc.SEGMENT_IDS].findIndex(id => id === newData[cc.SELECTED_SEGMENT_ID])

        // Needle
        this.needle.transition()
                    .duration(this.parameters[cc.DATA_CHANGE_TRANSITION_TIME])                 
                    .attr("transform", `rotate(${90 - this.getCorrespondingAngle(newData[cc.NEEDLE_PERCENTAGE])*180/Math.PI})`)


        // Main Label
        this.mainLabel.transition()
                    .duration(this.parameters[cc.DATA_CHANGE_TRANSITION_TIME])                 
                    .text(newData[cc.SEGMENT_LABELS][selectedLabelPos])
                    .attr("fill", this.colorScheme(selectedLabelPos))

        this.secondLabel.transition()
                    .duration(this.parameters[cc.DATA_CHANGE_TRANSITION_TIME])  
                    .text(`${round(100*newData[cc.NEEDLE_PERCENTAGE])}%`)
                    

    }

    getColorScheme(numElements)
    {

        return(d3.scaleOrdinal().domain([...Array(numElements).keys()])
                .range(d3.schemeRdBu[numElements]))
    }


    // Support
    getStartAngle(i)
    {
        return(this.speedometerStart - i*this.speedometerWith/this.numberOfSegments)
    }

    getEndAngle(i)
    {
        return(this.speedometerStart - (i+1)*this.speedometerWith/this.numberOfSegments)
    }

    getMiddleAngle(i)
    {
        return(this.getStartAngle(i) + (this.getEndAngle(i) - this.getStartAngle(i))/2)
    }

    getCorrespondingAngle(percentage)
    {
        return(Math.PI*(1-percentage))
    }





}