// TODO - Documentar
import React from 'react'
import {TransactionsSummary} from '../components/transactionsSummary/TransactionsSummary'
import { CurrentPositionParameters } from '../components/parameters/CurrentPositionParameters'
import { USABLE_SCREEN_SIZE } from '../GlobalConstants'
import { LargeLabel } from '../templates/label/Labels'

function CurrentPositionPage() {
    
    return (
        <div style={{minHeight: USABLE_SCREEN_SIZE + "vh"}}>
            <LargeLabel text="Posición Actual" />   
            <CurrentPositionParameters />
            <div style={{ paddingLeft : '15px', paddingRight : '15px'}}>
                <TransactionsSummary />
            </div>
        </div>
    )
}

export default CurrentPositionPage
