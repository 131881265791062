// Actions for the scenarios reducer

import axios from 'axios';
import * as types from "./types"
import store from '../store';
import * as con from "./../../GlobalConstants"
import { getGeneratorScenarioFunctionByMonthlyValuesReference, getGeneratorScenarioFunctionByReference} from "../../utils/scenarioFunctions"



// Get scenarios
export const getModelScenarios = () =>
{

    // Gets the historic scenarios
    axios.get(con.rest_base_url + '/scenarios/api/historic/')
            .then(res => {

                // Builds from reference
                let historic_scenarios = res.data.map((sce) => {

                    // Extracts the days and values
                    let values = Array(13).fill(1).map((_,i) => {return(sce[`month_${i}`])})
                    
                    return({
                        [con.ID] : sce.scenario_id,
                        [con.NAME] : sce.name,
                        [con.GENERATING_FUNCTION] : getGeneratorScenarioFunctionByMonthlyValuesReference(values,false),
                        [con.MONTHLY_VALUES] : values, 
                            })
                    })
                
                // Converts to object
                historic_scenarios = Object.assign({},...(historic_scenarios.map((esc) => {return({[esc[con.ID]]:esc})})))

                // Dispatches
                store.dispatch({
                        type : types.REPLACE_SCENARIOS,
                        payload : historic_scenarios
                    })
                
            })
            .catch(err => console.log(err))


        // Gets the Bank of Republic Scenarios
        axios.get(con.rest_base_url + '/scenarios/api/bank_republic_current/')
        .then(res => {

            let bor_data = res.data

            // Days
            let days = [6*con.DAYS_IN_MONTH, 12*con.DAYS_IN_MONTH]

            // Values
            let values_min = [bor_data['min_6'],bor_data['min_12']]
            let values_avg = [bor_data['avg_6'],bor_data['avg_12']]
            let values_max = [bor_data['max_6'],bor_data['max_12']]

            // Constructs the object
            let bor_scenarios = {
                [con.SCENARIO_BOR_MIN] : {
                        [con.ID] : con.SCENARIO_BOR_MIN,
                        [con.NAME] : con.SCENARIO_BOR_MIN_NAME,
                        [con.GENERATING_FUNCTION] : getGeneratorScenarioFunctionByReference(days,
                                                                          values_min,
                                                                          true)},    
                [con.SCENARIO_BOR_AVG] : {
                        [con.ID] : con.SCENARIO_BOR_AVG,
                                [con.NAME] : con.SCENARIO_BOR_AVG_NAME,
                                [con.GENERATING_FUNCTION] : getGeneratorScenarioFunctionByReference(days,
                                                                                  values_avg,
                                                                                  true)},
                [con.SCENARIO_BOR_MAX] : {
                    [con.ID] : con.SCENARIO_BOR_MAX,
                            [con.NAME] : con.SCENARIO_BOR_MAX_NAME,
                            [con.GENERATING_FUNCTION] : getGeneratorScenarioFunctionByReference(days,
                                                                              values_max,
                                                                              true)} 
                                                                        
            }

            // Dispatches
            store.dispatch({
                    type : types.REPLACE_SCENARIOS,
                    payload : bor_scenarios
                })            
        })
        .catch(err => console.log(err))



        // Gets the vinco scenarios
        axios.get(con.rest_base_url + '/scenarios/api/vinco_current/')
        .then(res => {

            let vinco_esc_data = res.data

            // Extracts the days and values
            let values = Array(13).fill(1).map((_,i) => vinco_esc_data[`month_${i}`])

            
            let vinco_scenario = {
                    [con.ID] : con.SCENARIO_VINCO,
                    [con.NAME] : con.SCENARIO_VINCO_NAME,
                    [con.GENERATING_FUNCTION] : getGeneratorScenarioFunctionByMonthlyValuesReference(values,false, true),
                    [con.MONTHLY_VALUES] : values
                        }

            // Dispatches
            setModelScenario(con.SCENARIO_VINCO, vinco_scenario)
            
        })
        .catch(err => console.log(err))


}

// Set Model Scenario 
export const setModelScenario = (id, value) =>
{    
    store.dispatch({
        type : types.SET_SCENARIO,
        payload : {[con.ID] : id, [con.VALUE] : value}
    })
}


