// Exposures
// TODO - Documentar
import React from 'react'
import * as con from "../../GlobalConstants"

import { EDIT_COMMAND, VIEW_ANNOTATIONS_COMMAND } from '../transactionTable/TransactionTableConstants'

// Style
import "../ComponentsGlobalStyle.css"
import { TransactionTable } from '../transactionTable/TransactionTable'
import { Account, getTransactionsAnnotationFunction } from '../../store/actions/transactions'
import TransactionTableVisualizer from './TransactionTableVisualizer'
import { AccountsHook } from '../hooks/TransactionsHooks'
import { filterObject } from '../../GlobalFunctions'
import { RefModelParameterHook } from '../hooks/ModelParameterHooks'
import { allAccountColumns, selectedAccountColumns } from '../../utils/transactionFunctions'

function TableAccounts() {

  // Transaction
  const trans = AccountsHook()

  // Client Type
  const clientType = RefModelParameterHook(con.CLIENT_TYPE)
    
  // Columns
  const columns = selectedAccountColumns.map(col => allAccountColumns[col[con.ID]]) 

  return (         
            <TransactionTableVisualizer transactionType={con.ACCOUNTS}>             
                    <TransactionTable   transactionType={con.ACCOUNTS}
                                        columnArray={columns} 
                                        dataDictionary={clientType === con.IMPORTER ? trans : filterObject(trans, acc => acc[con.ACCOUNT_TYPE] === con.NATURAL_COVERAGE) } 
                                        setRow={(id, vals) => Account.set(id, vals)} 
                                        getAnnotations={getTransactionsAnnotationFunction(con.ACCOUNTS)}
                                        commands={[EDIT_COMMAND, VIEW_ANNOTATIONS_COMMAND]}
                                        includeAddition={false}
                          />
            </TransactionTableVisualizer>
 
  )
}

export default TableAccounts