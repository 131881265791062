// Collection of functions related to vinco markets scenarios

/**
 * Functions for scenario managing. Each scenario translates to a generating function. This function should receive a positive
 * float for the current spot and an integer, indicating the day, to returns the corresponding dolar cost.
 */

// Statistics
import * as jStat from "jstat/dist/jstat";

// Global constants
 import * as con from "../GlobalConstants"


// Constants for scenario direction
export const UP = 1
export const DOWN = -1


/**
 * Method that return the generating function of a scenario, extrapolating from a given an array of days as reference and their corresponding currency values. 
 * The intermideate days are approximatly linearly. Days after the max reference day are computed using the average slope of the reference values.
 * @param {Array<number>} days - Array with numbers respesenting the days of the objective scenario 
 * @param {Array<number>} values - Array with numbers respeenting the value of the currency at the corresponding day
 * @param {boolean} [fixed=false] - Boolean indicating if the scenario is completily fixed or if should be adjusted to the current spot and the value given on day 0
 * @param {boolean} [useSpot=false] - If the SPOT value should be used for the zero day value
 * @returns {Function} Funciton that receives the current SPOT and the target day, to return the scenario's value on that day.
 */
export const getGeneratorScenarioFunctionByReference = (days, values, fixed = false, useSpot = false) =>
{

    // 

    // Extracts max day
    const max_day =  days[days.length -1]
    const min_day = days[0]

    if(!fixed && min_day !== 0)
        throw new Error('If scenario is not fixed, the first day has to be 0')

    // Checks is use Spot
    if(useSpot && min_day !== 0)
    {
        days = [0].concat(days)
        values = [3500].concat(values)
    }

    // Extracts the average of growth to use it when projecting the scenario indefinetly
    // Jump in days
    const jumps = days.map((_, i) => days[Math.min(i + 1, days.length - 1)] - days[i])
    jumps.pop()
    
    // slopes
    const slopes = jumps.map((_, i) => (values[i+1] - values[i])/jumps[i])

    // Extracts average growth
    // Weighted average
    let avg = jumps.map((_, i) => jumps[i]*slopes[i]).reduce((a,b) => a+b)/jumps.reduce((a,b) => a+b) 


    // Creates generating function
    let fun = (spot, d) => {

        let shift = fixed ? 0 : spot - values[0]

        if(useSpot)
        {
            shift = 0
            values[0] = spot
        }

        // Max day exceeded. 
        // Uses average
        if(d >= max_day)
            return(avg*(d-max_day) + values[values.length-1] + shift)
        
        // Before min day
        if(d < min_day)
            return(d*(values[0] - spot)/min_day + spot)

        // Finds the construction day inmidialtly before the given one
        let x = days.filter((di) => di <= d).pop()
        let i = days.indexOf(x)
        return( slopes[i]*(d-x) + values[i] +  shift)
    }


    return(fun)    

}


/**
 * Method that return the generating function of a scenario, extrapolating from a given an array values as reference, respresenting the monthly currency value. 
 * The intermideate days are approximatly linearly. Days after the max reference day are computed using the average slope of the reference values.
 * @param {Array<number>} values - Array with numbers respeenting the value of the currency at the corresponding months.
 * @param {boolean} [fixed=false] - Boolean indicating if the scenario is completly fixed or if should be adjusted to the current spot and the value given on day 0
 * @param {boolean} [useSpot=false] - If the SPOT value should be used for the zero day value
 * @returns {Function} Funciton that receives the current SPOT and the target day, to return the scenario's value on that day.
 */
 export const getGeneratorScenarioFunctionByMonthlyValuesReference = (values, fixed = false, useSpot = false) =>
 {

    let days = Array(values.length).fill(1).map((_,i) => i*con.DAYS_IN_MONTH) 
    return(getGeneratorScenarioFunctionByReference(days, values, fixed, useSpot))
 }


/**
 * Method that return the generating function of the var scenario, given the market's volatility, confidence level and direction.
 * @param {number} volatility - Market's volatility
 * @param {number} confidenceLevel - The scenario confidence level 
 * @param {1 |-1} direction - the direction of the scenario, either 1 (UP) or -1 (Down)
 * @returns {Function} Funciton that receives the current SPOT and the target day, to return the scenario's value on that day.
 */
 export const getGeneratorScenarioFunctionByVolatility = (volatility, 
                                                          confidenceLevel = 0.95,
                                                          direction ) =>
 {
    
    // Percentile
    const percentile = jStat.normal.inv(confidenceLevel, 0, 1 )
    


    // Creates generating function
    let fun = (spot , d ) =>
    {   
        return(spot*(1 + direction*(percentile*volatility*Math.sqrt(d/con.DAYS_IN_YEAR))))
    }
    
    return(fun)
 }


/**
 * Method that return the generating function of the var scenario, given the Spot, volatility, confidence level and direction.
 */
 export const getGeneratorScenarioFunctionBySimulation = (volatility,
                                                          oportunisticPercentage,
                                                          bands, 
                                                          confidence_level = 0.95,
                                                          direction) =>
{

    
    //TODO
}




/**
 * Method that return the generating function of the percentile scenario
 * @param {number} percentage - The scenario's percentage 
 * @param {string} clienType - The client's type 
 * @returns {Function} Function that receives the current SPOT and the target day, to return the scenario's value on that day.
 */
 export const getGeneratorScenarioFunctionByPercentage = (percentage , 
                                                          clienType ) =>
{

    // Converts direction
    let direction =  clienType === con.IMPORTER ? 1 : -1

    // Day Change
    const day_change = (1 + direction*percentage)**(1/con.DAYS_IN_YEAR)
    
     
    // Creates generating function
    let fun = (spot, d) =>
    {
        return(spot*(day_change**d))
    }

    return(fun)
}


/**
 * Method that extends or shortens the given monthly values to the given term. 
 * If term is shorter will simply remove trailing values and if term is longer will interpolate using the monthly values as reference 
 * @param {Array<number>} monthlyValues - Monthly values array
 * @param {number} newTerm - The new term (i.e the lenght of the montly values array)
 * @returns {Array<number>} Adjusted monthly values
 */
export const AdjustMonthlyValuesToTerm = (monthlyValues, newTerm) =>
{
    let days = Array(monthlyValues.length).fill(1).map((_,i) => i*con.DAYS_IN_MONTH) 
    let genFun = getGeneratorScenarioFunctionByReference(days , monthlyValues, true)

    return(Array(newTerm+1).fill(1).map((_,i) => genFun(monthlyValues[0], i*con.DAYS_IN_MONTH)))

}

/**
 * 
 * @param {function} genFun - The generating function to extract the anual change
 * @returns {float} Anual porcentual change 
 */
export const getAnualChangeByGeneratingFunction = (spot, genFun) =>
{
    return(genFun(spot, con.DAYS_IN_YEAR)/spot - 1)

}