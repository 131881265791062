// TODO - Documentar
import React, { useEffect } from 'react'
import {  NEEDLE_PERCENTAGE, NUMBER_OF_SEGMENTS, SEGMENT_IDS, SEGMENT_LABELS, SELECTED_SEGMENT_ID } from '../../templates/charts/ChartConstants'
import * as con from "../../GlobalConstants"
import { RefOrionRealTimeValueHook } from '../hooks/MarketValuesHook'
import LoadingIcon from '../../templates/loading/LoadingIcon'
import { SpeedometerChartHook } from '../../templates/charts/hooks/SpeedometerChartHook'
import {  translateOrionSignal } from '../../utils/translateFunctions'
import { RefModelParameterHook } from '../hooks/ModelParameterHooks'
import { getSignal } from '../../utils/orionFunctions'


function OrionSpeedometerChart({chartParameters}) {


    RefModelParameterHook(con.CLIENT_TYPE)

    // Orion Parameters
    // -----------------
    const orionRealTimeValue = RefOrionRealTimeValueHook()

    
    // ContinuousBarAndMultipleLineChart   
    const [objectReference, chart] = SpeedometerChartHook({parameters : {...chartParameters}})

    
    useEffect(() => {

        if(orionRealTimeValue !== null)
        {
                // Adds historic Scenario
                let data = {
                    [NUMBER_OF_SEGMENTS] : 5,
                    [SEGMENT_IDS] : con.ORIONS_SIGNALS,
                    [SEGMENT_LABELS] : con.ORIONS_SIGNALS.map(translateOrionSignal),
                    [SELECTED_SEGMENT_ID] : getSignal(orionRealTimeValue[con.ORION_SCORE]/100),
                    [NEEDLE_PERCENTAGE] : Math.min(0.99,orionRealTimeValue[con.ORION_SCORE]/100)                                 
                }

                // Checks if structural change happened
                if(!chart.initialized)
                {
                    chart.initialize()
                    chart.build(data)
                }
                else
                {
                    chart.updateData(data)
                }
        }
  
            

    }, [orionRealTimeValue, chart])

    
    return (     
        <div>
            {
                orionRealTimeValue === null
                ? <div style={{marginTop : "46%"}}><LoadingIcon /> </div>
                : <svg ref={objectReference}/> 
            }
            

        </div>   
                   
    )
}


export default OrionSpeedometerChart
