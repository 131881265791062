// TODO - Documentar
import React, { useEffect, useState } from 'react'


// Global constants
import * as con from "../../GlobalConstants"
import { RefApplicationParameterHook, RefSPOT } from '../hooks/ApplicationParametersHooks'
import { setApplicationParameter } from '../../store/actions/applicationParameters'
import { updateSpot } from '../../utils/marketFunctions'
import NumberFormat from 'react-number-format'

import "./Spot.css"
import { parseMoney } from '../../utils/moneyFunctions'
import { Button, Checkbox } from '@blueprintjs/core'

function SimpleSpot() {


    // Current SPOT and Spot scheme        
    const spot = RefSPOT()
    const spotScheme = RefApplicationParameterHook(con.SPOT_SCHEME)
    const spotDirection = RefApplicationParameterHook(con.SPOT_DIRECTION)
    const lasUpdated = RefApplicationParameterHook(con.LAST_SPOT_UPDATE)

    const [justUpdated, setJustUpdated] = useState(false)

    const toggleSpotScheme = () =>
    {
        if(spotScheme === con.MANUAL)
        {
            updateSpot()
            setApplicationParameter(con.SPOT_SCHEME, con.AUTOMATIC)

        }
        else
            setApplicationParameter(con.SPOT_SCHEME, con.MANUAL)
                
    }

    useEffect(() => {
        
        setJustUpdated(true)
        setTimeout(() => {setJustUpdated(false)}, con.VALUE_CHANGE_TIMEOUT)
        
    }, [lasUpdated])

    return ( spotScheme === con.AUTOMATIC ? <p style={{display: "flex",
                                                       alignItems: "center",
                                                       color: justUpdated
                                                       ? spotDirection === con.NEUTRAL
                                                       ? "blue"
                                                       : spotDirection === con.UP
                                                       ? "green"
                                                       : "red"
                                                    : "var(--text-color-highlighted)"} }>
                                                <Button text="SPOT" icon="refresh" onClick={(e) => updateSpot()} />
                                                <div style={{margin: "0 10px"}}>{spot}</div>
                                                <Checkbox label="Manual" style={{margin:"0"}} onChange={() => toggleSpotScheme()}/></p>
                                         : <p style={{display:"flex", alignItems:"center"}}>
                                             <p style={{marginTop:"10px"}}> Spot Manual: </p>
                                                    <NumberFormat thousandSeparator={true} 
                                                                className="input-editing spot-input"
                                                                onFocus={e => e.target.select()} 
                                                                onClick={e => e.target.select()} 
                                                                onBlur={(ob) => (ob.target.value === "") ? setApplicationParameter(con.SPOT, spot) : setApplicationParameter(con.SPOT, parseMoney(ob.target.value))}                                                                 
                                                                value={spot}
                                                                style={{margin: "0 10px"}}/>
                                                        <Checkbox label="Manual" style={{margin:"0"}} onChange={() => toggleSpotScheme()}/>
                                                                                               
                                             </p>)
}

export default SimpleSpot
