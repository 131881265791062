// Functions for the Orion Model
import * as jStat from "jstat/dist/jstat";

import * as con from "./../GlobalConstants"
import { addDays, getToday } from "./dateFunctions";
import { quoteAssets } from "./marketFunctions";
import { translateOrionDaysAgoValue, translateOrionSignal } from "./translateFunctions";

// Gets the corresponding score
export const getScore = (estimated, realRate, mu, sigma) =>{

    // Computes difference
    let dif = (estimated/realRate) - 1;
    
    // Extract quantile
    let resp = jStat.normal.cdf(dif, mu, sigma)
    
    return(resp)

}


export const getSignal = (score) =>
{

    // Gets Orion Signal
    let i = con.ORIONS_SIGNALS_VALUES.findIndex((sig) => score <= sig)
    if(i === -1)
        i = con.ORIONS_SIGNALS.length-1

    return(con.ORIONS_SIGNALS[i])
}

export const getMinOrionSummaryDate = () =>
{
    return(addDays(getToday(), -1*con.ORION_SUMMARY_WINDOW_IN_DAYS))
}

export const constructGidTable = (clientType, exposureAmount, amountToBuy, stopLoss, initialLevel, finalLevel, spread) =>
{

    let level = stopLoss

    let response = []

    let accumulatedCoverage = null

    while(clientType === con.IMPORTER ? level >= finalLevel : level <= finalLevel)
    {
        // Level Amount
        let levelAmount = 0
        if(clientType === con.IMPORTER ? level < initialLevel : level > initialLevel)
            levelAmount = (clientType === con.IMPORTER ? 1 : -1)*amountToBuy/((initialLevel - finalLevel)/spread)
        else if(level === stopLoss)
            levelAmount = amountToBuy

        // Coverage
        const coveragePercentage = levelAmount/exposureAmount

        accumulatedCoverage = accumulatedCoverage === null ? 0 : accumulatedCoverage + coveragePercentage

        response.push({
            [con.LEVEL] : level,
            [con.AMOUNT] : levelAmount,
            [con.COVERAGE_PERCENTAGE] : coveragePercentage,
            [con.COVERAGE_PERCENTAGE_ACCUMULATED] : accumulatedCoverage,
        })

        level = clientType === con.IMPORTER ? level - spread : level + spread
    }

    return(response)

}


export const buildHistoricTable = (orionRealTimeValue, orionSummary, daysBack = [10,20]) =>
{
    const noValue = "---" 

    // Adds the mos current one in case its missing
    if(!daysBack.includes(1))
        daysBack = [1].concat(daysBack)

    let selectedValues = daysBack.map((d) => orionSummary[orionSummary.length - d])

    // Sets to current
    if(orionRealTimeValue !== null)
        selectedValues[0] = {...selectedValues[0], ...orionRealTimeValue, [con.ORION_SCORE] : Math.min(99.99, orionRealTimeValue[con.ORION_SCORE])}
    else
        selectedValues[0] = {...selectedValues[0], ...{[con.ORION_ESTIMATED_RATE] : noValue, [con.ORION_SCORE] : noValue }}

    // Adds Signal
    selectedValues = selectedValues.map((ob,i) => {return({...ob, [con.DATE] : translateOrionDaysAgoValue(daysBack[i]), [con.ORION_SCORE] : ob[con.ORION_SCORE] === noValue ? noValue : ob[con.ORION_SCORE]/100, [con.ORION_SIGNAL] : ob[con.ORION_SCORE] === noValue ? noValue : translateOrionSignal(getSignal(ob[con.ORION_SCORE]/100))})})



    return(selectedValues)

}


export const parseBetasFromReport = (report) =>
{
    try {
        return JSON.parse(report);
      } catch (error) {
        console.error('Error parsing Betas:', error);
        return null;
      }
}

export const processCustomCustomBetas = async (beta) =>
{
    switch (beta) {
        case con.CUSTOM_BETA_SPREAD:
            const [US10Y, CO10Y] = await quoteAssets([con.US10Y_ASSET , con.CO10Y_ASSET])
            return (((1+(CO10Y/100))/(1+(US10Y/100)))-1)*100
    
        default:
            console.warn(`No support for custom Orion beta: ${beta}`)
            return(null);
    }
}

