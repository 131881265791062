// TODO - Documentar
import React from 'react'
import { USABLE_SCREEN_SIZE } from '../GlobalConstants'
import "./Pages.css"
import ReportMenu from '../components/reports/ReportMenu'

function ReportsPage() {
    return (
        <div style={{height : USABLE_SCREEN_SIZE + "vh"}}>
            <ReportMenu/>
        </div>
    )
}

export default ReportsPage
