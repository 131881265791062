import { ProgressBar } from '@blueprintjs/core'
import React from 'react'

function CustomProgressBar({value, stripes = false}) {
  return (
    <div  className='progressBar'>
        <ProgressBar value={value} stripes={stripes}/>
    </div>
  )
}

export default CustomProgressBar