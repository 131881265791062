// Global functions for charts
import * as d3 from "d3";



// Generic return object for chart manipulation
export const genericActions = {initialize : () => true,
  updateData : (newData) => newData,
   setComputing : (bool) => bool,
}

// Global parameters
export const FONT_MULTIPLIER = 8.2

export const wrap = (text, width) => {
    text.each(function() {
      var text = d3.select(this),
          words = text.text().split(/\s+/).reverse(),
          word,
          line = [],
          lineNumber = 0,
          lineHeight = 1.1, // ems
          y = text.attr("y"),
          dy = parseFloat(text.attr("dy")),
          tspan = text.text(null).append("tspan").attr("x", 0).attr("y", y).attr("dy", dy + "em")
      
      word = words.pop()
      while (word) {
        line.push(word)
        tspan.text(line.join(" "))
  
        if (FONT_MULTIPLIER*line.join(" ").length > width) {
          line.pop()
          tspan.text(line.join(" "))
          line = [word]
          tspan = text.append("tspan").attr("x", 0).attr("y", y).attr("dy", `${++lineNumber * lineHeight + dy}em`).text(word)
        }

        word = words.pop()
      }
    })
  }



export const getDefaultColorScheme = (numElements) =>
{
  return(d3.scaleOrdinal().domain([0,numElements])
                          .range(d3.schemeSet1))
}