import { Button, Text } from '@blueprintjs/core'
// TODO - Documentar
import React, { useEffect, useState } from 'react'
import * as con from "../../GlobalConstants"
import { finalPage, firstPage, nextPage, previousPage } from '../../store/actions/applicationParameters'
import { getTotalTransactionPages } from '../../utils/transactionFunctions'
import { RefApplicationParameterHook } from '../hooks/ApplicationParametersHooks'



function TransactionTableNavegation({ forcedSelectedTransaction }) {

    const selectedTransactions = RefApplicationParameterHook(con.SELECTED_TRANSACTIONS)
    const currentNavegationPage = RefApplicationParameterHook(con.CURRENT_TRANSACTION_NAVIGATION_PAGE)
    const totalTransactionsBeingDisplayed = RefApplicationParameterHook(con.TOTAL_TRANSACTIONS_DISPLAYED)


    // Local Transactions
    const [localSelectedTransactions, setLocalSelectedTransactions] = useState(() => forcedSelectedTransaction !== null && forcedSelectedTransaction !== undefined ? forcedSelectedTransaction : selectedTransactions)
    
    useEffect(() => {
      
      if(forcedSelectedTransaction === null || forcedSelectedTransaction === undefined)
        setLocalSelectedTransactions(selectedTransactions)


    }, [selectedTransactions, forcedSelectedTransaction])
    

    // Loading
    // Transactions Loaded
    const transStatus = RefApplicationParameterHook(con.TRANSACTIONS_LOADED)
    // Loading
    const loading = RefApplicationParameterHook(con.LOADING_CURRENT_TRANSACTIONS)


  return (  
    <div>
      { getTotalPages(localSelectedTransactions, totalTransactionsBeingDisplayed) > 0 &&
        transStatus[localSelectedTransactions][con.STATUS] === con.OK &&
        !loading &&

      <div className="line-arrangement">      
          <Button icon={"double-chevron-left"} minimal={true} onClick={ ()=> firstPage(localSelectedTransactions)}/>
          <Button icon={"chevron-left"} minimal={true} onClick={ ()=> previousPage(localSelectedTransactions)}/>
          <Text>Pagina {currentNavegationPage[localSelectedTransactions] + 1} de {getTotalPages(localSelectedTransactions, totalTransactionsBeingDisplayed) + 1}</Text>
          <Button icon={"chevron-right"} minimal={true} onClick={ ()=> nextPage(localSelectedTransactions)}/>
          <Button icon={"double-chevron-right"} minimal={true} onClick={ ()=> finalPage(localSelectedTransactions)}/>  
      </div>
    }
    </div>
  )
}

TransactionTableNavegation.defaultProps = {
  forcedSelectedTransaction : null
}

export default TransactionTableNavegation

const getTotalPages = (selectedTransactions, totalTransactions) =>
{
    return(getTotalTransactionPages(totalTransactions[selectedTransactions]))
}