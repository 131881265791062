// TODO - Documentar
import React, { useMemo, useState } from 'react';
import { FaCheck,  FaTimes } from 'react-icons/fa';
import * as con from "../../GlobalConstants"


import { GenericCellFromRow } from './TransactionCells';

// Style
import "../../templates/Templates.css"
import "../../templates/tables/Table.css"
import { StateApplicationParameterHook } from '../hooks/ApplicationParametersHooks';
import { buildDefaultRow } from '../../utils/transactionFunctions';
import { isValueValid } from '../../templates/Types';

function RowAdditionElement({columnArray,addRow}) {

    // Parameter for adding new row
    const [addingNewRow, setAddingNewRow] = StateApplicationParameterHook(con.ADDING_NEW_ROW)

    // Add
    const addRowLocal = () =>
    {
      // Extracts values
      let newRow = {...initialNewRowValues}

      // Only sets if valid
      Object.keys(initialNewRowValues).forEach( k =>
        {

          let col = columnArray.find((col) => col[con.ID] === k)
          if(isValueValid(col[con.TYPE], newRowValues[k], col[con.VALUE_CHECKER]))
             newRow[k] =  newRowValues[k]

        })


      addRow(newRow)
      setNewRowValues(initialNewRowValues)
  
    }
    
    // Initial values for the Popup scheme
    const initialNewRowValues = useMemo(() =>  buildDefaultRow(columnArray),[columnArray])
          
  
    // New Row Values
    const [newRowValues, setNewRowValues] = useState(() => initialNewRowValues)
  
  
      
      return (
        <div>
          {addingNewRow &&   <div className="contenedor-tabla">                        
                                <div className="flex-tabla">
                                    <div className="flex-fila header-row">
                                        {  
                                            // Iterates over values
                                            Object.values(columnArray).map((col) =>                 
                                            <div key={col[con.ID]} className="flex-celda">{col.name}</div>
                                            )
                                            
                                        }
                                                              
                                      <div className="flex-celda">Opciones</div>                                         
                                        
                                        
                                              
                                    </div>                  
                                      <div className="flex-fila">                                        
                                        { Object.values(columnArray).map((col) => 
                                            <GenericCellFromRow {...col}
                                                              key={`newRow-${col[con.ID]}`} 
                                                              cellType={col[con.TYPE]} 
                                                              idCol={col[con.ID]}                                  
                                                              initialValue={newRowValues[col[con.ID]]}
                                                              setValueLocalRow={(val) => setNewRowValues({...newRowValues, [col[con.ID]] : val})} 
                                                              editing={true}
                                                              />) }
                                          
                                          <div className="flex-celda comandos command-cell-editing">
                                              <FaCheck style={{cursor: "pointer"}}  onClick={() => {addRowLocal();
                                                                                                        setAddingNewRow(false)}}/>
                                              <FaTimes style={{cursor: "pointer"}} onClick={() => {setNewRowValues(initialNewRowValues);
                                                                                                        setAddingNewRow(false)}}/>
                                            </div>                                                                                                     
                                      </div>
                                    
                                </div>
                                
                          </div>}
        </div>
                                      
      )
}

export default RowAdditionElement

