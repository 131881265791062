// TODO - Documentar
import React from 'react'
import { Radio, RadioGroup, Text } from '@blueprintjs/core'
import * as con from "../../GlobalConstants"

import { RefUserAttributesHook } from '../hooks/UserAttributesHook'
import { RefNestedApplicationParameterHook } from '../hooks/ApplicationParametersHooks'
import LoadingIconSmall from '../../templates/loading/LoadingIconSmall'
import { setMainAdvisor } from '../../store/actions/advisors'
import { IsStaff } from '../hooks/AuthenticationHook'

function AdvisorRadio({advisorId}) {

    // Main advisor ID 
    const mainAdvisorId = RefUserAttributesHook(con.MAIN_ADVISOR)

    // Status
    const status = RefNestedApplicationParameterHook(con.MAIN_ADVISOR_SAVED, con.STATUS)

    // Is Staff
    const isStaff = IsStaff() 
    return (
        <div style={{marginTop : "3px"}}>
            {
                isStaff ? 
                status === con.LOADING ?
                    <div className='smallParameterBox'>
                        <LoadingIconSmall />
                    </div>:     
                <RadioGroup selectedValue={mainAdvisorId}>
                    <Radio label='Asesor Princial' value={advisorId} onClick={()=> mainAdvisorId !== advisorId ? setMainAdvisor(advisorId): true}/>
                </RadioGroup> 
                : mainAdvisorId === advisorId ? <Text>Asesor Principal</Text> : <div></div>
            }
        </div>

    )
}

export default AdvisorRadio