// TODO - Documentar
import React from 'react';

// NumberFormat
import NumberFormat from "react-number-format";

// Global Functions
import * as funGlobales from "../GlobalFunctions"
import * as dateFun from "../utils/dateFunctions"
import * as con  from "../GlobalConstants"
import { Icon } from '@blueprintjs/core';



export const getDefaultValue = (type) =>
{


    if(type === con.TEXT)
        return("")
    
    if(type === con.NUMERIC)
        return(0)

    if(type === con.MONEY || type === con.MONEY_MILLIONS)
        return(0)        
    
    if(type === con.DATE)
        return(dateFun.formatDate(dateFun.getTomorrow()))

    if(type === con.DATE_TIME)
        return(dateFun.formatDateAndTime(dateFun.getToday()))

    if(type === con.ACTION_TYPE)
        return(() => true)

    return("")
}


export const formatAccordingToType = (type, value, otherParameters) =>
{

    let sufijo = ""

    if(value === con.TOTAL)
        return(con.TOTAL)


    switch(type){
        
        case con.TEXT:
            return(value)
        case con.DATE:
        
            return(dateFun.formatDateForUser(dateFun.parseDate(value)))
        
        case con.DATE_TIME:
        
            return(dateFun.formatDateAndTimeForUser(dateFun.parseDate(value)))
            
        case con.NUMERIC:
            return(funGlobales.round(value))
        
        case con.MONEY:                

            if(otherParameters[con.CURRENCY] === con.MONEY_COP)
                sufijo = con.SUFFIX_COLOMBIAN_PESOS
            if(otherParameters[con.CURRENCY] === con.MONEY_USD)
                sufijo = con.SUFFIX_DOLLARS

            let denominator = 1
            if(otherParameters[con.DENOMINATOR] !== undefined)
                denominator = otherParameters[con.DENOMINATOR]

            value = value / denominator

            return(<NumberFormat value={otherParameters.allowDecimals ? value : funGlobales.round(value)} thousandSeparator={true} displayType={'text'} suffix={sufijo} decimalScale={otherParameters.allowDecimals ? 2 : 0} fixedDecimalScale={otherParameters.allowDecimals === true}/>)
        
        case con.MONEY_THOUSANDS:
        
          
            if(otherParameters[con.CURRENCY] === con.MONEY_COP)
                sufijo = ` ${con.THOUSANDS_TEXT} ${con.SUFFIX_COLOMBIAN_PESOS}`
            if(otherParameters[con.CURRENCY] === con.MONEY_USD)
                sufijo =` ${con.THOUSANDS_TEXT} ${con.SUFFIX_DOLLARS}`

            return(<NumberFormat value={value/con.THOUSAND} thousandSeparator={true} displayType={'text'} suffix={sufijo} decimalScale={2} fixedDecimalScale={true}/>)
        
        case con.MONEY_MILLIONS:
        

            if(otherParameters[con.CURRENCY] === con.MONEY_COP)
                sufijo = ` ${con.MILLIONS_TEXT} ${con.SUFFIX_COLOMBIAN_PESOS}`
            if(otherParameters[con.CURRENCY] === con.MONEY_USD)
                sufijo =` ${con.MILLIONS_TEXT} ${con.SUFFIX_DOLLARS}`

            return(<NumberFormat value={value/con.MILLION} thousandSeparator={true} displayType={'text'} suffix={sufijo} decimalScale={2} fixedDecimalScale={true}/>)
            
        case con.PERCENTAGE:
        
            return(<NumberFormat value={funGlobales.round(100*value,2)} thousandSeparator={true} displayType={'text'} suffix={con.SUFFIX_PERCENTAGE} />)
            
        case con.MULTIPLE_SELECTION:
            return(otherParameters[con.VALUE_TRANSLATOR] !== undefined ? otherParameters[con.VALUE_TRANSLATOR](value) : value)
        
        case con.ACTION_TYPE:
            return(<Icon icon={otherParameters[con.ICON]} onClick={value}/>)
        default:
        
            console.warn(`No support for formting the value: ${value} of type: ${type}`)
            return(value)
    }
    
    
}


export const getClassByType = (tipo, valor, otros_parametros) =>
{
    if(tipo === con.TEXT)
        if(String(valor).includes("Fuera Banda"))
            return("warning-cell")
    
    return("")
    
}


export const isValueValid = (type, value, customValidation) =>
{

    if(type === con.NUMERIC)
    {
        if(!funGlobales.isNumeric(value))
            return false

    }
        
    if(type === con.MONEY || type === con.MONEY_MILLIONS)
    {
        if(!funGlobales.isNumeric(value))
            return false
    }       
    
    if(type === con.DATE || type === con.DATE_TIME)
    {
        if(!dateFun.isStingValidForDate(value))
            return false

    }

    if(customValidation === null || customValidation === undefined)
        return true

    return customValidation(value)

}