
import { useEffect, useState } from 'react'
import { RefApplicationParameterHook } from '../hooks/ApplicationParametersHooks'

import * as con from "../../GlobalConstants"
import TransactionTableVisualizer from '../transactions/TransactionTableVisualizer'
import { TransactionTable } from '../transactionTable/TransactionTable'
import { CoverageOption, getTransactionsAnnotationFunction } from '../../store/actions/transactions'
import { allOptionCoverageColumns } from '../../utils/transactionFunctions'
import { IsStaff } from '../hooks/AuthenticationHook'
import TransactionTableNavegation from '../transactions/TransactionTableNavegation'
import { EDIT_COMMAND, REMOVE_COMMAND, VIEW_ANNOTATIONS_COMMAND } from '../transactionTable/TransactionTableConstants'


// User Columns
const selectedUserColumns = [{ [con.ID] : con.OPENING_DATE},
                            { [con.ID] : con.EXPIRATION_DATE},
                            { [con.ID] : con.AMOUNT},
                            { [con.ID] : con.OPENING_SPOT},
                            { [con.ID] : con.STRIKE},
                            { [con.ID] : con.PREMIUM},
                            { [con.ID] : con.OPTION_TYPE},
                            { [con.ID] : con.COVERAGE_TYPE},
                            { [con.ID] : con.COUNTERPARTY},                      
                            { [con.ID] : con.ASSESSMENT},
                            { [con.ID] : con.TOTAL_ASSESSMENT}]    

// Staff Columns
const selectedStaffColumns = [{ [con.ID] : con.EXPIRATION_DATE},
                            { [con.ID] : con.AMOUNT},
                            { [con.ID] : con.OPENING_SPOT},
                            { [con.ID] : con.STRIKE},
                            { [con.ID] : con.PREMIUM},                      
                            { [con.ID] : con.ASSESSMENT_RATE},   
                            { [con.ID] : con.OPTION_TYPE},                      
                            { [con.ID] : con.COVERAGE_TYPE},
                            { [con.ID] : con.EXPIRY_DAYS},
                            { [con.ID] : con.FOREIGN_RATE},
                            { [con.ID] : con.DISCOUNT_RATE},                            
                            { [con.ID] : con.DELTA},
                            { [con.ID] : con.VOLATILITY_CURVE},   
                            { [con.ID] : con.ASSESSMENT},
                            { [con.ID] : con.TOTAL_ASSESSMENT}]    

function OptionAssessmentTable({assessedOptionDictionary, includeCommands = true, includedColumns = selectedUserColumns}) {

  // Is Staff and Hidden Columns
  const isStaff = IsStaff()
  const showHiddenColumns = RefApplicationParameterHook(con.OPTION_ASSESSMENT_SHOW_HIDDEN_COLUMNS)

  const [selectedColumns, setSelectedColumns] = useState(() => includedColumns)

  // Option Filter Function
  const option_assessment_filter_function = RefApplicationParameterHook(con.OPTION_ASSESSMENT_FILTER_FUN)


  useEffect(() => {
    
    if(isStaff && showHiddenColumns)
      setSelectedColumns(selectedStaffColumns)
    else
      setSelectedColumns(includedColumns)

  }, [isStaff, showHiddenColumns, includedColumns])
  

  return (

    <div>
        <div>
        <h3>Coberturas</h3>
        <TransactionTableNavegation forcedSelectedTransaction={con.COVERAGES_OPTION} />
      </div>
 
      <TransactionTableVisualizer transactionType={con.COVERAGES_OPTION}>           
                  <TransactionTable transactionType={con.COVERAGES_OPTION}
                                    columnArray={selectedColumns.map(col => allOptionCoverageColumns[col[con.ID]])}
                                    dataDictionary={assessedOptionDictionary} 
                                    setRow={(id, vals) => CoverageOption.set(id, vals)}                                   
                                    deleteRow={(id) => CoverageOption.delete(id)}
                                    getAnnotations={getTransactionsAnnotationFunction(con.COVERAGES_OPTION)}
                                    includeAddition={false}
                                    includeLastEdited={false}
                                    commands={includeCommands ?  [EDIT_COMMAND, REMOVE_COMMAND, VIEW_ANNOTATIONS_COMMAND] : false}
                                    filterFunction={option_assessment_filter_function}
                                    noResultsWhenFilteredMessage="No hay coberturas activas para la fecha seleccionada."/>
      </TransactionTableVisualizer>     
    </div>   

  )
}


export default OptionAssessmentTable