// TODO - Documentar
import React from 'react'
import {  Text } from '@blueprintjs/core'
import { FaTrashAlt } from 'react-icons/fa'
import * as con from "../../GlobalConstants"
import { deleteAdvisor } from '../../store/actions/advisors'
import { contactAdvisor } from '../../utils/emailFunctions'
import { formatPhoneNumber } from '../../utils/translateFunctions'
import { buildWhatsAppContactFunction } from '../../utils/whatsAppFunctions'
import logo from "./../../img/whatsapp_logo.png"
import { IsStaff } from '../hooks/AuthenticationHook'
import AdvisorRadio from './AdvisorRadio'


function Advisor({advisor}) {

    // Is Staff
  const isStaff = IsStaff()


  return (
    <div>
        <h3 style={{marginBottom : "3px"}}>{advisor[con.NAME]} {isStaff && <FaTrashAlt style={{cursor: "pointer", marginLeft : "5px"}}
                                                                                                        onClick={() => deleteAdvisor(advisor[con.ID])}
                                                                                                        title="Eliminar"/>   }</h3> 
        <Text className="active" onClick={buildWhatsAppContactFunction(advisor[con.PHONE_NUMBER])}> <img src={logo} alt="Logo" width="auto" height={12}/> <strong>Telefono: </strong>{formatPhoneNumber(advisor[con.PHONE_NUMBER])}</Text>
        <Text className="active" onClick={() => contactAdvisor(advisor[con.EMAIL])}><strong>Email: </strong>{advisor[con.EMAIL]}</Text>
        <AdvisorRadio advisorId={advisor[con.ID]} />
        </div>
  )
}


export default Advisor