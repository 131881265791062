// TODO - Documentar
import React from 'react'
import ThreeDots from 'react-loading-icons/dist/components/three-dots'

function LoadingIcon() {
    return (
        <div>
            <ThreeDots speed={0.6}/>
        </div>
    )
}


export default LoadingIcon
