import { useRef, useEffect } from "react";

/**
 * Hook that alerts clicks outside of the passed ref
 */
 export const ClickOutsideAction = (action, regex = null) => {

    // Component ref
    const ref = useRef(null)

    useEffect(() => {

        function handleClickOutside(event) {
            
            

            if(ref.current && 
                !ref.current.contains(event.target))
                {              
                   
                    let ob = event.target
                    while(ob && ob.className === "")                    
                        ob = ob.parentNode
                                                                
                    // Checks regex
                    if(regex === null || (!regex.test(ob.className) 
                    && (!ob.className instanceof SVGAnimatedString || !regex.test(ob.className.baseVal) ) ))
                        action()
                       
                  
                }
        }

        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [ref, action, regex]);


    return ref
}