import React, { useState, useEffect } from 'react';
import "../../templates/Templates.css";
import "../../templates/tables/Table.css";
import "../ComponentsGlobalStyle.css";
import SecondLeftRow from './SecondLeftRow';
import { useSelector } from 'react-redux';
import { formatAccordingToType } from '../../templates/Types';
import * as con from '../../GlobalConstants';

function SecondLeftTable({ data }) {
    const spot = useSelector(state => state['store']['applicationParameters']['spot']);
    const [styles, setStyles] = useState({ fontSize: '12px', padding: '5px 10px' });
    const [isSmallScreen, setIsSmallScreen] = useState(false);

    const adjustStyles = () => {
        const width = window.innerWidth;
        const newStyles = {
            fontSize: width < 400 ? '9px' : width < 768 ? '11px' : '14px',
            padding: width < 400 ? '3px 5px' : width < 768 ? '4px 8px' : '5px 10px',
        };
        setStyles(newStyles);
        setIsSmallScreen(width < 768);
    };

    useEffect(() => {
        adjustStyles();
        window.addEventListener('resize', adjustStyles);
        return () => window.removeEventListener('resize', adjustStyles);
    }, []);

    const safeCalculation = (fn) => {
        try {
            const result = fn();
            return isNaN(result) || result === Infinity || result === -Infinity ? '-' : result;
        } catch (error) {
            return '-';
        }
    };

    const cierreAnterior = data.cierre_anterior;
    const primerRegistroSemana = data.primer_registro_semana;
    const apertura = data.apertura;

    const diffCierreAnterior = data.ultimo === 0 ? 0 : safeCalculation(() => (spot - cierreAnterior).toFixed(2));
    const volatilidadJornada = safeCalculation(() => spot - apertura);
    const variacionSemanal = safeCalculation(() => spot - primerRegistroSemana);
    const variacionSemanalPorcentaje = safeCalculation(() => ((spot - primerRegistroSemana) / primerRegistroSemana) * 100);

    return (
        <div className="contenedor-tabla" style={{ fontSize: styles.fontSize, padding: '0 10px' }}>
            <div className="flex-tabla">
                <div className="flex-fila header-row" style={{ padding: styles.padding }}>
                    {!isSmallScreen && <div className="flex-celda">CIERRE ANTERIOR</div>}
                    {!isSmallScreen && <div className="flex-celda">DIF. CIERRE ANTERIOR</div>}
                    <div className="flex-celda">TRM(HOY)</div>
                    <div className="flex-celda">VOLATILIDAD JORNADA</div>
                    <div className="flex-celda">VARIACIÓN SEMANAL</div>
                    <div className="flex-celda">INDICADOR AGOTAMIENTO</div>
                </div>
            </div>

            <div className="flex-tabla">
                <SecondLeftRow 
                    data={{
                        cierreAnterior: formatAccordingToType(con.MONEY,cierreAnterior, { allowDecimals: true }) || '-',
                        diffCierreAnterior: diffCierreAnterior !== '-' ? diffCierreAnterior : '-',
                        trmReal: data.trm_real ? formatAccordingToType(con.MONEY, data.trm_real, { allowDecimals: true }) : '-',
                        volatilidadJornada: volatilidadJornada !== '-' ? formatAccordingToType(con.MONEY, volatilidadJornada, { allowDecimals: true }) : '-',
                        variacionSemanal: variacionSemanal !== '-' ? variacionSemanal : '-',
                        variacionSemanalPorcentaje: variacionSemanalPorcentaje !== '-' ? variacionSemanalPorcentaje : '-',
                        indicadorAgotamiento: data.indicador_agotamiento || '-'
                    }}
                    styles={styles}
                    isSmallScreen={isSmallScreen}
                />
            </div>
        </div>
    );
}

export default SecondLeftTable;
