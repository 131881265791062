// Module for annotations

// TODO - Documentar
import React from 'react'


import '../../App.css'
import {StaticTable} from '../../templates/tables/StaticTable'
import { DATE_TIME, TEXT, ID, NAME, TYPE } from '../../GlobalConstants'

export default function Annotations({annotations}) {
    
    const columns = [{
        [NAME] : 'Fecha', 
        [TYPE] : DATE_TIME,                         
        [ID] : 'date'
      },{
        [NAME] : 'Anotación', 
        [TYPE] : TEXT,                         
        [ID] : 'text'
      }]

    return (
        <div>              
            <h2 style={{color : "var(--text-color)"}}>Anotaciones</h2>
            {annotations.length > 0 ? <StaticTable columnArray={columns} dataDictionary={Object.assign({}, ...annotations.map((x,i) => ({[x[ID]]: x})))}/>
                                   : <p>El registro no tiene anotaciones</p>}
        </div>
    )
}
