import { openInNewTab } from "../GlobalFunctions"

// Whats App Functions

/**
 * Construct the corresponding WA desktop/web link
 * @param {string} phoneNumber - Phone number in its international WA format 
 * @returns {string} Complete WA Link
 */
export const constructWhatsAppLink = (phoneNumber )  => 
{
    return(`https://wa.me/${phoneNumber}`)
} 

/**
 * Constructs the function that opens the WA appliaction and contacts the given phone number
 * @param {string} phoneNumber - Phone number in its international WA format
 * @returns {function} Parameterless function 
 */
export const buildWhatsAppContactFunction = (phoneNumber)  =>
{    
    return(()  => openInNewTab(constructWhatsAppLink(phoneNumber)))
}