//Component for transaction manipulation
// TODO - Documentar
import React from 'react'

import * as con from "../../GlobalConstants"



// Style
import "../ComponentsGlobalStyle.css"
import { RefApplicationParameterHook } from '../hooks/ApplicationParametersHooks'
import TableExposures from './TableExposures'
import TableCoveragesFwd from './TableCoveragesFwd'
import TableCoveragesSpot from './TableCoveragesSpot'
import TableAccounts from './TableAccounts'
import TableCoveragesOption from './TableCoveragesOption'

function Transactions() {
    
    const selectedTransactions = RefApplicationParameterHook(con.SELECTED_TRANSACTIONS)
    

    return (
        <div>
          <div>
           {
             selectedTransactions === con.EXPOSURES ? 
              <TableExposures  /> :
              
             selectedTransactions === con.COVERAGES_FWD ? 
              <TableCoveragesFwd /> :
            
              selectedTransactions === con.COVERAGES_SPOT ? 
                <TableCoveragesSpot />:

              selectedTransactions === con.COVERAGES_OPTION ? 
              <TableCoveragesOption />:
                
                selectedTransactions === con.ACCOUNTS ? 
                  <TableAccounts /> :
                  <div></div>
              }
          </div>
            
        </div>
    )
}

export default Transactions
